import {
  COLOR_COMMUNITY,
  COLOR_GAME,
  COLOR_NEWS,
  COLOR_STORE,
  COLOR_TV
} from "constants/color";
import { SITE_LANGUAGES } from "constants/siteLanguages";
import { sports } from "constants/sport";

// Set language from URL
const langMatch = location.pathname.match(/[a-z]{2}/);
if (langMatch && SITE_LANGUAGES.includes(langMatch[0])) {
  window.localStorage.setItem("siteLangCode", langMatch[0]);
}

// Language from navigator
const userLang = String(navigator.language || navigator.userLanguage).slice(0, 2);

const defaultSportSlug = (lang, sportId) => {
  const sport = sports.find(item => item.lang === lang && item.id === sportId);
  if (sport) {
    return sport.slug;
  }
  switch (lang) {
    case 'en':
      return 'all-cue-sports';
    case 'fr':
      return 'tous-les-billards';
    case 'nl':
      return 'alle-biljart';
    case 'es':
      return 'todos-los-billares'
    default:
      return 'all-cue-sports';
  }
}

export const siteLangCode = window.localStorage.getItem("siteLangCode") || (SITE_LANGUAGES.includes(userLang) ? userLang : "en");
export let siteSportId = Number(window.localStorage.getItem("siteSportId")) || 1;
export let siteSportSlug = window.localStorage.getItem("siteSportSlug") || defaultSportSlug(siteLangCode, siteSportId);

export const setSiteSportBySlug = (slug) => {
  const selectedSport = sports.find(item => item.slug === slug);
  if (selectedSport) {
    window.localStorage.setItem("siteSportSlug", selectedSport.slug);
    window.localStorage.setItem("siteSportId", selectedSport.id);
  }
};

export const setSiteSportById = (id) => {
  const selectedSport = sports.find(item => item.id === id && item.lang === siteLangCode);
  if (selectedSport) {
    window.localStorage.setItem("siteSportId", selectedSport.id);
    window.localStorage.setItem("siteSportSlug", selectedSport.slug);
  }
};

const colors = {
  news: COLOR_NEWS,
  tv: COLOR_TV,
  game: COLOR_GAME,
  community: COLOR_COMMUNITY,
  store: COLOR_STORE
};

const premiumPassLinkLegacyMap = {
  en: "https://www.kozoom.com/en/billiard-carom/premium-pass/",
  fr: "https://www.kozoom.com/fr/billard-carambole/premium-pass/",
  de: "https://www.kozoom.com/de/billard-karambol/premium-pass/",
  nl: "https://www.kozoom.com/nl/biljart-carambole/premium-pass/",
  es: "https://www.kozoom.com/es/billar-carambola/premium-pass/",
  ko: "https://www.kozoom.com/ko/5/premium-pass/",
  tr: "https://www.kozoom.com/tr/karambol-bilardo/premium-pass/",
  vi: "https://www.kozoom.com/en/billiard-carom/premium-pass/",
};

export const currentSite = (() => {
  if (window.location.hostname.match("news")) {
    return "news";
  }
  if (window.location.hostname.match("tv")) {
    return "tv";
  }
  if (window.location.hostname.match("game")) {
    return "game";
  }
  if (window.location.hostname.match(/^dev.kozoom.com|home.kozoom.com$/)) {
    return "global";
  }
  return process.env.REACT_APP_SITE || "global";
})();

export const majorColor = colors[currentSite] || "#02023c";

const storeDomain = window.location.hostname.match(/dev|localhost/) ? "https://staging-store.kozoom.com" : "https://store.kozoom.com";

export const premiumPassLink = premiumPassLinkLegacyMap[siteLangCode];
export const accountInfoLink = `${storeDomain}/${siteLangCode}/customer/account/edit/`;

