import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    top: 130,
  },
}));

export const ReviveVerticalBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <iframe
        id="aa73fdae"
        name="aa73fdae"
        src="https://adserver.kozoom.com/afr.php?zoneid=6705&amp;cb=%n&amp;ct0=%c"
        frameBorder="0"
        scrolling="no"
        width="300"
        height="600"
        allowtransparency="true"
        allow="autoplay"
        title="Ad"
      >
        <a
          href="https://adserver.kozoom.com/ck.php?n=ac4c3034&amp;cb=%n"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://adserver.kozoom.com/avw.php?zoneid=6705&amp;cb=%n&amp;n=ac4c3034&amp;ct0=%c"
            border="0"
            alt=""
          />
        </a>
      </iframe>
    </div>
  );
}

