import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode } from "../../utils/site";

export const GET_EVENT_DETAIL_START = "GET_EVENT_DETAIL_START";
export const GET_EVENT_DETAIL_FINISH = "GET_EVENT_DETAIL_FINISH";
export const GET_EVENT_DETAIL_ERROR = "GET_EVENT_DETAIL_ERROR";

export const GET_PLANS_BY_EVENT_START = "GET_PLANS_BY_EVENT_START";
export const GET_PLANS_BY_EVENT_FINISH = "GET_PLANS_BY_EVENT_FINISH";
export const GET_PLANS_BY_EVENT_ERROR = "GET_PLANS_BY_EVENT_ERROR";

export const GET_RELATED_EVENTS_START = "GET_RELATED_EVENTS_START";
export const GET_RELATED_EVENTS_FINISH = "GET_RELATED_EVENTS_FINISH";
export const GET_RELATED_EVENTS_ERROR = "GET_RELATED_EVENTS_ERROR";

export const LIVE_SERVER_EVENT_UPDATE = "LIVE_SERVER_EVENT_UPDATE";


export const getEventDetail = (id = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_DETAIL_START,
    });
    axios
      .get(`${SERVER_URL}/event/${id}`, {
        params: {
          lang: siteLangCode,
        },
      })
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_EVENT_DETAIL_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_EVENT_DETAIL_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_EVENT_DETAIL_ERROR,
          payload: err,
        });
      });
  };
};

export const getRelatedEvents = (relatedEventId = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_RELATED_EVENTS_START,
    });
    axios
      .get(`${SERVER_URL}/event`, {
        params: {
          lang: siteLangCode,
          relatedEventId,
        },
      })
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_RELATED_EVENTS_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_RELATED_EVENTS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_RELATED_EVENTS_ERROR,
          payload: err,
        });
      });
  };
};

export const getEventDetailPlan = (eventId = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_PLANS_BY_EVENT_START,
    });
    axios
      .get(`${SERVER_URL}/eventDay`, {
        params: { eventId }
      })
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_PLANS_BY_EVENT_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_PLANS_BY_EVENT_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EVENT_DETAIL_ERROR,
          payload: err,
        });
      });
  };
};
