import { Box } from "@mui/material";
import { LoadMoreSection, Spinner, VideoCard } from "components";
import queryString from "query-string";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPlayerVideos } from "store/actions";
import { siteLangCode, siteSportSlug } from "utils/site";

export const PlayerVideos = ({ tab }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { videoId, recordId } = queryString.parse(history.location.search);

  const { id: playerId, slug } = useSelector((state) => state.playerDetail.data);
  const playlist = useSelector((state) => state.playlist);
  const livestreamRecords = useSelector(state => state.playerDetail.livestreamRecords.data);

  const videos = tab === 'videos' ? playlist.videos : livestreamRecords;

  const onClickVideo = video => {
    if (tab === "videos") {
      history.push(`/${siteLangCode}/player/${slug}?videoId=${video.id}`);
    } else {
      history.push(`/${siteLangCode}/player/${slug}?recordId=${video.id}`);
    }
  };
  const onLoadMore = () => {
    dispatch(getPlayerVideos(playerId, videos.length, 20));
  };

  if (playlist.loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <>
      {videos.map((video, index) => (
        <VideoCard
          key={index}
          video={video}
          isCurrent={Number(videoId) === video.id || Number(recordId) === video.id}
          onClick={() => onClickVideo(video)}
        />
      ))}
      {tab === 'videos' && playlist.hasMore &&
        <LoadMoreSection
          loading={playlist.loading}
          onLoadMore={onLoadMore}
        />
      }
    </>
  );
};

