import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { siteLangCode } from "../../utils/site";
import { authorizationHeader } from "auth";

/**
 * Playlist actions use in event detail page, player detail page
 */
export const GET_EVENT_VIDEOS_START = "GET_EVENT_VIDEOS_START";
export const GET_EVENT_VIDEOS_FINISH = "GET_EVENT_VIDEOS_FINISH";
export const GET_EVENT_VIDEOS_ERROR = "GET_EVENT_VIDEOS_ERROR";

export const GET_PLAYER_VIDEOS_START = "GET_PLAYER_VIDEOS_START";
export const GET_PLAYER_VIDEOS_FINISH = "GET_PLAYER_VIDEOS_FINISH";
export const GET_PLAYER_VIDEOS_ERROR = "GET_PLAYER_VIDEOS_ERROR";

export const UPDATE_LAST_PLAYED_TIME_START = "UPDATE_LAST_PLAYED_TIME_START";
export const UPDATE_LAST_PLAYED_TIME_FINISH = "UPDATE_LAST_PLAYED_TIME_FINISH";
export const UPDATE_LAST_PLAYED_TIME_ERROR = "UPDATE_LAST_PLAYED_TIME_ERROR";


const url = `${SERVER_URL}/video`;

export const getEventVideos = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_VIDEOS_START,
      payload: { resetVideos: true }
    });
    axios
      .get(url, { params: { eventId, lang: siteLangCode, limit: 1000 }, ...authorizationHeader() })
      .then((res) => {
        dispatch({
          type: GET_EVENT_VIDEOS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EVENT_VIDEOS_ERROR,
          payload: err,
        });
      });
  };
};

export const getPlayerVideos = (playerId, offset = 0, limit = 20) => {
  const url = `${SERVER_URL}/video`;
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_VIDEOS_START,
      payload: !offset ? { resetVideos: true } : null
    });
    axios
      .get(url, {
        params: { playerId, lang: siteLangCode, limit, offset },
        ...authorizationHeader(),
      })
      .then((res) => {
        dispatch({
          type: GET_PLAYER_VIDEOS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PLAYER_VIDEOS_ERROR,
          payload: err,
        });
      });
  };
};

export const updateVideoLastPlayedTime = (videoId, lastPlayed) => {
  const url = `${SERVER_URL}/video_resuming`;
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAST_PLAYED_TIME_START,
    });
    axios.post(url, { videoId, lastPlayed }, authorizationHeader())
      .then(() => {
        dispatch({
          type: UPDATE_LAST_PLAYED_TIME_FINISH,
          payload: {
            videoId,
            lastPlayed
          },
        });
      })
      .catch(err => {
        console.log(err);
        dispatch({
          type: UPDATE_LAST_PLAYED_TIME_ERROR,
          payload: err,
        });
      });
  };
};
