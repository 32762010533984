import { indigo, red } from "@mui/material/colors";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { MsalProvider } from "@azure/msal-react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@date-io/date-fns";
import { theme as EpgTheme } from "pages/TV/TV247Channel/epg/theme"

import React, { useState } from "react";

import { siteLangCode, majorColor } from "utils/site";
import { COLOR_COMMUNITY } from "constants/color";

const AppContext = React.createContext();
const AppProvider = ({ children, pca }) => {
  const [state, setState] = useState({
    mode: "light",
    direction: "ltr",
    bLanguageDialogOpened: false,
  });

  const fontFamily =
    siteLangCode === "vi"
      ? "Helvetica, Arial, sans-serif"
      : "'Kulim Park', sans-serif";

  const theme = createTheme({
    direction: state.direction,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1296,
        xl: 1536,
      },
    },
    palette: {
      mode: state.mode,
      primary: {
        main: majorColor,
        // contrastText: "#FFFFFF",
      },
      white: "#ffffff",
      grayLight: "#e0e0e2",
      secondary: indigo,
      darkBlue: "#02023C",
      gray: "#707272",
      dark: "#444444",
      error: red,
      community: {
        main: COLOR_COMMUNITY,
        contrastText: "#FFFFFF"
      }
    },
    typography: {
      fontFamily,
      headline: {
        fontSize: "1rem",
      },
      subheading: {
        fontSize: "0.8125rem",
      },
      button: {
        fontWeight: 400,
        textTransform: "initial"
      },
    },
    shape: {
      borderRadius: 4,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20
          },
        },
      },

    },
    ...EpgTheme,
  });


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MsalProvider instance={pca}>
            <AppContext.Provider
              value={{
                state,
                toggleTheme: () =>
                  setState({
                    ...state,
                    mode: state.mode === "light" ? "dark" : "light",
                  }),
                toggleLanguageDialog: () =>
                  setState({ ...state, bLanguageDialogOpened: !state.bLanguageDialogOpened }),
              }}
            >
              {children}
            </AppContext.Provider>
          </MsalProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppProvider;
