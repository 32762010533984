import React from "react";
import { siteLangCode } from "../../../utils/site";

export default class MobileBanner extends React.Component {
  getId = (lang) => {
    switch (lang) {
    case "en":
      return "ac191c5b";
    case "fr":
      return "a5c189a4";
    case "es":
      return "a676eef3";
    case "nl":
      return "a0d01881";
    case "de":
      return "a7f33fa9";
    case "tr":
      return "af68bc5a";
    case "ko":
      return "abd72fe7";
    case "vi":
      return "a244e37e";
    default:
      return "ac191c5b";
    }
  };

  getZoneId = (lang) => {
    switch (lang) {
    case "en":
      return "6773";
    case "fr":
      return "6724";
    case "es":
      return "6723";
    case "nl":
      return "6725";
    case "de":
      return "6726";
    case "tr":
      return "6727";
    case "ko":
      return "6729";
    case "vi":
      return "6728";
    default:
      return "6773";
    }
  };

  getN = (lang) => {
    switch (lang) {
    case "en":
      return "a9a1270d";
    case "fr":
      return "a0f10cd8";
    case "es":
      return "ae359b57";
    case "nl":
      return "ae9d69d6";
    case "de":
      return "ab72bdfe";
    case "tr":
      return "a916669b";
    case "ko":
      return "ad402960";
    case "vi":
      return "ab2840f1";
    default:
      return "a9a1270d";
    }
  };

  render() {

    const id = this.getId(siteLangCode);
    const zoneId = this.getZoneId(siteLangCode);
    const n = this.getN(siteLangCode);
    return (
      <iframe
        title="revive default banner"
        id={id}
        name={id}
        src={`https://adserver.kozoom.com/afr.php?zoneid=${zoneId}`}
        frameBorder="0"
        scrolling="no"
        width="300"
        height="250"
        allowtransparency="true"
        allow="autoplay"
      >
        <a
          href={`https://adserver.kozoom.com/ck.php?n=${n}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`https://adserver.kozoom.com/avw.php?zoneid=${zoneId}&amp;n=${n}`}
            border="0"
            alt=""
          />
        </a>
      </iframe>
    );
  }
}
