export const SearchPageStyles = (theme) => ({
  tabs: {
    borderBottom: "1px solid #333333",
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  customTabs: {
    top: 120,
    zIndex: "99",
    position: "sticky",
    backgroundColor: theme.palette.grayLight,
    [theme.breakpoints.down("md")]: {
      top: 60,
    },
  },
  tabIndicator: {
    backgroundColor: "transparent"
  },
  badgeItem: {
    "& span": {
      [theme.breakpoints.down("sm")]: {
        transform: "translate(40%, -a50%)",
      },
    },
  },
  tab: {
    color: theme.palette.dark,
    textTransform: "uppercase",
    opacity: 0.7
  },
  tabActive: {
    position: "relative",
    opacity: 1,
    color: `${theme.palette.dark}!important`,
    "&::after": {
      position: "absolute",
      content: "''",
      width: 0,
      height: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      borderLeft: "7px solid transparent",
      borderRight: "7px solid transparent",
      borderBottom: `7px solid ${theme.palette.dark}`,
    },
  },
  search: {
    textAlign: "center",
    color: "#3e3e3e",
    textTransform: "uppercase",
    fontWeight: 700,
  },
});

