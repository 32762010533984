import React, { useCallback } from "react";
import { generatePath, useLocation, useRouteMatch } from "react-router-dom";
import { sports } from "constants/sport";
import { setSiteSportById, setSiteSportBySlug, siteLangCode, siteSportId } from "../../utils/site";
import PrimaryHeader from "./PrimaryHeader";
import SecondaryHeader from "./SecondaryHeader";

const Header = () => {
  const { pathname } = useLocation();
  const { path, url } = useRouteMatch();
  const changeLanguage = useCallback((newLang) => {
    const selectedSport = sports.find(item => item.lang === newLang && item.id === siteSportId);
    window.localStorage.setItem("siteLangCode", newLang);
    window.localStorage.setItem("siteSportSlug", selectedSport.slug);

    // For news, players, slugs are being used, so redirect to news page
    if (/\/news\/[a-z-_]+/.test(pathname)) {
      window.location.href = `${window.location.origin}/${newLang}/${selectedSport.slug}/news`;
      return;
    }
    // for events
    if (/\/event\/\d+/.test(pathname)) {
      window.location.href = window.location.href.replace(/\/[a-z]{2}\/event/, `/${newLang}/event`);
      return;
    }
    const newPath = generatePath(pathname.replace(url, path), {
      lang: newLang,
      sport: selectedSport.slug
    });
    window.location.href = newPath;
  }, [path, pathname, url]);

  const changeSport = useCallback((id) => {
    setSiteSportById(id);
    const selectedSport = sports.find(item => item.lang === siteLangCode && item.id === id);
    setSiteSportBySlug(selectedSport.slug);

    const newPath = generatePath(pathname.replace(url, path), {
      lang: siteLangCode,
      sport: selectedSport.slug
    });
    window.location.href = newPath;
  }, [path, pathname, url]);

  return (
    <>
      <PrimaryHeader
        changeLanguage={changeLanguage}
      />
      <SecondaryHeader
        changeSport={changeSport}
        changeLanguage={changeLanguage}
      />
    </>
  );

};

export default Header;
