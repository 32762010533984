import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import { Spinner } from "components";
import {
  SCOREBOARD_LIVE_TEMPLATE,
} from "constants/serverUrl";

const LiveScoreBoard = ({ streams = [], onChangeTable, scoreBoards, currentTable = 0 }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const agent = navigator.userAgent.toLowerCase();
    const isIOS = agent.match(/(iphone|ipod|ipad)/);
    let addedDelay = 0;
    if (isIOS) {
      addedDelay = 25000;
    }

    window.$.when(
      scoreBoards.loadTemplates(SCOREBOARD_LIVE_TEMPLATE)
    ).done(() => {
      console.log("SCOREBOARD TEMPLATE LOADED");
      streams.forEach((stream, index) => {
        window.$(`#score-board-${index}`).scoreBoard({
          refresh: 30000,
          token: stream.token,
          delay: addedDelay + stream.delay,
          state: streams.length > 6 ? "small" : "big",
          optionGlobalPlay: false,
          optionGoFirst: true,
          autoresize: false,
          videoCB: (token) => {
            console.log("Change table", token, index);
            onChangeTable(index);
          },
        });
      });
      setLoading(false);
    }).fail((jqXHR, textStatus, errorThrown) => {
      console.warn(textStatus);
    })
  }, [streams, onChangeTable, scoreBoards]);

  useEffect(() => {
    if (loading) {
      return;
    }
    const activeId = window.$(".score-board .onair").parents(".score-board").attr("id");
    const activeScoreBoard = window.$(`#${activeId}`);
    if (activeScoreBoard.length) {
      activeScoreBoard.scoreBoard("videoDeactive");
    }
    const newActiveScoreBoard = window.$(`#score-board-${currentTable}`);
    if (newActiveScoreBoard.length) {
      newActiveScoreBoard.scoreBoard("videoActive");
    }
  }, [currentTable, loading]);

  return (
    <Box>
      {loading && (
        <Box display="flex" justifyContent="center" py={4}>
          <Spinner />
        </Box>
      )}
      <Grid container mt={2}>
        {Array.isArray(streams) &&
          streams.map((item, index) => (
            <Grid item sm={6} xs={12} key={`index-${index}`}>
              <Box id={`score-board-${index}`} className="score-board" mt={1} minHeight={24} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

LiveScoreBoard.propTypes = {
  streams: PropTypes.arrayOf(PropTypes.any),
  onChangeTable: PropTypes.func,
};

export default React.memo(LiveScoreBoard);
