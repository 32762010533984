import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  metadata: {
    display: "inline-block",
    minWidth: "calc(100% - 250px)"
  },
  title: {
    fontWeight: 600,
    fontSize: 32,
    color: "#4c4c4c",
  },
  content: {
    "& *": {
      fontFamily: "'Kulim Park', serif",
    },
    "& p, p span": {
      fontFamily: "'Kulim Park', serif !important",
      fontSize: "16px !important",
      color: "#333333 !important"
    },
    "& img": {
      maxWidth: "80vw !important",
      objectFit: "contain",
      display: "block",
      margin: "auto",
    },
    "& .fr-img-space-wrap": {
      textAlign: "center",
    },
    "& .fr-img-caption .fr-img-wrap > span": {
      margin: "auto",
      display: "block",
      padding: "5px 5px 10px",
      fontSize: 14,
      textAlign: "center",
      fontWeight: 400,
      opacith: 0.9
    },
  },
  authorName: {
    fontSize: 18,
    fontStyle: "italic",
    color: "#4c4c4c",
    fontWeight: 300,
  },
  date: {
    fontSize: 18,
    fontStyle: "italic",
    color: "#4c4c4c",
    fontWeight: 300,
  },
  legend: {
    backgroundColor: "#ECEBEB",
    fontStyle: "italic",
    padding: 15
  },
  credit: {
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "#fff",
    padding: "2px 15px",
    textAlign: "center",
    position: "absolute",
    fontSize: 12,
    right: 0,
    bottom: 0
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    float: "left"
  },
}));
