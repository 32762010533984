import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import i18n from "i18next";
import { Spinner, CalendarCard } from "components";
import { useEventSchedule } from "../hooks";

const useStyles = makeStyles(() => ({
  eventItem: {
    marginBottom: 16,
    padding: 8,
    display: "flex",
    borderRadius: 4,
    backgroundColor: "#4F4E79",
  },
  eventContent: {
    color: "white",

    "& h3": {
      marginTop: 0,
      marginBottom: 6,
      fontSize: 14,
      lineHeight: "18px",
    },

    "& p": {
      marginTop: 0,
      marginBottom: 6,
      fontSize: 12,
      lineHeight: "13px",
    },
    "& p:last-child": {
      marginBottom: 0,
    },
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
    lineHeight: "11px",
    textTransform: "uppercase",
    color: "white",
  },
  time: {
    padding: "2px 4px",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "13px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "black",
    backgroundColor: "white",
  },
}));

const SchedulePanel = ({ eventId }) => {
  const classes = useStyles();
  const { plans, dates, loading, selectedIndex, setSelectedIndex } = useEventSchedule(
    eventId
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <Spinner />
      </Box>
    );
  }

  if (!loading && !dates.length) {
    return (
      <Box display="flex" justifyContent="center" py={2} color="white">
        {i18n.t("No plans found")}
      </Box>
    );
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        pt={2}
        mb={2}
      >
        {dates.map((date, index) => (
          <Box mx={0.5} key={index} style={{ cursor: "pointer" }}>
            <CalendarCard
              date={date}
              zoomed={selectedIndex === index}
              onClick={() => setSelectedIndex(index)}
            />
          </Box>
        ))}
      </Box>
      {(plans).map((plan, index) => (
        <Box key={index} className={classes.eventItem}>
          {plan.time &&
            <Box
              mr={2}
              p={0.5}
              alignSelf="flex-start"
              bgcolor="primary.main"
              borderRadius="4px"
              minWidth={60}
            >
              <Box className={classes.label}>{i18n.t("Local Time")}</Box>
              <Box className={classes.time}>{plan.time}</Box>
            </Box>
          }
          <Box
            className={classes.eventContent}
            dangerouslySetInnerHTML={{ __html: plan.planning }}
          />
        </Box>
      ))}
    </Box>
  );
};

SchedulePanel.propTypes = {
  eventId: PropTypes.number,
};

export default SchedulePanel;
