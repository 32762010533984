import React from "react";
import PropTypes from "prop-types";

export const UserIcon = ({ color, size = 27 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size * 30 / 27} fill={color || "currentColor"} viewBox="0 0 27 30"><g><g><path d="M19.786 6.87c0-3.53-2.87-6.4-6.4-6.4-3.529 0-6.4 2.87-6.4 6.4 0 3.529 2.871 6.4 6.4 6.4 3.53 0 6.4-2.871 6.4-6.4zm6.4 18.61c0-2.49-.728-4.793-2.106-6.661-1.312-1.777-3.186-3.143-5.423-3.949-.338.138-.665.288-1.012.448-1.23.566-2.503 1.152-4.258 1.152-1.953 0-3.41-.704-4.697-1.326a44.873 44.873 0 0 0-.574-.274C3.402 16.525.586 20.49.586 25.48c2.6 2.515 6.907 3.79 12.8 3.79 5.895 0 10.201-1.275 12.8-3.79z" /></g></g></svg>
  );
};

UserIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

