import { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    color: "white",
  },
}));

export const usePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const masterclass = useSelector((state) => state.masterclass);

  const [currentDate, setCurrentDate] = useState(moment().format("Y-M-D"));
  const [offset, setOffset] = useState(0);
  const [author, setAuthor] = useState(null);

  const onSelectPlayer = (authorId) => {
    if (author !== authorId) {
      setAuthor(authorId);
    } else {
      setAuthor(null);
    }
  };

  const onLoadMore = () => {
    // getMasterclassEvents(author, offset + 10)(dispatch);
    setOffset(offset + 10);
  };

  useEffect(() => {
    // getMasterclassPlayers()(dispatch);
  }, [dispatch]);

  useEffect(() => {
    // getMasterclassEvents(author, 0, 10, true)(dispatch);
  }, [dispatch, author]);

  return {
    classes,
    currentDate,
    setCurrentDate,
    selected: author,
    onSelectPlayer,
    onLoadMore,
    events: masterclass.events.data,
    hasMore: masterclass.events.hasMore,
    players: masterclass.players.data,
    loading: masterclass.events.loading && masterclass.players.loading,
    eventsLoading: masterclass.events.loading,
    error: masterclass.events.error || masterclass.players.error,
  };
};
