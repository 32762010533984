import SendIcon from "@mui/icons-material/Send";
import { Box, Button, TextField, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { login } from "auth";
import i18n from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComment, getComments } from "store/actions";
import { siteLangCode } from "utils/site";
import { Comment } from "./Comment";

export const CmtTextField = styled((props) => (
  <TextField {...props} variant="standard" size="small" multiline fullWidth />
))(({ theme, mode }) => ({
  '& .MuiInput-root': {
    borderRadius: 20,
    color: mode === 'dark' ? 'white' : 'initial',
    '&::before': {
      borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.42)' : 'rgba(0, 0, 0, 0.42)',
    },
    '&:hover::before': {
      borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.42)' : 'rgba(0, 0, 0, 0.42)',
    },
  },
}));

export const CmtButton = styled(Button)(({ theme }) => ({
  "&:disabled": {
    backgroundColor: '#4F4E79',
    color: 'white'
  }
}));


export const Comments = ({ entityId, entity, mode, showHeading = true }) => {
  const {
    comments,
    auth,
    content,
    setContent,
    onComment
  } = useComment(entityId, entity);

  return <Box color={mode === "dark" ? "white" : "initial"}>
    {showHeading &&
      <Box mb={2}>
        <Typography fontSize={20} fontWeight={600}>{i18n.t("Comments")}</Typography>
      </Box>
    }
    {comments.map((item, index) => <Comment key={index} comment={item} mode={mode} />)}
    {!!auth.userInfo &&
      <Box mt={3}>
        <CmtTextField
          mode={mode}
          placeholder={i18n.t("Type something to comment")}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Box textAlign="right" marginTop={2}>
          <CmtButton variant="contained" endIcon={<SendIcon />} color="primary" onClick={onComment} disabled={!content}>
            {i18n.t("Send")}
          </CmtButton>
        </Box>
      </Box>
    }
    {!auth.userInfo &&
      <Box mt={2}>
        <CmtButton onClick={login} variant="contained">
          {i18n.t("Login to comment")}
        </CmtButton>
      </Box>
    }
  </Box>;
};

const useComment = (entityId, entity) => {
  const comment = useSelector(state => state.comment);
  const auth = useSelector(state => state.auth);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getComments(entityId, entity));
  }, [entityId, entity, dispatch]);

  const onComment = () => {
    dispatch(addComment({
      entityId: comment.entityId,
      entity: comment.entity,
      lang: siteLangCode,
      content
    }));
    setContent("");
  };

  // Sort comments and put children into parents
  const comments = useMemo(
    () => {
      const sortedComments = Object.values(comment.commentsById).map(item => ({ ...item })).sort((a, b) => {
        return comment.commentIds.indexOf(a.id) - comment.commentIds.indexOf(b.id);
      });
      for (const item of sortedComments) {
        if (item.parentId) {
          const parent = sortedComments.find(c => c.id === item.parentId);
          if (!parent) {
            continue;
          }
          if (!parent.children) {
            parent.children = [item];
          } else {
            parent.children.push(item);
          }
        }
      }
      return sortedComments.filter(item => !item.parentId);
    },
    [comment.commentIds, comment.commentsById]
  );

  return {
    comments,
    auth,
    content,
    setContent,
    onComment
  };
};
