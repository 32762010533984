import {
  SET_SEARCH_KEYWORD,
} from "store/actions";

const initialState = {
  searchTerms: "",
};

export default function (state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case SET_SEARCH_KEYWORD:
      newState.searchTerms = action.payload;
      return newState;

    default:
      return newState;
  }
}
