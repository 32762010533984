export const socketEvents = {
  userLoggedIn: 'user_logged_in',
  userLoggedOut: 'user_logged_out',
  joinEventRoom: 'join_event_room',
  leaveEventRoom: 'leave_event_room',
  startWatching: 'start_watching',
  joinChat: 'join_chat',
  eventRoomUserCount: 'event_room_user_count',
  eventUpdate: 'event_update',
  scheduleUpdate: 'schedule_update',
  streamsUpdate: 'streams_update',
  videosUpdate: 'videos_update',
  chatHistories: 'chat_histories',
  newMessage: 'new_message',
  editMessage: 'edit_message',
  removeMessage: 'remove_message',
  toggleMessageByAmin: 'toggle_message_by_admin',
  banUser: 'ban_user',
  unbanUser: 'unban_user',
  playOut: 'play_out',
}