
import React from "react";
import PropTypes from "prop-types";

export const GlobeIcon = ({ color, size = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color || 'currentColor'} viewBox="0 0 20 20"><g transform="translate(18.883)"><path d="M10,0A10,10,0,1,0,20,10,10.012,10.012,0,0,0,10,0ZM9.606,18.951c-.957,0-1.99-1.775-2.532-4.624h5.064C11.6,17.176,10.564,18.951,9.606,18.951ZM6.91,13.3A24.007,24.007,0,0,1,6.7,10.513h5.812A24.164,24.164,0,0,1,12.3,13.3ZM1.052,10.513H5.671A25.324,25.324,0,0,0,5.88,13.3H1.663A8.9,8.9,0,0,1,1.052,10.513ZM9.606,1.049c.943,0,1.959,1.722,2.508,4.5H7.1C7.646,2.771,8.663,1.049,9.606,1.049Zm2.68,5.523a23.87,23.87,0,0,1,.226,2.915H6.7a23.9,23.9,0,0,1,.226-2.915ZM5.671,9.487H1.052a8.91,8.91,0,0,1,.658-2.915H5.9A25.109,25.109,0,0,0,5.671,9.487Zm7.871,1.026h5.407a8.92,8.92,0,0,1-.611,2.788H13.332A25.4,25.4,0,0,0,13.542,10.513Zm0-1.026a25.338,25.338,0,0,0-.227-2.915h4.976a8.9,8.9,0,0,1,.658,2.915Zm4.239-3.941H13.15a11,11,0,0,0-1.638-4.384A8.991,8.991,0,0,1,17.781,5.547ZM7.551,1.374A11.619,11.619,0,0,0,6.063,5.547H2.219A9,9,0,0,1,7.551,1.374ZM2.143,14.327h3.9a11.806,11.806,0,0,0,1.51,4.3A9.01,9.01,0,0,1,2.143,14.327Zm9.369,4.51a11.173,11.173,0,0,0,1.66-4.51h4.686A8.993,8.993,0,0,1,11.511,18.837Z" transform="translate(-18.883)" /></g></svg>
  );
};

GlobeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
