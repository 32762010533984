import { makeStyles } from '@mui/styles';
import clothBgImage from './assets/bg-cloth.png';
import storeBgImage from './assets/store-bg.png';

export const useStyles = makeStyles((theme) => ({
  sectionOne: {
    backgroundColor: theme.palette.darkBlue,
    color: theme.palette.white,
    paddingTop: theme.spacing(5)
  },
  sectionTwo: {
    background: `transparent url(${clothBgImage})`,
    color: "white",
    [theme.breakpoints.up('md')]: {
      paddingBottom: 20
    },
    [theme.breakpoints.up('lg')]: {
      paddingBottom: 0
    },
  },
  sectionTwoBg: {
    background: "transparent linear-gradient(180deg, #02023C 0%, #090941EA 35%, #55557C00 100%) 0% 0% no-repeat",
  },
  sectionThree: {
    backgroundColor: "#02023C",
    color: "white"
  },
  sectionFour: {
    background: `url(${storeBgImage}) right/cover`,
    color: "white"
  },
  sectionFive: {
    backgroundColor: "#02023C",
    color: "white"
  },
  img: {
    maxWidth: "100%"
  },
  multiTableImage: {
    marginBottom: 30,
    marginTop: 15,
    maxWidth: "100%",
    width: 440,
    [theme.breakpoints.up('md')]: {
      marginTop: -100
    },
  }
}));