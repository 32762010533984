import { languages } from "constants/language";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { socketEvents, socketIo } from "socket";
import { siteLangCode } from "utils/site";
import { v4 as uuidv4 } from 'uuid';

export const useChat = (table) => {
  const { eventId } = useParams();
  const [messages, setMessages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState(languages.map(item => item.iso));
  const [editingMessage, setEditingMessage] = useState(null);

  const [setting, setSetting] = useState({
    sameTable: false,
    showFeed: true,
    showReply: false,
  });

  const userInfo = useSelector(state => state.auth.userInfo);

  const onSendMessage = useCallback((text) => {
    if (socketIo && text) {
      const newMessage = {
        id: uuidv4(),
        user: userInfo,
        text,
        table,
        lang: siteLangCode,
        time: new Date(),
      };
      socketIo.emit(socketEvents.newMessage, newMessage);
      setMessages([...messages, newMessage]);
    }
  }, [messages, table, userInfo])

  const onToggleLang = useCallback((newLang, checked) => {
    if (checked) {
      setSelectedLanguages([...selectedLanguages, newLang]);
    } else {
      setSelectedLanguages(selectedLanguages.filter(lang => lang !== newLang));
    }
  }, [selectedLanguages]);

  const onToggleAll = (checked) => {
    if (checked) {
      setSelectedLanguages(languages.map(item => item.iso));
    } else {
      setSelectedLanguages([]);
    }
  }

  const onEdit = (message) => {
    setEditingMessage(message);
  }

  const onCancelEditMode = () => {
    setEditingMessage(null);
  }

  const filteredMessages = useMemo(() => {
    return messages.filter(msg => {
      let check = true;
      if (setting.sameTable && table !== undefined) {
        check = msg.table === table;
      }
      check = selectedLanguages.includes(msg.lang);
      return check;
    });
  }, [selectedLanguages, setting.sameTable, table, messages]);

  const userCount = useSelector(state => state.live.liveViewers[`event_${eventId}`]);

  useEffect(() => {
    if (!socketIo) {
      return;
    }

    socketIo.emit(socketEvents.joinChat);

    socketIo.on(socketEvents.chatHistories, (messages) => {
      if (Array.isArray(messages)) {
        setMessages(messages);
      }
    });

    socketIo.on(socketEvents.newMessage, (message) => {
      setMessages((messages) => [...messages, message]);
    });

    return () => {
      socketIo.off(socketEvents.chatHistories);
      socketIo.off(socketEvents.newMessage);
    }
  }, [eventId]);

  return {
    messages: filteredMessages,
    userInfo,
    userCount,
    selectedLanguages,
    setting,
    editingMessage,
    onChangeSetting: setSetting,
    onSendMessage,
    onToggleAll,
    onToggleLang,
    onEdit,
    onCancelEditMode,
  };
};