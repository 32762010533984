import {
  SEARCH_EVENTS_START,
  SEARCH_EVENTS_FINISH,
  SEARCH_EVENTS_ERROR,
  SEARCH_NEWS_START,
  SEARCH_NEWS_FINISH,
  SEARCH_NEWS_ERROR,
  SEARCH_VIDEOS_START,
  SEARCH_VIDEOS_FINISH,
  SEARCH_VIDEOS_ERROR,
  SEARCH_PLAYERS_START,
  SEARCH_PLAYERS_FINISH,
  SEARCH_PLAYERS_ERROR,
} from "store/actions";

const initialState = {
  news: {
    loading: false,
    data: [],
    total: 0,
    err: null
  },
  events: {
    loading: false,
    data: [],
    total: 0,
    hasMore: false,
    err: null
  },
  players: {
    loading: false,
    data: [],
    total: 0,
    err: null
  },
  videos: {
    loading: false,
    data: [],
    total: 0,
    err: null
  }
};

export default function (state = initialState, action) {
  let key = "";
  if ([SEARCH_NEWS_START, SEARCH_NEWS_FINISH, SEARCH_NEWS_ERROR].includes(action.type)) {
    key = "news";
  }
  if ([SEARCH_EVENTS_START, SEARCH_EVENTS_FINISH, SEARCH_EVENTS_ERROR].includes(action.type)) {
    key = "events";
  }
  if ([SEARCH_PLAYERS_START, SEARCH_PLAYERS_FINISH, SEARCH_PLAYERS_ERROR].includes(action.type)) {
    key = "players";
  }
  if ([SEARCH_VIDEOS_START, SEARCH_VIDEOS_FINISH, SEARCH_VIDEOS_ERROR].includes(action.type)) {
    key = "videos";
  }
  switch (action.type) {
    case SEARCH_NEWS_START:
    // eslint-disable-next-line
    case SEARCH_EVENTS_START:
    // eslint-disable-next-line
    case SEARCH_PLAYERS_START:
    // eslint-disable-next-line
    case SEARCH_VIDEOS_START:
      return {
        ...state,
        [key]: {
          ...state[key],
          data: action.payload.firstLoad ? [] : state[key].data,
          loading: true,
          err: null
        }
      };
    case SEARCH_NEWS_FINISH:
    case SEARCH_VIDEOS_FINISH:
    case SEARCH_EVENTS_FINISH:
    case SEARCH_PLAYERS_FINISH:
      {
        const data = state[key].data.concat(action.payload);
        return {
          ...state,
          [key]: {
            data,
            total: action.payload.length === 20 ? `${data.length}+` : data.length,
            hasMore: action.payload.length === 20,
            loading: false,
            err: null
          }
        };
      }
    case SEARCH_NEWS_ERROR:
    // eslint-disable-next-line
    case SEARCH_EVENTS_ERROR:
    // eslint-disable-next-line
    case SEARCH_PLAYERS_ERROR:
    // eslint-disable-next-line
    case SEARCH_VIDEOS_ERROR:
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: false,
          err: action.payload
        }
      };

    default:
      return state;
  }
}
