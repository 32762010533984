import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode } from "../../utils/site";

export const RESET_PLAYERMATCH = "RESET_PLAYERMATCH";

export const GET_PLAYERMATCH_START = "GET_PLAYERMATCH_START";
export const GET_PLAYERMATCH_FINISH = "GET_PLAYERMATCH_FINISH";
export const GET_PLAYERMATCH_ERROR = "GET_PLAYERMATCH_ERROR";

export const SET_RANKING_ACTIVE_EVENT_PLAYER = "SET_RANKING_ACTIVE_EVENT_PLAYER";


const url = `${SERVER_URL}/matches`;


export const setRankingActiveEventPlayer = (eventPlayer) => ({
  type: SET_RANKING_ACTIVE_EVENT_PLAYER,
  payload: eventPlayer
});

export const getPlayerMatches = (eventId, playerId) => {
  return (dispatch) => {
    dispatch({
      type: GET_PLAYERMATCH_START,
    });
    axios
      .get(url, {
        params: {
          lang: siteLangCode,
          event_id: eventId,
          player_id: playerId,
        },
      })
      .then((res) => {
        res = {
          data: res.data,
          playerId,
          eventId,
        };
        dispatch({
          type: GET_PLAYERMATCH_FINISH,
          payload: res,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_PLAYERMATCH_ERROR,
          payload: err,
        });
      });
  };
};
