
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode } from "utils/site";

export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';

export const getConfigs = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIG,
    });
    axios
      .get(`${SERVER_URL}/configuration`, { params: { lang: siteLangCode } })
      .then((res) => {
        dispatch({
          type: GET_CONFIG_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_CONFIG_FAILURE,
          payload: err,
        });
      });
  };
};

