import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: 32,
    [theme.breakpoints.down("md")]: {
      marginBottom: 100
    }
  },
  videoPlayerContainer: {
    [theme.breakpoints.down("md")]: {
      marginLeft: -24,
      marginRight: -24,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: -16,
      marginRight: -16,
      position: "sticky",
      top: 60,
      zIndex: 2
    },
  },
  tabContainer: {
    borderBottom: "2px solid #E51135",
    color: "white",
    ".MuiTabs-indicator": {
      backgroundColor: "#E51135",
    },
    [theme.breakpoints.down("sm")]: {
      position: "sticky",
      top: "calc(100vw * 9 / 16 + 60px)",
      zIndex: 2,
      backgroundColor: theme.palette.darkBlue
    },
  },
  tabButton: {
    border: "none",
    color: "white!important",
    textTransform: 'uppercase'
  },
  tabIndicator: {
    position: "relative",
    backgroundColor: "transparent!important",
    display: "block",
    "&::after": {
      position: "absolute",
      content: "''",
      width: 0,
      height: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "5px solid #E51135",
    },
  },
  article: {
    color: "white",
  },
  defaultBanner: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  playList: {
    maxHeight: 710,
    overflow: "auto",
    margin: "0 auto 25px"
  },
  videoDescription: {
    color: "#ffffff"
  },
  mobileOnly: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  nextStreamingContainer: {
    position: "relative",
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    content: "''",
    backgroundColor: "#02023C",
    opacity: 0.7
  },
  nextStreaming: {
    color: "white",
    fontSize: "2em",
    top: "20%",
    left: 0,
    right: 0,
    textAlign: "center",
    position: "absolute",
  }
}));