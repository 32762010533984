import {
  GET_PLANS_BY_EVENT_START,
  GET_PLANS_BY_EVENT_FINISH,
  GET_PLANS_BY_EVENT_ERROR,
} from "store/actions";

const initialState = {
  loading: false,
  data: [],
  err: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLANS_BY_EVENT_START:
      return {
        ...state,
        loading: true
      };
    case GET_PLANS_BY_EVENT_FINISH:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_PLANS_BY_EVENT_ERROR:
      return {
        ...state,
        err: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
