export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const UPLOADED_MEDIA_URL = "https://b-cdn.kozoom.com";
export const EVENTS_MEDIA_URL = "https://kozoom.b-cdn.net/uploads/events";
export const PLAYER_MEDIA_URL = "https://kozoom.b-cdn.net/uploads/players";
export const SPONSOR_MEDIA_URL = "https://kozoom.b-cdn.net/uploads/sponsors";
export const SCOREBOARD_LIVE_URL =
  "https://live.kozoom.com/livescore/scoreApp/scoreBoard.js";
export const SCOREBOARD_LIVE_STYLE_URL =
  "https://live.kozoom.com/livescore/scoreApp/scoreBoard.css";
export const SCOREBOARD_LIVE_TEMPLATE =
  "https://live.kozoom.com/livescore/scoreApp/template";
export const USER_DEFAULT_AVATAR =
  "https://b-cdn.kozoom.com/assets/static/images/default-avatar.jpg";
export const CHAT_SOCKET_SERVER_URL = process.env.REACT_APP_CHAT_SOCKET_SERVER_URL || "http://localhost:4000";
