import React, { useEffect, useMemo, useState } from "react";
import { StarOutlined as StarIcon } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import i18n from "i18next";
import { makeStyles } from "@mui/styles";
import { CountryFlag } from "components";
import { Link } from "react-router-dom";
import { siteLangCode } from "utils/site";

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: "auto",
  },
  table: {
    minWidth: "100%",
    borderSpacing: "1px",
    "& tbody tr": {
      backgroundColor: "#F8E5BF",
      "&:nth-child(odd)": {
        backgroundColor: "#ECEBEB"
      }
    },
    "& th": {
      textTransform: "uppercase"
    },
    "& th, td": {
      textAlign: "center",
      lineHeight: "30px",
      fontWeight: 600,
      fontSize: 14,
      color: "#474747",
      maxWidth: "40vw"
    }
  },
  numberCell: {
    padidngLeft: 5,
    padidngRight: 5,
    fontFamily: "Arial, Helvetica, sans-serif"
  },
  playerName: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginLeft: 12,
    fontWeight: 600,
    fontSize: "inherit",
    lineHeight: "30px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
    },
  },
  starIcon: {
    color: "#FDC232",
    marginLeft: 10
  },
  footerBtn: {
    minWidth: 120
  }
}));

const formatAvg = (points, innings) => (innings ? (points / innings).toFixed(3) : '').toString().substring(0, 5);

export const EventRankings = ({ ranking, event }) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [shownColumns, setShownColumns] = useState({});

  useEffect(() => {
    setData(ranking.data.slice(0, 16));
    const newShownColumns = {};
    ranking.data.forEach(item => {
      ['money', 'day', 'won', 'lost', 'run', 'points', 'innings'].forEach(key => {
        if (item[key]) {
          newShownColumns[key] = true;
        }
      });
    });
    setShownColumns(newShownColumns);
  }, [ranking.data]);

  const USDollar = useMemo(() => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: event?.currency || 'USD',
      maximumFractionDigits: 0
    });
  }, [event]);

  const seeMoreOrLess = () => {
    if (ranking.data.length > data.length) {
      setData(ranking.data);
    } else {
      setData(ranking.data.slice(0, 16));
    }
  }

  if (!ranking.data.length) {
    return (
      <Box mt={2}>
        <Typography>
          {i18n.t("No rankings found")}
        </Typography>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Paper className={classes.container}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th colSpan="2"></th>
              {!!shownColumns.money &&
                <th>{i18n.t('Prize')}</th>
              }
              {!!shownColumns.day &&
                <th>{i18n.t('DAY')}</th>
              }
              {!!shownColumns.won &&
                <th>{i18n.t('WON')}</th>
              }
              {!!shownColumns.lost &&
                <th>{i18n.t('LOSS')}</th>
              }
              {!!shownColumns.run &&
                <th>{i18n.t('RUN')}</th>
              }
              {!!shownColumns.points &&
                <th>{i18n.t('PTS')}</th>
              }
              {!!shownColumns.innings &&
                <th>{i18n.t('INN')}</th>
              }
              <th>{i18n.t('AVG')}</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) =>
              <tr key={item.id}>
                <td className={classes.numberCell}>{item.rank}</td>
                <td>
                  {item.player &&
                    <Box display="flex" marginRight="5px" alignItems="center">
                      <CountryFlag countryId={item.player.birthplaceId} />
                      <Link to={`/${siteLangCode}/player/${item.player.slug}`}>
                        <Typography className={classes.playerName} color={index % 2 === 0 ? "#4C4C4C" : "#000000"}>
                          {`${item.player.lastName} ${item.player.firstName}`}
                        </Typography>
                      </Link>
                      {item.player.isChampion && <StarIcon fontSize="small" className={classes.starIcon} />}
                    </Box>
                  }
                </td>
                {!!shownColumns.money &&
                  <td className={classes.numberCell}>{item.money ? USDollar.format(item.money) : ''}</td>
                }
                {!!shownColumns.day &&
                  <td className={classes.numberCell}>{item.day}</td>
                }
                {!!shownColumns.won &&
                  <td className={classes.numberCell}>{item.won}</td>
                }
                {!!shownColumns.lost &&
                  <td className={classes.numberCell}>{item.lost}</td>
                }
                {!!shownColumns.run &&
                  <td className={classes.numberCell}>{item.run}</td>
                }
                {!!shownColumns.points &&
                  <td className={classes.numberCell}>{item.points}</td>
                }
                {!!shownColumns.innings &&
                  <td className={classes.numberCell}>{item.innings}</td>
                }
                <td className={classes.numberCell}>{formatAvg(item.points, item.innings)}</td>
              </tr>
            )}
          </tbody>
        </table>

        {ranking.data.length > 16 &&
          <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
            <Typography fontWeight={600}>{i18n.t('{{number}} players', { number: ranking.data.length })}</Typography>
            <Button variant="outlined" className={classes.footerBtn} size="small" onClick={seeMoreOrLess}>{i18n.t(ranking.data.length > data.length ? 'See more' : 'See less')}</Button>
          </Box>
        }
      </Paper>
    </Box>
  );
};
