export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getFileNameFromUrl(url) {
  const hashIndex = url.indexOf('#');
  url = hashIndex !== -1 ? url.substring(0, hashIndex) : url;
  return (url.split('/').pop() || '').replace(/[?].*$/g, '');
}