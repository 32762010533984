import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { siteLangCode } from "utils/site";
import { getEventDetailPlan } from "store/actions";

export const useEventSchedule = (eventId) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.eventPlan);
  const dates = useMemo(() => {
    let uniqDates = [];
    if (plans && Array.isArray(plans.data)) {
      plans.data.forEach((data, index) => {
        const eventDate = moment(data.date).format("Y-MM-DD");
        // Select the current date by default
        if (eventDate === moment().format('Y-MM-DD')) {
          setSelectedIndex(index);
        }
        if (!uniqDates.includes(eventDate)) {
          uniqDates.push(eventDate);
        }
      });
    }
    return uniqDates;
  }, [plans]);

  useEffect(() => {
    setSelectedIndex(dates.findIndex(date => date === moment().format('Y-MM-DD')));
  }, [dates]);

  const selectedPlans = useMemo(() => {
    const foundPlans = plans.data.filter((plan) =>
      moment(plan.date).isSame(moment(dates[selectedIndex]), "day")
    );
    return foundPlans.map(plan => {
      const trans = plan.i18n.find(item => item.lang === siteLangCode);
      return {
        ...plan,
        planning: trans.planning || ''
      }
    });

  }, [plans, dates, selectedIndex]);

  useEffect(() => {
    dispatch(getEventDetailPlan(eventId));
  }, [dispatch, eventId]);

  return {
    dates,
    plans: selectedPlans,
    loading: plans.loading,
    error: plans.error,
    selectedIndex,
    setSelectedIndex,
  };
};