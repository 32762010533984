import { EVENTS_MEDIA_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { useEffect, useState } from "react";

export const useReplayBottomTab = (videos, ranking, currentVideo, feeds, relatedEvents) => {
  const [bottomTab, setBottomTab] = useState("comment");
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      const newWidth = window.innerWidth || document.documentElement.clientWidth;
      if (newWidth !== viewportWidth) {
        setBottomTab(getDefaultTab(videos, ranking, currentVideo ? currentVideo.article : null, feeds, relatedEvents));
      }
    };
    // Add event listener
    window.addEventListener("resize", handleResize);
    setBottomTab(getDefaultTab(videos, ranking, currentVideo ? currentVideo.article : null, feeds, relatedEvents));

    return () => window.removeEventListener("resize", handleResize);
  }, [videos, ranking, currentVideo, feeds, relatedEvents, viewportWidth]);

  return { bottomTab, setBottomTab };
};

const getDefaultTab = (videos, ranking, article, feeds, relatedEvents, currentVideo) => {
  const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  if (viewportWidth <= 960 && !!videos.length) {
    return "videos";
  }
  if (currentVideo) {
    return "comment";
  }
  if (ranking && ranking.data && ranking.data.length) {
    return "ranking";
  }
  if (article) {
    return "article";
  }
  if (feeds && feeds.length) {
    return "news";
  }
  if (relatedEvents && relatedEvents.length) {
    return "relatedEvents";
  }
  return "comment";
};

export const useVideoPoster = (video) => {
  if (!video) {
    return null;
  }
  return video.thumbnail.startsWith("http") ? video.thumbnail :
    (video.eventId && video.thumbnail
      ? `${EVENTS_MEDIA_URL}/${video.eventId}/videos/${video.thumbnail}`
      : `${UPLOADED_MEDIA_URL}/assets/static/images/default-image.jpg`
    );
};
