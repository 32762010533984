import React from "react";
import PropTypes from "prop-types";

export const LockPremiumIcon = ({ invert = false, width = 23, height = 28, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25.964 33.78"
      {...props}
    >
      <g transform="translate(-691.725 -601.259)">
        <g transform="translate(635.412 601.259)">
          <path
            fill={invert ? "white" : "currentColor"}
            d="M80.435,13.642h-.658V10.561A10.5,10.5,0,0,0,69.6,0c-.154,0-.463,0-.617,0A10.5,10.5,0,0,0,58.813,10.561v3.081h-.658a2.137,2.137,0,0,0-1.842,2.339V31.432a2.147,2.147,0,0,0,1.842,2.348h22.28a2.147,2.147,0,0,0,1.842-2.348V15.981A2.137,2.137,0,0,0,80.435,13.642Zm-5,0H63.151V10.561a6.144,6.144,0,1,1,12.287,0v3.081Z"
            transform="translate(0 0)"
          />
        </g>
        <g transform="translate(696.016 616.008)">
          <path
            fill={invert ? "currentColor" : "white"}
            d="M17.78,6.415l-6.116-.906L8.858,0,6.107,5.537,0,6.5,4.415,10.83l-.969,6.106,5.48-2.862,5.508,2.808-1.028-6.1ZM8.914,4.872A4.437,4.437,0,1,1,4.477,9.309,4.437,4.437,0,0,1,8.914,4.872Z"
          />
          <g transform="translate(5.779 5.821)">
            <circle
              fill={invert ? "currentColor" : "white"}
              cx="1.72"
              cy="1.72"
              r="1.72"
              transform="translate(1.436)"
            />
            <path
              fill={invert ? "currentColor" : "white"}
              d="M158.012,254.281h-1.92a2.194,2.194,0,0,0-2.194,2.194h6.309A2.194,2.194,0,0,0,158.012,254.281Z"
              transform="translate(-153.898 -250.553)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

LockPremiumIcon.propTypes = {
  invert: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};
