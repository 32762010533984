


import React from "react";
import PropTypes from "prop-types";

export const FilterIcon = ({ color, size = 20 }) => {
  return (
    <svg width={size} fill={color || "currentColor"} viewBox="0 0 291.8 290.8">
      <path d="M0.8,40c1.9,11.4,7,22,14.8,30.6l70.4,76.8c17.4,19,27.1,43.9,27,69.7v73.8h66V217c0-25.8,9.6-50.6,27-69.6l70.3-76.8
	C284,62,289.2,51.4,291.1,40H0.8z"/>
      <path d="M0,0h291.8v28H0V0z" />
    </svg>
  );
};

FilterIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
