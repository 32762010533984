import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 18,
    fontWeight: 600,
    textTrasform: "uppercase",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
}));

