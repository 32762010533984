import {
  GET_COMMENTS,
  GET_COMMENTS_SUCCESS,
  GET_COMMENTS_FAILURE,
  ADD_COMMENT_SUCCESS,
  UPDATE_COMMENT_SUCCESS,
  DELETE_COMMENT_SUCCESS,
} from "store/actions";

const initialState = {
  entityId: null,
  entity: null,
  commentsById: {},
  commentIds: [],
  loading: false,
  err: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        ...action.payload,
        commentsById: {},
        commentIds: [],
        loading: true,
        err: null
      };
    case GET_COMMENTS_SUCCESS: {
      const commentsById = {};
      const commentIds = [];
      if (Array.isArray(action.payload)) {
        action.payload.forEach(item => {
          commentsById[item.id] = item;
          commentIds.push(item.id);
        });
      }
      return {
        ...state,
        commentsById,
        commentIds,
        loading: false,
        err: null
      };
    }
    case GET_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        err: action.payload
      };
    case ADD_COMMENT_SUCCESS:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.payload.id]: action.payload
        },
        commentIds: [...state.commentIds, action.payload.id],
      };
    case UPDATE_COMMENT_SUCCESS:
      return {
        ...state,
        commentsById: {
          ...state.commentsById,
          [action.payload.id]: action.payload
        },
      };
    case DELETE_COMMENT_SUCCESS: {
      const commentsById = { ...state.commentsById };
      const commentIds = [...state.commentIds];
      delete commentsById[action.payload];
      commentIds.splice(commentIds.indexOf(action.payload), 1);
      return {
        ...state,
        commentsById,
        commentIds
      };
    }
    default:
      return state;
  }
}
