import React from "react";
import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import { EventCard } from "components";


export const EventList = ({
  replayEvents = [],
  liveEvents = [],
  upcomingEvents = [],
  searchEvents = [],
}) => {

  return (
    <>
      {!!replayEvents.length &&
        <Grid container spacing={2}>
          {replayEvents.map((event, key) => (
            <Grid key={key} item xs={12} sm={6} md={4}>
              <EventCard event={event} replayMode={true} />
            </Grid>
          ))}
        </Grid>
      }
      {!!searchEvents.length &&
        <Grid container spacing={2}>
          {searchEvents.map((event, key) => (
            <Grid key={key} item xs={12} sm={6} md={4}>
              <EventCard event={event} />
            </Grid>
          ))}
        </Grid>
      }
      {!!liveEvents.length &&
        <>
          {liveEvents.map((event, key) => (
            <Box mb={2} key={key}>
              <EventCard event={event} />
            </Box>
          ))}
        </>
      }
      {!!upcomingEvents.length &&
        <Grid container spacing={2}>
          {upcomingEvents.map((event, key) => (
            <Grid item key={key} xs={12} sm={6}>
              <EventCard event={event} />
            </Grid>
          ))}
        </Grid>
      }
    </>
  );
};

EventList.propTypes = {
  replayEvents: PropTypes.arrayOf(PropTypes.object),
  liveEvents: PropTypes.arrayOf(PropTypes.object),
  upcomingEvents: PropTypes.arrayOf(PropTypes.object),
};
