import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { UserIcon } from "components/icons";
import { MD5 } from "crypto-js";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => ({
  small: {
    width: 35,
    height: 35
  },
}));

export const Gravatar = ({ userInfo, small }) => {
  const classes = useStyles();
  const [hasGravatar, setHasGravatar] = useState(true);
  if (!userInfo) {
    return <UserIcon color="white" size={25} />;
  }
  const url = `https://www.gravatar.com/avatar/${MD5(userInfo.email)}?d=404`;

  useEffect(() => {
    axios.get(url).then(() => {
      setHasGravatar(true);
    }).catch(() => {
      setHasGravatar(false);
    });
  }, [url]);

  const avatarString = (userInfo.firstName && userInfo.lastName ? userInfo.firstName[0] + userInfo.lastName[0] : userInfo.email.slice(0, 2)).toUpperCase();

  return (
    <>
      {hasGravatar &&
        <Avatar src={url} className={small ? classes.small : null} />
      }
      {!hasGravatar &&
        <Avatar className={small ? classes.small : null}><span>{avatarString}</span></Avatar>
      }
    </>
  );
};