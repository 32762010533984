import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { GameBadge, EventLink } from "components";
import ReactHtmlParser from "react-html-parser";
import { useStyles } from "./styles";
import { COLOR_NEWS, COLOR_TV } from "constants/color";

export const NewsSocialWidget = ({ showLocation, news }) => {
  const classes = useStyles();

  const { gameId, eventId, text, title, isEventLive, eventTypeName, city, countryId } = news;
  const parsedText = useMemo(() => {
    return text
      .replace(/<div.+fr-embedly.+href="(https:\/\/www.facebook.com\/\S+\/posts\/\S+)".+><\/div>/, '<div class="fb-wg-container"><div class="fb-post" data-href="$1" data-width="634" data-show-text="true"></div></div>')
      .replace(/<div.+fr-embedly.+href="(https:\/\/www.facebook.com\/\S+\/videos\/\S+)".+><\/div>/, '<div class="fb-wg-container"><div class="fb-video" data-href="$1" data-show-text="false"></div></div>')
      .replace(/<p><br><\/p>$/, '');

  }, [text]);

  return (
    <div className={classes.root}>
      <Box display="flex" alignItems="center">
        <GameBadge gameId={Number(gameId)} bgColor={isEventLive ? COLOR_TV : COLOR_NEWS} />
        <EventLink
          eventId={eventId}
          eventTypeName={eventTypeName}
          city={showLocation ? city : null}
          countryId={showLocation ? countryId : null} />

        <div className={classes.space}></div>
      </Box>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Box>
        {ReactHtmlParser(parsedText ? parsedText : "")}
      </Box>
    </div>
  );

}

