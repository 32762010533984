export const subscriptionList = {
  submitUrl: "https://0b6a4498.sibforms.com/serve/MUIEAHbU0JNNW2wMnqGylconxbS0O9m_LxrbmQR2aCOtqRV1-bPkxsWnJlKAJvaRYFDEF08sKUg4Z2_zR5QJMc7A37eADVF2jSrzkNxdY-2NfRngZDnDzvOYxwRRxfIPgtAByA4aq1SrLE1t4OSXCp2U9sZhTuXlR_HzUKAmdcjpc2o6e2rXEdBDiTub0jwp_Yuj_e5ADy0BbY3Q?isAjax=1",
  en: [
    {
      "name": "lists_28[]",
      "label": "Carom",
      "value": "11"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "30"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "2"
    }
  ],
  de: [
    {
      "name": "lists_28[]",
      "label": "Karambol",
      "value": "13"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "35"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "41"
    }
  ],
  es: [
    {
      "name": "lists_28[]",
      "label": "Carambole",
      "value": "12"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "31"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "40"
    }
  ],
  fr: [
    {
      "name": "lists_28[]",
      "label": "Carambole",
      "value": "10"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "32"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "39"
    }
  ],
  ko: [
    {
      "name": "lists_28[]",
      "label": "캐롬빌리아드",
      "value": "16"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "33"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "43"
    }
  ],
  nl: [
    {
      "name": "lists_28[]",
      "label": "Carambole",
      "value": "14"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "34"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "42"
    }
  ],
  tr: [
    {
      "name": "lists_28[]",
      "label": "Karambol",
      "value": "18"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "36"
    },
    {
      "name": "lists_28[]",
      "label": "Pool",
      "value": "45"
    }
  ],
  vi: [
    {
      "name": "lists_28[]",
      "label": "Đánh Bida",
      "value": "38"
    },
    {
      "name": "lists_28[]",
      "label": "Snooker",
      "value": "37"
    },
    {
      "name": "lists_28[]",
      "label": "Bida Lỗ",
      "value": "44"
    }
  ]
};