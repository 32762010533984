import React from "react";
import { Box, Grid, Typography, IconButton, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  PlayArrow as PlayArrowIcon,
  History as HistoryIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import * as VideoTypes from "constants/videoType";
import VideoCardStyles from "styles/Components/Cards/VideoCard";
import classnames from "classnames";
import { formatSeconds, formatDate } from "utils/time";
import { isYoutubeUrl, getYoutubeThumbnail } from "utils/video";
import { EVENTS_MEDIA_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { abbreviateNumber } from "utils";

const useStyles = makeStyles(VideoCardStyles);

const VideoCard = ({
  video,
  isCurrent,
  onClick,
}) => {
  const { type, path, eventId, thumbnail, hits, duration, createdAt, description } = video;
  const classes = useStyles();
  let playedPercent = ((video.lastPlayed || 0) / video.duration) * 100;
  if (playedPercent > 100) {
    playedPercent = 0;
  }

  const defaultImg = `${UPLOADED_MEDIA_URL}/assets/static/images/default-image.jpg`;
  let thumbnailImage = defaultImg;
  if (thumbnail) {
    thumbnailImage = thumbnail.startsWith("http") ? thumbnail : `${EVENTS_MEDIA_URL}/${eventId}/videos/${thumbnail}`;
  }

  if (type === VideoTypes.EXTERNAL) {
    if (isYoutubeUrl(path)) {
      thumbnailImage = getYoutubeThumbnail(path);
    }
  }

  return (
    <Grid container className={classnames(classes.root, isCurrent ? classes.isCurrent : null)} onClick={onClick}>
      <Grid item lg={6} md={5} sm={5} xs={5}>
        <Box className={classes.thumbnailContainer}>
          <img
            onError={(e) => e.target.src = defaultImg}
            alt="thumbnail"
            src={thumbnailImage}
            className={classes.thumbnail}
          />
          <Box className={classes.overlay}>
            <IconButton color="primary" className={classes.playBtn} size="large">
              <PlayArrowIcon fontSize="large" />
            </IconButton>
          </Box>
          <LinearProgress
            variant="determinate"
            value={playedPercent}>

          </LinearProgress>
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.second}
          >
            {formatSeconds(duration)}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        lg={6} md={7} sm={7} xs={7}
        className={classes.infoContainer}
      >
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
          <Typography
            variant="body2"
            className={classes.infoItem}
          >
            <HistoryIcon className={classes.infoIcon} />
            &nbsp;
            {formatDate((new Date(createdAt)).getTime(), "L", true, 7)}
          </Typography>
          {!!hits &&
            <Typography
              variant="body2"
              className={classes.infoItem}
            >
              <VisibilityIcon className={classes.infoIcon} />
              &nbsp;{abbreviateNumber(hits)}
            </Typography>
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoCard;
