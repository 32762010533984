export const PrimaryHeaderStyles = (theme) => ({
  appBar: {
    background: "white",
    color: theme.palette.dark
  },
  primaryNavsContainer: {
    display: "flex",
    maxWidth: 850,
    height: "inherit",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  navItem: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3.4),
    height: 40,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
    fontWeight: 800,
  },
  logo: {
    height: 18,
  },
  logoText: {
    fontWeight: 800,
    fontSize: 17,
  },
  activeTopNavItem: {
    position: "relative",
    "&::before": {
      position: "absolute",
      content: "''",
      background: `url(${"https://b-cdn.kozoom.com/assets"}/static/icons/rounded-triangle-indicator.svg)`,
      filter:
        "invert(7%) sepia(52%) saturate(4099%) hue-rotate(236deg) brightness(92%) contrast(117%)",
      width: 20,
      height: 8,
      bottom: -2,
      left: "calc(50% - 10px)",
    },
  },
  flag: {
    width: 20,
    height: 20,
    borderRadius: 18,
    border: "1px solid #ddd"
  }
});
