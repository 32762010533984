import { IconButton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  setSearchKeyword
} from "store/actions/searchInput";
import { searchNews, searchEvents, searchPlayers, searchVideos } from "store/actions/search";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { activeRoute } from "routes.client";
import { SearchInputStyles } from "./styles";
import { siteLangCode, siteSportSlug } from "../../utils/site";

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(window.location.search);
    const keyword = this.props.searchTerms || query.get("k") || "";
    this.state = { keyword };

    this.props.setSearchKeyword(keyword);
    this.handleSearchBySite(false);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onKeyChange = (e) => {
    this.setState({ keyword: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.onSearch();
  };

  onSearch = () => {
    const { keyword } = this.state;
    this.props.setSearchKeyword(keyword);
    this.handleSearchBySite();
  };

  handleSearchBySite(pushHistory = true) {
    const { keyword } = this.state;
    if (!keyword) return;

    // Dispatch search depends on the active route
    switch (activeRoute.name) {
      case "tv":
        this.props.searchNews(keyword);
        this.props.searchPlayers(keyword);
        this.props.searchEvents(keyword);
        this.props.searchVideos(keyword);
        break;

      default:
        break;
    }

    if (pushHistory) {
      this.props.history.push({
        pathname: `/${siteLangCode}/${siteSportSlug}/search`,
        search: `?k=${keyword}`,
      });
    }
  }


  render() {
    const { classes, borderColor } = this.props;
    return (
      <div className={classes.searchWrapper}>
        <form
          className={classes.searchForm}
          style={{ borderColor: borderColor ? borderColor : "white" }}
          onSubmit={this.handleSubmit}
        >
          <input
            className={classes.searchInput}
            type="text"
            placeholder={i18n.t("Search")}
            value={this.state.keyword}
            onChange={this.onKeyChange}
          />
          {this.state.keyword ? (
            <IconButton
              aria-label="Reset"
              className={classes.searchIcon}
              onClick={() => {
                this.setState({ keyword: "" });
              }}
              size="large">
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Search"
              className={classes.searchIcon}
              onClick={this.onSearch}
              size="large">
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </form>
      </div>
    );
  }
}

SearchInput.propTypes = {
  borderColor: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    searchTerms: state.searchInput.searchTerms,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setSearchKeyword,
      searchNews,
      searchPlayers,
      searchEvents,
      searchVideos,
    },
    dispatch
  );
};
export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(withStyles(SearchInputStyles)(SearchInput))
);
