import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { siteLangCode } from "utils/site";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "inherit",
  },
  icon: {
    verticalAlign: "middle",
    marginLeft: 5,
    marginRight: 5,
  }
}));

export const EventLink = ({ eventId, eventTypeName, city, countryId }) => {
  const classes = useStyles();
  const countries = useSelector(state => state.countries);
  let country = "";
  if (countryId && countries.countriesById[countryId]) {
    country = countries.countriesById[countryId].name;
  }
  const eventLink = `/${siteLangCode}/event/${eventId}`;

  return (
    <Link to={eventLink}>
      <Typography className={classes.title}>
        {eventTypeName}
        {(city || country) &&
          <RoomOutlinedIcon className={classes.icon} />
        }
        {city && country &&
          `${city} (${country})`
        }
        {city && !country &&
          `${city}`
        }
        {!city && country &&
          `${country}`
        }
      </Typography>
    </Link>
  );
};

EventLink.propTypes = {
  eventId: PropTypes.number,
  eventTypeName: PropTypes.string,
  city: PropTypes.string,
  countryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

