import { Box, Typography } from "@mui/material";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import React from "react";
import i18n from "i18next";
import { useSelector } from "react-redux";

const securitySVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Security & Payment.svg`;
const shippingSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Shipping & tracking.svg`;
const shakeSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/returns, refunds & exchanges.svg`;
const dealSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Deals for clubs and retailers.svg`;
const rewardsSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Reward Points.svg`;
const termsSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Terms & conditions.svg`;

export const ShopWithTrust = ({ classes }) => {
  const config = useSelector(state => state.config.configByKey);

  return (
    <Box my={2}>
      <Typography className={classes.title}>
        {i18n.t("Shop with trust")}
      </Typography>
      <a href={config?.SecurityPaymentLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={securitySVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Security & Payment")}
      </a>
      <a href={config?.ShippingTrackingLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={shippingSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Shipping & Tracking")}
      </a>
      <a href={config?.ReturnsRefundsLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={shakeSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Returns, Refunds & Exchanges")}
      </a>
      <a href={config?.DealLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={dealSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Deal for clubs and retailers")}
      </a>
      <a href={config?.RewardsPointsLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={rewardsSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Rewards Points")}
      </a>
      <a href={config?.TermsConditionsLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={termsSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Terms & Conditions")}
      </a>
    </Box>
  );
};
