import React from "react";
import { makeStyles } from "@mui/styles";
import DesktopBanner from "./desktop";
import MobileBanner from "./mobile";
import { Hidden } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const TopNewsFeedBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Hidden mdDown>
        <DesktopBanner />
      </Hidden>
      <Hidden mdUp>
        <MobileBanner />
      </Hidden>
    </div>
  );
};
