
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { useEffect, useState } from "react";
import { siteLangCode, siteSportId } from "utils/site";

const url = `${SERVER_URL}/event`;
const pageSize = 6;

export const usePage = () => {
  const [offset, setOffset] = useState(0);
  const [state, setState] = useState({
    data: [],
    loading: false,
    error: null,
    hasMore: false
  });
  const [currentDate] = useState(new Date());

  const onLoadMore = () => {
    setOffset(offset + 6);
  };

  useEffect(() => {
    setState((state) => ({
      ...state,
      loading: true,
    }));
    axios
      .get(`${url}`, {
        params: {
          startBefore: currentDate,
          limit: pageSize,
          offset,
          lang: siteLangCode,
          isLive: 1,
          hasVideosOnly: 1,
          sportId: siteSportId,
          orderBy: 'endTime',
          order: 'DESC'
        },
      })
      .then((res) => {
        setState((state) => ({
          ...state,
          loading: false,
          data: !offset ? res.data : [...state.data, ...res.data],
          hasMore: res.data.length >= pageSize
        }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: err.message
        }));
      });
  }, [offset, currentDate]);

  return {
    ...state,
    onLoadMore
  }
};
