import React from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import { login } from "auth";

const useStyles = makeStyles(() => ({
  button: {
    margin: "0 auto",
    padding: "8px 24px",
    borderRadius: 24,
    color: "white",
  },
}));

export const ChatLogginRequired = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center">
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={login}
      >
        {i18n.t("Login to start chatting")}
      </Button>
    </Box>
  );
};
