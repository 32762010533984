import {
  AppBar,
  Box,
  Hidden,
  Container,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import i18n from "i18next";
import React from "react";
import { Link, matchPath } from "react-router-dom";
import { activeRoute } from "routes.client";
import { SubNavigatorStyles } from "./SubNavigatorStyles";
import { siteLangCode, siteSportSlug } from "../../utils/site";

const SubNavigator = () => {

  if (!activeRoute) {
    return null;
  }

  const { path: baseLink, children: subRoutes } = activeRoute;
  const classes = makeStyles(SubNavigatorStyles)();
  return (
    <Hidden mdDown>
      <AppBar className={classes.appBar}>
        <Container>
          <Box display="flex" justifyContent="center" py={1}>
            {Array.isArray(subRoutes) &&
              subRoutes.map((route, index) => {
                if (route.visible === false) return null;
                const isCurrentPath = !!matchPath(
                  location.pathname + location.search,
                  (route.absolutePath ? "" : baseLink) + route.path
                );
                return (
                  <div
                    key={index}
                    className={classNames(
                      classes.subNavContainer,
                      isCurrentPath ? classes.active : null
                    )}
                  >
                    <Link to={`${route.absolutePath ? "" : "/" + siteLangCode + "/" + siteSportSlug}${route.path.replace(':lang', siteLangCode).replace(':sport', siteSportSlug)}`}>
                      <Typography className={classes.subNav}>
                        {i18n.t(route.name)}
                      </Typography>
                    </Link>
                  </div>
                );
              })}
          </Box>
        </Container>
      </AppBar>
    </Hidden>
  );
};

export default SubNavigator;
