import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { siteLangCode } from "../../utils/site";

export const GET_LIVE_STREAMS_START = "GET_LIVE_STREAMS_START";
export const GET_LIVE_STREAMS_FINISH = "GET_LIVE_STREAMS_FINISH";
export const GET_LIVE_STREAMS_ERROR = "GET_LIVE_STREAMS_ERROR";

export const GET_EVENT_FEEDS_START = "GET_EVENT_FEEDS_START";
export const GET_EVENT_FEEDS_FINISH = "GET_EVENT_FEEDS_FINISH";
export const GET_EVENT_FEEDS_ERROR = "GET_EVENT_FEEDS_ERROR";


const url = `${SERVER_URL}/stream`;

export const getLiveStreams = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIVE_STREAMS_START,
    });
    axios
      .get(url, {
        params: {
          eventId,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_LIVE_STREAMS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_LIVE_STREAMS_ERROR,
          payload: err,
        });
      });
  };
};

export const getEventFeeds = (eventId = 0, entities = "news,video") => {
  return (dispatch) => {
    dispatch({
      type: GET_EVENT_FEEDS_START,
    });
    axios
      .get(`${SERVER_URL}/feed`, {
        params: {
          lang: siteLangCode,
          eventId,
          entities,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_EVENT_FEEDS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_EVENT_FEEDS_ERROR,
          payload: err,
        });
      });
  };
};
