import React, { useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import moment from "moment";
import i18n from "i18next";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { useCountDown } from "../../pages/TV/Event/hooks/live";
import { formatSeconds } from "utils/time";
import { useDispatch } from "react-redux";
import { useEventBanner } from "pages/TV/Event/hooks";
import { eventGoesLive } from "store/actions";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: theme.palette.darkBlue,
  },
  thumbnail: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  live: {
    position: "absolute",
    top: 8,
    left: 8,
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase"
  },
  replay: {
    position: "absolute",
    top: 8,
    left: 8,
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: "bold",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  upcoming: {
    fontWeight: "bold",
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  upcomingDay: {
    fontSize: 24,
    marginRight: 5,
    textTransform: "uppercase"
  },
  upcomingLabel1: {
    fontSize: 13,
    lineHeight: 1,
    marginTop: 5,
    textTransform: "uppercase"
  },
  upcomingLabel2: {
    fontSize: 18,
  },
}));

const EventCardPreview = ({ event, status, className = "" }) => {
  const classes = useStyles();
  const thumbnail = useEventBanner(event);

  const defaultImg = `${UPLOADED_MEDIA_URL}/assets/static/images/default-image.jpg`;

  const onLoadDefault = (ev) => {
    ev.target.src = defaultImg;
  };
  const diffDays = moment(event.startTime).diff(moment(), "days");
  const diffHours = moment(event.startTime).diff(moment(), "hours");

  return (
    <Box className={`${classes.container} ${className}`}>
      <img
        onError={onLoadDefault}
        className={classes.thumbnail}
        alt="thumbnail"
        src={thumbnail || defaultImg}
      />
      {status === 'live' && (
        <Box px={1} borderRadius="4px" className={classes.live}>
          {i18n.t("Live")}
        </Box>
      )}
      {status === 'replay' && (
        <Box px={1} borderRadius="4px" className={classes.replay}>
          {i18n.t("Replay")}
        </Box>
      )}
      {status === 'upcoming' && (
        <Box
          px={1}
          borderRadius="4px"
          className={classes.replay}
        >
          <Box display="flex">
            <Box className={classes.upcomingDay}>
              {diffDays > 1 ? diffDays + 1 : diffHours > 1 ? diffHours : <CountDown toTime={event.startTime} eventId={event.id} />}
            </Box>
            {diffDays > 1 &&
              <Box className={classes.upcomingLabel2}>
                {i18n.t("Days")}
              </Box>
            }
            {diffDays <= 1 && diffHours > 1 &&
              <Box className={classes.upcomingLabel2}>
                {i18n.t("Hours")}
              </Box>
            }
          </Box>
        </Box>
      )}
    </Box>
  );
};

EventCardPreview.propTypes = {
  event: PropTypes.object,
  status: PropTypes.string,
  className: PropTypes.string,
};

const CountDown = ({ toTime, eventId }) => {
  const countDownSeconds = useCountDown(toTime);
  const dispatch = useDispatch();
  useEffect(() => {
    if (countDownSeconds === 0) {
      // There must be a delay before letting the event go live, otherwise, there will be a loop here
      setTimeout(() => {
        dispatch(eventGoesLive(eventId))
      }, 1000)
    }
  }, [countDownSeconds, dispatch, eventId]);
  return formatSeconds(countDownSeconds > 0 ? countDownSeconds : 0);
}

export default EventCardPreview;
