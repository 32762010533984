import { Box, Typography } from "@mui/material";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import React from "react";
import { NavLink } from "react-router-dom";
import i18n from "i18next";
import { useSelector } from "react-redux";
import { currentSite, siteLangCode } from "utils/site";
import { kozoomSites } from "routes.client";

const contactUsSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Contact us.svg`;
const premiumPassSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Premium Pass.svg`;
const advertiseWithUsSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/advertise with us.svg`;
const ourCompanySVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Our company.svg`;
const downloadOurLogoSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Download our logos.svg`;
const privacySVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Privacy.svg`;


export const AboutKozoom = ({ classes }) => {
  const config = useSelector(state => state.config.configByKey);
  return (
    <Box my={2}>
      <Typography className={classes.title}>
        {i18n.t("About Kozoom")}
      </Typography>
      <a href={config?.ContactUsLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={contactUsSVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Contact Us")}
      </a>
      {currentSite === 'tv' &&
        <NavLink to={`/${siteLangCode}/premium-pre-checkout`} className={classes.link}>
          <img src={premiumPassSVG} alt="icon" className={classes.linkIcon} />
          {i18n.t("Premium Pass")}
        </NavLink>
      }
      {currentSite !== 'tv' &&
        <a href={`${kozoomSites.tv}/${siteLangCode}/premium-pre-checkout`} target="_blank" rel="noreferrer" className={classes.link}>
          <img src={premiumPassSVG} alt="icon" className={classes.linkIcon} />
          {i18n.t("Premium Pass")}
        </a>
      }
      <a href={config?.AdvertiseWithUsLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img
          src={advertiseWithUsSVG}
          alt="icon"
          className={classes.linkIcon}
        />
        {i18n.t("Advertise with us")}
      </a>
      <a href={config?.OurCompanyLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={ourCompanySVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Our Company")}
      </a>
      <a href={config?.DownloadOurLogosLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img
          src={downloadOurLogoSVG}
          alt="icon"
          className={classes.linkIcon}
        />
        {i18n.t("Download our logos")}
      </a>
      <a href={config?.PrivacyCookiePolicyLink?.value} target="_blank" rel="noreferrer" className={classes.link}>
        <img src={privacySVG} alt="icon" className={classes.linkIcon} />
        {i18n.t("Privacy & Cookie Policy")}
      </a>
    </Box>
  );
};

export default AboutKozoom;
