import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AppProvider from "./components/AppProvider/AppProvider";
import Client from "./containers/Client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import configureStore from "./store";
import { siteLangCode, siteSportSlug, currentSite } from "./utils/site";
import i18nConfig from "i18n/i18n";
import { msalInstance } from "auth";
import * as Sentry from "@sentry/react";

export const { store } = configureStore();


i18nConfig.changeLanguage(siteLangCode);

export const getDefaultPath = (lang, sportSlug) => {
  switch (currentSite) {
    case "news":
      return `/${lang}/${sportSlug}/news`;
    case "tv":
      return `/${lang}/tv-channel`;
    case "game":
      return `/${lang}/general/instructions`;
    default:
      return `/${lang}/${sportSlug}`;
  }
};

Sentry.init({
  dsn: "https://4ec39f5d743c45fb8496dbdb82e04a01@o4505137399136256.ingest.sentry.io/4505137437081600",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.4, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== 'development',
  allowUrls: ['kozoom.com']
});

const appLoader = document.getElementById('app-loader');
if (appLoader) {
  appLoader.remove();
}

render(
  <Provider store={store}>
    <AppProvider pca={msalInstance}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to={getDefaultPath(siteLangCode, siteSportSlug)} />}
          />
          <Route path="/:lang/:sport" component={Client} />
          <Route path="/:lang" component={Client} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  </Provider>,
  document.getElementById("root")
);
serviceWorkerRegistration.unregister();
