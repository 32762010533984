import { useEffect, useState, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  getLiveAndUpcomingEvents,
} from "store/actions";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
}));

export const usePage = () => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  const liveAndUpcomingEvents = useSelector((state) => state.liveAndUpcomingEvents);

  const [currentDate, setCurrentDate] = useState(new Date());

  const onLoadMore = () => {
    dispatch(getLiveAndUpcomingEvents(currentDate, offset + 8));
    setOffset(offset + 8);
  };

  useEffect(() => {
    dispatch(getLiveAndUpcomingEvents(currentDate, 0, 8));
  }, [currentDate, dispatch]);

  return {
    classes,
    currentDate,
    setCurrentDate,
    liveEvents: liveAndUpcomingEvents.live.data,
    upcomingEvents: liveAndUpcomingEvents.upcoming.data,
    hasMore: liveAndUpcomingEvents.upcoming.hasMore,
    loadingMore: liveAndUpcomingEvents.upcoming.loading,
    initLoading: liveAndUpcomingEvents.initLoading,
    error: liveAndUpcomingEvents.upcoming.error,
    onLoadMore,
  };
};
