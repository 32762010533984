import {
  GET_EVENT_VIDEOS_START,
  GET_EVENT_VIDEOS_FINISH,
  GET_EVENT_VIDEOS_ERROR,
  GET_PLAYER_VIDEOS_START,
  GET_PLAYER_VIDEOS_FINISH,
  GET_PLAYER_VIDEOS_ERROR,
  UPDATE_LAST_PLAYED_TIME_FINISH,
} from "store/actions";
import { unionBy } from "lodash";

/**
 * This playlist reducer is used for event replay page, player profile page to show the playlist
 */
const initialState = {
  loading: false,
  videos: [],
  err: null,
  hasMore: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER_VIDEOS_START:
    case GET_EVENT_VIDEOS_START:
      return {
        ...state,
        videos: action.payload && action.payload.resetVideos ? [] : state.videos,
        loading: true
      };
    case GET_PLAYER_VIDEOS_FINISH:
    case GET_EVENT_VIDEOS_FINISH:
      return {
        ...state,
        videos: Array.isArray(action.payload) ? unionBy(state.videos, action.payload, 'id') : [],
        hasMore: Array.isArray(action.payload) && action.payload.length === 20 ? true : false,
        loading: false,
        err: null
      };
    case GET_PLAYER_VIDEOS_ERROR:
    case GET_EVENT_VIDEOS_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload
      };
    case UPDATE_LAST_PLAYED_TIME_FINISH: {
      const videos = state.videos;
      const video = videos.find(item => item.id == action.payload.videoId);
      if (video) {
        video.lastPlayed = action.payload.lastPlayed;
      }
      return {
        ...state,
        videos
      };
    }
    default:
      return state;
  }
}
