export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const showSnackbar = (severity, message) => ({
  type: SHOW_SNACKBAR,
  payload: {
    severity,
    message
  },
});

export const hideSnackbar = () => ({
  type: HIDE_SNACKBAR,
});
