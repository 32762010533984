import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { Typography } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { Star as StarIcon } from "@mui/icons-material";

import MatchResultCardStyles from "styles/Components/Cards/MatchResultCard";
import { formatDate, formatSeconds } from "utils/time";
import { findItemById } from "utils";
import { UPLOADED_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import { siteLangCode, siteSportSlug } from "../../utils/site";

class MatchResultCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  addDefaultImage = (ev) => {
    ev.target.src = USER_DEFAULT_AVATAR;
  };

  _renderVideoInfo = () => {
    const { classes, video } = this.props;
    let video_link = `/${siteLangCode}/tv/replay/${video.id}`;
    return (
      <div className={classes.videoThumbnailContainer}>
        <NavLink to={video_link}>
          <img
            onError={(ev) =>
              (ev.target.src = "https://b-cdn.kozoom.com/assets/static/images/default-image.jpg")
            }
            alt="video thumbnail"
            src={`${UPLOADED_MEDIA_URL}/video.thumbnail`}
            className={classes.videoThumbnail}
          />
        </NavLink>
        <div className={classes.videoDuration}>
          {formatSeconds(video.duration)}
        </div>
      </div>
    );
  };

  _renderSingleMatchSet = () => {
    const { classes, match_sets } = this.props;
    const item = match_sets[0];
    return (
      <div style={{ display: "flex", height: "100%" }}>
        <div
          className={classes.singleMatchSet}
          style={{ background: "#bbbbbb" }}
        >
          <div className="points">{item.points_1}</div>
          <div className="innings">{item.innings_1}</div>
        </div>
        <div
          className={classes.singleMatchSet}
          style={{ background: "#dca229", flexDirection: "row-reverse" }}
        >
          <div className="points">{item.points_2}</div>
          <div className="innings">{item.innings_2}</div>
        </div>
      </div>
    );
  };

  _renderMultiMatchSets = () => {
    let { classes, match_sets, nb_sets } = this.props;
    match_sets = match_sets.sort((a, b) => a.number - b.number);
    let sets_data = [...match_sets];
    for (var i = 0; i < nb_sets - match_sets.length; i++) {
      sets_data.push(null);
    }

    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          className={classes.multiMatchSets}
          style={{ background: "#bbb", alignItems: "flex-end" }}
        >
          {sets_data.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.multiMatchSetItem}
                style={{
                  minWidth: `calc(100% / ${nb_sets} - 3px)`,
                  borderTopLeftRadius: 6,
                  borderTopRightRadius: 6,
                }}
              >
                {item && (
                  <div className="points">
                    {item.points_1}
                    <sub className="innings">{item.innings_1}</sub>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={classes.multiMatchSets}
          style={{ background: "#dca229", alignItems: "flex-start" }}
        >
          {sets_data.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.multiMatchSetItem}
                style={{
                  minWidth: `calc(100% / ${nb_sets} - 3px)`,
                  borderBottomLeftRadius: 6,
                  borderBottomRightRadius: 6,
                }}
              >
                {item && (
                  <div className="points">
                    {item.points_2}
                    <sup className="innings">{item.innings_2}</sup>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  _renderMatchSets = () => {
    const { classes, match_sets } = this.props;
    return (
      <div className={classes.matchSetsContainer}>
        {match_sets.length > 0
          ? match_sets.length === 1
            ? this._renderSingleMatchSet()
            : this._renderMultiMatchSets()
          : null}
      </div>
    );
  };
  _renderDetail = () => {
    const {
      classes,
      player1,
      player2,
      countries,
      run1,
      run2,
      match_sets,
    } = this.props;

    let player1_nationality = "";
    player1_nationality = findItemById(countries, player1.country_id);
    player1_nationality
      ? (player1_nationality = player1_nationality.cio)
      : (player1_nationality = "");

    let player2_nationality = "";
    player2_nationality = findItemById(countries, player2.country_id);
    player2_nationality
      ? (player2_nationality = player2_nationality.cio)
      : (player2_nationality = "");

    let points1 = 0;
    let points2 = 0;
    let innings1 = 0;
    let innings2 = 0;

    for (var item of match_sets) {
      item.points_1 > 0 && (points1 += item.points_1);
      item.points_2 > 0 && (points2 += item.points_2);
      item.innings_1 > 0 && (innings1 += item.innings_1);
      item.innings_2 > 0 && (innings2 += item.innings_2);
    }

    return (
      <div className={classes.detailSection}>
        <div className={classNames(classes.player, classes.player1)}>
          <img
            className={classes.flag}
            alt="flag"
            src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${player1_nationality}.svg`}
          />
          <Typography className={classes.player_name}>
            {player1.name}
            {player1.isChampion ? (
              <StarIcon className={classes.iconChampion} />
            ) : null}
          </Typography>
          <div className={classes.recordContainer}>
            <div className={classes.recordItem}>
              <div className="title">HR</div>
              <div className="value">{run1}</div>
            </div>
            <div className={classes.recordItem}>
              <div className="title">AVG</div>
              <div className="value">
                {innings1 > 0 ? (points1 / innings1).toFixed(3) : 0}
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <img
            onError={this.addDefaultImage}
            className={classes.avatar}
            alt="avatar"
            src={`${UPLOADED_MEDIA_URL}/${player1.avatar}`}
            style={{ borderColor: "#bbb" }}
          />
          {this._renderMatchSets()}
          <img
            onError={this.addDefaultImage}
            className={classes.avatar}
            alt="avatar"
            src={`${UPLOADED_MEDIA_URL}/${player2.avatar}`}
            style={{ borderColor: "#e6aa2c" }}
          />
        </div>
        <div className={classNames(classes.player, classes.player2)}>
          <div className={classes.recordContainer}>
            <div className={classes.recordItem}>
              <div className="title">HR</div>
              <div className="value">{run2}</div>
            </div>
            <div className={classes.recordItem}>
              <div className="title">AVG</div>
              <div className="value">
                {innings2 > 0 ? (points2 / innings2).toFixed(3) : 0}
              </div>
            </div>
          </div>
          <Typography
            className={classes.player_name}
            style={{ justifyContent: "flex-end" }}
          >
            {player2.isChampion ? (
              <StarIcon className={classes.iconChampion} />
            ) : null}
            {player2.name}
          </Typography>
          <img
            className={classes.flag}
            alt="flag"
            src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${player2_nationality}.svg`}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      classes,
      video,
      event_id,
      event,
      game,
      match_date,
      match_stage,
    } = this.props;
    let eventLink = `/${siteLangCode}/${siteSportSlug}/event/${event_id}`;
    return (
      <div className={classes.root}>
        {video && this._renderVideoInfo()}
        <div className={classes.infoContainer}>
          <div style={{ display: "flex" }}>
            {game && game !== "" && (
              <Typography variant="body1" className={classes.game}>
                {game}
              </Typography>
            )}
            <NavLink to={eventLink} className={classes.eventTitle}>
              {event}
            </NavLink>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>{match_stage}</Typography>
            <Typography color="textSecondary" className={classes.date}>
              {formatDate(match_date, "L")}
            </Typography>
          </div>
          {this._renderDetail()}
        </div>
      </div>
    );
  }
}

MatchResultCard.propTypes = {
  id: PropTypes.number,
  event_id: PropTypes.number,
  event: PropTypes.string,
};

export default withStyles(MatchResultCardStyles)(MatchResultCard);
