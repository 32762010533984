import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getComments } from "store/actions";

export const useCommentCount = (videoId) => {
  const commentCount = useSelector((state) => state.comment.commentIds.length);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getComments(videoId, "video"));
  }, [videoId, dispatch])

  return commentCount;
};
