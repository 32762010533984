import React, { useEffect, useState } from "react";
import { NewsCard, ReviveVerticalBanner, Spinner } from "components";
import { Box, Paper, Typography } from "@mui/material";
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { siteLangCode } from "utils/site";
import i18n from "i18next";

export const RelatedNews = ({ id, eventId }) => {
  const [loading, setLoading] = useState(false);
  const [relatedNews, setRelatedNews] = useState([]);
  useEffect(() => {
    if (eventId) {
      setLoading(true);
      axios.get(`${SERVER_URL}/news`, { params: { eventId, lang: siteLangCode } }).then(res => {
        setRelatedNews(res.data.filter(item => item.id !== id).slice(0, 10));
        setLoading(false);
      })
    }
  }, [id, eventId]);

  return (
    <>
      {loading &&
        <Box display="flex" alignItems="center" justifyContent="center" margin={2}>
          <Spinner />
        </Box>
      }
      {!!relatedNews.length &&
        <Paper>
          <Box p={2} mb={2}>
            <Typography fontSize={20} fontWeight={600}>
              {i18n.t("Related News")}
            </Typography>
            {relatedNews.map((item, index) => (
              <Box key={index} mt={!index ? 1 : 2}>
                <NewsCard
                  news={item}
                  isSimple={true}
                />
              </Box>
            ))}
          </Box>
        </Paper>
      }
      <ReviveVerticalBanner />
    </>
  );
};