import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import DatePicker from "react-datepicker";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const useStyles = makeStyles(() => ({
  calendar: {
    position: "sticky",
    top: 130,
    textAlign: "center"
  },
}));

const CalendarView = ({ selectedDates = [], date = new Date(), onChange }) => {
  const classes = useStyles();

  const renderDayContents = (day, date) => {
    if (selectedDates.includes(moment(date).format("Y-MM-DD"))) {
      if (moment(date).isAfter(moment())) {
        return <span className="has-event">{date.getDate()}</span>;
      }
      return <span className="has-event old-event">{date.getDate()}</span>;
    }
    return <span>{date.getDate()}</span>;
  };

  return (
    <div className={classes.calendar}>
      <DatePicker
        inline
        monthsShown={2}
        renderDayContents={renderDayContents}
        minDate={new Date()}
        selected={date}
        onChange={onChange}
      />
    </div>
  );
};

CalendarView.propTypes = {
  disablePastDates: PropTypes.bool,
  date: PropTypes.any,
  selectedDates: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
};

export default CalendarView;
