export const theme = {
  primary: {
    600: "#fff",
    900: "#e0e0e2"
  },
  grey: { 300: "#333" },
  white: "transparent",
  green: {
    300: "#e00e2f"
  },
  scrollbar: {
    border: "#ffffff",
    thumb: {
      bg: "transparent"
    }
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#e0e0e2"
  },
  gradient: {
    blue: {
      300: "#a9a9bc",
      600: "#8c8ca6",
      900: "#747495"
    }
  },
  text: {
    grey: {
      300: "#444",
      500: "#333"
    }
  },
  timeline: {
    divider: {
      bg: "#444"
    }
  }
};
