import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import i18n from "i18next";
import { CalendarCard } from "components";
import { useLocalTime } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 4,
    overflow: "hidden",
  },
  container: {
    width: 65,
    padding: "2px 4px 4px",
    backgroundColor: theme.palette.primary.main,
  },
  small: {
    width: 40,
    padding: "2px 4px 4px",
    margin: "0 1px",
    backgroundColor: theme.palette.primary.main,
  },
  label: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
    lineHeight: "11px",
    textTransform: "uppercase",
    color: "white",
  },
  value: {
    padding: "2px 4px",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "13px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "black",
    backgroundColor: "white",
  },
  live: {
    margin: "0 2px",
    padding: "5px 12px",
    fontSize: 24,
    lineHeight: 1,
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase",
  },
  replay: {
    margin: "0 4px",
    padding: "4.5px 8px",
    fontSize: 24,
    lineHeight: 1,
    fontWeight: "bold",
    borderRadius: 4,
    backgroundColor: "white",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
}));

export const LiveStatus = ({ totalViews = 0, timezone = "Greenwich" }) => {
  const time = useLocalTime(timezone);
  const classes = useStyles();

  return (
    <Box display="flex" alignItems="flex-start" className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.label}>{i18n.t("Live Viewers")}</Box>
        <Box className={classes.value}>{totalViews}</Box>
      </Box>
      <Box className={classes.live}>{i18n.t("Live")}</Box>
      <Box className={classes.container}>
        <Box className={classes.label}>{i18n.t("Local Time")}</Box>
        <Box className={classes.value}>{time}</Box>
      </Box>
    </Box>
  );
};

export const ReplayStatus = ({ startTime, endTime }) => {
  const classes = useStyles();
  const isSameDay = moment(startTime).isSame(
    moment(endTime),
    "day"
  );

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Box mx={0.5}>
        <CalendarCard date={startTime} color="gray" />
      </Box>
      {!isSameDay && (
        <Box mx={0.5}>
          <CalendarCard date={endTime} color="gray" />
        </Box>
      )}
      <Box className={classes.replay}>{i18n.t("Replay")}</Box>
    </Box>
  );
};

export const UpcomingStatus = ({ startTime }) => {
  const classes = useStyles();
  const startMoment = moment(startTime);
  const [duration, setDuration] = useState(
    moment.duration(startMoment.diff(moment()))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setDuration(moment.duration(startMoment.diff(moment())));
    }, 60000);

    return () => clearInterval(interval);
  }, [startMoment]);

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <Box className={classes.small}>
        <Box className={classes.label}>{i18n.t("Days")}</Box>
        <Box className={classes.value}>{Math.floor(duration.asDays())}</Box>
      </Box>
      <Box className={classes.small}>
        <Box className={classes.label}>{i18n.t("Hours")}</Box>
        <Box className={classes.value}>{duration.hours()}</Box>
      </Box>
      <Box className={classes.small}>
        <Box className={classes.label}>{i18n.t("Minutes")}</Box>
        <Box className={classes.value}>{duration.minutes()}</Box>
      </Box>
    </Box>
  );
};

LiveStatus.propTypes = {
  totalView: PropTypes.number,
  timezone: PropTypes.string,
};

ReplayStatus.propTypes = {
  startTime: PropTypes.any,
  endTime: PropTypes.any,
};

UpcomingStatus.propTypes = {
  startTime: PropTypes.any,
};
