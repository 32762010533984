import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { siteLangCode, siteSportId } from "../../utils/site";

export const GET_LIVE_AND_UPCOMING_EVENTS_START = "GET_LIVE_AND_UPCOMING_EVENTS_START";
export const GET_LIVE_AND_UPCOMING_EVENTS_FINISH = "GET_LIVE_AND_UPCOMING_EVENTS_FINISH";
export const GET_LIVE_AND_UPCOMING_EVENTS_ERROR = "GET_LIVE_AND_UPCOMING_EVENTS_ERROR";

export const EVENT_GOES_LIVE = "EVENT_GOES_LIVE";

export const eventGoesLive = (event) => ({
  type: EVENT_GOES_LIVE,
  payload: event
});

export const getLiveAndUpcomingEvents = (endAfter = null, offset = 0, limit = 8) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIVE_AND_UPCOMING_EVENTS_START,
      payload: { isInit: !offset }
    });
    axios
      .get(`${SERVER_URL}/event`, {
        params: {
          endAfter,
          lang: siteLangCode,
          offset,
          limit,
          isLive: 1,
          sportId: siteSportId,
          orderBy: 'startTime',
          order: 'ASC'
        },
      })
      .then((res) => {
        dispatch({
          type: GET_LIVE_AND_UPCOMING_EVENTS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_LIVE_AND_UPCOMING_EVENTS_ERROR,
          payload: err,
        });
      });
  };
};

