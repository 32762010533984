import {
  GET_PLAYER_DETAIL_START,
  GET_PLAYER_DETAIL_FINISH,
  GET_PLAYER_DETAIL_ERROR,
  GET_PLAYER_LIVESTREAM_RECORDS_START,
  GET_PLAYER_LIVESTREAM_RECORDS_FINISH,
  GET_PLAYER_LIVESTREAM_RECORDS_ERROR,
  UPDATE_PLAYER_PROFILE_START,
  UPDATE_PLAYER_PROFILE_FINISH,
  UPDATE_PLAYER_PROFILE_ERROR,
  PLAYER_PROFILE_FORM_CHANGE,
} from "store/actions";
import { stringToSeconds } from "utils/time";

const initialState = {
  loading: false,
  saving: false,
  data: null,
  err: null,
  videosLoading: false,
  livestreamRecords: {
    loading: false,
    data: [],
    err: null
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLAYER_DETAIL_START:
      return {
        ...state,
        loading: true,
        data: null,
        err: null
      };
    case GET_PLAYER_DETAIL_FINISH:
      return {
        ...state,
        loading: false,
        data: action.payload,
        err: null
      };
    case GET_PLAYER_DETAIL_ERROR:
    case UPDATE_PLAYER_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload,
      };
    case UPDATE_PLAYER_PROFILE_START:
      return {
        ...state,
        saving: true,
      };
    case UPDATE_PLAYER_PROFILE_FINISH:
      return {
        ...state,
        saving: false,
      };
    case PLAYER_PROFILE_FORM_CHANGE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        }
      }
    case GET_PLAYER_LIVESTREAM_RECORDS_START:
      return {
        ...state,
        livestreamRecords: {
          ...state.livestreamRecords,
          data: [],
          loading: true
        }
      }
    case GET_PLAYER_LIVESTREAM_RECORDS_FINISH:
      return {
        ...state,
        livestreamRecords: {
          ...state.livestreamRecords,
          data: action.payload.map(item => ({
            id: Number(item.id),
            description: item.desc,
            duration: stringToSeconds(item.duration),
            baseVideoUrl: item.stream.replace(/^switlive/, "https:/"),
            thumbnail: item.thumbnail,
            createdAt: item.start,
            isLivestreamRecord: true
          })),
          loading: false,
          err: null
        }
      }
    case GET_PLAYER_LIVESTREAM_RECORDS_ERROR:
      return {
        ...state,
        livestreamRecords: {
          data: [],
          loading: false,
          err: action.payload
        }
      }
    default:
      return state;
  }
}
