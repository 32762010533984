import React from "react";
import { Box, Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 12,
    borderRadius: 10,
    color: "white",
    backgroundColor: "#4F4E79",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  text: {
    margin: "0 18px",
    fontSize: 20,
    fontWeight: 600,
    textAlign: "center",
  },
  subscribe: {
    marginLeft: 12,
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: 12,
    },
  },
  button: {
    width: 200,
    marginLeft: 16,
    marginRight: 16,
    borderWidth: 2,
    borderRadius: 24,
    color: "white",
  },
  caption: {
    marginTop: 8,
    fontSize: 12,
    fontWeight: 200,
    color: "white",
  },
}));

const Subscribe = ({ text, price, onSubscribe }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.text}>{text}</Typography>
      <Box className={classes.subscribe}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onSubscribe}
        >
          {i18n.t("Subscribe")}
        </Button>
        <Typography className={classes.caption}>
          {i18n.t("from")}
          {" "}
          <strong>{i18n.t("€{{price}}/month", { price })}</strong>
          {" "}
          {i18n.t("billed annually")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Subscribe;
