import React from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import Axios from "axios";
import classNames from "classnames";
import { GameBadge, Spinner, TopNewsFeedBanner } from "components";
import { COLOR_NEWS, COLOR_STORE, COLOR_TV } from "constants/color";
import { SERVER_URL } from "constants/serverUrl";
import i18n from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import DocumentTitle from "react-document-title";
import { useStyles } from "./styles";
import { siteLangCode, siteSportId } from "../../utils/site";

export const HomePage = () => {
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const params = {
      lang: siteLangCode,
      limit: 8,
    };
    const sportId = siteSportId;
    if (sportId > 1) {
      params["sportId"] = sportId;
    }

    setLoading(true);
    Axios.get(SERVER_URL + "/slide", { params })
      .then((res) => {
        setSlides(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const gameBgColor = useCallback((site) => {
    switch (site) {
      case 'news':
        return COLOR_NEWS;
      case 'store':
        return COLOR_STORE;
      default:
        return COLOR_TV;
    }
  }, []);

  const slideContent = useCallback((slide) => {
    return (
      <>
        <div className={classes.gameIcon}>
          {slide.gameId && Number(slide.gameId) !== 30 && (
            <GameBadge gameId={Number(slide.gameId)} bgColor={gameBgColor(slide.site)} />
          )}
          {slide.site === "store" && slide.storeTag && (
            <span className={classes.storeTag}>{slide.storeTag}</span>
          )}
        </div>
        <div className={classes.galleryTitle}>
          <h4>
            {slide.site === "tv" && !!slide.isLive && (
              <>
                <span className={classNames(classes.isLive)}></span>
                <span className={classNames(classes.isLiveText)}>
                  {i18n.t("Live")}
                </span>
              </>
            )}
            <span>{slide.title}</span>
          </h4>
        </div>
      </>
    );
  }, [classes, gameBgColor]);

  const docTitle = "Kozoom";
  const promoteSlide = useMemo(() => slides.find((item) => item.isPromoted), [slides]);
  const firstSlide = useMemo(() => slides.find((item) => !item.isPromoted), [slides]);
  const otherSlides = useMemo(() => slides.filter((item) => !item.isPromoted && item.id !== firstSlide.id), [slides, firstSlide]);

  return (
    <DocumentTitle title={docTitle}>
      <Container>
        <Typography className={classes.headerText}>
          {i18n.t("Welcome To Kozoom")}
        </Typography>
        {loading &&
          <div className={classes.loading}>
            <Spinner />
          </div>
        }
        {!!slides.length &&
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {firstSlide && (
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={0}>
                      <a
                        href={firstSlide.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={classNames(
                            classes.galleryItem,
                            firstSlide.title ? classes.overlay : null
                          )}
                          style={{
                            backgroundImage: `url(${firstSlide.image})`,
                            paddingTop: "calc(56.25% + 10px)",
                          }}
                        >
                          {slideContent(firstSlide)}
                        </div>
                      </a>
                    </Paper>
                  </Grid>
                )}
                {promoteSlide && (
                  <Grid item xs={12} sm={12}>
                    <Paper elevation={0}>
                      <a
                        href={promoteSlide.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={classNames(
                            classes.galleryItem,
                            classes.promotionItem
                          )}
                          style={{
                            backgroundImage: `url(${promoteSlide.image})`,
                          }}
                        ></div>
                      </a>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {otherSlides.map((slide, index) => (
                  <Grid key={index} item xs={12} sm={6}>
                    <Paper elevation={0}>
                      <a
                        href={slide.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className={classNames(
                            classes.galleryItem,
                            slide.title ? classes.overlay : null
                          )}
                          style={{
                            backgroundImage: `url(${slide.image})`,
                          }}
                        >
                          {slideContent(slide)}
                        </div>
                      </a>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        }
        {!loading && !slides.length &&
          <Typography className={classes.errorText}>
            {i18n.t("No Slides Available")}
          </Typography>
        }
        <Box mt={2}>
          <TopNewsFeedBanner />
        </Box>
      </Container>
    </DocumentTitle>
  );
}

