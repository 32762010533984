import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Hidden, Typography } from "@mui/material";
import {
  VisibilityOutlined as VisibilityOutlinedIcon
} from "@mui/icons-material";
import { GameBadge, EventLink } from "components";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { siteLangCode, siteSportSlug } from "utils/site";
import { formatDate } from "utils/time";
import { COLOR_NEWS, COLOR_TV } from "constants/color";
import { useStyles } from "./styles";
import { abbreviateNumber } from "utils";
import classNames from "classnames";

export const NewsCard = ({
  news,
  isSimple,
  showLocation }) => {
  const addDefaultImage = useCallback((ev) => {
    ev.target.src = "https://b-cdn.kozoom.com/assets/static/images/default-image.jpg";
  }, []);
  const getThumbnailImage = useCallback((text) => {
    let imageURL = null;
    ReactHtmlParser(text ? text : "", {
      transform: function (node) {
        if (node.type === "tag" && node.name === "img") {
          if (
            !node.attribs.src.includes("https://") &&
            !node.attribs.src.includes("http://")
          )
            node.attribs.src = `${UPLOADED_MEDIA_URL}/${node.attribs.src}`;
        }
        if (node.type === "tag" && node.name === "img" && !imageURL) {
          imageURL = node.attribs.src;
        }
      },
    });
    return imageURL || "https://b-cdn.kozoom.com/assets/static/images/default-image.jpg";
  }, []);

  const {
    isEventLive,
    slug,
    gameId,
    title,
    text,
    thumbnail,
    publishedDate,
    eventTypeName,
    hits,
    eventId,
    city,
    countryId,
  } = news;

  const classes = useStyles();
  const [thumbnailImage, setThumbnailImage] = useState('');
  useEffect(() => {
    let image = thumbnail;
    if (thumbnail && !thumbnail.startsWith("http")) {
      image = `https://b-cdn.kozoom.com/uploads/events/${eventId}/news/${thumbnail}`;
    }

    if (!thumbnail) {
      image = getThumbnailImage(text);
    }

    image += "?width=600";
    setThumbnailImage(image);
  }, [thumbnail, eventId, text, getThumbnailImage]);

  const newsLink = `/${siteLangCode}/${siteSportSlug}/news/${slug ? slug : news.id}`;
  return (
    <Grid container className={classes.root}>
      <Grid item md={5} sm={12} xs={12} className={classes.thumbnailContainer}>
        <Link to={newsLink} className={classes.thumbnailImage}>
          {thumbnailImage &&
            <img
              onError={addDefaultImage}
              alt="thumbnail"
              src={thumbnailImage}
              className={classes.thumbnail}
            />
          }
        </Link>
      </Grid>
      <Grid item md={7} sm={12} xs={12} className={classNames(classes.informationSection, isSimple ? classes.simpleContent : null)} >
        {!isSimple &&
          <Box display="flex" alignItems="center" marginBottom={1}>
            <GameBadge gameId={Number(gameId)} bgColor={isEventLive ? COLOR_TV : COLOR_NEWS} />
            <EventLink
              eventId={eventId}
              eventTypeName={eventTypeName}
              city={showLocation ? city : null}
              country={showLocation ? countryId : null} />
          </Box>
        }
        <Link to={newsLink} className={classes.title}>
          {title}
        </Link>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body2"
            color="textSecondary"
            className={classes.date}
          >
            <i> {formatDate(publishedDate, "L", true, 7)} </i>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <VisibilityOutlinedIcon className={classes.icon} />
            <i>&nbsp;{abbreviateNumber(hits)}</i>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

