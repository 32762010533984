import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getFlagUrlByCio } from "utils";

export const CountryFlag = ({ size = 30, countryId }) => {
  const country = useSelector(state => state.countries.countriesById)[countryId];
  if (country) {
    return (
      <img
        alt="Nationality"
        src={getFlagUrlByCio(country.cio)}
        width={size}
        height={size}
      />
    )
  }
  return <Box sx={{ width: size, height: size, backgroundColor: '#e00e2f' }}></Box>
}
