import React from "react";
import { withStyles } from "@mui/styles";
import ReviveDefaultBannerStyles from "styles/Components/Revive";

class ReviveDefaultBanner extends React.Component {
  render() {
    const { classes, height } = this.props;

    return (
      <div className={classes.root}>
        <iframe
          title="revive default banner"
          id="ac191c5b"
          name="ac191c5b"
          src="https://adserver.kozoom.com/afr.php?zoneid=6773"
          frameBorder="0"
          scrolling="no"
          width="100%"
          height={height || 250}
          allowtransparency="true"
          allow="autoplay"
        >
          <a
            href="https://adserver.kozoom.com/ck.php?n=a9a1270d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://adserver.kozoom.com/avw.php?zoneid=6773&amp;n=a9a1270d"
              border="0"
              alt=""
            />
          </a>
        </iframe>
      </div>
    );
  }
}

export default withStyles(ReviveDefaultBannerStyles)(ReviveDefaultBanner);
