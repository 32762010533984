import React, { useEffect, useState } from "react";
import { Grid, Tabs, Tab, Badge, Container, Box, Typography, Tooltip } from "@mui/material";
import { Edit as EditIcon, Star as StarIcon } from "@mui/icons-material";
import { Spinner } from "components";
import i18n from "i18next";
import { PlayerProfile, PlayerVideos } from "./components";
import { usePlayerDetail } from "./hooks";
import { useSelector } from "react-redux";
import { getFlagUrlByCio } from "utils";
import { NoneWatchablePlaceholder, ReplayVideo } from "pages/TV/Event/components";
import { Link } from "react-router-dom";
import { siteLangCode } from "utils/site";
import { usePlaylist, useVideoPoster } from "pages/TV/Event/hooks";
import { Helmet } from "react-helmet";
import { PLAYER_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import { socketIo } from "socket";

export const PlayerDetailPage = () => {
  const {
    classes,
    loading,
    detail,
    videos,
    videoId,
    hasMore,
    livestreamRecords,
    country,
    sideTab,
    onChangeTab
  } = usePlayerDetail();

  const { currentVideo, isFirst, isLast, onNext, onPrev } = usePlaylist(videoId ? videos : livestreamRecords, videoId ? 'videoId' : 'recordId');
  const { userInfo, loading: userLoading } = useSelector(state => state.auth);
  const [seo, setSEO] = useState({
    title: i18n.t("Kozoom Player"),
    thumbnail: USER_DEFAULT_AVATAR
  });
  useEffect(() => {
    if (detail) {
      setSEO({
        title: `${detail.firstName} ${detail.lastName} - ${i18n.t("Kozoom Player")}`,
        thumbnail: detail.playerAvatar ? `${PLAYER_MEDIA_URL}/${detail.playerAvatar}` : USER_DEFAULT_AVATAR
      });
    }
  }, [detail]);

  const activeOrders = useSelector(state => state.premium.activeOrders);

  const videoPoster = useVideoPoster(currentVideo);
  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }
  if (!detail) {
    return null;
  }

  return (
    <Container>
      <Helmet>
        <title>{seo.title}</title>
        <meta property="og:title" content={seo.title} />
        <meta property="og:type" content="kozoom:player" />
        <meta property="og:image" content={seo.thumbnail} />
        <meta property="og:url" content={location.href} />
        <meta name="twitter:card" content={seo.thumbnail} />
      </Helmet>
      <Grid container spacing={2} mt={2} mb={4}>
        <Grid item lg={8} md={7} sm={12}>
          <>
            <Box display="flex" alignItems="center" marginBottom={1}>
              {country && (
                <Tooltip title={country.cio}>
                  <img
                    className={classes.country}
                    alt={country.cio}
                    src={getFlagUrlByCio(country.cio)}
                  />
                </Tooltip>
              )}
              <Link to={`/${siteLangCode}/player/${detail?.slug}`}>
                <Typography component="h2" className={classes.playerName}>
                  {`${detail.firstName} ${detail.lastName}`}
                  {detail.isChampion && <StarIcon fontSize="small" className={classes.starIcon} />}
                </Typography>
              </Link>
              {userInfo && detail.userId && userInfo.id === detail.userId &&
                <Box ml="auto">
                  <Link to={`/${siteLangCode}/player/${detail.id}/edit`}>
                    <EditIcon />
                  </Link>
                </Box>
              }
            </Box>
            {currentVideo ?
              <Box mb={2}>
                {!!userInfo && activeOrders['all'] && socketIo.connected ?
                  <ReplayVideo
                    defaultBanner={videoPoster}
                    video={currentVideo}
                    nextBtnDisabled={isLast}
                    prevBtnDisabled={isFirst}
                    onNext={onNext}
                    onPrev={onPrev}
                  />
                  :
                  <NoneWatchablePlaceholder loginRequired={!userInfo} userLoading={userLoading} premiumRequired={true} defaultBanner={videoPoster} />
                }
              </Box> :
              <PlayerProfile data={detail} />
            }
          </>
        </Grid>
        <Grid item lg={4} md={5} sm={12}>
          {!!(videos.length || livestreamRecords.length) &&
            <Tabs
              value={sideTab}
              centered
              className={classes.tabContainer}
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
              onChange={(_e, value) => onChangeTab(value)}
            >
              <Tab
                value="videos"
                label={
                  <Badge badgeContent={`${videos.length}${hasMore ? '+' : ''}`} color="primary">
                    {i18n.t("Videos")}
                  </Badge>
                }
                className={classes.tabButton}
              />
              {!!livestreamRecords.length &&
                <Tab
                  value="livestreams"
                  label={
                    <Badge badgeContent={livestreamRecords.length} color="primary">
                      {i18n.t("Livestream Records")}
                    </Badge>
                  }
                  className={classes.tabButton}
                />
              }
            </Tabs>
          }
          <Box className={classes.tabContent}>
            {sideTab === "videos" && (
              <PlayerVideos tab={"videos"} />
            )}
            {sideTab === "livestreams" && (
              <PlayerVideos tab={"livestreamRecords"} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
