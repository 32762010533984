import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode, siteSportId } from "../../utils/site";

export const RESET_RANKINGS = "RESET_RANKINGS";

export const GET_RANKINGS_START = "GET_RANKINGS_START";
export const GET_RANKINGS_FINISH = "GET_RANKINGS_FINISH";
export const GET_RANKINGS_ERROR = "GET_RANKINGS_ERROR";

const url = `${SERVER_URL}/ranking`;

export const resetRankings = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_RANKINGS,
    });
  };
};

export const getRankings = (offset = null, category, eventId) => {
  return (dispatch) => {
    const lang = siteLangCode;
    const sport = siteSportId;
    dispatch({
      type: GET_RANKINGS_START,
    });
    axios
      .get(
        url,
        eventId
          ? {
            params: {
              lang,
              sport,
              category,
              eventId,
            },
          }
          : {
            params: {
              lang,
              sport,
              offset,
              category,
            },
          }
      )
      .then((res) => {
        res = {
          data: res.data.data,
          category: category,
          isFirstPage: offset ? false : true,
        };
        dispatch({
          type: GET_RANKINGS_FINISH,
          payload: res,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_RANKINGS_ERROR,
          payload: err,
        });
      });
  };
};
