import React from "react";
import { Grid, Box, Container, Hidden } from "@mui/material";
import { Alert } from "@mui/material";
import DocumentTitle from "react-document-title";
import {
  Spinner,
  ReviveVerticalBanner,
  LoadMoreSection,
} from "components";
import { usePage } from "./hooks";
import i18n from "i18next";
import { EventList } from "../components";
import { EventsTimeline } from "../components/EventsTimeline";

export const ReplayEventPage = () => {
  const docTitle = i18n.t("Kozoom Replay");
  const { data, loading, error, onLoadMore, hasMore } = usePage();

  return (
    <DocumentTitle title={docTitle}>
      <Container>
        {loading && (
          <Box display="flex" justifyContent="center" py={4}>
            <Spinner />
          </Box>
        )}
        <Grid container spacing={2} my={2}>
          <Grid item xs={10} md={11} lg={8}>
            {error && (
              <Alert variant="filled" severity="error">
                {i18n.t("Error while loading events")}
              </Alert>
            )}
            {!error && (
              <>
                <EventList replayEvents={data} />
                {hasMore && (
                  <Box mt={4}>
                    <LoadMoreSection
                      automatic
                      label="More"
                      hasMore={true}
                      loading={loading}
                      onLoadMore={onLoadMore}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={2} md={1} lg={1}>
            <EventsTimeline events={data} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Hidden lgDown>
              <ReviveVerticalBanner />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </DocumentTitle>
  );
};
