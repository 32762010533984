import React, { useEffect, useState } from "react";
import { Grid, Box, Tabs, Tab, Badge, Typography } from "@mui/material";
import { Spinner, ReviveVerticalBanner, VideoCard, Comments, RankingCard } from "components";
import i18n from "i18next";
import {
  EventHeader,
  NewsPanel,
  ReplayVideo,
  RelatedEventsPanel,
  NoneWatchablePlaceholder,
  EventRankings
} from "./components";

import {
  useRelatedEvents,
  useReplayBottomTab,
  usePlaylist,
  useEventFeeds,
  useEventBanner,
  useRanking,
  useStyles,
  useCommentCount,
  useWatchingGuard,
  useEventVideos
} from "./hooks";

import classNames from "classnames";
import MobileBanner from "components/Revive/InFeed/mobile";
import { socketEvents, socketIo } from "socket";

const ReplayEvent = ({ event }) => {
  const classes = useStyles();
  const relatedEvents = useRelatedEvents(event.id);
  const { videos, loading } = useEventVideos(event.id);

  const { currentVideo, onChangeVideo, isFirst, isLast, onNext, onPrev } = usePlaylist(videos, 'videoId', true);
  const commentCount = useCommentCount(currentVideo ? currentVideo.id : null);
  const { feeds } = useEventFeeds(event.id, "news");
  const ranking = useRanking(event);
  const defaultBanner = useEventBanner(event);
  const { bottomTab, setBottomTab } = useReplayBottomTab(videos, ranking, currentVideo, feeds, relatedEvents);
  const { watchable, loginRequired, userLoading, premiumRequired } = useWatchingGuard(event);
  const [startedWatching, setStartedWatching] = useState(false);

  const onChangeTab = (value) => {
    setBottomTab(value);
  };

  useEffect(() => {
    if (videos.length && watchable && !startedWatching) {
      console.log('Start watching...');
      setStartedWatching(true);
      socketIo.emit(socketEvents.startWatching, event.id);
    }
  }, [event.id, videos.length, watchable, startedWatching]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <EventHeader
          event={event}
          status="replay"
        />
        <Box mb={2} className={classes.videoPlayerContainer}>
          {!watchable &&
            <NoneWatchablePlaceholder
              subscriptionPlanId={event.subscriptionPlanId}
              defaultBanner={defaultBanner}
              loginRequired={loginRequired}
              userLoading={userLoading}
              premiumRequired={premiumRequired} />
          }
          {watchable &&
            <ReplayVideo
              video={currentVideo}
              nextBtnDisabled={isLast}
              prevBtnDisabled={isFirst}
              onNext={onNext}
              onPrev={onPrev}
              defaultBanner={defaultBanner}
            />
          }
        </Box>
        {currentVideo &&
          <Box mb={2}>
            <Typography component="h2" className={classes.videoDescription}>{currentVideo.description}</Typography>
          </Box>
        }
        <Tabs
          value={bottomTab}
          className={classes.tabContainer}
          scrollButtons="auto"
          variant="scrollable"
          TabIndicatorProps={{
            className: classes.tabIndicator,
          }}
          onChange={(_e, value) => onChangeTab(value)}
        >
          {!!videos.length &&
            <Tab
              value="videos"
              label={
                <Badge badgeContent={videos.length} color="primary">
                  {i18n.t("Videos")}
                </Badge>
              }
              className={classNames(classes.mobileOnly, classes.tabButton)}
            />
          }
          {!!currentVideo &&
            <Tab
              label={
                <Badge badgeContent={commentCount} color="primary">
                  {i18n.t("Comments")}
                </Badge>
              }
              value="comment"
              className={classes.tabButton}
            />
          }
          {!!(ranking.data && ranking.data.length) &&
            <Tab
              label={i18n.t("Rankings")}
              value="ranking"
              className={classes.tabButton}
            />
          }
          {!!currentVideo && !!currentVideo.article &&
            <Tab
              label={i18n.t("Article")}
              value="article"
              className={classes.tabButton}
            />
          }
          {!!feeds.length &&
            <Tab
              value="news"
              label={
                <Badge badgeContent={feeds.length} color="primary">
                  {i18n.t("News")}
                </Badge>
              }
              className={classes.tabButton}
            />
          }
          {!!relatedEvents.length &&
            <Tab
              value="relatedEvents"
              label={i18n.t("Related Events")}
              className={classes.tabButton}
            />
          }
        </Tabs>
        <Box
          hidden={bottomTab !== "article"}
          p={2}
          className={classes.article}
          dangerouslySetInnerHTML={{ __html: currentVideo ? currentVideo.article : "" }}
        />
        <Box mt={2} hidden={bottomTab !== "ranking"}>
          <EventRankings ranking={ranking} event={event} />
        </Box>
        {currentVideo &&
          <Box hidden={bottomTab !== "comment"}>
            <Comments entityId={Number(currentVideo.id)} entity="video" mode="dark" showHeading={false} />
          </Box>
        }
        <Box p={2} hidden={bottomTab !== "news"}>
          <NewsPanel
            event={event}
            feeds={feeds}
            onPlayVideo={onChangeVideo}
          />
        </Box>
        <Box p={2} hidden={bottomTab !== "relatedEvents"}>
          <RelatedEventsPanel events={relatedEvents} />
        </Box>
        {bottomTab === "videos" && !!videos.length &&
          <Box className={classes.playList} display={{ xs: "block", md: "none" }}>
            {videos.map((item, index) => (
              <VideoCard
                key={index}
                isCurrent={currentVideo && item.id === currentVideo.id}
                video={item}
                onClick={() => onChangeVideo(item.id)}
              />
            ))}
          </Box>
        }
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <Box display={{ xs: "none", md: "block" }}>
          {!!videos.length &&
            <Tabs
              centered
              value="videos"
              className={classes.tabContainer}
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}>
              <Tab
                value="videos"
                label={
                  <Badge badgeContent={videos.length} color="primary">
                    {i18n.t("Videos")}
                  </Badge>
                }
                className={classes.tabButton}
              />
            </Tabs>
          }
          {!!videos.length &&
            <Box className={classes.playList}>
              {videos.map((item, index) => (
                <VideoCard
                  key={index}
                  isCurrent={currentVideo && item.id === currentVideo.id}
                  video={item}
                  onClick={() => onChangeVideo(item.id)}
                />
              ))}
            </Box>
          }
          {!videos.length &&
            <ReviveVerticalBanner />
          }
          {!!videos.length &&
            <MobileBanner />
          }
        </Box>
      </Grid>
    </Grid >
  );
};

export default ReplayEvent;
