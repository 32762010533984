import React, { useEffect, useMemo, useState } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import { siteLangCode } from "utils/site";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "-8px 0 0",
    padding: 0,
  },
  timelineItem: {
    height: 318,

    "&:before": {
      padding: 2,

      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  },
  timelineContent: {
    fontSize: 14,
    fontWeight: 500,
    color: "#4c4c4c",
    minWidth: 50,

    [theme.breakpoints.down("sm")]: {
      padding: 6,
      minWidth: 20,
      fontSize: 12,
    },
  },
}));

export const EventsTimeline = ({ events, numCols = 3 }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const cols = useMemo(() => {
    let result = numCols;
    if (isTablet) result = 2;
    if (isMobile) result = 1;
    return result;
  }, [isTablet, isMobile, numCols]);

  const timelines = useMemo(() => {
    const items = [];
    let lastDate = null;
    for (let key in events) {
      const startDate = moment(
        events[key].startTime
      ).format("MMM YYYY", siteLangCode);

      if (key % cols === 0) {
        items.push({ value: lastDate !== startDate || cols < 3 ? startDate : "" });
        lastDate = startDate;
      }
    }
    return items;
  }, [events, cols]);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const updateSize = () => {
      const firstEventCard = document.querySelector(".event-card");
      setHeight(firstEventCard ? firstEventCard.offsetHeight + 16 : 0);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  });

  return (
    <Timeline className={classes.root} align="left">
      {timelines.map((timeline, key) => (
        <TimelineItem key={key} className={classes.timelineItem} style={{ height }}>
          <TimelineSeparator>
            <TimelineDot variant="outlined" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={classes.timelineContent}>
            {timeline.value}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};
