import {
  AppBar,
  Hidden,
  IconButton,
  Container,
  Grid,
  Collapse,
} from "@mui/material";
import {
  SearchOutlined as SearchIcon
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import Account from "components/Account";
import BurgerMenu from "components/Burger/BurgerMenu";
import GlobalSearchInput from "components/GlobalSearchInput";
import SearchInput from "components/SearchInput";
import SportFilter from "components/SportFilter";
import React, { useState } from "react";
import { activeRoute } from "routes.client";
import { SecondaryHeaderStyles } from "./SecondaryHeaderStyles";
import SubNavigator from "./SubNavigator";
import { currentSite } from "utils/site";


const SecondaryHeader = ({ changeSport, changeLanguage }) => {
  const [searchExpanded, setSearchExpanded] = useState(false);

  const classes = makeStyles(SecondaryHeaderStyles)();

  const renderCurrentTab = (activeRoute) => {
    return (
      <div className={classes.outerImgLogo}>
        {activeRoute && activeRoute.secondaryImage && (
          <img
            className={classes.logo}
            src={`https://b-cdn.kozoom.com/assets/static/images/logos/${activeRoute.secondaryImage}.svg`}
            alt={activeRoute.name}
          />
        )}
      </div>
    );
  };

  const renderSearch = () => {
    switch (activeRoute.name) {
      case "global":
        return (
          <GlobalSearchInput
            borderColor={activeRoute && activeRoute.majorColor}
          />
        );
      default:
        return (
          <SearchInput
            borderColor={activeRoute && activeRoute.majorColor}
          />
        );
    }
  };

  return (
    <AppBar id="secondary-header" position="sticky" className={classes.appBar}>
      <Container className={classes.middlebar}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Hidden mdUp>
              <BurgerMenu
                changeLanguage={changeLanguage}
                changeSport={changeSport}
              />
              <IconButton
                className={classNames(classes.toolbarButton, searchExpanded ? classes.mainBgColor : null)}
                onClick={() => setSearchExpanded(!searchExpanded)}
                size="large">
                <SearchIcon className={classes.icon} />
              </IconButton>
            </Hidden>
            <Hidden mdDown>
              {renderSearch()}
            </Hidden>
          </Grid>
          <Grid item>
            {renderCurrentTab(activeRoute)}
          </Grid>
          <Grid item>
            <div className={classes.rightSection}>
              {currentSite !== 'global' &&
                <SportFilter changeSport={changeSport} />
              }
              <Account />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Collapse in={searchExpanded}>
        <Hidden mdUp>
          <div className={classes.mobileSearch} >
            {renderSearch()}
          </div>
        </Hidden>
      </Collapse>
      {currentSite !== "global" && (
        <SubNavigator />
      )}
    </AppBar>
  );
};

export default SecondaryHeader;

