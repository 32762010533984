import { Box, Button, Divider, FormControlLabel, Popover, Switch, Typography } from "@mui/material";
import {
  ExitToApp as ExitToAppIcon,
  Help as HelpIcon,
  Man as ManIcon,
  AccountCircle as AccountCircleIcon
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { getIdToken, login, logout } from "auth";
import { Gravatar } from "components/Gravatar";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { siteLangCode } from "utils/site";
import { useDispatch, useSelector } from "react-redux";
import { TOGGLE_ADMIN_FEATURE } from "store/actions";
import { Link } from "react-router-dom";
import { PremiumIcon } from "components/icons";
import { getCurrentUser } from "store/actions";
import { getActiveOrders } from "store/actions/premium";

const useStyles = makeStyles(() => ({
  userInfo: {
    color: "white",
    position: "relative"
  },
  premiumIcon: {
    left: 4,
    top: 4,
    position: "absolute",
    zIndex: 1
  },
  accountName: {
    fontWeight: 600
  },
  accountEmail: {
    opacity: 0.7
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  menuIcon: {
    marginRight: 5,
    verticalAlign: "middle",
    display: "inline-block",
  },
  menuItem: {
    display: "block",
    marginBottom: 8,
    '& span': {
      verticalAlign: 'middle',
      lineHeight: 1
    }
  }
}));

const AccountSection = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const classes = useStyles();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const { userInfo, loading, adminOn } = auth;

  useEffect(() => {
    if (!userInfo && getIdToken()) {
      dispatch(getCurrentUser());
      dispatch(getActiveOrders())
    }
  }, [dispatch, userInfo]);

  const logUserOut = () => {
    setMenuAnchorEl(null);
    logout();
  };
  const toggleAdminFeature = () => {
    dispatch({ type: TOGGLE_ADMIN_FEATURE });
  }

  if (loading) {
    return (
      <div>
        <Button>
          <Gravatar small />
        </Button>
      </div>
    );
  }

  if (!userInfo) {
    return (
      <div>
        <Button onClick={login}>
          <Gravatar small />
        </Button>
      </div>
    );
  }

  return (
    <>
      <Button className={classes.userInfo} onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
        {userInfo.isPremium &&
          <span className={classes.premiumIcon}>
            <PremiumIcon size={18} />
          </span>
        }
        <Gravatar userInfo={userInfo} small />
      </Button>
      <Popover
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box px={2} py={1}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography className={classes.accountName}>{userInfo.firstName} {userInfo.lastName}</Typography>
            <Typography className={classes.accountEmail}>{userInfo.email}</Typography>
          </Box>
          <Divider className={classes.divider} />
          {userInfo.linkedPlayerId &&
            <Link to={`/${siteLangCode}/player/${userInfo.linkedPlayerId}`} className={classes.menuItem}>
              <ManIcon className={classes.menuIcon} fontSize="small" />
              <span>{i18n.t("Your player profile")}</span>
            </Link>
          }
          <Link to={`/${siteLangCode}/premium-pass`} className={classes.menuItem}>
            <span className={classes.menuIcon}>
              <PremiumIcon size={20} className={classes.menuIcon} />
            </span>
            <span>{i18n.t("Premium Pass")}</span>
          </Link>
          <a href="https://gravatar.com/" target="_blank" className={classes.menuItem} rel="noreferrer">
            <AccountCircleIcon className={classes.menuIcon} fontSize="small" />
            <span>{i18n.t('Update Your Avatar')}</span>
          </a>
          <a href="https://help.kozoom.com/help" target="_blank" className={classes.menuItem} rel="noreferrer">
            <Box display="flex" alignItems="center">
              <HelpIcon className={classes.menuIcon} fontSize="small" />
              <span>{i18n.t("Help & Support")}</span>
            </Box>
          </a>
          {userInfo && userInfo.isAdmin &&
            <Box>
              <FormControlLabel control={<Switch checked={!!adminOn} />} onChange={toggleAdminFeature} label="Admin Tool" />
            </Box>
          }
          <Divider className={classes.divider} />
          <a role="button" onClick={logUserOut}>
            <Box display="flex" alignItems="center">
              <ExitToAppIcon className={classes.menuIcon} fontSize="small" />
              <span>{i18n.t("Logout")}</span>
            </Box>
          </a>
        </Box>
      </Popover>
    </>
  );
};

export default AccountSection;
