import React from "react";
import { Box, Grid } from "@mui/material";
import Alert from "@mui/material/Alert";
import i18n from "i18next";
import { EventCard } from "components";

export const RelatedEventsPanel = ({ events = [] }) => {

  if (!events || !events.length) {
    return (
      <Box mt={2}>
        <Alert variant="outlined" severity="info" style={{ color: "white" }}>
          {i18n.t("No related events found")}
        </Alert>
      </Box>
    );
  }

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        {events.map((event, key) => (
          <Grid item key={key} xs={12} sm={6}>
            <EventCard event={event} hasBorder={true} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
