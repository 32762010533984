import { authorizationHeader } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";

export const UPDATE_EVENT_ROOM_USER_COUNT = "UPDATE_EVENT_ROOM_USER_COUNT";
export const ADD_BANNED_USER = "ADD_BANNED_USER";
export const REMOVE_BANNED_USER = "REMOVE_BANNED_USER";
export const GET_BANNED_USERS = "GET_BANNED_USERS";
export const PLAY_OUT = "PLAY_OUT";

export const updateEventRoomUserCount = (count) => ({
  type: UPDATE_EVENT_ROOM_USER_COUNT,
  payload: count
});

export const playtOut = () => ({
  type: PLAY_OUT,
});

export const getBannedUsers = () => (dispatch) => {
  axios.get(`${SERVER_URL}/user`, {
    ...authorizationHeader(),
    params: {
      isBanned: true,
      limit: 99999
    }
  }).then(res => {
    dispatch({
      type: GET_BANNED_USERS,
      payload: res.data
    });
  }).catch(e => {
    console.warn(e);
  });
}

export const addBannedUser = (user) => ({
  type: ADD_BANNED_USER,
  payload: user
});

export const removeBannedUser = (user) => ({
  type: REMOVE_BANNED_USER,
  payload: user
});