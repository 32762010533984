import { Typography, Box } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { searchNews } from "store/actions/search";
import { NewsSocialWidget, LoadMoreSection, NewsCard } from "components";
import i18n from "i18next";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatDate } from "utils/time";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  time: {
    flexBasis: 50,
    maxWidth: 50,
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flexBasis: 30,
      maxWidth: 30,
      padding: theme.spacing(1),
    },
  },
  separator: {
    padding: 0,
    flexBasis: 12,
    maxWidth: 12
  },
  content: {
    padding: 0,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(1),
    },
  }
})

class NewsSearch extends React.Component {
  constructor(props) {
    super(props);
    this.fetchData = this.fetchData.bind(this);
  }
  fetchData = () => {
    const { result, searchTerms } = this.props;
    this.props.searchNews(
      searchTerms,
      result.data.length
    );
  };

  handleTimeLineManagement = (publishedDate) => {
    const { result } = this.props;
    const format = "MMM YYYY";
    const foundItems = result.data.filter(
      (item) => formatDate(publishedDate, format) === formatDate(item.publishedDate, format)
    );
    if (foundItems.length === 1)
      return (
        <TimelineDot variant="outlined" />
      );
    const index = foundItems.findIndex((v) => publishedDate === v.publishedDate);
    if (index === 0)
      return (
        <TimelineDot variant="outlined" />
      );
  };
  handleTimeLineData = (publishedDate) => {
    const { result } = this.props;
    const format = "MMM YYYY";
    const foundItems = result.data.filter(
      (item) => formatDate(publishedDate, format) === formatDate(item.publishedDate, format)
    );
    if (foundItems.length === 1)
      return (
        <Typography variant="body2" color="textSecondary">
          <i>{this.dateFormat(publishedDate)}</i>
        </Typography>
      );
    const index = foundItems.findIndex((v) => publishedDate === v.publishedDate);
    if (index === 0)
      return (
        <Typography variant="body2" color="textSecondary">
          <i>{this.dateFormat(publishedDate)}</i>
        </Typography>
      );
  };
  dateFormat = (data) => {
    let start_month = formatDate(data, "MMM");
    let start_year = formatDate(data, "YYYY");
    return start_month + " " + start_year;
  };

  _renderData = () => {
    const { result, classes } = this.props;
    const data = result.data;

    if (!Array.isArray(data)) return null;
    if (data.length === 0 && !result.loading)
      return (
        <Box mt={1} textAlign="center">{i18n.t("No news found")}</Box>
      );
    return (
      <Timeline sx={{ padding: 0 }}>
        {data.map((item, index) => (
          <TimelineItem key={index} sx={{ padding: 0 }}>
            <TimelineContent className={classes.content}>
              <Box mt={2}>
                {item.isHighlighted &&
                  <NewsSocialWidget
                    news={item}
                  />
                }
                {!item.isHighlighted &&
                  <NewsCard
                    news={item}
                    showLocation={false}
                  />
                }
              </Box>
            </TimelineContent>
            <TimelineSeparator className={classes.separator}>
              {this.handleTimeLineManagement(item.publishedDate)}
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineOppositeContent className={classes.time}>
              {this.handleTimeLineData(item.publishedDate)}
            </TimelineOppositeContent>
          </TimelineItem>
        ))}
      </Timeline>);
  };

  render() {
    const { result } = this.props;
    setTimeout(() => {
      if (window.FB) {
        window.FB?.XFBML?.parse();
      }
    }, 200);
    return (
      <>
        {this._renderData()}
        {(result.hasMore) &&
          <LoadMoreSection
            loading={result.loading}
            onLoadMore={this.fetchData}
          />
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    result: state.search.news,
    searchTerms: state.searchInput.searchTerms,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { searchNews },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(styles)(NewsSearch));
