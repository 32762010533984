import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1152,
    margin: "auto",
  },
  iframeContainer: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    paddingTop: "56.25%",
    margin: "auto",
  },
  responsiveIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    border: "none"
  },
  image: {
    maxWidth: "100%"
  },
  dialog: {
    background: "#747495",
    color: "white"
  },
  loader: {
    position: "absolute",
    zIndex: 99,
    width: "100%",
    bottom: 30
  }
}));