import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0`,
    margin: "auto",
    borderRadius: 5,
    maxWidth: 600,
    overflow: "hidden",
    border: "1px solid #ccc",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
    "& .fb-wg-container": {
      marginLeft: -16,
      marginRight: -16,
    },
    "& .fr-embedly": {
      height: "initial !important",
      marginLeft: -16,
      marginRight: -16,
      display: "block"
    },
    "& *": {
      fontFamily: "'Kulim Park', serif",
    },
  },
  space: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "initial",
      flex: 1,
    },
  },
  title: {
    fontSize: 20,
    textDecoration: "none",
    color: "black",
    lineHeight: 1.2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    opacity: 0.7,
    fontWeight: 600,
    maxHeight: 48,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
