import React from "react";
import { Typography } from "@mui/material";

import { NavLink } from "react-router-dom";

import { siteLangCode, siteSportSlug } from "utils/site";
class HashtagsSection extends React.Component {
  render() {
    const { hashtags } = this.props;
    return (
      <Typography
        variant="body1"
        style={{
          fontStyle: "italic",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: 600,
        }}
        color="textSecondary"
      >
        {Array.isArray(hashtags) &&
          hashtags.map((item, index) => (
            <NavLink
              to={`/${siteLangCode}/${siteSportSlug}/search?k=${item.label}`}
              exact
              key={index}
              style={{ marginRight: "0.7rem" }}
            >
              {`#${item.label}`}
            </NavLink>
          ))}
      </Typography>
    );
  }
}

export default HashtagsSection;
