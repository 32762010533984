export const ShareStyles = (theme) => ({
  root: {
    display: "inline-block",
    justifyContent: "flex-end",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    alignItems: "center",
  },
  icon: {
    pointer: "cursor",
    color: "#615f5d",
    fontSize: 27,
    margin: `0 ${theme.spacing(2)}`,
    transition: "0.3s",
    "& .MuiBadge-badge": {
      background: "#FDC232 !important",
    },
  },
  imageIcon: {
    height: 29,
    filter: "invert(0.3)",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  comment: {
    height: 32,
  },
  shareFacebook: {
    transition: "0.3s",
    "&:hover": {
      filter: "invert(0.5) sepia(1) saturate(5) hue-rotate(190deg)",
      color: "#3d5b96",
    },
  },
  shareTwitter: {
    transition: "0.3s",
    "&:hover": {
      filter: "invert(0.5) sepia(1) saturate(5) hue-rotate(150deg)",
      color: "#4fc8f8",
    },
  },
  shareLink: {
    transition: "0.3s",
    "&:hover": {
      filter: "invert(0.5) sepia(1) saturate(5)",
      color: "#f9d57a",
    },
  },
});
