import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import { Typography } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

import { Star as StarIcon } from "@mui/icons-material";

import MiniMatchResultCardStyles from "styles/Components/Cards/MiniMatchResultCard";
import { findItemById } from "utils";
import { UPLOADED_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import { siteLangCode } from "../../utils/site";

class MiniMatchResultCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
    };
  }

  addDefaultImage = (ev) => {
    ev.target.src = USER_DEFAULT_AVATAR;
  };

  getScore = () => {
    const { match_sets } = this.props;
    let score1 = 0;
    let score2 = 0;

    if (match_sets.length === 1) {
      score1 = match_sets[0].points_1;
      score2 = match_sets[0].points_2;
    } else if (match_sets.length > 1) {
      for (var item of match_sets) {
        if (item.points_1 > item.points_2) score1++;
        else if (item.points_1 < item.points_2) score2++;
      }
    }

    return {
      score1,
      score2,
    };
  };

  _renderDetail = () => {
    const { classes, player1, player2, countries } = this.props;

    let player1_nationality = "";
    player1_nationality = findItemById(countries, player1.country_id);
    player1_nationality
      ? (player1_nationality = player1_nationality.cio)
      : (player1_nationality = "");

    let player2_nationality = "";
    player2_nationality = findItemById(countries, player2.country_id);
    player2_nationality
      ? (player2_nationality = player2_nationality.cio)
      : (player2_nationality = "");

    const { score1, score2 } = this.getScore();

    return (
      <div className={classes.detailSection}>
        <div className={classNames(classes.player, classes.player1)}>
          <img
            className={classes.flag}
            alt="flag"
            src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${player1_nationality}.svg`}
          />
          <div style={{ width: "calc(100% - 32px)", display: "flex" }}>
            <Typography className={classes.player_name}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {player1.name}
              </span>
              {player1.isChampion ? (
                <StarIcon className={classes.iconChampion} />
              ) : null}
            </Typography>
            <Typography className={classes.score}>{score1}</Typography>
          </div>
        </div>
        <div className={classNames(classes.player, classes.player2)}>
          <div style={{ width: "calc(100% - 32px)", display: "flex" }}>
            <Typography className={classes.score}>{score2}</Typography>
            <Typography
              className={classes.player_name}
              style={{ justifyContent: "flex-end" }}
            >
              {player2.isChampion ? (
                <StarIcon className={classes.iconChampion} />
              ) : null}
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {player2.name}
              </span>
            </Typography>
          </div>
          <img
            className={classes.flag}
            alt="flag"
            src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${player2_nationality}.svg`}
          />
        </div>
      </div>
    );
  };

  render() {
    const { classes, video } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.replayIconContainer}>
          {video && (
            <div className={classes.replayIcon}>
              <NavLink to={`/${siteLangCode}/tv/replay/${video.id}`}>REPLAY</NavLink>
            </div>
          )}
        </div>
        {this._renderDetail()}
      </div>
    );
  }
}

MiniMatchResultCard.propTypes = {
  id: PropTypes.number,
  event_id: PropTypes.number,
  event: PropTypes.string,
};

export default withStyles(MiniMatchResultCardStyles)(MiniMatchResultCard);
