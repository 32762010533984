import { Box, Typography } from "@mui/material";
import { LoadMoreSection, RankingCard } from "components";
import i18n from "i18next";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRankings, resetRankings } from "store/actions";

let rankingEventTotal = 0;
let cat = "World";
class RankingEvent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [], offset: 0 };
  }

  componentDidMount = () => {
    const { eventId, category } = this.props;
    this.props.getRankings(
      0,
      category,
      eventId
    );
  };

  handleLoadMore = () => {
    const { offset } = this.state;
    if (offset < rankingEventTotal) {
      this.setState({ offset: rankingEventTotal });
      this.props.getRankings(
        rankingEventTotal,
        cat
      );
    }
  };

  _renderRankings = () => {
    const { rankings, classes, category } = this.props;
    let { data } = this.state;
    if (category === "World") {
      data = rankings.dataW;
    } else if (category === "Continental") {
      data = rankings.dataC;
    } else if (category === "National") {
      data = rankings.dataN;
    } else {
      data = rankings.data;
    }
    return data.length > 0 ? (
      <div>
        {Array.isArray(data) &&
          data.map((item, index) => {
            return (
              <RankingCard
                key={index}
                ranking={item}
              />
            );
          })}
      </div>
    ) : (
        !rankings.loading && (
          <Box mt={2} textAlign="center">
            <Typography>
              {i18n.t("No rankings found")}
            </Typography>
          </Box>
        )
      );
  };

  render() {
    const { rankings, eventId, category } = this.props;
    if (category === "World") {
      rankingEventTotal = rankings.dataW.length;
    } else if (category === "Continental") {
      rankingEventTotal = rankings.dataC.length;
    } else if (category === "National") {
      rankingEventTotal = rankings.dataN.length;
    } else {
      rankingEventTotal = rankings.data.length;
    }
    cat = category;

    return (
      <Box mt={2}>
        {this._renderRankings()}
        {!eventId && (rankings.total === 5 || rankings.loading) && (
          <LoadMoreSection
            automatic={true}
            label="More"
            hasMore={true}
            loading={rankings.loading}
            onLoadMore={this.handleLoadMore}
          />
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rankings: state.rankings,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      resetRankings,
      getRankings,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(RankingEvent);
