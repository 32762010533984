import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { NEED_RELOGIN } from "store/actions/auth";

export const GET_COUNTRIES_START = "GET_COUNTRIES_START";
export const GET_COUNTRIES_FINISH = "GET_COUNTRIES_FINISH";
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR";

const url = `${SERVER_URL}/country`;

export const getCountries = () => {
  return (dispatch) => {
    dispatch({
      type: GET_COUNTRIES_START,
    });
    axios
      .get(url)
      .then((res) => {
        dispatch({
          type: GET_COUNTRIES_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_COUNTRIES_ERROR,
          payload: err,
        });
      });
  };
};

