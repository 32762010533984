import { DateTimePicker } from "@mui/lab";
import { Paper, Box, Typography, Select, MenuItem, TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { formatDateTimeWithTimezone } from "utils/time";


export const AdminControlBar = ({ status, onUpdateStatus, onUpdateEventAttribute }) => {
  const userInfo = useSelector(state => state.auth.userInfo);
  const adminOn = useSelector(state => state.auth.adminOn);
  const event = useSelector(state => state.eventDetail.data);

  return <>
    {
      userInfo && userInfo.isAdmin && adminOn &&
      <Paper>

        <Box p={2}>
          <Typography component="h4" marginBottom={2}>Admin can override the values below for testing purpose</Typography>
          <Box display="flex">
            <Box m={1}>
              <Select
                value={status}
                label="Status"
                onChange={(e) => onUpdateStatus(e.target.value)}
                size="small"
              >
                <MenuItem value={'live'}>Live</MenuItem>
                <MenuItem value={'upcoming'}>Upcoming</MenuItem>
                <MenuItem value={'replay'}>Replay</MenuItem>
              </Select>

            </Box>
            <Box m={1}>
              <DateTimePicker
                fullWidth
                ampm={false}
                label={'Next streaming Time'}
                value={event.nextStreamingTime}
                onChange={(value) => onUpdateEventAttribute('nextStreamingTime', value)}
                renderInput={(params) => <TextField {...params} size="small" helperText={formatDateTimeWithTimezone(event.nextStreamingTime, event.timezone)} />}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    }
  </>
}