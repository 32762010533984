const VideoCardStyles = (theme) => ({
  root: {
    backgroundColor: "#4F4E79",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    overflow: "hidden",
    cursor: "pointer",
    transition: "all 0.2s",
    color: "white",
  },
  thumbnailContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  playBtn: {
    padding: 5,
    border: "1px solid #e00e2f",
  },
  overlay: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  second: {
    position: "absolute",
    right: 0,
    bottom: 4,
    padding: "3px 5px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    fontSize: 12,
    color: "white",
  },
  infoContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontSize: 12
  },
  infoItem: {
    fontSize: 12
  },
  infoIcon: {
    verticalAlign: "middle"
  },
  event: {
    color: "#f9b201",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  description: {
    fontSize: 12,
    maxHeight: 54,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  author: {
    display: "flex",
    alignItems: "center",
  },
  isCurrent: {
    backgroundColor: theme.palette.primary.main,
    transition: "all 0.2s"
  }
});

export default VideoCardStyles;
