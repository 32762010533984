import React from "react";
import { Grid } from "@mui/material";
import { MasterEvent } from "components";
import { siteLangCode } from "utils/site";

const EventsList = ({ events }) => {
  const eventProps = (event) => ({
    ...event,
    title: event.eventTypeName,
    location: event.city,
    isPremium: event.level === "premium",
    href: `/${siteLangCode}/event/${event.id}`,
    author: `${event.authorFirstname || ""} ${event.authorLastname || ""}`,
    videos: event.videoCount,
    totalLength: event.totalVideoDuration,
  });

  return (
    <Grid container spacing={2}>
      {events.map((event, key) => (
        <Grid key={key} item xs={12} sm={6}>
          <MasterEvent {...eventProps(event)} />
        </Grid>
      ))}
    </Grid>
  );
};

export default EventsList;
