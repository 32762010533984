import { authorizationHeader } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_CURRENT_USER_SUCCESS } from "store/actions";

export const useCustomerId = () => {
  const userInfo = useSelector(state => state.auth.userInfo);
  const [customerId, setCustomerId] = useState(userInfo ? userInfo.customerId : null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (!userInfo.customerId) {
      axios.get(`${SERVER_URL}/customer`, authorizationHeader()).then((res) => {
        setCustomerId(res.data.id);
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          payload: {
            userInfo: {
              ...userInfo,
              customerId: res.data.id
            }
          }
        })
      });
    } else {
      setCustomerId(userInfo.customerId);
    }
  }, [userInfo, dispatch]);
  return customerId;
}