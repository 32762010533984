import { useEffect, useMemo, useState } from "react";
import { useEpg } from "planby";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import moment from "moment";
import { useWindowSize } from "utils/hooks";
import i18n from "i18next";


export function useSchedule(selectedDate) {
  const [channels, setChannels] = useState([]);
  const [epg, setEpg] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const startDate = useMemo(() => moment(selectedDate).startOf('day'), [selectedDate]);
  const endDate = useMemo(() => moment(selectedDate).endOf('day'), [selectedDate]);
  const channelsData = useMemo(() => channels, [channels]);
  const epgData = useMemo(() => epg, [epg]);
  const windowSize = useWindowSize();
  const isSidebar = useMemo(() => windowSize.width > 768, [windowSize]);

  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channelsData,
    epg: epgData,
    dayWidth: 7200,
    sidebarWidth: 100,
    height: 170,
    itemHeight: 80,
    isSidebar,
    isTimeline: true,
    isLine: true,
    startDate,
    endDate,
    isBaseTimeFormat: true,
  });

  useEffect(() => {
    setIsLoading(true);

    axios.get(`${SERVER_URL}/epg`).then(res => {
      const { channel, programme } = res.data;
      let temMinDate, temMaxDate;
      setChannels([{
        logo: "https://kozoom.b-cdn.net/assets/static/images/logos/k-tv-dark.png",
        uuid: channel['@attributes'].id,
        title: channel['channel']
      }]);
      const mergedPrograms = [];
      programme.forEach((item, index) => {
        if (index === 0) {
          mergedPrograms.push(item);
        } else {
          const lastItem = mergedPrograms[mergedPrograms.length - 1];
          if (lastItem.title === item.title) {
            lastItem['@attributes'].stop = item['@attributes'].stop;
          } else {
            mergedPrograms.push(item);
          }
        }
      });
      setEpg(mergedPrograms.map((item, index) => {
        const { start, stop } = item['@attributes'];
        const since = parseDateFromString(start);
        const till = parseDateFromString(stop);

        if (index === 0) {
          temMinDate = since;
          temMaxDate = till;
        } else {
          if (since < temMinDate) {
            temMinDate = since;
          }
          if (till > temMaxDate) {
            temMaxDate = till;
          }
        }
        return {
          channelUuid: item['@attributes'].channel,
          description: typeof item.desc === 'string' ? item.desc : i18n.t('NA'),
          title: typeof item.title === 'string' ? item.title : i18n.t('No title'),
          image: item.icon['@attributes'].src,
          id: 'epg' + index,
          since,
          till
        }
      }));
      setMinDate(moment(temMinDate).startOf('day').toDate());
      setMaxDate(moment(temMaxDate).endOf('day').toDate());
      setIsLoading(false);
    }).catch((e) => {
      console.warn(e);
      setIsLoading(false);
    });
  }, []);

  return { getEpgProps, getLayoutProps, isLoading, minDate, maxDate };
}

/**
 * 
 * @param {*} str format as "20230103033103 +0000"
 * @returns Date object
 */
const parseDateFromString = (str) => {
  return new Date(`${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, 8)}T${str.slice(8, 10)}:${str.slice(10, 12)}:${str.slice(12, 14)}${str.slice(15, 18)}:${str.slice(18, 20)}`);
}