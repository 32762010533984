import {
  RESET_INSTRUCTION_DETAIL,
  GET_INSTRUCTION_DETAIL_START,
  GET_INSTRUCTION_DETAIL_FINISH,
  GET_INSTRUCTION_DETAIL_ERROR,
  GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_FINISH,
} from "store/actions";
const initialState = {
  loading: false,
  data: {},
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case RESET_INSTRUCTION_DETAIL:
      newState.data = {};
      return newState;
    case GET_INSTRUCTION_DETAIL_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_INSTRUCTION_DETAIL_FINISH:
      newState.loading = false;
      newState.data = action.payload;
      newState.err = null;
      return newState;
    case GET_INSTRUCTION_DETAIL_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    case GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_FINISH:
      if (Array.isArray(action.payload)) {
        newState.data = {
          ...newState.data,
          more: [...action.payload],
        };
      }
      return newState;
    default:
      return newState;
  }
}
