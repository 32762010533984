export const isYoutubeUrl = url => {
  var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  if (url.match(p)) {
    return url.match(p)[1];
  }
  return false;
};

export const isFacebookUrl = url => {
  if (/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i.test(url)) return true;
  return false;
};

export const isTwitchUrl = url => {
  if (/^(https?:\/\/)?((w{3}\.)?)twitch.tv\/.*/i.test(url)) return true;
  return false;
};

export const getYoutubeThumbnail = url => {
  if (url === null) {
    return "";
  }
  let results = url.match("[\\?&]v=([^&#]*)");
  let video = results === null ? url : results[1];

  return "http://img.youtube.com/vi/" + video + "/0.jpg";
};

export function captureVideoFrame(video, format, quality) {
  if (typeof video === "string") {
    video = document.getElementById(video);
  }

  format = format || "jpeg";
  quality = quality || 0.92;

  if (!video || (format !== "png" && format !== "jpeg")) {
    return false;
  }

  var canvas = document.createElement("CANVAS");

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext("2d").drawImage(video, 0, 0);

  var dataUri = canvas.toDataURL("image/" + format, quality);
  var data = dataUri.split(",")[1];
  var mimeType = dataUri.split(";")[0].slice(5);

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
}
