import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useWatchingGuard = (event) => {
  const { userInfo, loading: userLoading } = useSelector(state => state.auth);
  const { playOut } = useSelector(state => state.live);
  const activeOrders = useSelector(state => state.premium.activeOrders);

  const canLoggedInWatch = useMemo(() => {
    return event.level !== 'premium' || activeOrders['all'] || activeOrders[event.id] || activeOrders[event.parentEventId];
  }, [event, activeOrders]);

  const watchable = useMemo(() => {
    return userInfo && canLoggedInWatch && !playOut;
  }, [userInfo, canLoggedInWatch, playOut])

  return {
    watchable,
    userLoading,
    loginRequired: !userLoading && !userInfo,
    premiumRequired: !canLoggedInWatch,
  }
}