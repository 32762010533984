import { authorizationHeader, clearToken } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";

export const NEED_RELOGIN = "NEED_RELOGIN";

export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const GET_CURRENT_USER_SUCCESS = "GET_CURRENT_USER_SUCCESS";
export const GET_CURRENT_USER_FAILURE = "GET_CURRENT_USER_FAILURE";

export const BANNED_BY_ADMIN = "BANNED_BY_ADMIN";
export const UNBANNED_BY_ADMIN = "UNBANNED_BY_ADMIN";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const TOGGLE_ADMIN_FEATURE = "TOGGLE_ADMIN_FEATURE";

const url = `${SERVER_URL}/user`;

export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_USER,
    });
    axios.get(`${url}/me`, authorizationHeader())
      .then((res) => {
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          payload: {
            userInfo: res.data,
          }
        });
      })
      .catch((err) => {
        clearToken();
        dispatch({
          type: GET_CURRENT_USER_FAILURE,
          payload: {
            error: err
          }
        });
      });
  };
};

export const bannedByAdmin = () => ({
  type: BANNED_BY_ADMIN,
});

export const unbannedByAdmin = () => ({
  type: UNBANNED_BY_ADMIN
});