import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode, siteSportId } from "../../utils/site";
import { initialState } from "../reducers/feed";

export const GET_FEED_START = "GET_FEED_START";
export const GET_FEED_FINISH = "GET_FEED_FINISH";
export const GET_FEED_ERROR = "GET_FEED_ERROR";

const url = `${SERVER_URL}/feed`;

export const getFeeds = (entities = "news,video", newsOffset = 0, videoOffset = 0, eventId = null) => {
  const lang = siteLangCode;
  const sportId = siteSportId;
  const limit = initialState.limit;
  return (dispatch) => {
    dispatch({
      type: GET_FEED_START,
      payload: { firstLoad: newsOffset === 0 && videoOffset === 0 },
    });
    const params = { lang, sportId, eventId, newsOffset, videoOffset, limit, entities };
    if (!sportId || sportId === 1) {
      delete params.sportId;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dispatch({
          type: GET_FEED_FINISH,
          payload: res,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_FEED_ERROR,
          payload: err.response ? (err.response.data.msg || err.response.data.err) : "",
        });
      });
  };
};
