import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Grid, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ArrowDropDown } from "@mui/icons-material";
import classNames from "classnames";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
import RankingCardStyles from "styles/Components/Cards/RankingCard";
import { Location, GameBadge } from "components";
import { formatDateRange } from "utils/time";
import { siteLangCode, siteSportSlug } from "../../utils/site";
import RankingPlayer from "./RankingPlayer";
import { COLOR_NEWS, COLOR_TV } from "constants/color";

class RankingCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
    };
  }

  _rankingHeading() {
    const { classes, ranking } = this.props;
    return (
      <div>
        <h3 className={classes.eventName}>{ranking.event_type_name}</h3>
        <div className={classes.eventMeta}>
          {ranking.start_date && (
            <>
              <CalendarTodayIcon fontSize="14px" />&nbsp;
              {formatDateRange(
                ranking.start_date * 1000,
                ranking.end_date * 1000,
                "DD MMM"
              )}
            </>
          )}
          <Location city={ranking.city} countryId={ranking.country_id} />
        </div>
      </div>
    );
  }

  _renderRankingPlayers = () => {
    const { classes, ranking } = this.props;
    const { hasMore } = this.state;
    const players = [...ranking.ranking_data].filter((item) => item.player);
    if (!Array.isArray(players)) return;
    const hasData = this.hasData(players);
    players.sort((a, b) => a.rank - b.rank);

    if (hasMore) {
      players.splice(20);
    }

    return (
      <div className={classes.rankingsContainer}>
        {players.map((item, index) => {
          return (
            <RankingPlayer
              key={index}
              rank={index + 1}
              playerRanking={item}
              eventId={ranking.event_id}
              isTeam={ranking.is_team}
              bgColor={index % 2 ? "#f7e5bf" : "#ebebeb"}
              hasData={hasData}
            />
          );
        })}
        {ranking.ranking_data.length > 20 && hasMore && (
          <div className={classes.showMore}>
            <span>
              {ranking.ranking_data.length}{" "}
              {ranking.ranking_data.length !== 1
                ? i18n.t("players")
                : i18n.t("player")}
            </span>
            <button
              type="button"
              className={classes.showMoreBtn}
              onClick={() => this.setState({ hasMore: false })}
            >
              {i18n.t("See More")}
              <ArrowDropDown
                className={classNames(classes.caret, classes.seeMoreIcon)}
              />
            </button>
          </div>
        )}
      </div>
    );
  };

  hasData(rankings) {
    let hasEarning,
      hasDay,
      hasRun,
      hasWon,
      hasLoss,
      hasPts,
      hasInn,
      hasAvg,
      hasMatches;
    rankings.forEach((item) => {
      const player = item.event_player || {};
      if (Number(player.ep_earning)) {
        hasEarning = true;
      }
      if (Number(player.ep_day)) {
        hasDay = true;
      }
      if (Number(player.ep_day)) {
        hasDay = true;
      }
      if (Number(player.ep_won)) {
        hasWon = true;
      }
      if (Number(player.ep_lost)) {
        hasLoss = true;
      }
      if (Number(player.ep_run)) {
        hasRun = true;
      }
      if (Number(player.ep_points)) {
        hasPts = true;
      }
      if (Number(player.ep_inning)) {
        hasInn = true;
      }
      if (Number(player.ep_average)) {
        hasAvg = true;
      }
      if (item.matchStats) {
        hasMatches = true;
      }
    });
    return {
      hasEarning,
      hasDay,
      hasRun,
      hasWon,
      hasLoss,
      hasPts,
      hasInn,
      hasAvg,
      hasMatches,
    };
  }

  render() {
    const { classes, ranking } = this.props;
    const { gameId, is_event_live, event_id, thumbnail, federation_name } = ranking;
    const eventLink = `/${siteLangCode}/${siteSportSlug}/event/${event_id}`;
    const federationLink = "https://kozoom.b-cdn.net/federations/";
    return (
      <div className={classes.root}>
        <div className={classNames(classes.eventBox)}>
          <Grid container className={classNames(classes.textimageCenter)}>
            <Grid item xs={10}>
              <GameBadge gameId={gameId} bgColor={is_event_live ? COLOR_TV : COLOR_NEWS} />
            </Grid>
            <Grid item xs={2}>
              {thumbnail && (
                <div>
                  <img
                    className={classNames(classes.eventImg)}
                    alt={federation_name}
                    src={federationLink + thumbnail}
                  />
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classNames(classes.eventnameBox)}>
            <NavLink
              to={eventLink}
              className={classNames(classes.event, "gray")}
            >
              {this._rankingHeading()}
            </NavLink>
          </div>
        </div>
        {this._renderRankingPlayers()}
      </div>
    );
  }
}

RankingCard.propTypes = {
  ranking: PropTypes.object.isRequired,
};

export default withStyles(RankingCardStyles)(RankingCard);