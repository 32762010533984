export const BurgerMenuStyles = (theme) => ({
  expandedContainer: {
    backgroundColor: "white"
  },
  toggleIcon: {
    color: "white",
    padding: "18px 15px",
    borderRadius: 0,
  },
  closeIcon: {
    color: "white",
    borderRadius: 0,
    opacity: 0.8
  },
  mainBgColor: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    color: "white"
  },
  menuItem: {
    padding: theme.spacing(2),
  },
  flag: {
    width: 24,
    height: 24,
    borderRadius: 12,
    border: "1px solid #ddd",
  },
  collapsibleListItem: {
    padding: theme.spacing(2),
    cursor: "pointer",
    background: "#11111111",
    marginTop: 2,
  },
  listItem: {
    cursor: "pointer",
    color: "black",
  },
  caret: {
    minWidth: "auto",
    color: "white",
    marginLeft: "auto"
  },
  nav: {
    textTransform: "uppercase",
    fontWeight: 700,
    width: "100%",
  },
});
