import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18next";
import { formatVideoLength } from "utils/time";
import { GameBadge, LockPremiumIcon } from "components";
import { COLOR_TV } from "constants/color";

const useStyles = makeStyles((theme) => ({
  containter: {
    backgroundColor: "#4F4E79",
  },
  actionContainer: {
    display: "flex",
    alignItems: "unset",
    minHeight: 240,
    borderRadius: 5,
    borderWidth: 3,
    borderColor: "#4F4E79",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  content: {
    padding: "8px 16px",
    paddingBottom: "8px !important",
    width: "33%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",

    [theme.breakpoints.down("md")]: {
      width: "inherit",
    },
  },
  title: {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 500,
    color: "white",
  },
  cover: {
    width: "66%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 210,
    },
  },
  playBtn: {
    padding: 5,
    border: "1px solid white",

    "&:hover": {
      backgroundColor: "#E51135",
    },
  },
  premium: {
    color: "#E51135",
  },
  meta: {
    fontSize: 12,
    fontWeight: 200,
    color: "white",
  },
  with: {
    marginRight: 4,
    fontSize: 12,
    fontWeight: 200,
    lineHeight: 1,
    color: "white",
  },
  playerName: {
    fontSize: 14,
    lineHeight: 1,
    color: "white",
  },
  action: {
    flex: 1,
  },
  additionalInfo: {
    marginTop: 18,
    fontSize: 12,
    fontWeight: 200,
    color: "white",
  },
}));

const MasterEvent = ({
  gameId,
  image,
  title,
  isPremium,
  href,
  videos = 0,
  totalLength = 0,
  author,
  additionalInfo,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.containter}>
      <CardActionArea
        component={Link}
        to={href}
        className={classes.actionContainer}
      >
        <CardMedia className={classes.cover} image={image} title={title} />
        <CardContent className={classes.content}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom={1}
          >
            <GameBadge gameId={gameId} bgColor={COLOR_TV} />
            {isPremium && (
              <Box ml={1}>
                <LockPremiumIcon className={classes.premium} />
              </Box>
            )}
          </Box>
          <Typography className={classes.title}>{title}</Typography>
          {author && (
            <Box>
              <Typography
                variant="span"
                component="span"
                className={classes.with}
              >
                {i18n.t("with")}
              </Typography>
              <Typography
                variant="span"
                component="span"
                className={classes.playerName}
              >
                {author}
              </Typography>
            </Box>
          )}
          <Box className={classes.additionalInfo}>{additionalInfo}</Box>
          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            className={classes.action}
          >
            <Typography className={classes.meta}>
              {i18n.t("{{videos}} videos ({{totalLength}})", {
                videos,
                totalLength: formatVideoLength(totalLength || 0),
              })}
            </Typography>
            <IconButton
              color="inherit"
              className={classes.playBtn}
              component={Link}
              to={href}
              size="large">
              <PlayArrowIcon style={{ fontSize: 24, color: "white" }} />
            </IconButton>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MasterEvent;
