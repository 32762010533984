import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import axios from 'axios';
import { Spinner } from 'components';
import { PremiumIcon } from 'components/icons';
import { SERVER_URL } from 'constants/serverUrl';
import { useCustomerId } from 'hooks/premium';
import i18n from 'i18next';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import { useSelector } from 'react-redux';

import multiTableImage from './assets/multitable.png';
import cueBallImage from './assets/cue-ball.png';
import logoImage from './assets/logos.png';
import masterclassImage from './assets/masterclass.png';
import { EventPassCard, YearlyPassCard } from './SubscriptionCard';
import Carousel from 'react-material-ui-carousel'
import { siteLangCode } from 'utils/site';
import { uniqBy } from 'lodash';
import { useStyles } from './styles';

export const PremiumPreCheckoutPage = () => {
  const event = useSelector(state => state.eventDetail.data);
  const { userInfo, loading } = useSelector(state => state.auth);
  const customerId = useCustomerId();
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const activeOrders = useSelector(state => state.premium.activeOrders);

  useEffect(() => {
    // If there is a selected plan, check if it has a plan to include in to the list
    const hasEventPlan = event && event.subscriptionPlanId && event.subscriptionPrice && moment(event.endTime).isAfter(moment());

    axios.get(`${SERVER_URL}/event`, {
      params: {
        lang: siteLangCode,
        hasPriceOnly: 1,
        limit: 4,
        endAfter: new Date(),
        orderBy: 'startTime',
        order: 'asc'
      }
    }).then((res) => {
      // The selected event, if it has a plan price, then it is the first item in the list
      const newEvents = uniqBy(hasEventPlan ? [event, ...res.data] : [...res.data], 'id');
      setEvents(newEvents);
    }).catch((e) => {
      console.error(e);
    });
  }, [event]);

  if (loading) {
    return (
      <Box textAlign="center" my={5}>
        <Spinner />
      </Box>
    )
  }


  return (
    <DocumentTitle title={i18n.t('Premium Pass Subscription')}>
      <>
        <Box className={classes.sectionOne}>
          <Container >
            <Box textAlign="center" mb={4}>
              <Typography variant='h3' fontWeight={600}>{i18n.t('Choose your Pass')}</Typography>
            </Box>
            <PlansSelection events={events} userInfo={userInfo} activeOrders={activeOrders} customerId={customerId} />
          </Container>
        </Box>
        <Box className={classes.sectionTwo}>
          <Box className={classes.sectionTwoBg}>
            <Container>
              <Grid container paddingTop={5} alignItems="center">
                <Grid item md={7} sm={12} textAlign="center">
                  <img src={cueBallImage} alt="Cue ball" className={classes.img} />
                </Grid>
                <Grid item md={5} sm={12} textAlign="center">
                  <Box maxWidth={480} margin="auto">
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <PremiumIcon size={43} />
                      <Typography variant="h4" textTransform="uppercase" fontWeight={700} marginLeft={2}>
                        {i18n.t('Livestream')}
                      </Typography>
                    </Box>
                    <Typography marginY={3} fontSize={18}>
                      {i18n.t('Unlock your account to watch the best players in action in Worldclass tournaments and Kozoom’s studio show.')}
                    </Typography>
                    <Typography marginBottom={2} fontSize={18}>
                      {i18n.t('Since over 10 years, Kozoom has built broadcasting partnerships with many Federations and promoters to offer the largest catalog of Pool and Carom events.')}
                    </Typography>
                    <Box textAlign="center">
                      <img src={logoImage} alt="Logos" className={classes.img} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
        <Box className={classes.sectionThree}>
          <Container>
            <Grid container alignItems="center">
              <Grid item md={7} sm={12} textAlign="center">
                <Box maxWidth={510} margin="auto" marginTop={5}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <PremiumIcon size={43} />
                    <Typography variant="h4" textTransform="uppercase" fontWeight={700} marginLeft={2}>
                      {i18n.t('Multitable')}
                    </Typography>
                  </Box>
                  <Typography marginY={3} fontSize={18}>
                    {i18n.t('Kozoom platform offers a unique experience for billiard fans who can watch the game of their choice among many tables.')}
                  </Typography>
                  <Typography marginBottom={2} fontSize={18}>
                    {i18n.t('Every game is recorded and available on-demand for Premium Pass holders, including the matches played beside the tv tables.')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={5} sm={12} textAlign="center">
                <img src={multiTableImage} alt="Cue ball" className={classes.multiTableImage} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={7} sm={12}>
                <img src={masterclassImage} alt="Cue ball" className={classes.img} />
              </Grid>
              <Grid item md={5} sm={12} textAlign="center">
                <Box maxWidth={436} margin="auto" marginTop={5}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <PremiumIcon size={43} />
                    <Typography variant="h4" textTransform="uppercase" fontWeight={700} marginLeft={2}>
                      {i18n.t('Masterclass')}
                    </Typography>
                  </Box>
                  <Typography marginY={3} fontSize={18}>
                    {i18n.t("From 2023, Kozoom Studio will welcome World Class players to produce new exciting events for Premium Pass holders: webinars and video lessons")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.sectionFour} py={2}>
          <Container>
            <Grid container>
              <Grid item lg={5} md={12} textAlign="center">
                <Box maxWidth={586} margin="auto" marginTop={2}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <PremiumIcon size={43} />
                    <Typography variant="h4" textTransform="uppercase" fontWeight={700} marginLeft={2}>
                      {i18n.t('Save Money')}
                    </Typography>
                  </Box>
                  <Typography marginY={3} fontSize={18}>
                    {i18n.t('Buy your equipment on Kozoom Store and it will cover up to 100% of your Pass with the discounts applied on the whole catalog for Premium Members.')}
                  </Typography>
                  <Typography marginY={3} fontSize={18}>
                    {i18n.t('Get access to Private Sales reserved to Premium members giving priority on special deals and refurbished products.')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Box className={classes.sectionFive}>
          <Container>
            <Box py={5}>
              <PlansSelection events={events} userInfo={userInfo} activeOrders={activeOrders} customerId={customerId} />
            </Box>
          </Container>
        </Box>
      </>
    </DocumentTitle >
  );
}

const PlansSelection = ({ events, userInfo, activeOrders, customerId }) => (
  <Grid container spacing={3} flexWrap="wrap-reverse">
    <Grid item lg={3} md={4} sm={12} xs={12}>
      <YearlyPassCard userInfo={userInfo} customerId={customerId} activeOrders={activeOrders} />
    </Grid>
    <Grid item lg={9} md={8} sm={12} xs={12}>
      {events.length > 1 &&
        <Carousel navButtonsAlwaysVisible={true} fullHeightHover={true} autoPlay={false}>
          {events.map((item, index) =>
            <EventPassCard key={index} event={item} userInfo={userInfo} customerId={customerId} activeOrders={activeOrders} />
          )}
        </Carousel>
      }
      {events.length === 1 &&
        <EventPassCard event={events[0]} userInfo={userInfo} customerId={customerId} activeOrders={activeOrders} />
      }
    </Grid>
  </Grid>
);