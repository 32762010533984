import { Button, Modal, Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { authorizationHeader } from "auth";
import { GET_CURRENT_USER_SUCCESS } from "store/actions";


export const SubscribeButton = ({ variant = "outlined", subscriptionPlanId }) => {
  const [customerId, setCustomerId] = useState();
  const userInfo = useSelector(state => state.auth.userInfo);
  const dispatch = useDispatch();
  const [allEventsOpen, setAllEventsOpen] = useState(false);
  const [oneEventOpen, setOneEventOpen] = useState(false);

  useEffect(() => {
    if (!userInfo) {
      return;
    }
    if (!userInfo.customerId) {
      axios.get(`${SERVER_URL}/customer`, authorizationHeader()).then((res) => {
        setCustomerId(res.data.id);
        dispatch({
          type: GET_CURRENT_USER_SUCCESS,
          payload: {
            userInfo: {
              ...userInfo,
              customerId: res.data.id
            }
          }
        })
      });
    } else {
      setCustomerId(userInfo.customerId);
    }

  }, [userInfo, dispatch]);
  return (
    <>
      {customerId && subscriptionPlanId &&
        <>
          <Button variant={variant} onClick={() => setAllEventsOpen(true)} xs={{ marginRirght: 10 }}>
            {i18n.t('All Events')}
          </Button>
          <Button variant={variant} onClick={() => setOneEventOpen(true)}>
            {i18n.t('This Event')}
          </Button>
        </>
      }
      {customerId && !subscriptionPlanId &&
        <Button variant={variant} onClick={() => setAllEventsOpen(true)} xs={{ marginRirght: 10 }}>
          {i18n.t('Subscribe Now')}
        </Button>
      }
      <Modal
        open={allEventsOpen}
        onClose={() => setAllEventsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {i18n.t('All Events Subcription')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {i18n.t('No limit access to all events in one year.')}
          </Typography>
          <a href={`https://payments.pabbly.com/subscribe/629644e377c4af6029be10c4/monthly-premium-pass?customer_id=${customerId}`}
            rel="noreferrer"
          >
            <Button variant={variant}>
              {i18n.t('Subscribe Now')}
            </Button>
          </a>
        </Box>
      </Modal>
      <Modal
        open={oneEventOpen}
        onClose={() => setOneEventOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {i18n.t('One event subscription')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {i18n.t('No limit access to this event during live session and 2 weeks after the end date to watch replay videos.')}
          </Typography>
          <a href={`https://payments.pabbly.com/subscribe/${subscriptionPlanId}?customer_id=${customerId}`} rel="noreferrer">
            <Button variant={variant}>
              {i18n.t('Subscribe Now')}
            </Button>
          </a>
        </Box>
      </Modal>
    </>
  )
}