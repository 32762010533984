const InstructionCardStyles = (theme) => ({
  root: {
    backgroundColor: "white",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    border: "1px solid #a3a0a0",
    overflow: "hidden",
    // boxShadow: '0 4px 4px #ccc',
  },
  thumbnail: {
    width: 200,
    height: "100%",
    objectFit: "cover",
  },
  mini_thumbnail: {
    width: 100,
    height: "100%",
    objectFit: "cover",
  },
  thumbnailContainer: {
    alignSelf: "center",
    textAlign: "center",
    paddingTop: "5px",
    order: 1,
    [theme.breakpoints.down("md")]: {
      order: 2,
      width: "100%",
    },
    "&.vertical": {
      order: 2,
      width: "100%",
    },
  },
  infoContainer: {
    order: 2,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
    "&.vertical": {
      order: 1,
    },
  },
  game: {
    display: "flex",
    alignItem: "center",
    color: "white",
    backgroundColor: "#5DC5FC",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
    fontSize: 15,
    lineHeight: "20px",
    padding: "5px 8px",
    borderRadius: 3,
  },
  metadataContainer: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    "&.vertical": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  title: {
    fontSize: 20,
    textDecoration: "none",
    color: "black",
    lineHeight: 1.2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "box",
    lineClamp: 2,
    boxOrient: "vertical",
    opacity: 0.7,
    fontWeight: 600,
    "&.vertical": {
      paddingBottom: 0,
    },
    flexGrow: 1,
  },
  description: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  author: {
    display: "flex",
    alignItems: "center",
  },
  authorName: {
    marginLeft: theme.spacing(1),
  },
  create_date: {

  }
});

export default InstructionCardStyles;
