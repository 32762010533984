import React, { useMemo, useState } from "react";
import { MenuItem, Checkbox, Popover, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FilterIcon, SportIcon } from "components";
import { useSelector } from "react-redux";
import { siteLangCode, siteSportId } from "../../utils/site";
import { sports } from "constants/sport";

const useStyles = makeStyles(theme => ({
  filterButton: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    marginRight: theme.spacing(4),
  },
  subnav: {
    fontSize: 14,
    marginLeft: 5
  },
}));


const SportFilter = ({ changeSport }) => {
  const order = [1, 5, 4, 3];
  const [anchor, setAnchor] = useState(null);
  const sportList = useMemo(() => sports.filter(item => item.lang === siteLangCode), []);
  const selectedSport = useMemo(() => sports.find(item => item.lang === siteLangCode && item.id === siteSportId), []);
  const classes = useStyles();
  return <>
    <Hidden mdDown>
      <div className={classes.filterButton} onClick={(event) => setAnchor(event.currentTarget)} >
        <FilterIcon size={22} />
        <Typography className={classes.subnav}>
          {selectedSport ? selectedSport.name : ""}
        </Typography>
      </div>
      <Popover
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {sportList
          .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
          .map((sport, index) => (
            <MenuItem
              key={index}
              onClick={() => { changeSport(sport.id); }}
            >
              <Checkbox checked={Number(siteSportId) === sport.id} />
              <SportIcon sportId={sport.id} isGrey={true}></SportIcon>
              {sport.name}
            </MenuItem>
          ))}
      </Popover>
    </Hidden>
  </>;
};

export default SportFilter;
