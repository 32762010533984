import React from "react";
import styles from "./SportIcon.module.css";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";

const SportIcon = ({ sportId, isGrey, isEventLive }) => {
  let classes = styles.icon;
  if (isGrey) {
    classes += " " + styles.grey;
  }
  return (
    <>
      {sportId === 5 && (
        <img
          src={`${UPLOADED_MEDIA_URL}/assets/static/icons/carom.svg`}
          className={classes}
          alt="carom"
        />
      )}
      {sportId === 4 && (
        <img
          src={`${UPLOADED_MEDIA_URL}/assets/static/icons/pool.svg`}
          className={classes}
          alt="pool"
        />
      )}
      {sportId === 3 && (
        <img
          src={`${UPLOADED_MEDIA_URL}/assets/static/icons/snooker.svg`}
          className={classes}
          alt="snooker"
        />
      )}
    </>
  );
};

export default SportIcon;
