import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { NEED_RELOGIN } from "store/actions/auth";

export const RESET_INSTRUCTION_DETAIL =
  "RESET_INSTRUCTION_DETAIL";

export const GET_INSTRUCTION_DETAIL_START =
  "GET_INSTRUCTION_DETAIL_START";
export const GET_INSTRUCTION_DETAIL_FINISH =
  "GET_INSTRUCTION_DETAIL_FINISH";
export const GET_INSTRUCTION_DETAIL_ERROR =
  "GET_INSTRUCTION_DETAIL_ERROR";

export const GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_START =
  "GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_START";
export const GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_FINISH =
  "GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_FINISH";
export const GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_ERROR =
  "GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_ERROR";

const url = `${SERVER_URL}/instructions/detail`;

export const resetInstructionDetail = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_INSTRUCTION_DETAIL,
    });
  };
};

export const getInstructionDetail = (idOrSlug = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_INSTRUCTION_DETAIL_START,
    });
    axios
      .get(url, {
        params: {
          idOrSlug,
        },
      })
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_INSTRUCTION_DETAIL_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_INSTRUCTION_DETAIL_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_INSTRUCTION_DETAIL_ERROR,
          payload: err,
        });
      });
  };
};

export const getMoreInstructionsOfPlayer = (player_id) => {
  return (dispatch) => {
    dispatch({
      type: GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_START,
    });
    axios
      .get(`${SERVER_URL}/instructions/player/${player_id}`)
      .then((res) => {
        dispatch({
          type: GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_MORE_INSTRUCTIONS_OF_PLAYER_DETAIL_ERROR,
          payload: err,
        });
      });
  };
};
