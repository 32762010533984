
import React from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/EditOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { Gravatar } from "components/Gravatar";
import i18n from "i18next";
import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComment, deleteComment, updateComment } from "store/actions";
import { siteLangCode } from "utils/site";
import { formatDate } from "utils/time";
import { DeleteConfirmDialog } from "./DeleteConfirmDialog";
import { CmtButton, CmtTextField } from "./index";

const useStyles = makeStyles(() => ({
  authorName: {
    fontWeight: 700,
    color: "inherit",
    opacity: 0.87,
    marginRight: 10
  },
  metaData: {
    color: "inherit",
    opacity: 0.4,
  },
  content: {
    margin: "0.25em 0 0.5em",
    fontSize: "1em",
    wordBreak: "break-word",
    color: "inherit",
    opacity: 0.87,
    lineHeight: 1.3,
  },
  replyBtn: {
    color: "inherit",
    opacity: 0.4,
    cursor: "pointer",
    "&:hover, &:focus": {
      opacity: 0.6,
    }
  },
  replyCount: {
    display: "flex",
    alignItems: "center",
    marginRight: 5,
  },
  separator: {
    "&:after": {
      content: "\"•\"",
      marginLeft: 5,
      color: "inherit",
      opacity: 0.7,
    }
  }
}));


export const Comment = ({ comment, isReply, replyToParent, mode }) => {
  const { author, content } = comment;
  const loggedInUser = useSelector(state => state.auth.userInfo);
  const { menuAnchorEl, menuOpen, handleMenuClick, handleMenuClose } = useDropdownMenu();

  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [editingContent, setEditingContent] = useState(content);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [replyMode, setReplyMode] = useState(false);
  const [replyContent, setReplyContent] = useState("");
  const [showReplies, setShowReplies] = useState(false);
  const classes = useStyles();

  const onEdit = () => {
    setEditMode(true);
    handleMenuClose();
  };
  const onUpdate = () => {
    dispatch(updateComment(comment.id, {
      content: editingContent
    }));
    setEditMode(false);
  };

  const onDelete = () => {
    setOpenConfirmation(true);
  };
  const onCancelDelete = () => {
    setOpenConfirmation(false);
    handleMenuClose();
  };
  const onConfirmDelete = () => {
    dispatch(deleteComment(comment.id));
    setOpenConfirmation(false);
    handleMenuClose();
  };

  const toggleShowReplies = () => {
    setShowReplies(!showReplies);
  };
  const onReplyClick = () => {
    if (!isReply) {
      setShowReplies(true);
      setReplyMode(true);
    } else if (replyToParent) {
      replyToParent();
    }
  };
  const replyFromChild = () => {
    setReplyMode(true);
  };

  const onReply = () => {
    dispatch(addComment({
      entityId: comment.entityId,
      entity: comment.entity,
      lang: siteLangCode,
      content: replyContent,
      parentId: comment.id
    }));
    setReplyMode(false);
    setReplyContent("");
  };

  return <>
    <Box display="flex" mt={isReply ? 1 : 2}>
      <Box mr={2}>
        <Gravatar userInfo={author} />
      </Box>
      <Box>
        <Box display="flex" alignItems="end">
          <span className={classes.authorName}>{author.firstName} {author.lastName}</span>
          <Typography variant="subtitle2" className={classes.metaData}>{formatDate(comment.createdAt, "L", true)}</Typography>
        </Box>
        {!editMode &&
          <>
            <div className={classes.content}>
              {content}
            </div>
            <Box display="flex">
              {!isReply && comment.children &&
                <Box onClick={toggleShowReplies} className={classNames(classes.replyBtn, classes.replyCount, loggedInUser ? classes.separator : null)}>
                  {!showReplies && <KeyboardArrowDownIcon fontSize="small" />}
                  {showReplies && <KeyboardArrowUpIcon fontSize="small" />}
                  <span>{i18n.t("ReplyCount", { count: comment.children.length })}</span>
                </Box>
              }
              {!!loggedInUser &&
                <div className={classes.replyBtn} onClick={onReplyClick}>{i18n.t("Reply")}</div>
              }
            </Box>

          </>
        }
        {editMode &&
          <>
            <CmtTextField
              value={editingContent}
              mode={mode}
              onChange={(e) => setEditingContent(e.target.value)}
            />
            <Box textAlign="right" marginTop={2}>
              <CmtButton variant="outlined" size="small" onClick={() => setEditMode(false)}>
                {i18n.t("Cancel")}
              </CmtButton>&nbsp;
              <CmtButton variant="contained" size="small" disabled={!editingContent} onClick={onUpdate}>
                {i18n.t("Update")}
              </CmtButton>
            </Box>
          </>
        }
      </Box>
      {
        !!loggedInUser && (loggedInUser.id === author.id || loggedInUser.isAdmin) &&
        <Box>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-expanded={menuOpen ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}
            color="inherit"
            size="large">
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            onClose={handleMenuClose}
            open={menuOpen}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {loggedInUser.id === author.id &&
              <MenuItem onClick={onEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {i18n.t("Edit")}
                </ListItemText>
              </MenuItem>
            }
            <MenuItem onClick={onDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                {i18n.t("Delete")}
              </ListItemText>
            </MenuItem>
          </Menu>
          <DeleteConfirmDialog
            open={openConfirmation}
            onCancelDelete={onCancelDelete}
            onConfirmDelete={onConfirmDelete}
          />
        </Box>
      }
    </Box>
    {showReplies && comment.children &&
      <>
        <Box ml={3}>
          {comment.children.map((item, index) =>
            <Comment
              key={`reply-${item.id}-${index}`}
              comment={item}
              isReply={true}
              replyToParent={replyFromChild}
            />)
          }
        </Box>
      </>
    }
    {replyMode &&
      <Box ml={3}>
        <CmtTextField
          value={replyContent}
          autoFocus
          mode={mode}
          onChange={(e) => setReplyContent(e.target.value)}
        />
        <Box textAlign="right" marginTop={2}>
          <CmtButton variant="outlined" size="small" onClick={() => setReplyMode(false)}>
            {i18n.t("Cancel")}
          </CmtButton>&nbsp;
          <CmtButton variant="contained" size="small" color="primary" disabled={!replyContent} onClick={onReply}>
            {i18n.t("Reply")}
          </CmtButton>
        </Box>
      </Box>
    }
  </>;
};

Comment.propTypes = {
  comment: PropTypes.object,
  isReply: PropTypes.bool,
  replyToParent: PropTypes.func,
  mode: PropTypes.string
};

const useDropdownMenu = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const handleMenuClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  return {
    menuAnchorEl,
    menuOpen,
    handleMenuClick,
    handleMenuClose,
  };
};

