import React from "react";
import { Grid, Box, Container, Hidden } from "@mui/material";
import { Alert } from "@mui/material";
import DocumentTitle from "react-document-title";
import {
  Spinner,
  ReviveVerticalBanner,
  LoadMoreSection,
} from "components";
import { usePage } from "./hooks";
import i18n from "i18next";
import { AgendaView, EventList } from "../components";

export const LiveAndUpcomingPage = () => {
  const docTitle = "Kozoom Events";
  const {
    classes,
    loadingMore,
    initLoading,
    currentDate,
    liveEvents,
    upcomingEvents,
    hasMore,
    error,
    setCurrentDate,
    onLoadMore,
  } = usePage();

  return (
    <DocumentTitle title={docTitle}>
      <Container>
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <AgendaView
              currentDate={currentDate}
              onDateChange={setCurrentDate}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={6}>
            {initLoading && (
              <Box display="flex" justifyContent="center" py={4}>
                <Spinner />
              </Box>
            )}
            {error && (
              <Alert variant="filled" severity="error">
                {i18n.t('Error while loading events')}
              </Alert>
            )}
            {!error && (
              <>
                <EventList liveEvents={liveEvents} upcomingEvents={upcomingEvents} />
                {hasMore && (
                  <Box mt={4}>
                    <LoadMoreSection
                      automatic
                      label="More"
                      loading={loadingMore}
                      onLoadMore={onLoadMore}
                    />
                  </Box>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4} lg={3} >
            <Hidden lgDown>
              <ReviveVerticalBanner />
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </DocumentTitle>
  );
};
