import { authorizationHeader } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";

export const GET_ACTIVE_ORDERS = "GET_ACTIVE_ORDERS";
export const GET_ACTIVE_ORDERS_SUCCESS = "GET_ACTIVE_ORDERS_SUCCESS";
export const GET_ACTIVE_ORDERS_FAILURE = "GET_ACTIVE_ORDERS_FAILURE";


export const getActiveOrders = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ACTIVE_ORDERS,
    });
    axios.get(`${SERVER_URL}/activeOrders`, authorizationHeader())
      .then((res) => {

        dispatch({
          type: GET_ACTIVE_ORDERS_SUCCESS,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ACTIVE_ORDERS_FAILURE,
          payload: {
            error: err
          }
        });
      });
  };
};
