import React from 'react';
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { login } from 'auth';
import { PremiumIcon } from 'components/icons';
import i18n from 'i18next';

import { useEventBanner } from "pages/TV/Event/hooks";
import { NavLink } from 'react-router-dom';
import { siteLangCode } from 'utils/site';
import { formatDateRange } from 'utils/time';

export const useStyles = makeStyles((theme) => ({
  card: {
    textAlign: 'center',
    color: '#444',
    [theme.breakpoints.down('md')]: {
      maxWidth: 350,
      margin: "auto"
    },
  },
  price: {
    fontSize: 35,
  },
  btn: {
    minWidth: 191,
    textTransform: "uppercase"
  },
  eventBanner: {
    maxWidth: '100%'
  },
  planDesc: {
    height: 200
  },
  eventTime: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 'bold'
  }
}));

export const EventPassCard = ({ event, customerId, userInfo, activeOrders }) => {
  const priceParts = String(event.subscriptionPrice).split('.');
  const eventBanner = useEventBanner(event);
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container spacing={3} flexWrap="wrap-reverse">
          <Grid item textAlign="center" lg={4} md={5} sm={12} xs={12}>
            <PremiumIcon size={60} />
            <Typography variant="h4" textTransform="uppercase" fontWeight={700} color="primary">{i18n.t('Event Pass')}</Typography>
            <Box mb={3} className={classes.planDesc}>
              <Typography fontSize={26} fontWeight={700}>
                <span className={classes.price}>€{priceParts[0]}{priceParts[1] && <sup>.{priceParts[1]}</sup>}</span>
              </Typography>
              <Typography variant="body1" fontSize={16} fontWeight={700} color="#000" marginY={2}>
                {i18n.t('Watch this event')}<br />
                {i18n.t('Live & On-Demand')}<br />
              </Typography>
              <Typography variant="body1" fontSize={16} fontWeight={600} color="#000" maxWidth={200} margin="auto">
                {i18n.t('Replay available during 14 days after the live stream ends')}
              </Typography>
            </Box>
            {!userInfo &&
              <Button variant="contained" className={classes.btn} onClick={login}>
                {i18n.t('Login to get started')}
              </Button>
            }
            {customerId && !activeOrders[event.id] &&
              <a href={`https://payments.pabbly.com/subscribe/${event.subscriptionPlanId}/event_${event.id}?customer_id=${customerId}`} rel="noreferrer">
                <Button variant="contained" className={classes.btn}>
                  {i18n.t('Subscribe')}
                </Button>
              </a>
            }
            {!!activeOrders[event.id] &&
              <NavLink to={`/${siteLangCode}/event/${event.id}`}>
                <Button variant="contained" className={classes.btn}>
                  {i18n.t('Watch this event')}
                </Button>
              </NavLink>
            }
          </Grid>
          <Grid item lg={8} md={7} sm={12} xs={12}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box flexGrow={1}>
                <img src={eventBanner} className={classes.eventBanner} alt="Event Banner" />
              </Box>
              <Box>
                <Typography className={classes.eventTime}>{formatDateRange(event.startTime, event.endTime)}</Typography>
              </Box>
            </Box>

          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export const YearlyPassCard = ({ userInfo, customerId, activeOrders }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent >
        <PremiumIcon size={60} />
        <Typography variant="h4" textTransform="uppercase" fontWeight={700} color="primary">{i18n.t('Premium Pass')}</Typography>
        <Box mb={3} className={classes.planDesc}>
          <Typography fontSize={26} fontWeight={700}>
            {i18n.t('Annual')} <span className={classes.price}>€99<sup>.99</sup></span>
          </Typography>
          <Typography variant="subtitle1" fontStyle="italic" color="#02023C">
            {i18n.t('32 cts/Day')}
          </Typography>
          <Typography variant="body1" fontSize={16} fontWeight={700} color="#000" marginY={1}>
            {i18n.t('Unlimited access')}<br />
            {i18n.t('Every Event, Game, Masterclass')}<br />
            {i18n.t('Live & On-Demand')}<br />
          </Typography>
          <Typography variant="body1" fontSize={16} fontWeight={600} color="#000" marginY={1}>
            {i18n.t('See what is included')}<br />
          </Typography>
        </Box>
        {!userInfo &&
          <Button variant="contained" className={classes.btn} onClick={login}>
            {i18n.t('Login to get started')}
          </Button>
        }
        {customerId && !activeOrders['all'] &&
          <a href={`https://payments.pabbly.com/subscribe/6296448377c4af6029be10b5/one-year-premium-pass?customer_id=${customerId}`}
            rel="noreferrer"
          >
            <Button variant="contained" className={classes.btn}>
              {i18n.t('Subscribe')}
            </Button>
          </a>
        }
        {!!activeOrders['all'] &&
          <NavLink to={`/${siteLangCode}/event/live`}>
            <Button variant="contained" className={classes.btn}>
              {i18n.t('Watch now')}
            </Button>
          </NavLink>
        }
      </CardContent>
    </Card >
  )
}