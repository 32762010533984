import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchVideos } from "store/actions/search";
import { siteLangCode } from "utils/site";
import { searchEvents } from "store/actions/search";

export const useVideoSearch = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [offset, setOffset] = useState(0);
  const videos = useSelector((state) => state.search.videos);
  const keyword = useSelector((state) => state.searchInput.searchTerms);

  const onClickVideo = video => {
    history.push(`/${siteLangCode}/event/${video.eventId}?videoId=${video.id}`);
  };

  const onLoadMore = () => {
    if (offset !== videos.data.length) {
      dispatch(searchVideos(keyword, videos.data.length));
      setOffset(videos.data.length);
    }
  };

  return {
    videos: videos.data,
    hasMore: videos.hasMore,
    loading: videos.loading,
    error: videos.err,
    total: videos.total,
    onLoadMore,
    onClickVideo,
  };
};

const spliceIntoChunks = (arr, chunkSize) => {
  const res = [];
  const _clone = [...arr];
  while (_clone.length > 0) {
    const chunk = _clone.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
};

export const useEventSearch = () => {
  const [offset, setOffset] = useState(0);
  const dispatch = useDispatch();
  const events = useSelector((state) => state.search.events);
  const keyword = useSelector((state) => state.searchInput.searchTerms);

  const onLoadMore = () => {
    if (searchEvents && offset !== events.data.length) {
      dispatch(searchEvents(keyword, events.data.length));
      setOffset(events.data.length);
    }
  };

  return {
    events: events.data,
    hasMore: events.hasMore,
    groupedEvents: spliceIntoChunks(events.data, 2),
    loading: events.loading,
    error: events.err,
    total: events.total,
    onLoadMore,
  };
};
