import { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventDetail, getEventVideos, getRelatedEvents } from "store/actions";
import { EVENTS_MEDIA_URL, UPLOADED_MEDIA_URL, } from "constants/serverUrl";
import moment from "moment/moment";

export const useEvent = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();

  const { data: event, loading } = useSelector((state) => state.eventDetail);

  useEffect(() => {
    dispatch(getEventDetail(eventId));
  }, [dispatch, eventId]);

  return {
    eventId,
    event: event,
    loading: loading,
    status: useEventStatus(event),
  };
};

export const useEventStatus = (event) => {
  return useMemo(() => {
    if (event.startTime && event.endTime) {
      const startTime = moment(event.startTime);
      const endTime = moment(event.endTime);
      const currentTime = moment();

      if (currentTime.isBefore(startTime)) {
        return "upcoming";
      } else if (currentTime.isAfter(startTime) && currentTime.isBefore(endTime)) {
        return "live";
      } else {
        return "replay";
      }
    }
    return "replay";
  }, [event]);
}

export const useRelatedEvents = (eventId) => {
  const relatedEventsData = useSelector((state) => state.relatedEvents.data);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRelatedEvents(eventId));
  }, [dispatch, eventId]);
  return relatedEventsData
};

export const useEventBanner = ({ gameId, id, image }) => {
  const games = useSelector((state) => state.games);
  const game = games ? games.listById[gameId] : null;
  const defaultImg = game ? game.bannerImage
    : `${UPLOADED_MEDIA_URL}/assets/static/images/default-image.jpg`;
  if (/^http/.test(image)) {
    return image;
  }
  if (image) {
    return `${EVENTS_MEDIA_URL}/${id}/${image}`;
  }
  return defaultImg;
}

export const useEventVideos = (eventId) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEventVideos(eventId));
  }, [dispatch, eventId]);
  const { videos, loading } = useSelector(state => state.playlist);
  return {
    videos,
    loading
  }
}