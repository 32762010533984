import { useEffect, useState, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getLiveStreams
} from "store/actions";
import { siteLangCode } from "utils/site";
import queryString from "query-string";
import moment from "moment-timezone";
import { socketIo } from "socket";

export const useLiveEvent = (eventId) => {
  const history = useHistory();
  const { videoId } = queryString.parse(window.location.search);

  const [bottomTab, setBottomTab] = useState("livescore");
  const [rightTab, setRightTab] = useState(0);
  const [table, setTable] = useState(videoId ? -1 : 0);

  useEffect(() => {
    if (videoId) {
      setTable(-1);
    }
  }, [videoId]);

  const onChangeTab = (position, value) => {
    if (position === "right") {
      setRightTab(value);
    } else if (position === "bottom") {
      setBottomTab(value);
    }
  };

  const onChangeTable = useCallback((tableIndex) => {
    setTable(tableIndex);
    history.push(`/${siteLangCode}/event/${eventId}`);
  }, [eventId, history]);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
      if (viewportWidth > 960) {
        setBottomTab("livescore");
      }
    }
    // Add event listener
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    bottomTab,
    rightTab,
    table,
    onChangeTable,
    onChangeTab,
  };
};

export const useLiveStreams = (eventId, table) => {
  const dispatch = useDispatch();
  const liveStreams = useSelector((state) => state.liveStreams);
  useEffect(() => {
    dispatch(getLiveStreams(eventId));
  }, [eventId, dispatch]);
  const streams = useMemo(() => liveStreams.data.map(stream => ({
    token: stream.token,
    url: /youtube|youtu.be/i.test(stream.url) ? stream.url : `${stream.url}&session=${socketIo.id}`,
    // url: stream.server,
    delay: stream.delay,
    state: "big",
    optionGlobalPlay: "1",
    optionGoFirst: false,
    autoresize: false,
  })), [liveStreams.data]);
  return {
    loading: liveStreams.loading,
    streams,
    currentStreamUrl: streams[table] ? streams[table].url : ''
  };
}

export const useCountDown = (toTime) => {
  const [seconds, setSeconds] = useState(moment(toTime).diff(new Date, 'seconds'));

  useEffect(() => {
    setSeconds(moment(toTime).diff(new Date, 'seconds'));
  }, [toTime]);

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(moment(toTime).diff(new Date, 'seconds'));
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [toTime, seconds]);
  return seconds;
}

export const useLocalTime = (timezone = "Greenwich") => {
  const tzMoment = moment().tz(timezone || "Greenwich");
  const [time, setTime] = useState(tzMoment.format("HH:mm:ss"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tzMoment = moment().tz(timezone || "Greenwich");
      setTime(tzMoment.format("HH:mm:ss"));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timezone]);

  return time;
};