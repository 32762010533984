import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

  title: (props) => ({
    color: "inherit",
    fontSize: props.fontSize ? props.fontSize : theme.typography.htmlFontSize
  }),
  icon: (props) => ({
    verticalAlign: "middle",
    marginLeft: 5,
    marginRight: 5,
    fontSize: props.fontSize ? props.fontSize : theme.typography.htmlFontSize
  })
}));

export const Location = ({ city, countryId, showCode = false, fontSize }) => {
  const classes = useStyles({ fontSize });
  const countries = useSelector(state => state.countries);
  let country = "";
  if (countryId && countries.countriesById[countryId]) {
    if (showCode) {
      country = countries.countriesById[countryId].name;
    } else {
      country = countries.countriesById[countryId].cio;
    }
  }

  return (
    <Typography className={classes.title} display="inline">
      {(city || country) &&
        <RoomOutlinedIcon className={classes.icon} />
      }
      {city && country &&
        `${city} (${country})`
      }
      {city && !country &&
        `${city}`
      }
      {!city && country &&
        `${country}`
      }
    </Typography>
  );
};

Location.propTypes = {
  city: PropTypes.string,
  countryId: PropTypes.number,
  showCode: PropTypes.bool
};

