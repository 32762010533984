const EventRankingCardStyles = (theme) => ({
  root: {
    backgroundColor: "white",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    borderRadius: 5,
    border: "1px solid #a3a0a0",
    overflow: "hidden",
  },

  eventInfoContainer: {
    padding: theme.spacing(2),
  },
  game: {
    color: "#f13135",
    display: "inline-block",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
    fontWeight: 700,
    fontSize: 14,
  },
  eventTitle: {
    color: "#FDC232",
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 100,
    flex: 1,
    fontSize: 14,
  },
  dates: {
    color: "#aaa",
    fontStyle: "italic",
    fontSize: 14,
  },
  rankingsContainer: {
    position: "relative",
  },
  showMore: {
    padding: "12px 12px 12px 20px",
  },
  showMoreBtn: {
    paddingLeft: 15,
    paddingRight: 10,
    float: "right",
    background: "#fff",
    border: "1px solid #FDC232",
    borderRadius: 15,
    alignItems: "center",
    display: "flex",
    marginBottom: 10,
    textTransform: "uppercase",
    "&:focus": {
      border: "1px solid #FDC232",
    },
  },
  seeMoreIcon: {
    marginLeft: 10,
  },
  eventImg: {
    height: 50,
    width: "auto",
    float: "right",
    fontSize: 10,
    lineHeight: 12,
  },
  gameIcon: {
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
  },
  gameDiv: {
    display: "flex",
    alignItem: "center",
    color: "white",
    backgroundColor: "#FDC232",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
    fontSize: 15,
    lineHeight: "20px",
    padding: "5px 8px",
    borderRadius: 3,
  },
  eventBox: {
    padding: "15px 15px 10px 15px",
    width: "100%",
  },
  textimageCenter: {
    alignItems: "center",
  },
  eventnameBox: {
    marginTop: 3,
  },
  gameName: {
    color: "white",
    cursor: "pointer",
    display: "inline-flex",
    alignItems: "center",
    padding: "5px 8px",
    background: "#FDC209",
    borderRadius: 4,
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      padding: "2px 5px",
      fontSize: 12,
      borderRadius: 2,
    },
  },
  icon: {
    marginRight: 5,
  },

  eventMeta: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  },
  eventName: {
    textTransform: "uppercase",
    opacity: 0.7,
    fontSize: 18,
    fontWeight: 600,
    margin: "5px 0 10px"
  }
});

export default EventRankingCardStyles;
