export const LoadMoreStyles = (theme) => ({
  root: {
    textAlign: "center",
    marginTop: theme.spacing(2)
  },
  label: {
    cursor: "pointer",
    display: "inline-block",
    width: 215,
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    background: "#e00e2f",
    color: "white",
    borderRadius: 30,
    fontSize: 12,
    textTransform: "uppercase",

    "&:hover": {
      background: "#e00e2f",
    },
  },
});
