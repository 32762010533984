
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { useEffect, useState } from "react";

export const useRanking = (event) => {
  const [state, setState] = useState({
    data: [],
    loading: true,
  });
  useEffect(() => {
    if (!event.isRankingOnline) {
      return;
    }
    axios.get(`${SERVER_URL}/ranking`, { params: { eventId: event.id } }).then((res) => {
      setState({
        data: res.data,
        loading: false,
      });
    }).catch((e) => console.warn(e));
  }, [event]);
  return state;
};