import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { socketEvents, socketIo } from "socket";
import { bannedByAdmin, getEventVideos, unbannedByAdmin } from "store/actions";
import { getBannedUsers, updateEventRoomUserCount } from "store/actions/live";
import { GET_LIVE_STREAMS_FINISH, GET_PLANS_BY_EVENT_FINISH, LIVE_SERVER_EVENT_UPDATE } from "store/actions";

export const useEventSocket = () => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.auth.userInfo);
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(getBannedUsers());
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (eventId) {
      socketIo.emit(socketEvents.joinEventRoom, eventId);
    }
    return () => {
      socketIo.emit(socketEvents.leaveEventRoom, eventId);
    }
  }, [eventId]);

  useEffect(() => {
    socketIo.on(socketEvents.eventRoomUserCount, count => {
      dispatch(updateEventRoomUserCount(count));
    });

    socketIo.on(socketEvents.banUser, () => {
      dispatch(bannedByAdmin());
    });

    socketIo.on(socketEvents.unbanUser, () => {
      dispatch(unbannedByAdmin());
    });

    socketIo.on(socketEvents.eventUpdate, (event) => {
      console.log("Event updated", event);
      dispatch({
        type: LIVE_SERVER_EVENT_UPDATE,
        payload: event,
      });
    });

    socketIo.on(socketEvents.scheduleUpdate, (schedule) => {
      console.log("Schedule updated", schedule);
      dispatch({
        type: GET_PLANS_BY_EVENT_FINISH,
        payload: schedule,
      });
    });

    socketIo.on(socketEvents.streamsUpdate, (streams) => {
      console.log("Streams updated", streams);
      dispatch({
        type: GET_LIVE_STREAMS_FINISH,
        payload: streams,
      });
    });

    socketIo.on(socketEvents.videosUpdate, (streams) => {
      console.log("Videos updated", streams);
      dispatch(getEventVideos(eventId));
    });
    // clean up
    return () => {
      socketIo.off(socketEvents.eventRoomUserCount);
      socketIo.off(socketEvents.updateBannedUsers);
      socketIo.off(socketEvents.eventUpdate);
      socketIo.off(socketEvents.scheduleUpdate);
      socketIo.off(socketEvents.streamsUpdate);
      socketIo.off(socketEvents.videosUpdate);
      socketIo.off(socketEvents.banUser);
      socketIo.off(socketEvents.unbanUser);
    }
  }, [eventId, dispatch]);
}

