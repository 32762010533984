import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getEventFeeds,
} from "store/actions";


export const useEventFeeds = (eventId, entities = "news,video") => {
  const dispatch = useDispatch();
  const feeds = useSelector((state) => state.eventFeeds);

  useEffect(() => {
    dispatch(getEventFeeds(eventId, entities));
  }, [dispatch, eventId, entities]);

  return {
    feeds: feeds.data.sort((a, b) => (
      (new Date(a.publishedDate)) < (new Date(b.publishedDate)) ? 1 : -1
    )),
    loading: feeds.loading,
    error: feeds.err,
  };
};