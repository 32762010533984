import moment from "moment-timezone";
import i18n from "i18next";

export function monthDiff(d1, d2 = new Date()) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth() + 1;
  return months <= 0 ? 0 : months;
}

export const dayDiff = (d1, d2) => {
  return moment(d1).diff(moment(d2), "days");
};

export const formatSeconds = (seconds, withText = false) => {
  const h = parseInt(seconds / 3600, 10);
  const m = parseInt((seconds - h * 3600) / 60, 10);
  const s = parseInt(seconds % 60, 10);
  if (!withText) {
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
  } else {
    return `${h < 10 ? "0" + h : h} ${h !== 1 ? i18n.t('Hours') : i18n.t('Hour')} ${m < 10 ? "0" + m : m} ${m !== 1 ? i18n.t('Minutes') : i18n.t('Minute')} ${s < 10 ? "0" + s : s} ${s !== 1 ? i18n.t('Seconds') : i18n.t('Second')}`;
  }
};

export const formatVideoLength = (second) => {
  let str = "00";

  const hours = parseInt(second / 3600, 10);
  const minutes = parseInt((second - hours * 3600) / 60, 10);
  str = `${hours}h ${minutes}m`;

  return str;
};

export const stringToSeconds = (str) => {
  str = str.replace(new RegExp("_", "g"), "0");
  try {
    const parts = str.split(".");
    const milliSecond = parseFloat("0." + parseInt(parts[1], 10));
    const a = parts[0].split(":");
    const res = Number(a[0]) * 60 * 60 + Number(a[1] || 0) * 60 + Number(a[2] || 0);
    return res + milliSecond;
  } catch (e) {
    return 0;
  }
};

/**
 *
 * @param {number} ts timestamp
 * @param {string} lang language
 * @param {string} format default format
 * @param {boolean} enableFromNow check from now
 * @param {number} limitDate set limit dates when check from now
 */
export const formatDate = (
  ts,
  format = "l",
  enableFromNow = false,
  limitDate = 12
) => {
  if (
    enableFromNow &&
    moment(new Date()).diff(moment(ts), "days") <= limitDate
  ) {
    const result = moment(ts).fromNow();
    if (result === 'a day ago') {
      return "1 day ago";
    }
    return result;
  }
  return moment(ts).format(format);
};

// Show a range of 2 dates, ex: 29 Feb - 01 Mar 2020 or 01 Dec 2020 - 02 Feb 2021
export const formatDateRange = (
  startTime,
  endTime,
  format = "DD MMM"
) => {
  const startYear = moment(startTime).format("YYYY");
  const endYear = moment(endTime).format("YYYY");
  const formatStart = moment(startTime).format(format);
  const formatEnd = moment(endTime).format(format);
  if (formatStart === formatEnd && startYear === endYear) {
    return `${formatStart}, ${endYear}`;
  }
  return startYear === endYear
    ? formatStart + " - " + formatEnd + " " + startYear
    : formatStart + " " + startYear + " - " + formatEnd + " " + endYear;
};

export const formatDateTimeWithTimezone = (input, timezone) => {
  if (timezone && input) {
    return moment(input).tz(timezone).format('MM/DD/YYYY HH:mm zz');
  }
  return '';
}
