import React from "react";
import { withStyles } from "@mui/styles";
import ReviveInfeedBannerStyles from "styles/Components/Revive/infeedBanner";
import DesktopBanner from "./desktop";
import MobileBanner from "./mobile";

class ReviveInfeedBanner extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.desktop}>
          <DesktopBanner />
        </div>
        <div className={classes.mobile}>
          <MobileBanner />
        </div>
      </div>
    );
  }
}

export default withStyles(ReviveInfeedBannerStyles)(ReviveInfeedBanner);
