import React, { useRef, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import videojs from "video.js";
// Do not remove this import, it's a hack to enable youtube tech
// eslint-disable-next-line
import videojsYoutube from "videojs-youtube";
import airplay from "@silvermine/videojs-airplay";
import chromecast from "@silvermine/videojs-chromecast";
import useOnScreen from "utils/hooks";
import "video.js/dist/video-js.css";
import "./style.css";
import { socketEvents, socketIo } from "socket";
import { useDispatch } from "react-redux";
import { playtOut } from "store/actions/live";

airplay(videojs);
chromecast(videojs, { preloadWebComponents: true });

const KozoomVideoPlayer = ({
  url, poster, autoplay, isLive, initialTime, onProgressUpdate, prevBtnDisabled, nextBtnDisabled, onNext, onPrev,
}) => {
  const videoRef = useRef(null);
  useVideoJSPlayer({
    videoRef,
    url,
    autoplay,
    poster,
    isLive,
    prevBtnDisabled,
    nextBtnDisabled,
    initialTime,
    onProgressUpdate,
    onNext,
    onPrev,
  });

  return (
    <div className="kozoom-video-player">
      <div data-vjs-player>
        <video
          ref={videoRef}
          className="video-js wrapper"
          playsInline
        />
      </div>
    </div>
  );
};

const useVideoJSPlayer = ({
  videoRef,
  url,
  autoplay,
  poster,
  isLive,
  prevBtnDisabled,
  nextBtnDisabled,
  initialTime,
  onProgressUpdate,
  onNext,
  onPrev,
}) => {

  const [player, setPlayer] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const prevBtn = document.querySelector('.vjs-icon-previous-item')?.parentElement;
    if (prevBtn) {
      prevBtn.disabled = prevBtnDisabled;
    }
  }, [prevBtnDisabled, player]);
  useEffect(() => {
    const nextBtn = document.querySelector('.vjs-icon-next-item')?.parentElement;
    if (nextBtn) {
      nextBtn.disabled = nextBtnDisabled;
    }
  }, [nextBtnDisabled, player]);

  const videoType = useMemo(() => {
    if (typeof url !== "string") return "";
    if (url.includes("playlist.m3u8")) {
      return "application/x-mpegURL";
    }

    if (url.includes("https://vimeo.com")) {
      return "video/vimeo";
    }

    if (url.includes("youtube.com")) {
      return "video/youtube";
    }
    return "";
  }, [url]);

  const videoJsOptions = useMemo(() => ({
    width: "100%",
    height: "100%",
    autoplay,
    controls: true,
    techOrder: ["html5", "youtube"],
    liveui: isLive,
    plugins: {
      airPlay: {
        addButtonToControlBar: true
      },
      chromecast: {
        addButtonToControlBar: true
      }
    },
    responsive: true,
    controlBar: {
      volumePanel: {
        inline: false,
        vertical: true,
      },
      captionsButton: false,
      subtitlesButton: false,
      subsCapsButton: false
    },
  }), [isLive, autoplay]);

  useEffect(() => {
    const _player = videojs(videoRef.current, videoJsOptions);
    const Button = videojs.getComponent('Button');
    const nextButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        this.controlText("Next");
        this.el().querySelector(".vjs-icon-placeholder").classList.add("vjs-icon-next-item");
      }
    });
    const prevButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        this.controlText("Previous");
        this.el().querySelector(".vjs-icon-placeholder").classList.add("vjs-icon-previous-item");
      },
    });
    videojs.registerComponent('nextButton', nextButton);
    videojs.registerComponent('prevButton', prevButton);
    _player.getChild('controlBar').addChild('prevButton', {}, 0);
    _player.getChild('controlBar').addChild('nextButton', {}, 2);
    setPlayer(_player);
    return () => {
      _player && _player.dispose();
    };
  }, [videoRef, videoJsOptions]);

  useEffect(() => {
    const nextButton = videojs.getComponent('nextButton');
    if (nextButton) {
      nextButton.prototype.handleClick = onNext;
    }
  }, [onNext]);

  useEffect(() => {
    const nextButton = videojs.getComponent('prevButton');
    if (nextButton) {
      nextButton.prototype.handleClick = onPrev;
    }
  }, [onPrev]);

  // Update video progress every 30s or when pausing
  useEffect(() => {
    if (player) {
      const interval = setInterval(() => {
        if (typeof onProgressUpdate === "function" && !player.paused()) {
          onProgressUpdate(player.currentTime());
        }
      }, 30000);
      const updateProgress = () => {
        if (typeof onProgressUpdate === "function") {
          onProgressUpdate(player.currentTime());
        }
      };
      player.on("pause", updateProgress);
      return () => {
        clearInterval(interval);
        player.off('pause', updateProgress);
      };
    }
  }, [player, onProgressUpdate]);

  // To set initial playing time if possible
  // Don't update currentTime when having new initialTime, it will make the player jumps
  useEffect(() => {
    if (player && initialTime) {
      Promise.resolve().then(() => {
        player.currentTime(initialTime);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, url]);

  // Change url
  useEffect(() => {
    if (player) {
      player.src({
        src: url,
        type: videoType,
      });
    }
  }, [url, player, videoType]);

  // Change poster
  useEffect(() => {
    if (player) {
      player.poster(poster);
    }
  }, [player, poster]);

  useEffect(() => {
    socketIo.on(socketEvents.playOut, () => {
      socketIo.disconnect();
      dispatch(playtOut());
      if (player) {
        player.pause();
      }
    });
    return () => {
      socketIo.off(socketEvents.playOut);
    }
  }, [player, dispatch]);

  // handle picture in picture mode
  const onScreen = useOnScreen(videoRef);
  useEffect(() => {
    if (!onScreen && player && !player.paused() && document.pictureInPictureEnabled && !document.pictureInPictureElement) {
      player.requestPictureInPicture().catch((err) => {
        console.warn(err);
      });
    }
  }, [onScreen, player]);

  return player;
};

KozoomVideoPlayer.propTypes = {
  url: PropTypes.string,
  isLive: PropTypes.bool,
  poster: PropTypes.string,
  autoplay: PropTypes.bool,
  currentTime: PropTypes.number,
  onProgressUpdate: PropTypes.func,
  prevBtnDisabled: PropTypes.bool,
  nextBtnDisabled: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func
};

export default KozoomVideoPlayer;
