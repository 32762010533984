import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { LoadMoreStyles } from "./styles";
import { Spinner } from "components";

import { throttle } from "lodash";
import i18n from "i18next";
import { checkVisible } from "utils/element";

class LoadMoreSection extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = throttle(this.handleScroll.bind(this), 500);
    this.onLoadMore = this.onLoadMore.bind(this);
  }
  componentDidMount = () => {
    document.addEventListener("scroll", this.handleScroll);
  };
  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    const loadMoreBtn = document.getElementById('btn-load-more');
    if (!loadMoreBtn) {
      return;
    }
    const { loading, automatic } = this.props;
    if (loading || !automatic) {
      return;
    }

    checkVisible(loadMoreBtn) && this.onLoadMore();
  };

  onLoadMore = () => {
    const { loading } = this.props;
    if (loading) {
      return;
    }
    typeof this.props.onLoadMore === "function" && this.props.onLoadMore();
  };

  render() {
    const { classes, label, loading } = this.props;
    return (
      <div id="btn-load-more" className={classes.root}>
        {loading && <Spinner />}
        {!loading && (
          <Button
            size="small"
            color="primary"
            className={classes.label}
            onClick={this.onLoadMore}
          >
            {label ? label : i18n.t("Load More")}
          </Button>
        )}
      </div>
    );
  }
}

LoadMoreSection.propTypes = {
  automatic: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

LoadMoreSection.defaultProps = {
  automatic: true,
};

export default withStyles(LoadMoreStyles)(LoadMoreSection);
