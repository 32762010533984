import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Container } from "@mui/material";

import { SiteMap } from "./SiteMap";
import { Subscription } from "./Subscription";
import { AboutKozoom } from "./AboutKozoom";
import { ShopWithTrust } from "./ShopWithTrust";
import i18n from "i18next";

import { FooterStyles } from "./styles";

const useStyles = makeStyles(FooterStyles);

export const Footer = () => {

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Container>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap">
            <SiteMap classes={classes} />
            <AboutKozoom classes={classes} />
            <ShopWithTrust classes={classes} />
            <Subscription classes={classes} />
          </Box>
        </Container>
      </div>
      <div className={classes.copyright}>
        {i18n.t("© 1998-{{thisYear}} Kozoom Group - All rights reserved", { thisYear: (new Date()).getFullYear() })}
      </div>
    </>
  );
};

