const MatchResultCardStyles = (theme) => ({
  root: {
    backgroundColor: "white",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 5,
    border: "1px solid #a3a0a0",
    overflow: "hidden",
    // boxShadow: '0 4px 4px #ccc',
  },

  videoThumbnailContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
    overflow: "hidden",
  },
  videoThumbnail: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    transition: "0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  videoDuration: {
    position: "absolute",
    bottom: 0,
    right: 0,
    color: "white",
    fontStyle: "italic",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: "#00000088",
  },
  infoContainer: {
    padding: theme.spacing(2),
  },
  game: {
    color: "#f13135",
    display: "inline-block",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
    fontWeight: 700,
    fontSize: 14,
  },
  eventTitle: {
    color: "#FDC232",
    fontWeight: 700,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: 100,
    flex: 1,
    fontSize: 14,
  },
  date: {
    color: "#aaa",
    fontStyle: "italic",
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },
  player: {
    display: "flex",
    alignItems: "center",
  },
  player1: {
    backgroundColor: "#ddd",
  },
  player2: {
    backgroundColor: "#e6aa2c",
  },
  player_name: {
    flex: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  iconChampion: {
    fontSize: 16,
  },
  recordContainer: {
    display: "flex",
  },
  recordItem: {
    lineHeight: 1.2,
    textAlign: "center",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& .title": {
      fontSize: 9,
    },
    "& .value": {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  flag: {
    width: 32,
    height: 32,
  },
  avatar: {
    width: 60,
    height: 60,
    border: "3px solid gray",
  },
  matchSetsContainer: {
    flex: 1,
  },
  singleMatchSet: {
    flex: 1,
    height: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& .points": {
      backgroundColor: "#ffffff88",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
      minWidth: 40,
      textAlign: "center",
      borderRadius: 5,
      fontSize: 18,
      fontWeight: 600,
    },
    "& .innings": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      fontSize: 14,
    },
  },
  multiMatchSets: {
    display: "flex",
    flex: 1,
    justifyContent: "space-around",
  },
  multiMatchSetItem: {
    display: "flex",
    // paddingTop: theme.spacing(0.5),
    // paddingBottom: theme.spacing(0.5),
    backgroundColor: "#ffffff88",
    justifyContent: "center",
    minHeight: 28,
    "& .points": {
      fontSize: 18,
      fontWeight: 600,
    },
    "& .innings": {
      fontSize: 10,
    },
  },
});

export default MatchResultCardStyles;
