import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Paper, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Send as SendIcon, Close as CloseIcon } from "@mui/icons-material";
import { socketEvents, socketIo } from "socket";
import i18n from "i18next";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: "2px 8px",
    display: "flex",
    alignItems: "center",
    borderRadius: 32,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#DA0828",
    backgroundColor: "#4F4E79",
  },
  input: {
    marginLeft: 8,
    marginRight: 16,
    fontSize: "1rem",
    flex: 1,
    color: "white",
    backgroundColor: "transparent",
    border: 0,
    outline: "none",
    "&::placeholder": {
      color: "#acacac",
    },
    "&:-ms-input-placeholder": {
      color: "#acacac",
    },
    "&::-ms-input-placeholder": {
      color: "#acacac",
    },
  },
  bannedInput: {
    "& .Mui-disabled": {
      WebkitTextFillColor: theme.palette.primary.main
    }
  },
  iconButton: {
    padding: 4,
    color: "white",
  },
}));

export const ChatInput = ({ onSend, onCancelEditMode, editingMessage, isBanned }) => {
  const classes = useStyles();
  const [text, setText] = useState("");

  useEffect(() => {
    setText(editingMessage ? editingMessage.text : "");
  }, [editingMessage]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (!text) {
      return;
    }
    if (editingMessage) {
      socketIo.emit(socketEvents.editMessage, {
        ...editingMessage,
        text
      });
      onCancelEditMode();
    } else {
      onSend(text);
    }
    setText("");
  };

  return (
    <Paper component="form" className={classes.root} onSubmit={onSubmit}>
      {isBanned ?
        <>
          <input
            className={classNames(classes.input, classes.bannedInput)}
            value={i18n.t("Banned by Admin")}
            disabled
          />
          <SendIcon color="error" />
        </>
        :
        <>
          <input
            className={classes.input}
            placeholder={i18n.t("Say something...")}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          {editingMessage ?
            <IconButton
              type="button"
              onClick={onCancelEditMode}
              className={classes.iconButton}
              aria-label="close"
              size="large">
              <CloseIcon />
            </IconButton>
            :
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
              size="large">
              <SendIcon />
            </IconButton>
          }
        </>
      }
    </Paper>
  );
};

ChatInput.propTypes = {
  onSend: PropTypes.func,
  onCancelEditMode: PropTypes.func,
  editingMessage: PropTypes.object,
};
