import { DatePicker, LoadingButton } from "@mui/lab";
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { Spinner } from "components";
import { ImageUploader } from "components/ImageUploader";
import { PLAYER_MEDIA_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import i18n from 'i18next';
import React, { useEffect } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPlayerDetail, updatePlayerProfile } from "store/actions";
import { PLAYER_PROFILE_FORM_CHANGE } from "store/actions";

export const PlayerProfileEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const playerDetailState = useSelector(state => state.playerDetail);
  const { loading, saving, data: playerDetail } = playerDetailState

  useEffect(() => {
    dispatch(getPlayerDetail(id));
  }, [id, dispatch]);

  const avatarImage = playerDetail.playerAvatar ? `${PLAYER_MEDIA_URL}/${playerDetail.playerAvatar}` : `${UPLOADED_MEDIA_URL}/assets/static/images/default-avatar.jpg`;
  const onUploaded = (playerAvatar) => {
    dispatch({
      type: PLAYER_PROFILE_FORM_CHANGE,
      payload: {
        playerAvatar
      }
    });
  }
  const onFieldUpdate = (field, value) => {
    dispatch({
      type: PLAYER_PROFILE_FORM_CHANGE,
      payload: {
        [field]: value
      }
    });
  }

  const onSave = () => {
    dispatch(updatePlayerProfile(playerDetail));
  }

  return (
    <DocumentTitle title="Edit Player Profile">
      <Container>
        {loading &&
          <Box display="flex" justifyContent="center">
            <Spinner />
          </Box>
        }
        {!loading && playerDetail &&
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <h1>{i18n.t('Edit Player Profile')}</h1>
              <LoadingButton
                loading={saving}
                onClick={onSave}
                variant="contained"
              >{i18n.t('Save')}</LoadingButton>
            </Box>
            <Grid container my={3} spacing={2}>
              <Grid item md={4}>
                <ImageUploader image={avatarImage} aspect={3 / 4} onUploaded={onUploaded} path="uploads/players" />
              </Grid>
              <Grid item md={8}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      required
                      label={i18n.t('First Name')}
                      onChange={(e) => onFieldUpdate('firstName', e.target.value)}
                      defaultValue={playerDetail.firstName}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      fullWidth
                      required
                      size="small"
                      label={i18n.t('Last Name')}
                      onChange={(e) => onFieldUpdate('lastName', e.target.value)}
                      defaultValue={playerDetail.lastName}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="player-gender">Age</InputLabel>
                      <Select
                        labelId="player-gender"
                        value={playerDetail.gender}
                        label="Gender"
                        onChange={(e) => onFieldUpdate('gender', e.target.value)}
                      >
                        <MenuItem value={'Male'}>{i18n.t('Male')}</MenuItem>
                        <MenuItem value={'Female'}>{i18n.t('Female')}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <DatePicker
                      label={i18n.t('Birthday')}
                      value={playerDetail.birthday}
                      onChange={(value) => onFieldUpdate('birthday', value)}
                      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </Container>
    </DocumentTitle>
  );
};

