import {
  GET_ACTIVE_ORDERS,
  GET_ACTIVE_ORDERS_SUCCESS,
  GET_ACTIVE_ORDERS_FAILURE,
} from "store/actions/premium";

const initialState = {
  activeOrders: {},
  loading: true,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_ORDERS:
      return {
        loading: true,
        activeOrders: {},
        error: null,
      };
    case GET_ACTIVE_ORDERS_SUCCESS:
      let activeOrders = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach(order => {
          activeOrders[order.event && order.event.id ? order.event.id : 'all'] = order;
        });
      }
      return {
        loading: false,
        activeOrders,
        error: null
      };
    case GET_ACTIVE_ORDERS_FAILURE:
      return {
        loading: false,
        activeOrders: {},
        error: action.payload
      };;
    default:
      return state;
  }
}
