import { Badge, Box, Container, Grid, Hidden, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import { ReviveVerticalBanner, TabPanel } from "components";
import i18n from "i18next";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { EventSearch } from "./EventSearch";
import NewsSearch from "./NewsSearch";
import { PlayerSearch } from "./PlayerSearch";
import { VideoSearch } from "./VideoSearch";
import makeStyles from "@mui/styles/makeStyles";
import { SearchPageStyles } from "./styles";

export const SearchPage = () => {
  const [state, setState] = useState({
    tabIndex: 0,
    panels: [
      {
        label: i18n.t("news"),
        key: "news",
        component: NewsSearch,
      },
      {
        label: i18n.t("events"),
        key: "events",
        component: EventSearch,
      },
      {
        label: i18n.t("players"),
        key: "players",
        component: PlayerSearch,
      },
      {
        label: i18n.t("videos"),
        key: "videos",
        component: VideoSearch,
      },
    ],
  });
  const search = useSelector(state => state.search);
  const classes = makeStyles(SearchPageStyles)();

  const { tabIndex, panels } = state;
  const { players, events, videos, news } = search;
  const counts = {
    news: news.total,
    players: players.total,
    events: events.total,
    videos: videos.total,
  };

  return (
    <Container>
      <Grid container spacing={2} justifyContent="space-between" mt={0} mb={2}>
        <Grid item lg={9} sm={12}>
          <div className={classes.customTabs}>
            <Box mb={2} className={classes.search}>
              {i18n.t("Search Results")}
            </Box>
            <Tabs
              value={tabIndex}
              scrollButtons="auto"
              variant="scrollable"
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
              className={classes.tabs}
              onChange={(e, nv) => setState({ ...state, tabIndex: nv })}
            >
              {panels.map((item, index) => (
                <Tab
                  key={index}
                  label={
                    <div>
                      <Badge
                        color={"primary"}
                        className={classes.badgeItem}
                        badgeContent={counts[item.key]}
                        showZero
                        max={1000}
                      >
                        <b>{item.label}</b>
                      </Badge>
                    </div>
                  }
                  className={classNames(
                    classes.tab,
                    index === tabIndex ? classes.tabActive : null
                  )}
                />
              ))}
            </Tabs>
          </div>
          {panels.map((item, index) => (
            <TabPanel
              key={index}
              value={tabIndex}
              index={index}
            >
              {index === tabIndex && item.component && (
                <item.component
                  location={location}
                />
              )}
            </TabPanel>
          ))}
        </Grid>
        <Grid item lg={3} sm={12}>
          <Hidden lgDown>
            <ReviveVerticalBanner />
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
}

