import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";

import { NEED_RELOGIN } from "store/actions/auth";
import { MD5 } from "crypto-js";
import { authorizationHeader } from "auth";
import { showSnackbar } from "./snackbar";
import i18n from "i18next";

export const GET_PLAYER_DETAIL_START = "GET_PLAYER_DETAIL_START";
export const GET_PLAYER_DETAIL_FINISH = "GET_PLAYER_DETAIL_FINISH";
export const GET_PLAYER_DETAIL_ERROR = "GET_PLAYER_DETAIL_ERROR";

export const PLAYER_PROFILE_FORM_CHANGE = "PLAYER_PROFILE_FORM_CHANGE";

export const GET_PLAYER_LIVESTREAM_RECORDS_START = "GET_PLAYER_LIVESTREAM_RECORDS_START";
export const GET_PLAYER_LIVESTREAM_RECORDS_FINISH = "GET_PLAYER_LIVESTREAM_RECORDS_FINISH";
export const GET_PLAYER_LIVESTREAM_RECORDS_ERROR = "GET_PLAYER_LIVESTREAM_RECORDS_ERROR";

export const UPDATE_PLAYER_PROFILE_START = "UPDATE_PLAYER_PROFILE_START";
export const UPDATE_PLAYER_PROFILE_FINISH = "UPDATE_PLAYER_PROFILE_FINISH";
export const UPDATE_PLAYER_PROFILE_ERROR = "UPDATE_PLAYER_PROFILE_ERROR";

const url = `${SERVER_URL}/player`;

export const getPlayerDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_DETAIL_START,
    });
    axios
      .get(`${url}/${id}`)
      .then((res) => {
        dispatch({
          type: GET_PLAYER_DETAIL_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_PLAYER_DETAIL_ERROR,
          payload: err,
        });
      });
  };
};

export const updatePlayerProfile = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PLAYER_PROFILE_START,
    });
    axios
      .put(`${url}/${data.id}`, data, authorizationHeader())
      .then((res) => {
        dispatch({
          type: UPDATE_PLAYER_PROFILE_FINISH,
          payload: res.data,
        });
        dispatch(showSnackbar('success', i18n.t('Player profile updated successfully')));
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: UPDATE_PLAYER_PROFILE_ERROR,
          payload: err,
        });
        dispatch(showSnackbar('error', i18n.t('Server error. Cannot update player profile!')));
      });
  };
};

export const getPlayerLiveStreamRecords = (playerId) => {
  const c = process.env.REACT_APP_LIVESCORE_CONTROLLER;
  const k = MD5(playerId + process.env.REACT_APP_LIVESCORE_CONTROLLER_KEY).toString();
  const url = `https://live.kozoom.com/livescore/player/${playerId}/matchrecordlist.json`;
  return (dispatch) => {
    dispatch({
      type: GET_PLAYER_LIVESTREAM_RECORDS_START,
    });
    axios
      .get(url, { params: { c, k } })
      .then((res) => {
        dispatch({
          type: GET_PLAYER_LIVESTREAM_RECORDS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_PLAYER_LIVESTREAM_RECORDS_ERROR,
          payload: err,
        });
      });
  };
};