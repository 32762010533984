import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Grid, Typography, Divider, Link, Box } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import {
  ArrowDropDown,
  ArrowDropUp,
  NavigateBefore,
  NavigateNext,
  StarOutlined as StarIcon,
} from "@mui/icons-material";
import Slider from "react-slick";
import RankingPlayerStyles from "styles/Components/Cards/RankingPlayer";
import { PLAYER_MEDIA_URL, UPLOADED_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import classNames from "classnames";
import i18n from "i18next";
import {
  getPlayerMatches,
  setRankingActiveEventPlayer,
} from "store/actions";
import { LoadMoreSection } from "components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { kozoomSites } from "routes.client";
import { siteLangCode } from "../../utils/site";

class RankingPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      pageSize: 3,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  addDefaultImage = (ev) => {
    ev.target.src = USER_DEFAULT_AVATAR;
  };

  _renderPlayerInfo = () => {
    const {
      classes,
      countries,
      hasData,
      playerRanking,
      eventId,
      playerMatches,
    } = this.props;
    const { player, event_player } = playerRanking;
    const { activeEventPlayer } = playerMatches;

    const country = countries.countriesById[player.nationality];
    const countryCode = country ? country.cio : "";

    return (
      <div className={classes.playerInfo}>
        <Grid container>
          <Grid
            item
            className={classNames(classes.playerInfo, classes.gridBoxBorder)}
          >
            <img
              className={classes.playerFlag}
              alt=""
              src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${countryCode}.svg`}
            />
            <Typography className={classes.playerName}>
              {player.name}
            </Typography>
          </Grid>
          {hasData.hasEarning && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                $
                <br />
                <span className={classes.value}>
                  {event_player.ep_earning ? event_player.ep_earning : 0}
                </span>
              </Typography>
            </Grid>
          )}
          {hasData.hasDay && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("Day")}
                <br />
                <span className={classes.value}>{event_player.ep_day}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasWon && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("Won")}
                <br />
                <span className={classes.value}>{event_player.ep_won}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasLoss && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("Loss")}
                <br />
                <span className={classes.value}>{event_player.ep_lost}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasRun && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("Run")}
                <br />
                <span className={classes.value}>{event_player.ep_run}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasPst && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("PTS")}
                <br />
                <span className={classes.value}>{event_player.ep_points}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasInn && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("INN")}
                <br />
                <span className={classes.value}>{event_player.ep_inning}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasAvg && (
            <Grid item xs={1} className={classes.gridBoxBorders}>
              <Typography className={classes.scoreItem}>
                {i18n.t("AVG")}
                <br />
                <span className={classes.value}>{event_player.ep_average}</span>
              </Typography>
            </Grid>
          )}
          {hasData.hasMatches && (
            <div
              className={classes.caretContainer}
              onClick={() =>
                this.togglePlayerMatches(`${eventId}_${player.id}`)
              }
            >
              {activeEventPlayer === `${eventId}_${player.id}` && (
                <ArrowDropUp className={classes.caret} />
              )}
              {activeEventPlayer !== `${eventId}_${player.id}` && (
                <ArrowDropDown className={classes.caret} />
              )}
            </div>
          )}
        </Grid>
      </div>
    );
  };

  _renderTeamInfo = (team) => {
    if (typeof team !== "object") return null;
    const { classes } = this.props;

    return (
      <div className={classes.playerInfo}>
        <Typography className={classes.playerName}>{team.label}</Typography>
      </div>
    );
  };

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  _renderPlayerDetail = () => {
    const { eventId, classes, bgColor, playerMatches } = this.props;
    const { page, pageSize } = this.state;
    const { activeEventPlayer } = playerMatches;
    const matches = playerMatches.matchesByEventPlayer[activeEventPlayer];
    if (!matches) {
      return null;
    }

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    if (matches.length > 0) {
      return (
        <div className={classes.outerDivPlayerMatch}>
          <Grid container className={classes.slickGrid}>
            <Grid item className={classes.slideLeftRightBtnDiv}>
              {matches.length > settings.slidesToShow && (
                <NavigateBefore
                  className={classes.slickArrow}
                  onClick={this.previous}
                />
              )}
            </Grid>
            <Grid item className={classes.sliderDiv}>
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {matches.map((item, index) => {
                  return (
                    <div className={classes.slideOuterDiv} key={index}>
                      <div className={classes.slickSlide}>
                        <div className={classes.matchType}>
                          <i>{item.stage}</i>
                        </div>
                        <div className={classes.topPlayerName}>
                          <Grid container>
                            <Grid
                              item
                              xs={8}
                              className={classNames(classes.playerInfo)}
                            >
                              <img
                                className={classes.playerFlag}
                                alt=""
                                src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${this.playerFlagLink(
                                  item.player1.nationality
                                )}.svg`}
                              />
                              <Typography className={classes.slideplayerName}>
                                {item.player1.firstName +
                                  " " +
                                  item.player1.lastName}
                              </Typography>
                              <StarIcon className={classes.starIcon} />
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.scoreItem}>
                                {i18n.t("HR")}
                                <br />
                                <span className={classes.value}>
                                  {item.hr1}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.scoreItem}>
                                {i18n.t("AVG")}
                                <br />
                                <span
                                  className={classNames(
                                    classes.value,
                                    classes.valueAvg
                                  )}
                                >
                                  {item.avg1}
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                        <Grid container>
                          <Grid item xs={3} className={classes.playerLeft}>
                            <img
                              onError={this.addDefaultImage}
                              className={classes.avatar}
                              alt="avatar"
                              src={`${PLAYER_MEDIA_URL}/score/${item.player1.avatar}`}
                            />
                          </Grid>
                          {item.nb_sets > 1 ? (
                            <Grid item xs={6} className={classes.nb_Sets}>
                              {item.nb_sets > 3 && page !== 1 && (
                                <ChevronLeftIcon
                                  onClick={() =>
                                    this.setState({ page: page - 1 })
                                  }
                                />
                              )}
                              <Grid
                                container
                                className={classNames(
                                  classes.playerCardInfo,
                                  classes.playerLeftCenter
                                )}
                              >
                                {this.nbSets(item)}
                              </Grid>
                              {item.nb_sets > 3 &&
                                page !== Math.ceil(item.nb_sets / pageSize) && (
                                  <ChevronRightIcon
                                    onClick={() =>
                                      this.setState({ page: page + 1 })
                                    }
                                  />
                                )}
                            </Grid>
                          ) : (
                            <Grid item xs={6} className={classes.nb_set1}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={6}
                                  className={classNames(
                                    classes.playerInfo,
                                    classes.leftnbSet,
                                    classes.setDivHeight
                                  )}
                                >
                                  <div className={classes.centerBoxBigNbr}>
                                    {item.sets[0].points1}
                                  </div>
                                  <span className={classes.centerBoxSmallNbr}>
                                    {item.sets[0].innings1}
                                  </span>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  className={classNames(
                                    classes.playerInfo,
                                    classes.setDivHeight
                                  )}
                                >
                                  <span className={classes.centerBoxSmallNbr}>
                                    {item.sets[0].innings2}
                                  </span>
                                  <div className={classes.centerBoxBigNbr}>
                                    {item.sets[0].points2}
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}

                          <Grid item xs={3} className={classes.playerRight}>
                            <img
                              onError={this.addDefaultImage}
                              className={classes.avatar}
                              alt="avatar"
                              src={`${PLAYER_MEDIA_URL}/score/${item.player2.avatar}`}
                            />
                          </Grid>
                        </Grid>
                        <div className={classes.bottomPlayerName}>
                          <Grid container>
                            <Grid item xs={2}>
                              <Typography className={classes.scoreItem}>
                                {i18n.t("AVG")}
                                <br />
                                <span
                                  className={classNames(
                                    classes.value,
                                    classes.valueAvg
                                  )}
                                >
                                  {item.avg2}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Typography className={classes.scoreItem}>
                                {i18n.t("HR")}
                                <br />
                                <span className={classes.value}>
                                  {item.hr2}
                                </span>
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              className={classNames(
                                classes.playerInfo,
                                classes.columnJustify
                              )}
                            >
                              <Typography className={classes.slideplayerName}>
                                {item.player2.firstName +
                                  " " +
                                  item.player2.lastName}
                              </Typography>
                              <img
                                className={classes.playerFlag}
                                alt="Flag"
                                src={`${UPLOADED_MEDIA_URL}/flags/1x1/cio/${this.playerFlagLink(
                                  item.player2.nationality
                                )}.svg`}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {matches.length > 0 &&
                  matches.length < settings.slidesToShow &&
                  this.extraSlickSliderDiv(settings.slidesToShow)}
              </Slider>
            </Grid>
            <Grid item className={classes.slideLeftRightBtnDiv}>
              {matches.length > settings.slidesToShow && (
                <NavigateNext
                  className={classes.slickArrow}
                  onClick={this.next}
                />
              )}
            </Grid>
          </Grid>
          <Grid container className={classNames(classes.playerInfo)}>
            <Grid item xs={1}></Grid>
            <Grid item xs={3} className={classes.replaybtnOuter}>
              <Link
                href={`${kozoomSites.tv}/${siteLangCode}//tv/replay?event_id=${eventId}`}
                target="_blank"
                underline="hover">
                <button
                  className={classes.buttonsReplay}
                  style={{ backgroundColor: bgColor ? bgColor : "transparent" }}
                >
                  {i18n.t("Watch Replay")}
                </button>
              </Link>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div
          className={classNames(classes.outerDivPlayerMatch, classes.noResult)}
        >
          {i18n.t("No matches found")}
        </div>
      );
    }
  };

  nbSets = (item) => {
    const { classes } = this.props;
    const { page, pageSize } = this.state;
    if (Array.isArray(item.sets)) {
      let indexOfLastTodo = page * pageSize;
      let indexOfFirstTodo = indexOfLastTodo - pageSize;
      let currentTodos = item.sets.slice(indexOfFirstTodo, indexOfLastTodo);

      let data = currentTodos.map((n, idx) => {
        return (
          <Grid xs={3} key={idx}>
            <div className={classes.centerBoxOuterTop}>
              <div className={classes.centerBoxTopLeft}>
                <b className={classes.topBigNbr}>{n.points1}</b>
                <span className={classes.topSmallNbr}>{n.innings1}</span>
              </div>
            </div>
            <div className={classes.centerBoxOuterBottom}>
              <div className={classes.centerBoxBottomLeft}>
                <b className={classes.bottomBigNbr}>{n.points2}</b>
                <span className={classes.bottomSmallNbr}>{n.innings2}</span>
              </div>
            </div>
          </Grid>
        );
      });
      return data;
    }
  };
  extraSlickSliderDiv = (slide) => {
    const { playerMatches } = this.props;
    const { activeEventPlayer } = playerMatches;
    const matches = playerMatches.matchesByEventPlayer[activeEventPlayer];
    const indents = [];
    const m = slide - matches.length;
    for (let i = 0; i < m; i++) {
      indents.push(<div key={i}></div>);
    }
    return indents;
  };

  playerFlagLink = (nation) => {
    const { countries } = this.props;
    const country = countries.countriesById[parseInt(nation)];
    return country ? country.cio : "";
  };

  _renderTeamDetail = () => {
    const { classes, run, innings, points } = this.props;

    const avg = innings > 0 ? (points / innings).toFixed(3) : 0;
    return (
      <div className={classes.playerDetailDataContainer}>
        <div className={classes.avatarContainer}></div>
        <div className={classes.teamInnerDetail}>
          <Divider />
          <div className={classes.scoreSection}>
            <Typography className={classes.scoreItem}>
              {i18n.t("Points")}
              <br />
              <span className={classes.value}>{points}</span>
            </Typography>
            <Typography className={classes.scoreItem}>
              {i18n.t("Innings")}
              <br />
              <span className={classes.value}>{innings}</span>
            </Typography>
            <Typography className={classes.scoreItem}>
              {i18n.t("Average")}
              <br />
              <span className={classes.value}>{avg}</span>
            </Typography>
            <Typography className={classes.scoreItem}>
              {i18n.t("High Run")}
              <br />
              <span className={classes.value}>{run}</span>
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  _renderDetail = () => {
    const { isTeam } = this.props;
    if (!isTeam) return this._renderPlayerDetail();
    return this._renderTeamDetail();
  };

  togglePlayerMatches = (eventPlayer) => {
    const { playerRanking, eventId, playerMatches } = this.props;

    if (playerMatches.activeEventPlayer === eventPlayer) {
      this.props.setRankingActiveEventPlayer("");
    } else {
      this.props.setRankingActiveEventPlayer(eventPlayer);
      if (!playerMatches.matchesByEventPlayer[eventPlayer]) {
        this.props.getPlayerMatches(eventId, playerRanking.player.id);
      }
    }
  };

  render() {
    const {
      classes,
      bgColor,
      isTeam,
      eventId,
      rank,
      playerRanking,
      playerMatches,
    } = this.props;
    const { team, player } = playerRanking;
    const { activeEventPlayer } = playerMatches;
    return (
      <div
        className={classes.root}
        style={{ backgroundColor: bgColor ? bgColor : "transparent" }}
      >
        <div className={classes.titleSection}>
          <div className={classes.rank}>{rank}</div>
          {!isTeam ? this._renderPlayerInfo() : this._renderTeamInfo(team)}
        </div>
        {`${eventId}_${player.id}` === activeEventPlayer &&
          (playerMatches.matchesByEventPlayer[activeEventPlayer] ? (
            this._renderDetail()
          ) : (
            <div className={classNames(classes.outerDivPlayerMatch)}>
              <Box mt={2} mb={1}>
                <LoadMoreSection
                  automatic={false}
                  label="More"
                  loading={true}
                />

              </Box>
            </div>
          ))}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    playerMatches: state.playerMatches,
    countries: state.countries,
  };
};

const matchDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getPlayerMatches,
      setRankingActiveEventPlayer,
    },
    dispatch
  );
};
export default connect(
  mapStateToProps,
  matchDispatchToProps
)(withStyles(RankingPlayerStyles)(RankingPlayer));
