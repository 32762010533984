import {
  AppBar,
  Box,
  ButtonBase,
  Container, Hidden,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { PremiumIcon, GlobeIcon } from "components";
import i18n from "i18next";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { activeRoute, kozoomSites } from "routes.client";
import { PrimaryHeaderStyles } from "./PrimaryHeaderStyles";
import { siteLangCode, siteSportSlug } from "utils/site";
import { COLOR_COMMUNITY, COLOR_NEWS, COLOR_STORE, COLOR_TV } from "constants/color";
import { languages } from "constants/language";

const PrimaryHeader = ({ changeLanguage }) => {
  const [langAnchor, setLangAnchor] = useState(null);
  const classes = makeStyles(PrimaryHeaderStyles)();

  const currentLanguage = useMemo(() => languages.find(item => item.iso === siteLangCode), []);

  return (
    <AppBar className={classes.appBar} position="relative">
      <Container>
        <Box display="flex" justifyContent="space-between">
          <div className={classes.primaryNavsContainer}>
            <a
              href={activeRoute.name !== 'global' ? `${kozoomSites.global}/${siteLangCode}/${siteSportSlug}` : null}
              rel="noopener noreferrer" target="_blank"
              className={classes.navItem}
            >
              <img
                className={classes.logo}
                src="https://b-cdn.kozoom.com/assets/static/images/logos/kozoom-tm-dark.svg"
                alt="Logo"
              />
            </a>
            {activeRoute.name !== 'tv' &&
              <a
                href={`${kozoomSites.tv}/${siteLangCode}/${siteSportSlug}/news`}
                className={classes.navItem}
                style={{ color: COLOR_NEWS }}
                rel="noopener noreferrer" target="_blank"
              >
                {i18n.t("news")}
              </a>
            }
            {activeRoute.name === 'tv' &&
              <Link
                to={`/${siteLangCode}/${siteSportSlug}/news`}
                className={classes.navItem}
                style={{ color: COLOR_NEWS }}
              >
                {i18n.t("news")}
              </Link>
            }
            <a
              href={activeRoute.name !== 'tv' ? `${kozoomSites.tv}/${siteLangCode}/${siteSportSlug}/live` : null}
              className={classNames(classes.navItem, activeRoute.name === 'tv' ? classes.activeTopNavItem : null)}
              style={{ color: COLOR_TV }}
              rel="noopener noreferrer" target="_blank"
            >
              {i18n.t("tv")}
            </a>
            <a
              href={`${kozoomSites.store}/${siteLangCode}`}
              rel="noopener noreferrer" target="_blank"
              className={classes.navItem}
              style={{ color: COLOR_STORE }}
            >
              {i18n.t("store")}
            </a>
            <a
              href={`${kozoomSites.community}`}
              rel="noopener noreferrer" target="_blank"
              className={classes.navItem}
              style={{ color: COLOR_COMMUNITY }}
            >
              {i18n.t("community")}
            </a>
          </div>
          <Hidden mdDown>
            <Box display="flex">
              <Box mr={1}>
                {activeRoute.name === 'tv' &&
                  <Link to={`/${siteLangCode}/premium-pre-checkout`}>
                    <Box display="flex" p={1}>
                      <PremiumIcon />
                      <Typography mr={1} fontWeight={600} marginLeft={1}>
                        {i18n.t("Premium Pass")}
                      </Typography>
                    </Box>
                  </Link>
                }
                {activeRoute.name !== 'tv' &&
                  <a href={`${kozoomSites.tv}/${siteLangCode}/premium-pre-checkout`} target="_blank" rel="noreferrer">
                    <Box display="flex" p={1}>
                      <PremiumIcon />
                      <Typography mr={1} fontWeight={600} marginLeft={1}>
                        {i18n.t("Premium Pass")}
                      </Typography>
                    </Box>
                  </a>
                }
              </Box>
              <ButtonBase onClick={(event) => setLangAnchor(event.currentTarget)}>
                <Box display="flex" alignItems="center" p={1}>
                  <GlobeIcon />
                  <Typography mr={1} fontWeight={600} marginLeft={1}>
                    {currentLanguage ? currentLanguage.label : ""}
                  </Typography>
                </Box>
              </ButtonBase>
            </Box>
          </Hidden>
          <Popover
            id="language-menu"
            anchorEl={langAnchor}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            open={Boolean(langAnchor)}
            onClose={() => setLangAnchor(null)}
          >
            {languages.map((item, index) =>
              <MenuItem
                selected={siteLangCode === item.iso}
                key={index}
                onClick={() => {
                  setLangAnchor(null);
                  changeLanguage(item.iso);
                }}
              >
                <ListItemIcon>
                  <img className={classes.flag} alt={item.label} src={`https://b-cdn.kozoom.com/assets/static/images/flags/${item.isoFlag}.svg`} />
                </ListItemIcon>
                <ListItemText>
                  {item.label}
                </ListItemText>
              </MenuItem>
            )}
          </Popover>
        </Box>
      </Container>
    </AppBar>
  );
};

PrimaryHeader.propTypes = {
  changeLanguage: PropTypes.func
};

export default PrimaryHeader;
