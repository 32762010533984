import {
  GET_LIVE_AND_UPCOMING_EVENTS_START,
  GET_LIVE_AND_UPCOMING_EVENTS_FINISH,
  GET_LIVE_AND_UPCOMING_EVENTS_ERROR,
  EVENT_GOES_LIVE,
} from "store/actions";

const initialState = {
  initLoading: false,
  live: {
    loading: false,
    data: [],
    error: null,
  },
  upcoming: {
    loading: false,
    data: [],
    error: null,
    hasMore: false
  },
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_LIVE_AND_UPCOMING_EVENTS_START:
      if (action.payload.isInit) {
        return {
          ...initialState,
          initLoading: true,
        };
      }
      return {
        ...state,
        upcoming: {
          ...state.upcoming,
          loading: true
        },
        initLoading: false
      };
    case GET_LIVE_AND_UPCOMING_EVENTS_FINISH:
      if (Array.isArray(action.payload)) {
        // action.payload[0].startTime = moment().add(10, 'seconds')
        const live = [...state.live.data];
        const upcoming = [...state.upcoming.data];
        action.payload.forEach(event => {
          if (new Date(event.startTime) <= new Date()) {
            live.push(event);
          } else {
            upcoming.push(event);
          }
        });
        return {
          ...state,
          initLoading: false,
          live: {
            ...state.live,
            data: live.sort((a, b) => Number(b.isHighlighted) - Number(a.isHighlighted)),
            loading: false
          },
          upcoming: {
            ...state.upcoming,
            data: upcoming,
            loading: false,
            hasMore: action.payload.length >= 8
          }
        }
      }
      return {
        ...state,
        initLoading: false
      };
    case EVENT_GOES_LIVE: {
      const event = state.upcoming.data.find(item => item.id === action.payload);
      if (event) {
        return {
          ...state,
          live: {
            ...state.live,
            data: [...state.live.data, event].sort((a, b) => Number(b.isHighlighted) - Number(a.isHighlighted))
          },
          upcoming: {
            ...state.upcoming,
            data: state.upcoming.data.filter(item => item.id !== event.id)
          }
        }
      }
      return state;
    }

    case GET_LIVE_AND_UPCOMING_EVENTS_ERROR:
      return {
        ...state,
        initLoading: false,
        live: {
          ...state.live,
          loading: false,
          error: action.payload
        },
        upcoming: {
          ...state.upcoming,
          loading: false,
          error: action.payload,
        }
      };
    default:
      return state;
  }
}
