import { SHOW_SNACKBAR, HIDE_SNACKBAR } from "store/actions";

const initialState = {
  open: false,
  severity: 'info',
  message: '',
  duration: 6000
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_SNACKBAR:
      return {
        ...state,
        open: true,
        ...action.payload
      }
    case HIDE_SNACKBAR:
      return {
        ...state,
        open: false,
      }
    default:
      return state;
  }
}
