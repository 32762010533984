import {
  RESET_PLAYERMATCH,
  GET_PLAYERMATCH_START,
  GET_PLAYERMATCH_FINISH,
  GET_PLAYERMATCH_ERROR,
  SET_RANKING_ACTIVE_EVENT_PLAYER,
} from "store/actions";
const initialState = {
  loading: true,
  matchesByEventPlayer: {},
  activeEventPlayer: null,
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case RESET_PLAYERMATCH:
      newState.matchesByEventPlayer = {};
      return newState;
    case GET_PLAYERMATCH_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_PLAYERMATCH_FINISH:
      newState.loading = false;
      newState.matchesByEventPlayer[`${action.payload.eventId}_${action.payload.playerId}`] = action.payload.data;
      newState.err = null;
      return newState;
    case GET_PLAYERMATCH_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    case SET_RANKING_ACTIVE_EVENT_PLAYER:
      newState.activeEventPlayer = action.payload;
      return newState;
    default:
      return newState;
  }
}
