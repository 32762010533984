import queryString from "query-string";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import {
  getPlayerDetail,
  getPlayerLiveStreamRecords,
  getPlayerVideos
} from "store/actions";
import { useStyles } from "./styles";

export const usePlayerDetail = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const history = useHistory();
  const { videoId } = queryString.parse(history.location.search);

  const classes = useStyles();
  const [sideTab, setSideTab] = useState("videos");

  const countries = useSelector(state => state.countries);
  const playerDetail = useSelector(state => state.playerDetail);
  const playlist = useSelector(state => state.playlist);
  const livestreamRecords = useSelector(state => state.playerDetail.livestreamRecords.data);

  const onChangeTab = (value) => {
    setSideTab(value);
  };

  useEffect(() => {
    if (slug) {
      dispatch(getPlayerDetail(slug));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (playerDetail.data) {
      const { id } = playerDetail.data;
      dispatch(getPlayerVideos(id));
      dispatch(getPlayerLiveStreamRecords(id));
    }
  }, [dispatch, playerDetail.data])

  return {
    classes,
    loading: playerDetail.loading,
    detail: playerDetail.data,
    videos: playlist.videos,
    hasMore: playlist.hasMore,
    videoId,
    livestreamRecords,
    country: playerDetail.data ? countries.countriesById[playerDetail.data.countryId] : null,
    sideTab,
    onChangeTab
  };
};