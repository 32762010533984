import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "white",
    margin: "auto",
    borderRadius: 5,
    border: "1px solid #ccc",
    overflow: "hidden",
    maxWidth: 728,
  },
  thumbnailContainer: {
    order: 1,
    [theme.breakpoints.down("md")]: {
      order: 2,
      width: "100%",
    },
  },
  informationSection: {
    order: 2,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  simpleContent: {
    padding: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      "& a": {
        fontSize: 16,
        maxHeight: 40,
      }
    },
  },
  thumbnailImage: {
    display: "block",
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  date: {
    display: "inline-block",
    whiteSpace: "nowrap",
    flex: 1,
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: 20,
    textDecoration: "none",
    color: "#4c4c4c",
    lineHeight: 1.2,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "box",
    lineClamp: 2,
    maxHeight: 48,
    boxOrient: "vertical",
    fontWeight: 600,
    flexGrow: 1,
    marginBottom: theme.spacing(1)
  },
  icon: {
    verticalAlign: "middle",
    fontSize: 18
  }
}))