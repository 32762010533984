import { Badge, Box, Grid, Hidden, Tab, Tabs, Typography } from "@mui/material";
import classnames from "classnames";
import { Spinner, VideoCard } from "components";
import { Comments } from "components/Comments";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import {
  ChatPanel,
  EventHeader,
  LiveScoreBoard,
  LiveVideo,
  NewsPanel,
  RelatedEventsPanel,
  ReplayVideo,
  SchedulePanel,
  NoLiveStreamsPlaceholder,
  NoneWatchablePlaceholder
} from "./components";
import {
  useCommentCount,
  useEventBanner,
  useEventFeeds,
  useEventVideos,
  useLiveEvent,
  useLiveStreams,
  usePlaylist,
  useRelatedEvents,
  useStyles,
  useWatchingGuard
} from "./hooks";
import { socketEvents, socketIo } from "socket";
import { useDispatch } from "react-redux";
import { getEventVideos } from "store/actions";
import moment from "moment";

const Live = ({ event }) => {
  const {
    rightTab,
    bottomTab,
    table,
    onChangeTab,
    onChangeTable,
  } = useLiveEvent(event.id);

  const classes = useStyles();
  const dispatch = useDispatch();
  const relatedEvents = useRelatedEvents(event.id);
  const { loading, streams, currentStreamUrl } = useLiveStreams(event.id, table);
  const { videos } = useEventVideos(event.id);
  const { currentVideo, onChangeVideo, isFirst, isLast, onNext, onPrev } = usePlaylist(videos, 'videoId');
  const commentCount = useCommentCount(currentVideo ? currentVideo.id : null);
  const { feeds } = useEventFeeds(event.id);
  const defaultBanner = useEventBanner(event);
  const hasLiveStreams = moment(event.nextStreamingTime || 0).isBefore(new Date()) && streams.length && table !== -1;
  const { watchable, loginRequired, userLoading, premiumRequired } = useWatchingGuard(event);
  const [startedWatching, setStartedWatching] = useState(false);

  // Debounce to emit start watching to prevent multple events being fired
  useEffect(() => {
    if (watchable && (videos.length || hasLiveStreams) && !startedWatching) {
      setStartedWatching(true);
      console.log('Start watching...');
      socketIo.emit(socketEvents.startWatching, event.id);
    }
  }, [watchable, videos.length, event.id, hasLiveStreams, startedWatching]);

  useEffect(() => {
    dispatch(getEventVideos(event.id));
  }, [dispatch, event]);

  if (loading || !event) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <EventHeader
          event={event}
          status="live"
        />
        <Box mb={2} className={classes.videoPlayerContainer}>
          {!watchable &&
            <NoneWatchablePlaceholder
              subscriptionPlanId={event.subscriptionPlanId}
              defaultBanner={defaultBanner}
              loginRequired={loginRequired}
              userLoading={userLoading}
              premiumRequired={premiumRequired} />
          }
          {watchable &&
            <>
              {currentVideo ? (
                <ReplayVideo
                  video={currentVideo}
                  nextBtnDisabled={isLast}
                  prevBtnDisabled={isFirst}
                  onNext={onNext}
                  onPrev={onPrev}
                  defaultBanner={defaultBanner}
                />
              ) : hasLiveStreams ? (
                <LiveVideo
                  url={currentStreamUrl}
                  table={table}
                  numbTable={streams.length}
                  setTable={onChangeTable}
                  defaultBanner={defaultBanner}
                />
              ) : (
                <NoLiveStreamsPlaceholder event={event} />
              )
              }
            </>
          }
        </Box>
        {currentVideo &&
          <Box mb={2}>
            <Typography component="h2" className={classes.videoDescription}>{currentVideo.description}</Typography>
          </Box>
        }
        <Tabs
          value={bottomTab}
          className={classes.tabContainer}
          scrollButtons="auto"
          variant="scrollable"
          TabIndicatorProps={{
            className: classes.tabIndicator,
          }}
          onChange={(_e, value) => onChangeTab("bottom", value)}
        >
          <Tab
            label={i18n.t("Live Scores")}
            value="livescore"
            className={classes.tabButton}
          />
          {currentVideo && currentVideo.article && (
            <Tab
              label={i18n.t("Article")}
              value="article"
              className={classes.tabButton}
            />
          )}
          {!!feeds.length &&
            <Tab
              label={<Badge badgeContent={feeds.length} color="primary"> {i18n.t("News")} </Badge>}
              value="news"
              className={classes.tabButton}
            />
          }
          {currentVideo &&
            <Tab
              label={<Badge badgeContent={commentCount} color="primary"> {i18n.t("Comments")} </Badge>}
              value="comment"
              className={classes.tabButton}
            />
          }
          <Tab label={i18n.t("Chat")} className={classnames(classes.tabButton, classes.mobileOnly)} value="chat" />
          <Tab label={i18n.t("Schedule")} className={classnames(classes.tabButton, classes.mobileOnly)} value="schedule" />
          <Tab label={i18n.t("Videos")} className={classnames(classes.tabButton, classes.mobileOnly)} value="videos" />
          {!!relatedEvents.length &&
            <Tab
              value="relatedEvents"
              label={i18n.t("Related Events")}
              className={classes.tabButton}
            />
          }
        </Tabs>
        <Box hidden={bottomTab !== "livescore"}>
          {streams.length > 0 && window.scoreBoards &&
            <LiveScoreBoard streams={streams} scoreBoards={window.scoreBoards} onChangeTable={onChangeTable} currentTable={table} />
          }
        </Box>
        <Box hidden={bottomTab !== "chat"}>
          <ChatPanel table={table} />
        </Box>
        <Box hidden={bottomTab !== "schedule"}>
          <SchedulePanel eventId={event.id} />
        </Box>
        {currentVideo &&
          <Box hidden={bottomTab !== "comment"}>
            <Comments entityId={Number(currentVideo.id)} entity="video" mode="dark" showHeading={false} />
          </Box>
        }
        <Box
          hidden={bottomTab !== "article"}
          p={2}
          className={classes.article}
          dangerouslySetInnerHTML={{ __html: currentVideo ? currentVideo.article : "" }} />
        <Box p={2} hidden={bottomTab !== "news"}>
          <NewsPanel feeds={feeds} onPlayVideo={onChangeVideo} />
        </Box>
        <Box p={2} hidden={bottomTab !== "videos"}>
          {videos.map((item, index) => (
            <VideoCard
              key={index}
              isCurrent={currentVideo && item.id === currentVideo.id}
              video={item}
              eventId={event.id}
              onClick={() => onChangeVideo(item.id)}
            />
          ))}
        </Box>
        <Box p={2} hidden={bottomTab !== "relatedEvents"}>
          <RelatedEventsPanel events={relatedEvents} />
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid item md={4} sm={12}>
          <Tabs
            value={rightTab}
            centered
            className={classes.tabContainer}
            TabIndicatorProps={{
              className: classes.tabIndicator,
            }}
            onChange={(_e, value) => onChangeTab("right", value)}
          >
            <Tab label={i18n.t("Chat")} className={classes.tabButton} />
            <Tab label={i18n.t("Schedule")} className={classes.tabButton} />
            {!!videos.length &&
              <Tab
                label={
                  <Badge badgeContent={videos.length} color="primary">
                    {i18n.t("Videos")}
                  </Badge>
                }
                className={classes.tabButton}
              />
            }
          </Tabs>
          <Box hidden={rightTab !== 0}>
            <ChatPanel table={table} />
          </Box>
          <Box hidden={rightTab !== 1}>
            <SchedulePanel eventId={event.id} />
          </Box>
          <Box className={classes.playList} hidden={rightTab !== 2}>
            {videos.map((item, index) => (
              <VideoCard
                key={index}
                isCurrent={currentVideo && item.id === currentVideo.id}
                video={item}
                eventId={event.id}
                onClick={() => onChangeVideo(item.id)}
              />
            ))}
          </Box>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Live;
