import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export const usePlaylist = (videos, queryParamKey = 'videoId', defaultFirstVideo = false) => {
  const history = useHistory();
  const videoId = queryString.parse(window.location.search)[queryParamKey];
  const [playlistIndex, setPlaylistIndex] = useState(-1);
  const [isFirst, setIsFirst] = useState(false);
  const [isLast, setIsLast] = useState(false);
  useEffect(() => {
    const foundIndex = videos.findIndex(item => Number(item.id) === Number(videoId));
    if (foundIndex > -1) {
      setPlaylistIndex(foundIndex);
    } else if (defaultFirstVideo && videos.length) {
      setPlaylistIndex(0);
    } else {
      setPlaylistIndex(-1);
    }
  }, [videos, videoId, defaultFirstVideo]);

  const onChangeVideo = useCallback((videoId) => {
    window.scrollTo(0, 40);
    history.push(`${window.location.pathname}?${queryParamKey}=${videoId}`);
  }, [queryParamKey, history]);

  const onNext = useCallback(() => {
    const video = videos[playlistIndex + 1];
    if (video) {
      onChangeVideo(video.id);
    }
  }, [videos, playlistIndex, onChangeVideo]);

  const onPrev = useCallback(() => {
    const video = videos[playlistIndex - 1];
    if (video) {
      onChangeVideo(video.id);
    }
  }, [videos, playlistIndex, onChangeVideo]);

  useEffect(() => {
    setIsFirst(playlistIndex === 0);
    setIsLast(playlistIndex === videos.length - 1);
  }, [playlistIndex, videos]);

  return {
    isFirst,
    isLast,
    currentVideo: videos[playlistIndex],
    onNext,
    onPrev,
    onChangeVideo
  };
}