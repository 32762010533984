export const SearchInputStyles = (theme) => ({
  searchWrapper: {
    boxSizing: "border-box",
    width: "100%",
  },
  searchForm: {
    display: "flex",
    alignItems: "center",
    background: "transparent",
    border: "1px solid white",
    marginRight: 0,
    borderRadius: 40,
    overflow: "hidden",
    minWidth: 300,
    height: 42,
    [theme.breakpoints.down("md")]: {
      borderColor: "white!important"
    },
  },
  searchInput: {
    flex: 1,
    paddingLeft: 5,
    marginLeft: 5,
    cursor: "text",
    border: "none",
    background: "transparent",
    color: "white",
    outline: "0",
    fontSize: "1rem",
    "&::placeholder": {
      color: "rgba(255, 255, 255, 0.85)",
    },
    "&:-ms-input-placeholder": {
      color: "rgba(255, 255, 255, 0.85)",
    },
    "&::-ms-input-placeholder": {
      color: "rgba(255, 255, 255 0.85)",
    },
  },
  searchIcon: {
    padding: 7,
    color: "white",
  },
  suggestionPopper: {
    background: "white",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    border: "1px solid #a3a0a0",
  },
  popperTitle: {
    fontSize: 18,
    fontWeight: 600,
  },
  searchDropdown: {
    background: "transparent",
    marginRight: 0,
    color: "#fff",
    padding: "11px 10px",
    border: "none",
    borderRight: "1px solid white",
    "&:hover": {
      "&:before": {
        borderBottom: "none!important",
      },
      "&:after": {
        borderBottom: "none!important",
      },
    },
    "& select": {
      padding: 0,
      backgroundColor: "inherit!important",
    },
    "& .MuiNativeSelect-icon": {
      color: "#ffffff",
    },
    "&:before": {
      borderBottom: "none!important",
    },
    "&:after": {
      borderBottom: "none!important",
    },
  },
});
