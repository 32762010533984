import distrotv from "./Distrotv.png";
import roku from "./Roku.png";
import sportstribal from "./Sportstribal.png";
import tcl from "./TCL.png";
import xiaomi from "./Xiaomi.png";
import youtube from "./Youtube.png";
import kozoomTV247 from "./KozoomTV247.png";

export const logo = {
  distrotv,
  roku,
  sportstribal,
  tcl,
  xiaomi,
  youtube,
  kozoomTV247
};
