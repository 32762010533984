import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';
import i18n from "i18next";


export const ConfirmationDialog = ({ title, message, open, onAgree = () => { }, onCancel = () => { } }) => {

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined">{i18n.t('Cancel')}</Button>
        <Button onClick={onAgree} autoFocus variant="contained">{i18n.t('Confirm')}</Button>
      </DialogActions>
    </Dialog>
  );
}
