import { socketIo, socketEvents } from "socket";
import {
  BANNED_BY_ADMIN,
  GET_CURRENT_USER,
  GET_CURRENT_USER_FAILURE,
  GET_CURRENT_USER_SUCCESS,
  LOGOUT_SUCCESS,
  NEED_RELOGIN,
  TOGGLE_ADMIN_FEATURE,
  UNBANNED_BY_ADMIN
} from "store/actions";

const initialState = {
  loading: false,
  userInfo: null,
  error: null,
  adminOn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CURRENT_USER:
      return {
        loading: true,
        error: null,
        userInfo: null
      };
    case GET_CURRENT_USER_SUCCESS:
      if (socketIo.connected) {
        socketIo.emit(socketEvents.userLoggedIn, action.payload.userInfo);
      }

      return {
        loading: false,
        error: null,
        userInfo: action.payload.userInfo
      };
    case GET_CURRENT_USER_FAILURE:
      return {
        loading: false,
        error: action.payload.error,
        userInfo: null
      };
    case NEED_RELOGIN:
      return {
        ...state,
        loading: false,
        userInfo: null
      };
    case LOGOUT_SUCCESS:
      if (socketIo.connected) {
        socketIo.emit(socketEvents.userLoggedOut);
      }
      return initialState;
    case TOGGLE_ADMIN_FEATURE:
      return {
        ...state,
        adminOn: !state.adminOn
      };
    case BANNED_BY_ADMIN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isBanned: true
        }
      };
    case UNBANNED_BY_ADMIN:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          isBanned: false
        }
      };
    default:
      return state;
  }
}
