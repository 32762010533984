import React from "react";
import classNames from "classnames";

import commentSVG from "./assets/comment.svg";
import linkSVG from "./assets/link.svg";
import twitterSVG from "./assets/twitter.svg";
import facebookSVG from "./assets/facebook.svg";

import { withStyles } from "@mui/styles";
import { Tooltip, Badge } from "@mui/material";

import { ShareStyles } from "./styles";
import { copyStringToClipboard } from "utils";

class ShareSection extends React.Component {
  render() {
    const { classes, commentCount } = this.props;
    const link = window.location.href;

    return (
      <div className={classes.root}>
        <Tooltip title="View Comments">
          <a className={classNames(classes.icon)} href="#comments">
            <Badge badgeContent={commentCount} color="error">
              <img
                src={commentSVG}
                alt="comment"
                className={classNames(classes.imageIcon, classes.comment)}
              />
            </Badge>
          </a>
        </Tooltip>
        <Tooltip title="Copy Link">
          <a
            className={classNames(classes.icon)}
            onClick={() => copyStringToClipboard(link)}
            href="#!"
          >
            <img
              src={linkSVG}
              alt="link"
              className={classNames(classes.imageIcon, classes.shareLink)}
            />
          </a>
        </Tooltip>
        <Tooltip title="Share Twitter">
          <a
            className={classNames(classes.icon)}
            href={`https://twitter.com/share?url=${link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={twitterSVG}
              alt="twitter"
              className={classNames(classes.imageIcon, classes.shareTwitter)}
            />
          </a>
        </Tooltip>
        <Tooltip title="Share Facebook">
          <a
            className={classNames(classes.icon)}
            href={`https://facebook.com/sharer.php?u=${link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={facebookSVG}
              alt="facebook"
              className={classNames(classes.imageIcon, classes.shareFacebook)}
            />
          </a>
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(ShareStyles)(ShareSection);
