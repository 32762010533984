import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import i18n from "i18next";
import { formatSeconds } from "utils/time";
import { useCountDown, useEventBanner } from "../hooks";
import { useSelector } from "react-redux";
import { Button, Box } from "@mui/material";
import { SubscribeButton } from "components";
import { login } from "auth";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  overlay: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "absolute",
    content: "''",
    backgroundColor: "#02023C",
    opacity: 0.7
  },
  textContainer: {
    color: "white",
    fontSize: "2em",
    top: "20%",
    left: 0,
    right: 0,
    textAlign: "center",
    position: "absolute",
    [theme.breakpoints.down('lg')]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2em",
      top: "15%",
    },
  },
  countdown: {
    backgroundColor: 'rgba(224, 14, 47, 0.8)',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  localTime: {
    fontSize: '0.7em',
    fontStyle: "italic",
  },
  subscribeText: {
    fontSize: '0.7em',
    marginBottom: 10
  }

}));

export const NoLiveStreamsPlaceholder = ({ event }) => {
  const countDownSeconds = useCountDown(event.nextStreamingTime || event.startTime);
  const defaultBanner = useEventBanner(event);
  const userInfo = useSelector(state => state.auth.userInfo);

  const classes = useStyles();
  const nextStreamingTime = moment(event.nextStreamingTime);
  const startTime = moment(event.startTime);
  const now = new Date();
  if (countDownSeconds > 0) {
    return (
      <div className={classes.root}>
        <img src={defaultBanner} width="100%" />
        <div className={classes.overlay}></div>
        <div className={classes.textContainer}>
          {nextStreamingTime && startTime.isBefore(now) &&
            <div>{i18n.t("Next streaming starts in")}</div>
          }
          {startTime.isAfter(now) &&
            <div>{i18n.t("This event starts in")}</div>
          }
          <div className={classes.countdown}>{formatSeconds(countDownSeconds, true)}</div>
          {nextStreamingTime && startTime.isBefore(now) &&
            <div className={classes.localTime}>{nextStreamingTime.format('MMM DD, hh:mm A z')}</div>
          }
          {startTime.isAfter(now) &&
            <div className={classes.localTime}>{startTime.format('MMM DD, hh:mm A z')}</div>
          }
          {!userInfo &&
            <Box textAlign="center" mt={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={login}
              >
                {i18n.t("Login To Continue")}
              </Button>
            </Box>
          }
          {userInfo && !userInfo.isPremium &&
            <Box textAlign="center" mt={2}>
              <div className={classes.subscribeText}>{i18n.t("Subscribe to have full access.")}</div>
              <SubscribeButton variant="contained" />
            </Box>
          }
        </div>
      </div>
    )
  }
  return <img src={defaultBanner} width="100%" />;
}
