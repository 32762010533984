import React from "react";
import { Box, Grid, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import i18n from "i18next";
import * as VideoTypes from "constants/videoType";
import { isYoutubeUrl, getYoutubeThumbnail } from "utils/video";
import { EVENTS_MEDIA_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { GameBadge, EventLink, LockPremiumIcon } from "components";
import { COLOR_TV } from "constants/color";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 622,
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#4F4E79",
    padding: theme.spacing(3),
    borderRadius: 5,
    overflow: "hidden",
    cursor: "pointer",
  },
  header: {
    marginBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    color: "white",
  },
  heading: {
    fontSize: 14,
    color: "white",
  },
  thumbnailContainer: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
  },
  thumbnail: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  playBtn: {
    padding: 5,
    border: "1px solid #e00e2f",
  },
  overlay: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  replay: {
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: "bold",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    textTransform: "uppercase"
  },
  second: {
    position: "absolute",
    right: 0,
    bottom: 0,
    padding: "4px 8px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    fontSize: 12,
    color: "white",
  },
  infoContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  description: {
    fontSize: 14,
    color: "white",
  },
  premium: {
    color: theme.palette.primary.main,
  },
  duration: {
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontWeight: 100,
    fontStyle: "italic",
    color: "white",
  }
}));

const VideoReplayCard = ({
  video,
  onClick,
}) => {
  const {
    type,
    path,
    thumbnail,
    description,
    eventId,
    eventImage,
    eventTypeName,
    gameId,
    duration,
    level,
    city,
  } = video;
  const classes = useStyles();

  const defaultImg = `${UPLOADED_MEDIA_URL}/assets/static/images/default-image.jpg`;
  const eventImg = eventImage ? `${EVENTS_MEDIA_URL}/${eventId}/${eventImage}` : defaultImg;
  let defaultBanner = thumbnail ? `${EVENTS_MEDIA_URL}/${eventId}/videos/${thumbnail}` : eventImg;
  if (type === VideoTypes.EXTERNAL) {
    if (isYoutubeUrl(path)) {
      defaultBanner = getYoutubeThumbnail(path);
    }
  }

  const defaultImage = (ev) => {
    ev.target.src = defaultImg;
  };

  return (
    <Grid container className={classes.root} onClick={onClick}>
      <Grid item xs={12} className={classes.container}>
        <Box className={classes.header}>
          <Box px={1} mr={1} borderRadius="4px" className={classes.replay}>
            {i18n.t("Replay")}
          </Box>
          <GameBadge gameId={Number(gameId)} bgColor={COLOR_TV} />
          {level === "premium" && (
            <Box mr={1}>
              <LockPremiumIcon className={classes.premium} />
            </Box>
          )}
          <Box>
            <EventLink
              eventId={eventId}
              eventTypeName={eventTypeName}
              city={city} />
          </Box>
        </Box>
        <Box className={classes.thumbnailContainer}>
          <img
            onError={defaultImage}
            alt="thumbnail"
            src={defaultBanner}
            className={classes.thumbnail}
          />
          <Box className={classes.overlay}>
            <IconButton color="primary" className={classes.playBtn} size="large">
              <PlayArrowIcon style={{ fontSize: 36 }} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.infoContainer}>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
          <Typography
            variant="body2"
            className={classes.duration}
          >
            {`${parseInt(duration / 60)} minutes`}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoReplayCard;
