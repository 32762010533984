import {
  GET_EVENT_DETAIL_START,
  GET_EVENT_DETAIL_FINISH,
  GET_EVENT_DETAIL_ERROR,
  LIVE_SERVER_EVENT_UPDATE,
} from "store/actions";
const initialState = {
  loading: false,
  data: {},
  err: null,
};

export default function (state = initialState, action) {
  switch (action.type) {

    case GET_EVENT_DETAIL_START:
      return {
        loading: true,
        data: {},
        err: null
      }
    case GET_EVENT_DETAIL_FINISH:
      return {
        loading: false,
        data: action.payload,
        err: null
      }
    case GET_EVENT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload
      }
    case LIVE_SERVER_EVENT_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    default:
      return state;
  }
}
