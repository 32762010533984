import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import moment from "moment";
import i18n from "i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { CalendarView } from "components";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { siteSportId } from "utils/site";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    width: 150,
    margin: "0 auto 12px",
    borderRadius: 24,
    fontSize: 12,
    textTransform: "uppercase"
  },
}));

export const AgendaView = ({ currentDate, onDateChange }) => {

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [show, setShow] = useState(false);
  const [days, setDays] = useState([]);

  const onToggleView = () => setShow(!show);

  useEffect(() => {
    const startDate = moment().add(-1, "month").format("Y-MM-DD");
    const endDate = moment().add(1, "year").format("Y-MM-DD");
    axios
      .get(`${SERVER_URL}/eventCalendar`, {
        params: {
          startDate,
          endDate,
          sportId: siteSportId,
        },
      })
      .then((res) => {
        setDays(res.data);
      })
      .catch((err) => {
        setDays([]);
      });
  }, [currentDate]);

  const eventDates = [];
  days.forEach((day) => {
    if (day.startTime && day.endTime) {
      const startTime = moment(day.startTime).clone();
      const endTime = moment(day.endTime);
      while (startTime.isSameOrBefore(endTime)) {
        const dateFormat = startTime.format("Y-MM-DD");
        if (!eventDates.includes(dateFormat)) {
          eventDates.push(dateFormat);
        }
        startTime.add(1, "days");
      }
    }
  });

  const onCalendarUpdate = (date) => onDateChange(moment(date).format("Y-MM-DD"));
  return (
    <>
      {isMobile && (
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onToggleView}
        >
          {show ? i18n.t("Close") : i18n.t("Agenda")}
        </Button>
      )}
      {(!isMobile || show) && (
        <CalendarView
          disablePastDates
          selectedDates={eventDates}
          date={moment(currentDate).toDate()}
          onChange={onCalendarUpdate}
        />
      )}
    </>
  );
};

AgendaView.propTypes = {
  days: PropTypes.arrayOf(PropTypes.any),
  currentDate: PropTypes.any,
  onChange: PropTypes.func,
};
