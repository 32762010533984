export const FooterStyles = (theme) => ({
  root: {
    background: theme.palette.darkBlue,
    color: theme.palette.white,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  siteLink: {
    fontSize: 15,
    fontWeight: 600,
  },
  title: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 10,
  },
  siteSlogan: {
    fontStyle: "italic",
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
    marginBottom: 15,
  },
  link: {
    fontSize: 14,
    marginBottom: 10,
    display: "block",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: "middle",
  },
  socialLink: {
    marginRight: 10,
  },
  copyright: {
    color: "white",
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: "center",
    background: theme.palette.primary.main
  },
  subscribeSportItem: {
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontStyle: "italic",
    cursor: "pointer",
  },
  checkBox: {
    background: "white",
    width: 20,
    height: 20,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    borderRadius: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 5,
    marginRight: 5,
  },
  checkIcon: {
    color: "black",
    fontSize: 16,
  },
  messageInputContainer: {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: 'white',
    borderRadius: 30,
    display: "flex",
    padding: 8,
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10,
    width: "fill-available",
    maxWidth: 300,
  },
  messageInput: {
    flex: 1,
    fontSize: 14,
    height: "fit-content",
    background: "transparent",
    color: "white",
    border: 0,
    outline: "none",
    "&::placeholder": {
      color: "white",
    },
    "&:-ms-input-placeholder": {
      color: "white",
    },
    "&::-ms-input-placeholder": {
      color: "white",
    },
  },
  messageSendButton: {
    paddingLeft: 10,
    color: "white",
    display: "flex",
    cursor: "pointer",
  },
  successMessage: {
    color: "white",
    fontSize: "0.75rem",
  },
  emailList: {
    fontSize: 13,
    fontStyle: "italic",
    display: "flex",
    flexWrap: "wrap",
  }
});
