import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Grid, Container } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ReviveVerticalBanner } from "components";
import { RankingStyles } from "./styles";
import RankingEvent from "./RankingEvent";

const titles = ["Latest Event", "World", "Continental", "National"];
class RankingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
    };
  }

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;

    return (
      <Container>
        <Grid container spacing={1} justifyContent="space-between">
          <Grid item md={8} sm={12} className={classes.tabcol}>
            <div className={classes.navConatiner}>
              {titles.map((item, index) => {
                return (
                  <div key={index}
                    className={classNames(
                      classes.nav,
                      activeTab === index ? classes.active : classes.inactive
                    )}
                    onClick={() => this.setState({ activeTab: index })}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
            {titles.map((item, index) => {
              return (
                activeTab === index && (
                  <RankingEvent
                    key={index}
                    eventId={this.props.match.params.id}
                    category={item !== "Latest Event" ? item : null}
                  />
                )
              );
            })}
          </Grid>
          <Grid item md={3} sm={12}>
            <ReviveVerticalBanner />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rankings: state.rankings,
  };
};

export default connect(
  mapStateToProps
)(withStyles(RankingStyles)(RankingsPage));
