import React from "react";
import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Spinner } from "components";
import i18n from "i18next";
import { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { useDispatch } from "react-redux";
import { LIVE_SERVER_EVENT_UPDATE } from "store/actions";
import { AdminControlBar } from "./components/AdminControlBar";
import { useEvent, useEventSocket } from "./hooks";
import Live from "./Live";
import Replay from "./Replay";
import Upcoming from "./Upcoming";


const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.darkBlue
    },
  };
});

export const EventPage = () => {
  const { event, loading, status: eventStatus } = useEvent();
  const docTitle = i18n.t("Kozoom Live");
  const classes = useStyles();
  const [status, setStatus] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    setStatus(eventStatus);
  }, [eventStatus]);

  useEventSocket();

  const updateEventAttribute = (key, value) => {
    dispatch({
      type: LIVE_SERVER_EVENT_UPDATE,
      payload: { [key]: value },
    });
  }

  return (
    <DocumentTitle title={docTitle}>
      <div className={classes.root}>
        <Container>
          {loading ? (
            <Box display="flex" justifyContent="center" py={4}>
              <Spinner />
            </Box>
          ) : (
            <>
              <AdminControlBar
                onUpdateStatus={(status) => setStatus(status)}
                onUpdateEventAttribute={updateEventAttribute}
              />
              <Box py={2}>
                {status === "live" && <Live event={event} />}
                {status === "upcoming" && <Upcoming event={event} />}
                {status === "replay" && <Replay event={event} />}
              </Box>
            </>
          )}
        </Container>
      </div>
    </DocumentTitle>
  );
};
