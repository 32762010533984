import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  CardActions, CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Slider,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { authorizationHeader } from "auth";
import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import i18n from "i18next";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { showSnackbar } from "store/actions";
import { getFileNameFromUrl } from "utils/file";
import { useDebounceEffect } from "utils/hooks";
import { getBlobFromCroppedImage, centerAspectCrop } from './canvas-utils';


const useStyles = makeStyles(() => ({
  cropContainer: {
    maxHeight: '60vh',
    overflow: 'auto',
    marginBottom: 15,
  },
}))

export const ImageUploader = ({ image, aspect, onUploaded = () => { }, path }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState('');
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const classes = useStyles();
  const imgRef = useRef(null);
  const inputRef = useRef(null);
  const [blob, setBlob] = useState(null);
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      // reset initial values
      setCrop(undefined);
      setScale(1);
      setRotate(0);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result.toString() || '');
        setDialogOpen(true);
        inputRef.current.value = '';
      });
      reader.readAsDataURL(e.target.files[0]);
      setFile(e.target.files[0]);
    }
  }

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
    setUploading(false);
  }

  useDebounceEffect(
    () => {
      if (
        completedCrop?.width &&
        completedCrop?.height
      ) {
        getBlobFromCroppedImage(imgRef.current, file.type, completedCrop, scale, rotate).then(blob => {
          setBlob(blob);
        });
      }
    },
    100,
    [file, completedCrop, scale, rotate],
  );

  const onUploadClick = () => {
    setUploading(true);
    const form = new FormData();
    form.append('file', blob, file.name);
    if (path) {
      form.append('path', path);
    }

    axios.post(`${SERVER_URL}/file`, form, authorizationHeader()).then((res) => {
      setDialogOpen(false);
      setUploading(false);
      const url = path ? getFileNameFromUrl(res.data.url) : res.data.url
      onUploaded(url);
    }).catch(() => {
      setUploading(false);
      dispatch(showSnackbar('error', i18n.t('Server error. Cannot upload image!')));
    });
  }

  return <>
    <Card>
      <CardMedia
        component="img"
        image={image}
      >
      </CardMedia>
      <CardActions>
        <input type="file" ref={inputRef} accept="image/*" onChange={onSelectFile} />
      </CardActions>
    </Card>
    <Dialog
      open={dialogOpen}
      onClose={handleDialogClose}
    >
      <DialogContent>
        <div className={classes.cropContainer}>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              width="1080"
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography align="center">{i18n.t('Scale')} {scale}</Typography>
            <Slider defaultValue={1} min={0.5} max={3} step={0.1} onChange={(e, value) => setScale(value)} />
          </Grid>
          {/* <Grid item sm={6} xs={12}>
            <Typography align="center">{i18n.t('Rotate')} {rotate}°</Typography>
            <Slider defaultValue={0} min={0} max={180} onChange={(e, value) => setRotate(value)} />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} disabled={uploading}>{i18n.t('Cancel')}</Button>
        <LoadingButton
          onClick={onUploadClick}
          disabled={!blob}
          variant="contained"
          loading={uploading}
        >
          {i18n.t('Upload')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  </>
}

ImageUploader.propTypes = {
  image: PropTypes.string,
  aspect: PropTypes.number,
  onUploaded: PropTypes.func,
  path: PropTypes.string
};