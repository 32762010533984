import {
  GET_CONFIG,
  GET_CONFIG_SUCCESS,
  GET_CONFIG_FAILURE
} from "store/actions/config";

const initialState = {
  loading: false,
  configByKey: {},
  error: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CONFIG:
      return {
        loading: true,
        error: null,
        configByKey: {}
      };
    case GET_CONFIG_SUCCESS: {
      const configByKey = {};
      if (Array.isArray(action.payload)) {
        action.payload.forEach(config => {
          configByKey[config.configKey] = config;
        });
        return {
          loading: false,
          configByKey,
          error: null
        };
      }
    }
    case GET_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
