import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";

import { NEED_RELOGIN } from "store/actions/auth";

export const RESET_INSTRUCTIONS =
  "RESET_INSTRUCTIONS";

export const GET_INSTRUCTIONS_START =
  "GET_INSTRUCTIONS_START";
export const GET_INSTRUCTIONS_FINISH =
  "GET_INSTRUCTIONS_FINISH";
export const GET_INSTRUCTIONS_ERROR =
  "GET_INSTRUCTIONS_ERROR";

export const UPLOAD_INSTRUCTION_START =
  "UPLOAD_INSTRUCTION_START";
export const UPLOAD_INSTRUCTION_FINISH =
  "UPLOAD_INSTRUCTION_FINISH";
export const UPLOAD_INSTRUCTION_ERROR =
  "UPLOAD_INSTRUCTION_ERROR";


const url = `${SERVER_URL}/instructions`;

export const getInstructions = (offset = null) => {
  return (dispatch) => {
    dispatch({
      type: GET_INSTRUCTIONS_START,
    });
    axios
      .get(`${url}/feed`, {
        params: {
          offset,
        },
      })
      .then((res) => {
        dispatch({
          type: GET_INSTRUCTIONS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_INSTRUCTIONS_ERROR,
          payload: err,
        });
      });
  };
};

export const addInstructions = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPLOAD_INSTRUCTION_START,
    });
    axios
      .post(url, data)
      .then((res) => {
        dispatch({
          type: UPLOAD_INSTRUCTION_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: UPLOAD_INSTRUCTION_ERROR,
          payload: err.message,
        });
      });
  };
};
