import React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { USER_DEFAULT_AVATAR, UPLOADED_MEDIA_URL } from "constants/serverUrl";

const useStyles = makeStyles(() => ({
  container: {
    margin: "0 8px",
    borderRadius: 5,
    borderWidth: 5,
    borderColor: "#4F4E79",
    borderStyle: "solid",
    backgroundColor: "#4F4E79",
  },
  selected: {
    borderColor: "white",
  },
  media: {
    height: 0,
    paddingTop: "115%",
    borderRadius: "5px 5px 0 0",
  },
  content: {
    textAlign: "center",
    color: "#FFFDFD",
    paddingTop: 12,
    paddingBottom: "6px !important",
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: "#FFFDFD",
  },
  bio: {
    height: 14,
    marginTop: 4,
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 400,
  },
}));

const MasterPlayer = ({ player, selected, onClick }) => {
  const classes = useStyles();

  return (
    <Card
      className={`${classes.container} ${selected ? classes.selected : ""}`}
      onClick={onClick}
    >
      <CardMedia
        className={classes.media}
        image={
          player.playerAvatar
            ? `${UPLOADED_MEDIA_URL}/${player.playerAvatar}`
            : USER_DEFAULT_AVATAR
        }
        title={`${player.firstName} ${player.lastName}`}
      />
      <CardContent className={classes.content}>
        <Typography
          className={classes.title}
        >{`${player.firstName} ${player.lastName}`}</Typography>
        <Typography className={classes.bio}>
          {player.short_bio || ""}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MasterPlayer;
