import {
  StarOutlined as StarIcon
} from "@mui/icons-material";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import i18n from "i18next";
import React from "react";
import { NavLink } from "react-router-dom";
import { siteLangCode } from "../../utils/site";
import PropTypes from "prop-types";
import { PLAYER_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import { CountryFlag } from "components";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  playerInfo: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    borderRadius: 20,
    textTransform: "uppercase",
    width: 160,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: 150
    },
  },
  name: {
    marginLeft: 5,
    marginRight: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: 15,
  },
  starIcon: {
    color: "#FDC232",
  },
  flagImg: {
    width: 30,
    height: 30
  },
}));

export const PlayerCard = ({ player }) => {
  const { slug, firstName, lastName, countryId, isChampion } = player;
  const classes = useStyles();
  const playerLink = `/${siteLangCode}/player/${slug}`;
  const playerAvatar = player.playerAvatar ? `${PLAYER_MEDIA_URL}/${player.playerAvatar}` : USER_DEFAULT_AVATAR;
  return <NavLink to={playerLink}>
    <Card>
      <Grid container>
        <Grid item xs={4}>
          <div className={classes.avatar} style={{ backgroundImage: `url(${playerAvatar})`, }} ></div>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.playerInfo} >
            <CountryFlag countryId={countryId} />
            <Typography variant="body1" className={classes.name}>
              {`${firstName} ${lastName}`}
            </Typography>
            {isChampion && <StarIcon fontSize="small" className={classes.starIcon} />}
          </div>
          <Box my={5} textAlign="center">
            <Button variant="outlined" className={classes.btn}>{i18n.t("Player Profile")}</Button>
          </Box>
        </Grid>
      </Grid>
    </Card>
  </NavLink>;
}


PlayerCard.propTypes = {
  player: PropTypes.object,
};
