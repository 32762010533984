import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Typography } from "@mui/material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { GameBadge, LockPremiumIcon, CalendarCard, Location } from "components";
import { siteLangCode, siteSportSlug } from "utils/site";
import { LiveStatus, ReplayStatus, UpcomingStatus } from "./VideoStatus";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  leftGroup: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  rightGroup: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-around",
    },
  },
  gameInfo: {
    color: theme.palette.white,
    textAlign: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    color: theme.palette.white,
  },
  premium: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
    },
  },
  backIcon: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    padding: 5,
    lineHeight: 1,
    [theme.breakpoints.down("md")]: {
      marginRight: theme.spacing(2),
    },
  },
}));

const EventHeader = ({ event, status }) => {
  const classes = useStyles();
  const liveViewers = useSelector(state => state.live.liveViewers);

  return (
    <Box display="flex" mb={1} className={classes.root}>
      <Box display="flex" alignItems="flex-start" className={classes.leftGroup} mb={1}>
        <NavLink
          to={`/${siteLangCode}/${siteSportSlug}/${event.status === "replay" ? "replay" : "live"}`}
          className={classes.backIcon}
        >
          <ArrowBackIos fontSize="small" />
        </NavLink>
        <GameBadge gameId={event.gameId} isEventLive={1} />
        {event.level === 'premium' &&
          <LockPremiumIcon
            invert
            width={22}
            height={28}
            className={classes.premium}
          />
        }

        {status === "upcoming" && (
          <>
            <Box ml={1} mr={0.5}>
              <CalendarCard date={event.startTime} />
            </Box>
            <Box>
              <CalendarCard date={event.endTime} />
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.gameInfo} mb={1} mx={1}>
        <Typography variant="h3" className={classes.title}>
          {event.eventTypeName || ""}
        </Typography>
        <Location city={event.city} countryId={event.countryId} fontSize={14} />
      </Box>
      <Box display="flex" className={classes.rightGroup} mb={1}>
        {status === "live" && (
          <LiveStatus
            status={status}
            totalViews={liveViewers[`event_${event.id}`]}
            timezone={event.timezone}
          />
        )}
        {status === "replay" && (
          <ReplayStatus
            startTime={event.startTime}
            endTime={event.endTime}
          />
        )}
        {status === "upcoming" && (
          <UpcomingStatus
            startTime={event.startTime}
          />
        )}
      </Box>
    </Box>
  );
};

EventHeader.propTypes = {
  event: PropTypes.any,
  totalViews: PropTypes.number,
  status: PropTypes.string,
};

export default EventHeader;
