import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  IconButton,
} from "@mui/material";
import { PlayArrow as PlayArrowIcon } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import classnames from "classnames";
import EventCardPreview from "./EventCardPreview";
import CalendarCard from "./CalendarCard";
import { GameBadge, LockPremiumIcon } from "components";
import { useEventStatus, useWatchingGuard } from "pages/TV/Event/hooks";
import { siteLangCode } from "utils/site";
import { useDispatch } from "react-redux";
import { GET_EVENT_DETAIL_FINISH } from "store/actions";
import { COLOR_TV } from "constants/color";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 5,
    boxShadow: "none",
    height: "100%",

  },
  actionArea: {
    height: "100%",
  },
  border: {
    border: "1px solid rgba(255, 255, 255, 0.4)",
  },
  borderRoundTop: {
    borderRadius: "5px 5px 0 0",
  },
  borderRoundLeft: {
    borderRadius: "5px 0 0 5px",
  },
  content: {
    padding: 12,
  },
  title: {
    minHeight: 40,
    fontSize: 16,
    fontWeight: 600,
    textTransform: "uppercase",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
  mediaContainer: {
    padding: 3,
  },
  darkMode: {
    color: theme.palette.white,
    backgroundColor: theme.palette.darkBlue,
  },
  lockIcon: {
    verticalAlign: "sub"
  },
  playBtn: {
    padding: 5,
    border: "1px solid white",
    color: "white",
  },
  playBtnInverted: {
    padding: 5,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
}));

const EventCard = ({
  event,
  replayMode = false,
  hasBorder
}) => {
  const classes = useStyles();
  const { startTime, endTime, gameId, id, eventTypeName, city, countryIso } = event;
  const status = useEventStatus(event);
  const isSameDay = moment(startTime).isSame(moment(endTime), "day");
  const isDarkMode = ["live", "replay"].includes(status);
  const { watchable, premiumRequired } = useWatchingGuard(event);
  const dispatch = useDispatch();
  const history = useHistory();
  const priceParts = String(event.subscriptionPrice).split('.');

  const onCheckout = (e) => {
    e.preventDefault();
    dispatch({
      type: GET_EVENT_DETAIL_FINISH,
      payload: event,
    });
    history.push(`/${siteLangCode}/premium-pre-checkout`);
  }

  return (
    <Card
      className={classnames(
        classes.root,
        isDarkMode ? classes.darkMode : null,
        hasBorder ? classes.border : null,
        "event-card"
      )}
    >
      <CardActionArea
        component={Link}
        className={classes.actionArea}
        to={`/${siteLangCode}/event/${id}`}
      >
        <CardMedia className={classes.mediaContainer} >
          <EventCardPreview
            className={classes.borderRoundTop}
            status={status}
            event={event}
          />
        </CardMedia>
        <CardContent className={classes.content}>
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="row"
          >
            <Box display="flex" alignItems="center">
              <GameBadge gameId={Number(gameId)} bgColor={COLOR_TV} />
            </Box>
            <Box display="flex" alignItems="center">
              <CalendarCard
                date={startTime}
                color={status === 'replay' ? "gray" : "primary.main"}
              />
              {!isSameDay && (
                <CalendarCard
                  ml={1}
                  date={endTime}
                  color={status === 'replay' ? "gray" : "primary.main"}
                />
              )}
            </Box>
          </Box>
          <Box mt={1}>
            <Typography variant="h3" className={classes.title}>
              {eventTypeName}
            </Typography>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="space-between"
              width="100%"
            >
              <Box ml={-0.5} display="flex">
                <RoomIcon fontSize="small" />
                <Typography marginLeft="4px" fontSize={13} fontWeight={600}>
                  {`${city} ${countryIso ? `(${countryIso})` : ""}`}
                </Typography>
              </Box>
              {!watchable && premiumRequired &&
                <Box onClick={onCheckout} display="flex" alignItems="flex-end">
                  {event.subscriptionPrice && moment(event.endTime).add(14, 'days').isAfter(moment()) &&
                    <Typography fontWeight={600}>€{priceParts[0]}{priceParts[1] && <>.<sup>{priceParts[1]}</sup></>}</Typography>
                  }
                  <Box ml={1}>
                    <LockPremiumIcon color="#e00e2f" invert={isDarkMode} className={classes.lockIcon} />
                  </Box>
                </Box>
              }
              {watchable && replayMode &&
                <IconButton className={classes.playBtn}>
                  <PlayArrowIcon fontSize="smal" />
                </IconButton>
              }
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card >
  );
};

EventCard.propTypes = {
  replayMode: PropTypes.bool,
  event: PropTypes.object,
  hasBorder: PropTypes.bool,
};

export default EventCard;
