import { io } from "socket.io-client";
import {
  CHAT_SOCKET_SERVER_URL
} from "constants/serverUrl";
import { store } from "index";
import { socketEvents } from "./events";

export const socketIo = io(CHAT_SOCKET_SERVER_URL, {
  path: "/live-server/socket.io",
});

socketIo.on('connect', () => {
  const userInfo = store.getState().auth.userInfo;
  if (userInfo) {
    socketIo.emit(socketEvents.userLoggedIn, userInfo);
  }
});
