import { IconButton, NativeSelect } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import {
  COLOR_COMMUNITY,
  COLOR_GAME,
  COLOR_NEWS,
  COLOR_STORE,
  COLOR_TV,
} from "constants/color";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { SearchInputStyles } from "../SearchInput/styles";
import { siteLangCode, siteSportSlug } from "../../utils/site";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { kozoomSites } from "routes.client";

const colors = {
  news: COLOR_NEWS,
  tv: COLOR_TV,
  game: COLOR_GAME,
  community: COLOR_COMMUNITY,
  store: COLOR_STORE,
};
class SearchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      site: "",
      keyword: "",
    };
  }

  onKeywordChange = (e) => {
    this.setState({ keyword: e.target.value });
  };

  onSearch = () => {
    const { keyword, site } = this.state;
    let newLocation = "";
    switch (site) {
      case "tv":
        newLocation = `${kozoomSites.tv}/${siteLangCode}/${siteSportSlug}/search?k=${keyword}`;
        break;
      case "game":
        newLocation = `${kozoomSites.game}/${siteLangCode}/${siteSportSlug}/search?k=${keyword}`;
        break;
      case "community":
        newLocation = `${kozoomSites.community}/search?q=${keyword}`;
        break;
      case "store":
        newLocation = `${kozoomSites.store}/${siteLangCode}/catalogsearch/result/?q=${keyword}`;
        break;
      default:
        break;
    }
    if (newLocation) {
      window.location = newLocation;
    }
  };

  onReset(e) {
    e.preventDefault();
    this.setState({
      keyword: "",
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.onSearch();
  };

  handleSelectChange = (event) => {
    this.setState({ site: event.target.value });
  };

  render() {
    const { classes, borderColor } = this.props;
    const { keyword, site } = this.state;
    return (
      <div className={classes.searchWrapper}>
        <form
          className={classes.searchForm}
          style={{ borderColor: borderColor || "white" }}
          onSubmit={this.handleSubmit}
        >
          <NativeSelect
            onChange={this.handleSelectChange}
            style={{ backgroundColor: colors[site] || "transparent" }}
            className={classes.searchDropdown}
          >
            <option value="">{i18n.t("Search")}</option>
            <option value="tv">{i18n.t("TV")}</option>
            <option value="store">{i18n.t("Store")}</option>
          </NativeSelect>
          <input
            className={classes.searchInput}
            type="text"
            value={keyword}
            onChange={this.onKeywordChange}
            disabled={!site}
          />
          {this.state.keyword ? (
            <IconButton
              aria-label="Search"
              className={classes.searchIcon}
              onClick={this.onReset}
              size="large">
              <CloseIcon fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Search"
              className={classes.searchIcon}
              onClick={this.onSearch}
              size="large">
              <SearchIcon fontSize="small" />
            </IconButton>
          )}
        </form>
      </div>
    );
  }
}

SearchInput.propTypes = {
  borderColor: PropTypes.string,
};

export default withStyles(SearchInputStyles)(SearchInput);
