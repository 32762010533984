import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDownward from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import i18n from "i18next";
import { siteLangCode } from "utils/site";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    width: "100%",
    border: "1px solid white",
    borderRadius: 24,
    fontSize: 16,
    fontWeight: 600,
    color: "white",
    textTransform: "uppercase",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const RelatedDropdown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { loading, data: events, error } = useSelector(
    (state) => state.relatedEvents
  );


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading || !events.length) {
    return null;
  }

  return (
    <Box mb={3}>
      <Button
        className={classes.menuButton}
        aria-controls="related-events-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {i18n.t("Related Events")}
        <ArrowDownward fontSize="small" />
      </Button>
      <Menu
        id="related-events-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {events.map((event, key) => (
          <MenuItem
            key={key}
            component={Link}
            to={`/${siteLangCode}/event/${event.id}`}
          >
            {event.eventTypeName}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default RelatedDropdown;
