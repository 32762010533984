import axios from "axios";
import { SERVER_URL } from "constants/serverUrl";
import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode, siteSportId } from "../../utils/site";

export const SEARCH_NEWS_START = "SEARCH_NEWS_START";
export const SEARCH_NEWS_FINISH = "SEARCH_NEWS_FINISH";
export const SEARCH_NEWS_ERROR = "SEARCH_NEWS_ERROR";

export const SEARCH_EVENTS_START = "SEARCH_EVENTS_START";
export const SEARCH_EVENTS_FINISH = "SEARCH_EVENTS_FINISH";
export const SEARCH_EVENTS_ERROR = "SEARCH_EVENTS_ERROR";

export const SEARCH_PLAYERS_START = "SEARCH_PLAYERS_START";
export const SEARCH_PLAYERS_FINISH = "SEARCH_PLAYERS_FINISH";
export const SEARCH_PLAYERS_ERROR = "SEARCH_PLAYERS_ERROR";

export const SEARCH_VIDEOS_START = "SEARCH_VIDEOS_START";
export const SEARCH_VIDEOS_FINISH = "SEARCH_VIDEOS_FINISH";
export const SEARCH_VIDEOS_ERROR = "SEARCH_VIDEOS_ERROR";

export const searchNews = (searchStr = "", offset = 0) => {
  const url = `${SERVER_URL}/news`;

  return (dispatch) => {
    dispatch({
      type: SEARCH_NEWS_START,
      payload: { firstLoad: offset === 0 },
    });
    const params = {
      searchStr,
      lang: siteLangCode,
      sportId: siteSportId,
      offset
    };
    if (!searchStr.trim()) {
      delete params.searchStr;
    }
    if (!siteSportId || siteSportId === 1) {
      delete params.sportId;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dispatch({
          type: SEARCH_NEWS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: SEARCH_NEWS_ERROR,
          payload: err.response ? (err.response.data.msg || err.response.data.err) : "",
        });
      });
  };
};

export const searchEvents = (searchStr = "", offset = 0) => {
  const url = `${SERVER_URL}/event`;

  return (dispatch) => {
    dispatch({
      type: SEARCH_EVENTS_START,
      payload: { firstLoad: offset === 0 },
    });

    const params = { searchStr, lang: siteLangCode, sportId: siteSportId, offset };
    if (!searchStr.trim()) {
      delete params.searchStr;
    }
    if (!siteSportId || siteSportId === 1) {
      delete params.sportId;
    }

    axios
      .get(url, { params })
      .then((res) => {
        dispatch({
          type: SEARCH_EVENTS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: SEARCH_EVENTS_ERROR,
          payload: err.response.data.msg || err.response.data.err,
        });
      });
  };
};


/**
 * Search Videos
 */
export const searchVideos = (searchStr = "", offset = 0) => {
  const url = `${SERVER_URL}/video`;

  return (dispatch) => {
    dispatch({
      type: SEARCH_VIDEOS_START,
      payload: {
        firstLoad: offset === 0
      }
    });
    axios
      .get(url, {
        params: {
          lang: siteLangCode,
          sportId: siteSportId,
          offset,
          limit: 20,
          searchStr,
        },
      })
      .then((res) => {
        dispatch({
          type: SEARCH_VIDEOS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: SEARCH_VIDEOS_ERROR,
          payload: err,
        });
      });
  };
};


export const searchPlayers = (searchStr = "", offset = 0) => {
  const url = `${SERVER_URL}/player`;
  return (dispatch) => {
    dispatch({
      type: SEARCH_PLAYERS_START,
      payload: { firstLoad: offset === 0 },
    });
    const params = { searchStr, lang: siteLangCode, offset };
    if (!searchStr.trim()) {
      delete params.searchStr;
    }
    axios
      .get(url, { params })
      .then((res) => {
        dispatch({
          type: SEARCH_PLAYERS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: SEARCH_PLAYERS_ERROR,
          payload: err.response.data.msg || err.response.data.err,
        });
      });
  };
};