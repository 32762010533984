import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { siteLangCode, siteSportId } from "../../utils/site";

export const GET_MASTERCLASS_PLAYERS_START = "GET_MASTERCLASS_PLAYERS_START";
export const GET_MASTERCLASS_PLAYERS_FINISH = "GET_MASTERCLASS_PLAYERS_FINISH";
export const GET_MASTERCLASS_PLAYERS_ERROR = "GET_MASTERCLASS_PLAYERS_ERROR";
export const GET_MASTERCLASS_EVENTS_START = "GET_MASTERCLASS_EVENTS_START";
export const GET_MASTERCLASS_EVENTS_FINISH = "GET_MASTERCLASS_EVENTS_FINISH";
export const GET_MASTERCLASS_EVENTS_ERROR = "GET_MASTERCLASS_EVENTS_ERROR";
export const GET_MASTERCLASS_EVENTS_RESET = "GET_MASTERCLASS_EVENTS_RESET";


export const getMasterclassPlayers = () => {
  return (dispatch) => {
    dispatch({
      type: GET_MASTERCLASS_PLAYERS_START,
    });
    axios
      .get(`${SERVER_URL}/players?has_masterclass=1`)
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_MASTERCLASS_PLAYERS_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_MASTERCLASS_PLAYERS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_MASTERCLASS_PLAYERS_ERROR,
          payload: err,
        });
      });
  };
};

export const getMasterclassEvents = (
  authorId = null,
  offset = 0,
  limit = 10,
  reset = false
) => {
  const lang = siteLangCode;
  return (dispatch) => {
    if (reset) {
      dispatch({
        type: GET_MASTERCLASS_EVENTS_RESET,
      });
    }
    dispatch({
      type: GET_MASTERCLASS_EVENTS_START,
    });
    axios
      .get(`${SERVER_URL}/masterclass`, {
        params: {
          limit,
          offset,
          lang,
          sportId: siteSportId,
          author_player_id: authorId || undefined,
        },
      })
      .then((res) => {
        if (res.data.msg) {
          dispatch({
            type: GET_MASTERCLASS_EVENTS_ERROR,
            payload: res.data.msg,
          });
          return;
        }
        dispatch({
          type: GET_MASTERCLASS_EVENTS_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_MASTERCLASS_EVENTS_ERROR,
          payload: err,
        });
      });
  };
};
