import React from "react";
import PropTypes from "prop-types";

export const PremiumIcon = ({ color, size = 22 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22.248 22.248" fill={color || 'currentColor'}>
      <g>
        <path d="M22.248,8.427l-7.653-1.19L11.084,0,7.641,7.273,0,8.541l5.525,5.685L4.313,22.248l6.858-3.759,6.892,3.689-1.287-8.008ZM11.154,6.4a5.7,5.7,0,0,1,5.552,5.828,5.7,5.7,0,0,1-5.552,5.828A5.7,5.7,0,0,1,5.6,12.228,5.7,5.7,0,0,1,11.154,6.4Z" />
        <path d="M158.479,155.59a5.24,5.24,0,0,1-3.752-1.128,3.229,3.229,0,0,1,2.207-3.159l.168.082a2.978,2.978,0,0,0,1.377.395,2.774,2.774,0,0,0,1.248-.343c.1-.048.2-.092.3-.133a3.383,3.383,0,0,1,1.59,1.176,3.344,3.344,0,0,1,.618,1.983A5.24,5.24,0,0,1,158.479,155.59Zm0-4.764a1.906,1.906,0,1,1,1.876-1.906A1.891,1.891,0,0,1,158.479,150.826Z" transform="translate(-147.283 -139.015)" />
      </g>
    </svg>
  );
};

PremiumIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};
