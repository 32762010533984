import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { NEED_RELOGIN } from "store/actions/auth";
import { siteLangCode } from "utils/site";
import { authorizationHeader } from "auth";

export const GET_COMMENTS = "GET_COMMENTS";
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS";
export const GET_COMMENTS_FAILURE = "GET_COMMENTS_FAILURE";

export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

const url = `${SERVER_URL}/comment`;

export const getComments = (entityId, entity) => {
  return (dispatch) => {
    dispatch({
      type: GET_COMMENTS,
      payload: { entityId, entity }
    });
    axios
      .get(url, { params: { entityId, entity, lang: siteLangCode } })
      .then((res) => {
        dispatch({
          type: GET_COMMENTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_COMMENTS_FAILURE,
          payload: err,
        });
      });
  };
};

export const addComment = (data) => {
  return (dispatch) => {
    dispatch({
      type: ADD_COMMENT,
    });
    axios
      .post(url, data, authorizationHeader())
      .then((res) => {
        dispatch({
          type: ADD_COMMENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: ADD_COMMENT_FAILURE,
          payload: err,
        });
      });
  };
};

export const updateComment = (id, data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COMMENT,
    });
    axios
      .put(`${url}/${id}`, data, authorizationHeader())
      .then((res) => {
        dispatch({
          type: UPDATE_COMMENT_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: UPDATE_COMMENT_FAILURE,
          payload: err,
        });
      });
  };
};

export const deleteComment = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_COMMENT,
    });
    axios
      .delete(`${url}/${id}`, authorizationHeader())
      .then((res) => {
        dispatch({
          type: DELETE_COMMENT_SUCCESS,
          payload: id,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: DELETE_COMMENT_FAILURE,
          payload: err,
        });
      });
  };
};