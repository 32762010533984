import { Box, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses, Button } from '@mui/material';
import { styled } from '@mui/styles';
import { authorizationHeader } from 'auth';
import axios from 'axios';
import { Spinner } from 'components';
import { SERVER_URL } from 'constants/serverUrl';
import i18n from 'i18next';
import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import moment from 'moment';
import { premiumPassLink, siteLangCode } from 'utils/site';
import { useCustomerId } from 'hooks/premium';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold'
  },
}));

export const PremiumPassPage = () => {
  const { userInfo, loading } = useSelector(state => state.auth);
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const customerId = useCustomerId();
  const activeOrders = useSelector(state => state.premium.activeOrders);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    setLoadingOrders(true);
    axios.get(`${SERVER_URL}/order`, { ...authorizationHeader(), params: { userId: userInfo.id } }).then((res) => {
      setOrders(res.data);
      setLoadingOrders(false);
    });
  }, [userInfo, customerId]);

  const cancelOrder = (orderRef) => {
    const ok = confirm(i18n.t('Are you sure you want to cancel this subscription? Your Premium Pass will stil be valid until the end of the latest payment period.'));
    if (ok) {
      axios.post(`${SERVER_URL}/subscription/${orderRef}/cancel`, {}, authorizationHeader()).then((res) => {
        setOrders(orders.map(order => order.orderRef === orderRef ? {
          ...order,
          orderType: 'onetime'
        } : order
        ));
      }).catch(e => {
        alert(e.message);
      });
    }
  }
  if (loading) {
    return (
      <Box textAlign="center" my={5}>
        <Spinner />
      </Box>
    )
  }
  if (!loading && !userInfo) {
    return (
      <Box textAlign="center" my={5}>
        <Typography variant="h4">{i18n.t('Login is required to access this page')}</Typography>
      </Box>
    )
  }
  if (!customerId) {
    return null;
  }
  return (
    <DocumentTitle title={i18n.t('Premium Pass')}>
      <Container>
        <Box py={3}>
          <Typography variant="h4">
            {i18n.t('Premium Pass')}
          </Typography>
          {!activeOrders['all'] &&
            <Box my={4}>
              <Typography>
                {i18n.t('Subscribe to one-year plan to watch live streams and replay videos in all events.')}
              </Typography>
              <br />
              <a href={`https://payments.pabbly.com/subscribe/6296448377c4af6029be10b5/one-year-premium-pass?customer_id=${customerId}`}
                rel="noreferrer"
              >
                <Button variant='contained'>
                  {i18n.t('Subscribe Now')}
                </Button>
              </a>
            </Box>
          }
          {activeOrders['all'] &&
            <Box my={4}>
              <Typography>
                {i18n.t('You have Premium access to all events until {{endTime}}', { endTime: moment(activeOrders['all']['endTime']).format('MMM Do Y HH:mm:ss') })}
              </Typography>
            </Box>
          }
          {!!orders.length &&
            <>
              <Typography variant="h5">
                {i18n.t('Orders')}
              </Typography>
              <Typography variant="subtitle2" mb={2}>
                {i18n.t('List of currently purchased orders')}
              </Typography>
            </>
          }
          {loadingOrders &&
            <Box display="flex" justifyContent="center">
              <Spinner />
            </Box>
          }
          {!!orders.length && !loadingOrders &&
            <TableContainer sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', borderRadius: 1 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>{i18n.t('Order Ref')}</StyledTableCell>
                    <StyledTableCell align="center">{i18n.t('Related Event')}</StyledTableCell>
                    <StyledTableCell>{i18n.t('Start Time')}</StyledTableCell>
                    <StyledTableCell>{i18n.t('Expire Time')}</StyledTableCell>
                    <StyledTableCell>{i18n.t('Status')}</StyledTableCell>
                    <StyledTableCell>{i18n.t('Source')}</StyledTableCell>
                    <StyledTableCell>{i18n.t('Created At')}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRow
                      key={order.orderRef + order.source}
                    >
                      <StyledTableCell>{order.orderRef}</StyledTableCell>
                      <StyledTableCell align="center">
                        {order.eventId ?
                          <NavLink to={`/${siteLangCode}/event/${order.eventId}`}>
                            <Button>
                              {i18n.t('View Event')}
                            </Button>
                          </NavLink>
                          :
                          <span>{i18n.t('None')}</span>
                        }
                      </StyledTableCell>
                      <StyledTableCell>{moment(order.startTime).format('MMM Do Y HH:mm:ss')}</StyledTableCell>
                      <StyledTableCell>{moment(order.endTime).format('MMM Do Y HH:mm:ss')}</StyledTableCell>
                      <StyledTableCell>{moment(order.endTime).isAfter(moment()) ? order.status : i18n.t('Expired')}</StyledTableCell>
                      <StyledTableCell>{order.source}</StyledTableCell>
                      <StyledTableCell>{moment(order.createdAt).format('MMM Do Y HH:mm:ss')}</StyledTableCell>
                      {order.source === 'legacy' ?
                        <StyledTableCell>
                          <a href={premiumPassLink} target="_blank" rel="noreferrer">
                            <Button>
                              {i18n.t('View')}
                            </Button>
                          </a>
                        </StyledTableCell>
                        : order.source === 'pabbly' && order.orderType === 'recursive' ?
                          <StyledTableCell>
                            <Button onClick={() => cancelOrder(order.orderRef)}>
                              {i18n.t('Cancel')}
                            </Button>
                          </StyledTableCell>
                          :
                          <StyledTableCell></StyledTableCell>
                      }
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      </Container>
    </DocumentTitle >
  );
}
