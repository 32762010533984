import { Hidden, Snackbar, Alert } from "@mui/material";
import { Footer, Header } from "components";
import { getDefaultPath } from "index";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { getCountries, getGames, hideSnackbar } from "store/actions";
import { getConfigs } from "store/actions/config";
import { createGrooveWidget, initGroove } from "utils/groove";
import { activeRoute } from "../routes.client";
import { setSiteSportBySlug, siteLangCode } from "../utils/site";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl";

const Client = () => {
  const dispatch = useDispatch();
  const snackbar = useSelector(state => state.snackbar);
  const { lang, sport: sportSlug } = useParams();
  useEffect(() => {
    moment.locale(siteLangCode);
    dispatch(getGames(siteLangCode));
    dispatch(getCountries());
    dispatch(getConfigs());
  }, [dispatch]);

  useEffect(() => {
    setSiteSportBySlug(sportSlug);
  }, [sportSlug]);

  useEffect(() => {
    if (!window.groove) {
      initGroove();
    }
    if (!window.location.pathname.match(/^\/[a-z]+\/tv-channel$/)) {
      createGrooveWidget(lang);
    }
  }, [lang]);

  const handleHideSnackbar = () => {
    dispatch(hideSnackbar());
  }

  const getRoutes = (
    <Switch>
      {activeRoute.type === "external" ? null : activeRoute.type ===
        "submenu" ? (
        activeRoute.children.map((subItem, index) => {
          return (
            <Route
              key={index}
              exact
              path={`${subItem.absolutePath ? "" : activeRoute.path}${subItem.path}`}
              component={subItem.component}
              name={subItem.name}
            />
          );
        })
      ) : (
        <Route
          exact
          path={activeRoute.path}
          component={activeRoute.component}
          name={activeRoute.name}
        />
      )}
      <Redirect to={`${getDefaultPath(lang, sportSlug)}`} />
    </Switch>
  );

  return <>
    <Header />
    {getRoutes}
    <Hidden mdDown>
      <Footer />
    </Hidden>
    <Snackbar open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleHideSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={handleHideSnackbar} severity={snackbar.severity} variant="filled" sx={{ width: '100%' }}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  </>;
};

export default Client;
