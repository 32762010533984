export const sports = [
  {
    "id": 1,
    "name": "All cue sports",
    "lang": "en",
    "slug": "all-cue-sports"
  },
  {
    "id": 1,
    "name": "Todos los billares",
    "lang": "es",
    "slug": "todos-los-billares"
  },
  {
    "id": 1,
    "name": "Tous les billards",
    "lang": "fr",
    "slug": "tous-les-billards"
  },
  {
    "id": 1,
    "name": "Alle Biljart",
    "lang": "nl",
    "slug": "alle-biljart"
  },
  {
    "id": 3,
    "name": "Snooker",
    "lang": "en",
    "slug": "snooker"
  },
  {
    "id": 3,
    "name": "Snooker",
    "lang": "es",
    "slug": "snooker"
  },
  {
    "id": 3,
    "name": "Snooker",
    "lang": "fr",
    "slug": "snooker"
  },
  {
    "id": 3,
    "name": "Snooker",
    "lang": "nl",
    "slug": "snooker"
  },
  {
    "id": 4,
    "name": "Pool",
    "lang": "en",
    "slug": "pool-billiard"
  },
  {
    "id": 4,
    "name": "Pool",
    "lang": "es",
    "slug": "pool-billiard"
  },
  {
    "id": 4,
    "name": "Pool",
    "lang": "fr",
    "slug": "pool-billiard"
  },
  {
    "id": 4,
    "name": "Pool",
    "lang": "nl",
    "slug": "pool-billiard"
  },
  {
    "id": 5,
    "name": "Carom",
    "lang": "en",
    "slug": "billiard-carom"
  },
  {
    "id": 5,
    "name": "Billar Carambola",
    "lang": "es",
    "slug": "billar-carambola"
  },
  {
    "id": 5,
    "name": "Billard Carambole",
    "lang": "fr",
    "slug": "billard-carambole"
  },
  {
    "id": 5,
    "name": "Carambole Biljart",
    "lang": "nl",
    "slug": "biljart-carambole"
  }
]