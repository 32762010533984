import { FormHelperText, Box, Typography } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import Axios from "axios";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import React from "react";
import { subscriptionList } from "./SubscriptionList";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidEmail } from "utils/validation";
import i18n from "i18next";
import { siteLangCode } from "../../utils/site";

const facebookSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/facebook.svg`;
const twitterSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/twitter.svg`;
const instagramSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Instagram.svg`;
const enterSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/enter.svg`;

export class Subscription extends React.Component {
  errorMessage = {
    email: i18n.t("Invalid email"),
    selectedLists: i18n.t("Please select at least one list"),
    api: "",
  };
  successMessage = i18n.t("Your subscription has been created successfully");
  recaptchaRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      selectedLists: [],
      error: {},
      message: "",
      submitting: false,
    };
    this.handleToggleList = this.handleToggleList.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleToggleList = (list) => {
    const index = this.state.selectedLists.indexOf(list.value);
    const newLists = [...this.state.selectedLists];
    if (index !== -1) {
      newLists.splice(index, 1);
    } else {
      newLists.push(list.value);
    }
    this.setState({ selectedLists: newLists });
  };

  validateForm = () => {
    let validated = true;
    const { email, selectedLists } = this.state;
    const error = {};
    if (!isValidEmail(email)) {
      error["email"] = this.errorMessage.email;
      validated = false;
    } else {
      delete error.email;
    }
    if (!selectedLists.length) {
      error["selectedLists"] = this.errorMessage.selectedLists;
      validated = false;
    } else {
      delete error.selectedLists;
    }
    this.setState({ error });
    return validated;
  };

  handleSubscribe = async (e) => {
    e.preventDefault();
    this.setState({ success: "" });

    if (!this.validateForm()) {
      return;
    }
    const { email, selectedLists } = this.state;
    this.setState({
      error: {},
      message: i18n.t("Submitting"),
      submitting: true,
    });
    await this.recaptchaRef.current.executeAsync();

    const formData = new FormData();
    for (let i = 0; i < selectedLists.length; i++) {
      formData.append("lists_28[]", selectedLists[i]);
    }
    formData.append("EMAIL", email);
    formData.append(
      "g-recaptcha-response",
      this.recaptchaRef.current.getValue()
    );
    formData.append("email_address_check", "");
    formData.append("locale", siteLangCode);

    try {
      await Axios.post(subscriptionList.submitUrl, formData);
      this.setState({
        email: "",
        selectedLists: [],
        message: this.successMessage,
        submitting: false,
      });
    } catch (error) {
      this.setState({
        error: {
          ...this.state.error,
          api: error.toString(),
        },
        message: "",
        submitting: false,
      });
    }
  };

  render() {
    const { selectedLists, error, message, submitting } = this.state;
    const { classes } = this.props;
    const emailLists = subscriptionList[siteLangCode] ? subscriptionList[siteLangCode] : [];

    return (
      <Box my={2}>
        <Typography className={classes.title}>
          {i18n.t("Sign up our newsletter")}
        </Typography>

        <div className={classes.emailList}>
          {emailLists.map((item, index) => {
            return (
              <div
                key={index}
                className={classes.subscribeSportItem}
                onClick={() => this.handleToggleList(item)}
              >
                <div className={classes.checkBox}>
                  {selectedLists.includes(item.value) ? (
                    <CheckIcon className={classes.checkIcon} />
                  ) : null}
                </div>
                {item.label}
              </div>
            );
          })}
        </div>
        {error.selectedLists && (
          <Box>
            <FormHelperText error={true}>{error.selectedLists}</FormHelperText>
          </Box>
        )}
        <form onSubmit={this.handleSubscribe}>
          <div className={classes.messageInputContainer}>
            <input
              className={classes.messageInpu}
              placeholder={i18n.t("Enter your email")}
              value={this.state.email}
              onChange={(e) => this.setState({ email: e.target.value })}
              disabled={submitting}
            />
            <div className={classes.messageSendButton}>
              <input type="image" src={enterSVG} alt="Submit" width="28" />
            </div>
          </div>
          {error.email && (
            <FormHelperText error={true}>{error.email}</FormHelperText>
          )}
          {message && <p className={classes.successMessage}>{message}</p>}
        </form>
        {error.api && <FormHelperText error={true}>{error.api}</FormHelperText>}
        <Box overflow="hidden" mt={1}>
          <ReCAPTCHA
            size="invisible"
            sitekey="6Lf85tYZAAAAACzUMhvwmYxj1BanqHRaUEw4z8k_"
            badge="inline"
            ref={this.recaptchaRef}
          />
        </Box>
        <Box mt={1}>
          <Typography className={classes.title}>
            {i18n.t("Connect with us")}
          </Typography>
        </Box>
        <div>
          <a
            href="https://www.facebook.com/Kozoom"
            target="_blank"
            className={classes.socialLink}
            rel="noopener noreferrer"
          >
            <img src={facebookSVG} alt="icon" className={classes.socialIcon} />
          </a>
          <a
            href="https://twitter.com/kozoom"
            target="_blank"
            className={classes.socialLink}
            rel="noopener noreferrer"
          >
            <img src={twitterSVG} alt="icon" className={classes.socialIcon} />
          </a>
          <a
            href="https://www.instagram.com/kozoomofficial"
            target="_blank"
            className={classes.socialLink}
            rel="noopener noreferrer"
          >
            <img src={instagramSVG} alt="icon" className={classes.socialIcon} />
          </a>
        </div>
      </Box>
    );
  }
}
