import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import i18n from "i18next";
import {
  NewsCard,
  NewsSocialWidget,
  Spinner,
  VideoReplayCard,
} from "components";

const NewsPanel = ({ loading, feeds, onPlayVideo }) => {
  useEffect(() => {
    setTimeout(() => {
      if (window.FB) {
        window.FB?.XFBML?.parse();
      }
    }, 200);
  }, [feeds]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <Spinner />
      </Box>
    );
  }
  return (
    <Box mt={2}>
      {!loading && !feeds.length && (
        <Alert variant="outlined" severity="info" style={{ color: "white" }}>
          {i18n.t("No feeds found")}
        </Alert>
      )}
      {feeds.map((item, index) => (
        <Box key={index} my={2}>
          {item.isHighlighted ? (
            <NewsSocialWidget
              news={item}
            />
          ) : (
            <>
              {item.newsId && (
                <NewsCard
                  news={item}
                />
              )}
              {item.videoId && (
                <VideoReplayCard
                  video={item}
                  onClick={() => onPlayVideo(item.videoId)}
                />
              )}
            </>
          )}
        </Box>
      ))}
    </Box>
  );
};

NewsPanel.propTypes = {
  loading: PropTypes.bool,
  feeds: PropTypes.arrayOf(PropTypes.any),
  videos: PropTypes.arrayOf(PropTypes.any),
};

export default NewsPanel;
