import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import {
  ChatContainer,
  ChatInput,
  ChatSetting,
  ChatLogginRequired,
} from "components";
import { useChat } from "../hooks/chat";
import { socketIo } from "socket";
import i18n from "i18next";
import { COLOR_TV } from "constants/color";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  scrollDownBtn: {
    position: "absolute",
    bottom: 50,
    width: 30,
    left: "calc(50% - 30px)",
    backgroundColor: COLOR_TV,
    color: "white",

  },
  container: {
    marginBottom: 8,
    maxHeight: "60vh",
    // minHeight: 400,
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      width: 3,
    },

    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4F4E79",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "",
    },
  },
}));

const ChatPanel = ({ table }) => {
  const {
    messages = [],
    loading,
    selectedLanguages,
    setting,
    userCount,
    editingMessage,
    userInfo,
    onSendMessage,
    onToggleAll,
    onToggleLang,
    onChangeSetting,
    onEdit,
    onCancelEditMode,
  } = useChat(table);
  const classes = useStyles();
  const [autoScroll, setAutoScroll] = useState(true);
  const chatWrapperRef = useRef();

  useEffect(() => {
    if (autoScroll) {
      chatWrapperRef.current.scroll({ top: chatWrapperRef.current.scrollHeight, behavior: 'smooth' });
    }
  }, [messages, autoScroll]);

  const onScrollDownClick = useCallback(() => {
    chatWrapperRef.current.scroll({ top: chatWrapperRef.current.scrollHeight, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (chatWrapperRef.current) {
      const el = chatWrapperRef.current;
      el.onscroll = () => {
        // If scrolled to bottom, auto scroll will be ON, otherwise OFF
        setAutoScroll(el.scrollHeight - el.scrollTop - el.clientHeight < 5)
      };
    }
  }, [chatWrapperRef])

  return (
    <Box className={classes.root}>
      <ChatSetting
        count={userCount}
        selectedLanguages={selectedLanguages}
        setting={setting}
        onToggleLang={onToggleLang}
        onToggleAll={onToggleAll}
        onChangeSetting={onChangeSetting}
      />
      <Box className={classes.container} ref={chatWrapperRef}>
        <ChatContainer
          messages={messages}
          loading={loading}
          onEdit={onEdit}
        />
      </Box>
      {!autoScroll &&
        <Button className={classes.scrollDownBtn} variant="contained" onClick={onScrollDownClick}>
          <ArrowDownwardIcon />
        </Button>
      }
      {userInfo && socketIo.connected &&
        <ChatInput
          onSend={(text) => { setAutoScroll(true); onSendMessage(text) }}
          onCancelEditMode={onCancelEditMode}
          editingMessage={editingMessage}
          isBanned={userInfo.isBanned}
        />
      }
      {!userInfo &&
        <ChatLogginRequired />
      }
      {!socketIo.connected &&
        <Box textAlign="center">
          <Typography color="red">{i18n.t('Cannot connect to Live Chat Server')}</Typography>
        </Box>
      }
    </Box>
  );
};

ChatPanel.propTypes = {
  userCount: PropTypes.number,
  authenticated: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  lang: PropTypes.string,
  setting: PropTypes.any,
  onSend: PropTypes.func,
  onChangeLang: PropTypes.func,
  onChangeSetting: PropTypes.func,
};

export default ChatPanel;
