const RankingPlayer = (theme) => ({
  root: {
    marginTop: 1,
    marginBottom: 1,
    overflow: "hidden",
  },
  titleSection: {
    display: "flex",
    alignItems: "center",
  },
  rank: {
    width: 60,
    fontWeight: 600,
    textAlign: "center",
  },
  playerInfo: {
    display: "flex",
    alignItems: "center",
    flex: 1,
  },
  playerFlag: {
    width: 32,
    height: 32,
  },
  playerName: {
    marginLeft: theme.spacing(2),
    fontSize: 14,
    fontWeight: 600,
  },
  caretContainer: {
    width: 55,
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  caret: {
    fontSize: 20,
  },
  playerDetailDataContainer: {
    display: "flex",
  },
  avatarContainer: {
    width: 93,
  },
  avatar: {
    width: 54,
    height: 54,
    objectFit: "cover"
  },
  scoreSection: {
    display: "flex",
    width: "fill-available",
    justifyContent: "space-around",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  scoreItem: {
    fontSize: 8,
    textAlign: "center",
    textTransform: "uppercase"
  },
  value: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Arial",
    color: "#4C4C4C"
  },
  valueAvg: {
    fontSize: 13,
  },
  gridBoxBorder: {
    borderLeft: "0.5px solid #fff",
    borderRight: "0.5px solid #fff",
  },
  gridBoxBorders: {
    borderRight: "0.5px solid #fff",
    padding: "6px 2px 2px 2px",
  },
  outerDivPlayerMatch: {
    borderTop: "1px solid #fff",
    borderBottom: "1px solid #fff",
  },
  slickGrid: {
    textAlign: "center",
    alignItems: "center",
    paddingTop: 10,
    justifyContent: "center",
  },
  slickArrow: {
    fontSize: "3rem",
    color: "#fdc23c",
    cursor: "pointer"
  },
  slickSlide: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    overflow: "hidden",
  },
  matchType: {
    background: "#02023C",
    color: "#fff",
    padding: 5,
  },
  slideOuterDiv: {
    padding: "0px 8px",
  },
  slideLeftRightBtnDiv: {
    width: "6%",
  },
  sliderDiv: {
    width: "88%",
  },
  slideplayerName: {
    padding: "4.5px 0",
    overflow: "hidden",
    fontSize: 14,
    marginLeft: 5,
    whiteSpace: "nowrap",
    marginRight: 5,
    textOverflow: "ellipsis",
  },
  starIcon: {
    color: "#FDC232",
    width: 16,
    [theme.breakpoints.down("sm")]: {
      width: 10,
    },
  },
  topPlayerName: {
    boxShadow: "0px 2px 4px #0000000D",
    background: "#DFDDDD",
    position: "relative"
  },
  bottomPlayerName: {
    background: "#E8CA8B",
    boxShadow: "0px -2px 4px #0000000D",
    position: "relative"
  },
  playerLeft: {
    background: "#DFDDDD",
    padding: 3,
  },
  playerRight: {
    background: "#E8CA8B",
    padding: 3,
  },
  nb_Sets: {
    background: "linear-gradient(#DFDDDD 50%, #E8CA8B 50%)",
    display: "flex",
    alignItems: "center",
  },
  nb_set1: {
    background: "linear-gradient(90deg,#DFDDDD 50%, #E8CA8B 50%)",
  },
  setDivHeight: {
    height: 60,
  },
  leftnbSet: {
    justifyContent: "flex-end"
  },
  playerLeftCenter: {
    justifyContent: "space-evenly",
  },
  playerRightCenter: {
    justifyContent: "space-evenly",
  },
  buttonsReplay: {
    padding: 8,
    marginTop: 8,
    marginLeft: -10,
    borderRadius: 20,
    border: "1px solid rgb(234, 36, 26)",
    width: 160,
    textTransform: "uppercase",
    cursor: "pointer",
    "&:focus": {
      border: "1px solid rgb(234, 36, 26)",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      width: 150,
    },
  },
  replaybtnOuter: {
    textAlign: "center",
    marginBottom: 10,
  },
  teamInnerDetail: {
    flex: 1,
    minHeight: 100,
    paddingLeft: 8,
    paddingRight: 8,
  },
  columnJustify: {
    justifyContent: "flex-end",
  },
  noResult: {
    fontSize: 20,
    textAlign: "center",
    paddingTop: 10,
    paddingBottom: 10,
  },
  centerBoxOuterTop: {
    paddingTop: 8,
  },
  centerBoxOuterBottom: {
    paddingBottom: 8,
  },
  centerBoxTopLeft: {
    fontSize: 12,
    background: "#ffffff7a",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    position: "relative",
    textAlign: "left",
    padding: 2,
  },
  centerBoxBigNbr: {
    width: 35,
    background: "#ffffff7a",
    borderRadius: 5,
    padding: "1px 3px 1px 3px",
    fontSize: 14,
    fontWeight: 600,
  },
  centerBoxSmallNbr: {
    fontSize: 10,
    padding: 2,
  },
  centerBoxBottomLeft: {
    fontSize: 12,
    background: "#ffffff7a",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    position: "relative",
    textAlign: "left",
    padding: 2,
  },
  bottomSmallNbr: {
    fontSize: 10,
    position: "absolute",
    top: -2,
    right: 2,
  },
  topSmallNbr: {
    fontSize: 10,
    position: "absolute",
    bottom: -2,
    right: 2,
  },
  topBigNbr: {
    position: "relative",
    top: -3,
  },
  bottomBigNbr: {
    position: "relative",
    bottom: -3,
  },
  playerCardInfo: {
    flex: 1,
  },
  redNumber: {
    fontSize: 10,
    color: "red",
    textAlign: "center"
  }
});

export default RankingPlayer;
