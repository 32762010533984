import React, { useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import i18n from "i18next";
import { Button, Container, TextField, Box, Dialog, DialogTitle, DialogContent, DialogContentText, Typography, Grid } from "@mui/material";
import { Epg, Layout } from 'planby';
import { Timeline, ChannelItem, ProgramItem } from "./components";
import { useSchedule } from "./hook";
import { DatePicker } from "@mui/lab";
import { logo } from './assets';
import moment from "moment";
import bottomLogos from "./assets/bottom-logos.jpg";
import { Spinner } from "components";
import { createGrooveWidget, destroyGrooveWidget } from "utils/groove";
import { siteLangCode } from "utils/site";
import { useStyles } from "./styles";

export const TV247ChannelPage = () => {
  const classes = useStyles();
  useEffect(() => {
    if (window.groove && window.groove.widget) {
      destroyGrooveWidget();
    }
    return () => {
      createGrooveWidget(siteLangCode);
    }
  }, []);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedProgram, setSelectedProgram] = useState();

  const { isLoading, getEpgProps, getLayoutProps, minDate, maxDate } = useSchedule(selectedDate);

  return <DocumentTitle title={i18n.t("24/7 Kozoom TV Channel")}>
    <>
      <Container disableGutters={true}>
        <Box textAlign="center" my={2}>
          <img src={logo.kozoomTV247} alt="Top Logo" className={classes.image} />
        </Box>
        <div className={classes.container}>
          <div className={classes.iframeContainer}>
            <iframe
              className={classes.responsiveIframe}
              src={`https://player.twitch.tv/?channel=kozoom&parent=${location.hostname}`}
              height="100%"
              width="100%"
              allowFullScreen>
            </iframe>
          </div>
        </div>
      </Container>
      <Box paddingLeft="11px" paddingRight="21px" position="relative">
        {isLoading &&
          <Box display="flex" justifyContent="center" className={classes.loader}>
            <Spinner />
          </Box>
        }
        <Epg  {...getEpgProps()}>
          <Layout
            {...getLayoutProps()}
            renderTimeline={(props) => <Timeline {...props} />}
            renderProgram={({ program, ...rest }) => (
              <ProgramItem key={program.data.id} program={program} {...rest} onClick={() => setSelectedProgram(program.data)} />
            )}
            renderChannel={({ channel }) => (
              <ChannelItem key={channel.uuid} channel={channel} />
            )}
          />
        </Epg>
      </Box>
      <Container disableGutters={true}>
        <Box mb={2} display="flex" justifyContent="center">
          <DatePicker
            label={i18n.t('Date')}
            value={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(newValue) => {
              setSelectedDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} size="small" />}
          />
          <Box ml={5}>
            <Button variant="contained" sx={{ minWidth: 100 }} onClick={() => setSelectedDate(new Date())}>{i18n.t('Now')}</Button>
          </Box>
        </Box>
        <Box textAlign="center" my={4}>
          <img src={bottomLogos} className={classes.image} />
        </Box>
        <Dialog
          PaperProps={{ sx: { background: '#747495', color: 'white' } }}
          open={Boolean(selectedProgram)}
          onClose={() => setSelectedProgram(null)}
        >
          {!!selectedProgram &&
            <>
              <DialogTitle>{selectedProgram.title}</DialogTitle>
              <DialogContent>
                <img src={selectedProgram.image} width="100%" />
                <Box>
                  <Box fontWeight={700} my={2}>
                    {moment(selectedProgram.since).format('HH:mm')} - {moment(selectedProgram.till).format('HH:mm')}
                  </Box>
                  <Box>
                    <Typography fontSize={18} fontWeight={600} marginBottom={1}>{i18n.t('Description')}</Typography>
                    {selectedProgram.description}
                  </Box>
                </Box>
              </DialogContent>
            </>
          }
        </Dialog>
      </Container>
    </>
  </DocumentTitle >;
};