import { SERVER_URL } from "constants/serverUrl";
import axios from "axios";
import { NEED_RELOGIN } from "store/actions/auth";

export const GET_GAMES_START = "GET_GAMES_START";
export const GET_GAMES_FINISH = "GET_GAMES_FINISH";
export const GET_GAMES_ERROR = "GET_GAMES_ERROR";

const url = `${SERVER_URL}/game`;
export const getGames = (lang) => {
  return (dispatch) => {
    dispatch({
      type: GET_GAMES_START,
    });
    axios
      .get(url, {
        params: { lang }
      })
      .then((res) => {
        dispatch({
          type: GET_GAMES_FINISH,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 401) {
          dispatch({
            type: NEED_RELOGIN,
          });
        }
        dispatch({
          type: GET_GAMES_ERROR,
          payload: err,
        });
      });
  };
};


