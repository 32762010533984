import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Typography,
  Drawer,
  IconButton,
  Box
} from "@mui/material";
import {
  Close as CloseIcon, Menu as MenuIcon
} from "@mui/icons-material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import AboutKozoom from "components/Footer/AboutKozoom";
import { ShopWithTrust } from "components/Footer/ShopWithTrust";
import { FooterStyles } from "components/Footer/styles";
import { Subscription } from "components/Footer/Subscription";
import PropTypes from "prop-types";
import React, { useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { activeRoute } from "routes.client";
import { BurgerMenuStyles } from "./BurderMenuStyles";
import { currentSite, siteLangCode, siteSportId, siteSportSlug } from "../../utils/site";
import { FilterIcon, GlobeIcon } from "components/icons";
import { sports } from "constants/sport";
import { languages } from "constants/language";
import i18n from "i18next";

const BurgerMenu = ({ changeSport, changeLanguage }) => {
  const [languageExpanded, setLanguageExpanded] = useState(false);
  const [sportExpanded, setSportExpanded] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const classes = makeStyles(BurgerMenuStyles)();
  const footerClasses = makeStyles(FooterStyles)();

  const currentLanguage = useMemo(() => languages.find(item => item.iso === siteLangCode), []);

  const sportList = useMemo(() => sports.filter(item => item.lang === siteLangCode), []);
  const selectedSport = useMemo(() => sports.find(item => item.id === siteSportId && item.lang === siteLangCode), []);

  const onToggleMenu = useCallback((event) => {
    setAnchor(anchor ? null : event.currentTarget);
  }, [setAnchor, anchor]);

  const sportMenu = () => {
    const order = [1, 5, 4, 3];
    return (
      <>
        <ListItem
          className={classes.collapsibleListItem}
          onClick={() => setSportExpanded(!sportExpanded)}
        >
          <Box display="flex" alignItems="center">
            <FilterIcon />
            <Typography marginLeft={1}> {selectedSport ? selectedSport.name : ""} </Typography>
          </Box>
          <ListItemIcon className={classes.caret}>
            {sportExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
          </ListItemIcon>
        </ListItem>
        <Collapse
          in={sportExpanded}
          timeout="auto"
          unmountOnExit
          className={classes.expandedContainer}
        >
          <List disablePadding>
            {sportList.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id)).map((sport, index) =>
              <ListItem key={index} className={classes.listItem} onClick={() => changeSport(sport.id)}>
                <Checkbox
                  checked={siteSportId === sport.id}
                />
                <Typography>{sport.name}</Typography>
              </ListItem>
            )}
          </List>
        </Collapse>
      </>
    );
  };

  const languageMenu = () => {
    return (
      <>
        <ListItem
          className={classes.collapsibleListItem}
          onClick={() => setLanguageExpanded(!languageExpanded)}
        >
          <Box display="flex" alignItems="center">
            <GlobeIcon />
            <Typography marginLeft={1}>{currentLanguage ? currentLanguage.label : ""}</Typography>
          </Box>
          <ListItemIcon className={classes.caret}>
            {languageExpanded ? <ArrowDropUp /> : <ArrowDropDown />}
          </ListItemIcon>
        </ListItem>

        <Collapse
          in={languageExpanded}
          timeout="auto"
          unmountOnExit
          className={classes.expandedContainer}
        >
          <List disablePadding>
            {languages.map((item, index) =>
              <ListItem
                className={classes.listItem}
                selected={siteLangCode === item.iso}
                key={index}
                onClick={() => { changeLanguage(item.iso); }}
              >
                <img
                  className={classes.flag}
                  alt={item.label}
                  src={`https://b-cdn.kozoom.com/assets/static/images/flags/${item.isoFlag}.svg`}
                />
                <Typography
                  primary={item.label}
                  marginLeft={1}
                >
                  {item.label}
                </Typography>
              </ListItem>
            )}
          </List>
        </Collapse>
      </>
    );
  };

  return <>
    <IconButton
      className={classes.toggleIcon}
      onClick={onToggleMenu}
      size="large">
      <MenuIcon className={classes.icon} />
    </IconButton>
    <Drawer
      open={Boolean(anchor)}
      onClose={() => setAnchor(null)}
    >
      <Box className={classes.mainBgColor}>
        <IconButton
          className={classes.closeIcon}
          size="large"
          onClick={onToggleMenu}>
          <CloseIcon className={classes.icon} />
        </IconButton>
        {activeRoute && currentSite !== 'global' &&
          Array.isArray(activeRoute.children) &&
          activeRoute.children.map((item, index) => {
            if (item.visible === false) return null;
            return (
              <MenuItem key={index} className={classes.menuItem} onClick={() => setAnchor(null)} >
                <NavLink
                  to={`/${siteLangCode}/${siteSportSlug}${item.path}`}
                  exact
                  className={classes.nav}
                >
                  {i18n.t(item.name)}
                </NavLink>
              </MenuItem>
            );
          })}
        {languageMenu()}
        {currentSite !== 'global' && sportMenu()}
        <Box px={2} className={footerClasses.root}>
          <AboutKozoom classes={footerClasses} />
          <ShopWithTrust classes={footerClasses} />
          <Subscription classes={footerClasses} />
        </Box>
      </Box>
    </Drawer>
  </>;
};

BurgerMenu.propTypes = {
  changeLanguage: PropTypes.func,
  changeSport: PropTypes.func,
};

export default BurgerMenu;
