import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";

const useStyles = makeStyles(() => ({
  container: {
    minWidth: 25,

  },
  month: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 8,
    lineHeight: "11px",
    textTransform: "uppercase",
    color: "white",
  },
  monthBig: {
    fontSize: 10,
  },
  day: {
    padding: "2px 4px",
    fontWeight: "bold",
    fontSize: 13,
    lineHeight: "13px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "black",
    backgroundColor: "white",
  },
  dayBig: {
    marginTop: 4,
    padding: "4px 8px",
    fontSize: 18,
  },
}));

const CalendarCard = ({
  date,
  color = "primary.main",
  zoomed = false,
  ...rest
}) => {
  const classes = useStyles();
  const month = moment(date).format("MMM");
  const day = moment(date).format("DD");
  return (
    <Box
      className={classes.container}
      bgcolor={color}
      px={zoomed ? 1 : 0.5}
      pb={zoomed ? 1 : 0.5}
      pt={zoomed ? 0.2 : 0.1}
      borderRadius="4px"
      {...rest}
    >
      <Box
        className={classnames(classes.month, zoomed ? classes.monthBig : "")}
      >
        {month}
      </Box>
      <Box className={classnames(classes.day, zoomed ? classes.dayBig : "")}>
        {day}
      </Box>
    </Box>
  );
};

CalendarCard.propTypes = {
  zoomed: PropTypes.bool,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default CalendarCard;
