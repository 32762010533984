import {
  GET_LIVE_STREAMS_START,
  GET_LIVE_STREAMS_FINISH,
  GET_LIVE_STREAMS_ERROR,
} from "store/actions";
const initialState = {
  loading: false,
  data: [],
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_LIVE_STREAMS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_LIVE_STREAMS_FINISH:
      newState.loading = false;
      newState.data = action.payload;
      newState.err = null;
      return newState;
    case GET_LIVE_STREAMS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
