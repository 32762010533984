// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { loginRequest, msalConfig, resetPasswordRequest } from "./authConfig";
import { store } from "index";
import { LOGOUT_SUCCESS } from "store/actions";
import { getCurrentUser } from "store/actions";
import { deleteCookie, getCookie, setCookie } from "utils/cookie";
import { Buffer } from "buffer";
import { getActiveOrders } from "store/actions/premium";

export const msalInstance = new PublicClientApplication(msalConfig);
export const USER_LOGIN_FLAG = "ksk";
const DOMAIN = window.location.hostname.match(/\w+(\.\w+)?$/)[0];
const ID_TOKEN = Buffer.from("idToken").toString("base64");

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS, EventType.ACCOUNT_ADDED].includes(event.eventType) && event.payload) {
    const account = event.payload.account;
    retrievedTokenSuccess(event.payload);
    if (account) {
      msalInstance.setActiveAccount(account);
    }
  }
  if ([EventType.LOGOUT_SUCCESS, EventType.ACCOUNT_REMOVED].includes(event.eventType)) {
    clearToken();
    store.dispatch({
      type: LOGOUT_SUCCESS
    });
  }

  // Handle reset password
  if ([EventType.LOGIN_FAILURE, EventType.ACQUIRE_TOKEN_FAILURE].includes(event.eventType) && event.error && event.error.errorMessage && event.error.errorMessage.includes('AADB2C90118')) {
    msalInstance.loginRedirect(resetPasswordRequest).catch(e => {
      console.warn(e);
    });
  }
});

msalInstance.handleRedirectPromise().then((res) => {
  console.log(res);
}).catch((e) => {
  console.error(e);
}).finally(() => { });

export const getIdToken = () => {
  return getCookie(ID_TOKEN);
};

if (msalInstance.getAllAccounts().length && !getIdToken()) {
  msalInstance.acquireTokenSilent(loginRequest).catch(e => {
    console.warn(e);
  });
}

// Check cookie for SSO silent
const cookieEmail = Buffer.from(getCookie(USER_LOGIN_FLAG), "base64").toString("utf-8");
/**
 * If cookieEmail is available but the current account in msalInstance is mismatched, OR the user is not logged-in, log the user in silently.
 * ssoSilent is causing some issues on Safari iOS
 */
// if (cookieEmail && ((msalInstance.getActiveAccount() && msalInstance.getActiveAccount().idTokenClaims.email !== cookieEmail) || !msalInstance.getActiveAccount())) {
//   try {
//     msalInstance.ssoSilent({
//       ...loginRequest,
//       loginHint: cookieEmail
//     }).catch(e => {
//       console.warn(e);
//     });
//   } catch (error) {
//     console.warn(error);
//   }
// }
/**
 * If cookieEmailis not available, but msalInstance is logged-in, log the user out.
 */
// if (!cookieEmail && msalInstance.getActiveAccount()) {
//   msalInstance.logoutRedirect().catch(e => {
//     console.warn(e);
//   });
// }

export const login = () => {
  msalInstance.loginRedirect(loginRequest).catch(e => {
    console.warn(e);
  });
};

export const retrievedTokenSuccess = (result) => {
  setCookie(USER_LOGIN_FLAG, Buffer.from(result.idTokenClaims.email).toString("base64"), result.idTokenClaims.exp * 1000, DOMAIN);
  if (result.idToken) {
    setCookie(ID_TOKEN, result.idToken, result.idTokenClaims.exp * 1000, DOMAIN);
    store.dispatch(getCurrentUser());
    store.dispatch(getActiveOrders());
  } else {
    msalInstance.acquireTokenSilent(loginRequest).catch(e => {
      console.warn(e);
    });
  }
};

export const logout = () => {
  clearToken();
  msalInstance.logoutRedirect().catch(e => console.warn(e));
};

export const clearToken = () => {
  deleteCookie(USER_LOGIN_FLAG, DOMAIN);
  deleteCookie(ID_TOKEN, DOMAIN);
}

export const authorizationHeader = () => ({
  headers: {
    Authorization: `Bearer ${getIdToken()}`
  }
});
