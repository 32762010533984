import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  FormControl,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import i18n from "i18next";
import SettingsIcon from "@mui/icons-material/Settings";
import CancelIcon from "@mui/icons-material/Cancel";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { languages } from "constants/language";
import { CheckBox } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 16px",
    backgroundColor: "#4F4E79",
    color: "white",
  },
  content: {
    position: "absolute",
    left: 0,
    top: "100%",
    right: 0,
    padding: 16,
    backgroundColor: "#4F4E79",
    zIndex: 40,
    color: "white"
  },
  label: {
    marginBottom: 4,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    lineHeight: "18px",
    color: "white",
  },
  checklabel: {
    padding: 0,
    display: "flex",
    alignItems: "center",

    "& span": {
      padding: 0,
      marginRight: 4,
      fontSize: 12,
      lineHeight: "14px",
      color: "white",
    },
  },
  langSelect: {
    "& span": {
      paddingTop: 0,
      paddingBottom: 0,
      fontSize: 12,
      lineHeight: "14px",
      color: "white",
    },
  },
  setting: {
    padding: 4,
    marginLeft: 8,
    color: "white",
  },
  formControl: {
    marginLeft: 0,
    marginBottom: 4,
    color: "white"
  },
  langOption: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    color: "white",
  },
  langImg: {
    marginRight: 16,
    width: 20,
    height: 20,
    borderRadius: 18,
  },
}));

export const ChatSetting = ({
  count = 0,
  selectedLanguages = [],
  setting = {},
  onToggleAll,
  onToggleLang,
  onChangeSetting,
}) => {
  const classes = useStyles();
  const [show, setShow] = useState(false);

  const onUpdateSetting = (field) => () => {
    const newSetting = {
      ...setting,
      [field]: !setting[field],
    };
    onChangeSetting(newSetting);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.label}>
          {i18n.t("Chat with people", { count })}
        </Box>
        <Box className={classes.label}>
          {i18n.t("Manage your chatroom")}
          <IconButton className={classes.setting} onClick={() => setShow(!show)} size="large">
            {show ? (
              <CancelIcon fontSize="small" />
            ) : (
              <SettingsIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      </Box>
      {show && (
        <Box className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <Box as="label" className={classes.label}>
                  {i18n.t("Select one or more languages")}
                </Box>
                <FormGroup>
                  <FormControlLabel
                    className={classes.langSelect}
                    control={
                      <Checkbox
                        checked={languages.length === selectedLanguages.length}
                        onChange={(e) => onToggleAll(e.target.checked)}
                        name="all"
                      />
                    }
                    label={i18n.t("All")}
                  />
                  {languages.map((language, key) => (
                    <FormControlLabel
                      key={key}
                      value={language.iso}
                      className={classes.langSelect}
                      control={
                        <Checkbox
                          checked={selectedLanguages.includes(language.iso)}
                          onChange={(e) => onToggleLang(language.iso, e.target.checked)}
                          name={language.iso}
                        />
                      }
                      label={
                        <Box className={classes.langOption}>
                          <img
                            alt={language.label}
                            className={classes.langImg}
                            src={`${UPLOADED_MEDIA_URL}/assets/static/images/flags/${language.isoFlag}.svg`}
                          />
                          {language.label}
                        </Box>
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    className={classes.checklabel}
                    control={
                      <Checkbox
                        checked={setting.sameTable}
                        onChange={onUpdateSetting("sameTable")}
                        name="sameTable"
                      />
                    }
                    label={i18n.t(
                      "Show only posts of users watching the same table"
                    )}
                  />
                  <FormControlLabel
                    className={classes.checklabel}
                    control={
                      <Checkbox
                        checked={setting.showFeed}
                        onChange={onUpdateSetting("showFeed")}
                        name="showFeed"
                      />
                    }
                    label={i18n.t("Show News feed alerts")}
                  />
                  <FormControlLabel
                    className={classes.checklabel}
                    control={
                      <Checkbox
                        checked={setting.showReply}
                        onChange={onUpdateSetting("showReply")}
                        name="showReply"
                      />
                    }
                    label={i18n.t("Show Replays publishing alerts")}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

ChatSetting.propTypes = {
  count: PropTypes.number,
  setting: PropTypes.any,
  languages: PropTypes.string,
  onChangeLang: PropTypes.func,
  onChangeSetting: PropTypes.func,
};
