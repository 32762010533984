import { Grid, Box } from "@mui/material";
import { LoadMoreSection, PlayerCard } from "components";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchPlayers } from "store/actions/search";
import i18n from "i18next";

export const PlayerSearch = () => {
  const [offset, setOffset] = useState();
  const dispatch = useDispatch();
  const handleLoadMore = () => {
    if (offset !== players.data.length) {
      setOffset(players.data.length);
      dispatch(searchPlayers(searchTerms, players.data.length))
    }
  };

  const players = useSelector(state => state.search.players);
  const searchTerms = useSelector(state => state.searchInput.searchTerms);
  return (
    <>
      <Grid container spacing={2}>
        {Array.isArray(players.data) && players.data.map((item, index) => (
          <Grid item sm={6} xs={12} key={index}>
            <PlayerCard
              key={item.id}
              player={item}
            />
          </Grid>
        ))}
      </Grid>
      {players.hasMore &&
        <Box mt={3}>
          <LoadMoreSection loading={players.loading} onLoadMore={handleLoadMore} />
        </Box>
      }
      {!players.loading && !players.data.length &&
        <Box my={3} textAlign="center">
          {i18n.t('No players found')}
        </Box>
      }
    </>
  );
}
