import React from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import i18n from "i18next";

export const DeleteConfirmDialog = ({
  open,
  onCancelDelete,
  onConfirmDelete
}) => {
  return <Dialog
    open={open}
    onClose={onCancelDelete}
  >
    <DialogTitle>{i18n.t("Confirmation")}</DialogTitle>
    <DialogContent>
      <DialogContentText>{i18n.t("Are you sure you want to delete this comment?")}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button autoFocus onClick={onCancelDelete} variant="outlined" size="small">{i18n.t("Cancel")}</Button>
      <Button onClick={onConfirmDelete} color="primary" variant="outlined" size="small">{i18n.t("Yes")}</Button>
    </DialogActions>
  </Dialog>;
};

DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onCancelDelete: PropTypes.func,
  onConfirmDelete: PropTypes.func,
};