export const languages = [
  {
    iso: "en",
    isoFlag: "gb",
    label: "English",
  },
  {
    iso: "fr",
    isoFlag: "fr",
    label: "Français",
  },
  {
    iso: "nl",
    isoFlag: "nl",
    label: "Dutch",
  },
  {
    iso: "es",
    isoFlag: "es",
    label: "Español",
  }
];
