import React from "react";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { login } from "auth";
import { Spinner } from "components";
import i18n from "i18next";
import { NavLink } from "react-router-dom";
import { siteLangCode } from "utils/site";
import { socketIo } from "socket";
import { COLOR_TV } from "constants/color";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0
  },
  overlay: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  defaultBanner: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
  button: {
    width: 200,
    marginLeft: 16,
    marginRight: 16,
    borderRadius: 24,
    color: "white",
    textTransform: "capitalize",
    marginBottom: theme.spacing(1)
  },
  playoutMessage: {
    background: COLOR_TV,
    padding: theme.spacing(2),
    textAlign: "center",
    fontSize: 20,
    color: "white",
    flexGrow: 1
  }
}));

export const NoneWatchablePlaceholder = ({ defaultBanner, premiumRequired, loginRequired, userLoading }) => {
  const classes = useStyles();
  return <Box className={classes.root} tabIndex="0">
    <div className={classes.content}>
      <img
        src={defaultBanner}
        alt="Kozoom Video"
        className={classes.defaultBanner}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={loginRequired || premiumRequired || userLoading || socketIo.disconnected ? classes.overlay : null}
      >
        {socketIo.disconnected ?
          <Typography className={classes.playoutMessage}>
            {i18n.t("You have been disconnected by another session with the same account.")}
          </Typography>
          :
          <>
            {userLoading &&
              < Spinner />
            }
            {!userLoading &&
              <>
                {loginRequired ?
                  (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={login}
                    >
                      {i18n.t("Log in to watch")}
                    </Button>
                  ) :
                  (
                    premiumRequired &&
                    <Box textAlign="center">
                      <Typography color="white" mb={2} variant="h5" textTransform="capitalize">
                        {i18n.t("Subscribe to start watching")}
                      </Typography>
                      <NavLink to={`/${siteLangCode}/premium-pre-checkout`}>
                        <Button variant="contained">{i18n.t('Subscribe Now')}</Button>
                      </NavLink>
                    </Box>
                  )
                }
              </>
            }
          </>
        }
      </Box>
    </div>
  </Box>;
};