import React from "react";
import PropTypes from "prop-types";
import { List, Box, ListItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import i18n from "i18next";
import { ChatMessage } from "./ChatMessage";
import Spinner from "../Spinner";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
  },
  intro: {
    fontSize: 14,
    color: "white",
  },
  subHeader: {
    marginBottom: 4,
    fontSize: 10,
    lineHeight: "16px",
    color: "white",
    backgroundColor: "transparent",
    padding: "0 16px"
  },
}));

export const ChatContainer = ({ messages, loading, onEdit }) => {
  let currentDay = moment();
  const classes = useStyles();
  if (loading) {
    return (
      <Box py={3} display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  if (!loading && !messages.length) {
    return (
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        className={classes.intro}
      >
        {i18n.t("Type something to start chatting")}
      </Box>
    );
  }

  return (
    <List>
      {messages.map((message, key) => {
        let showLabel = false;
        if (!moment(message.time).isSame(currentDay, "day")) {
          showLabel = true;
          currentDay = moment(message.time);
        } else {
          showLabel = false;
        }
        return (
          <React.Fragment key={key}>
            {showLabel && (
              <ListItem className={classes.subHeader}>
                {currentDay.format("LL")}
              </ListItem>
            )}
            <ChatMessage
              message={message}
              onEdit={onEdit}
            />
          </React.Fragment>
        );
      })}
    </List>
  );
};

ChatContainer.propTypes = {
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onCancelEditMode: PropTypes.func,
  messages: PropTypes.arrayOf(PropTypes.any),
};
