import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { SERVER_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import { Gravatar } from "components/Gravatar";
import {
  MoreVert,
  Clear as ClearIcon,
  Edit as EditIcon,
  PersonOff as BlockIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material";
import i18n from "i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { socketEvents, socketIo } from "socket";
import axios from "axios";
import { authorizationHeader } from "auth";
import { addBannedUser, removeBannedUser } from "store/actions/live";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 4,
    padding: "4px 12px",
    borderRadius: 5,
    color: "white",
    backgroundColor: "transparent",
    "&.hasDropdown": {
      paddingRight: 0,
    },
  },
  highlight: {
    backgroundColor: theme.palette.primary.main,
  },
  name: {
    fontSize: 14,
    fontWeight: "bold",
    color: "white",
    flexGrow: 1,
  },
  time: {
    fontSize: 14,
    fontStyle: "italic",
    fontWeight: 100,
    color: "white",
  },
  message: {
    fontSize: 14,
    color: "white",
    wordWrap: "break-word",
    "&.hasDropdown": {
      marginRight: 12,
    },
  },
  table: {
    display: "inline",
    marginRight: 8,
    fontWeight: "bold",
    color: "#E00D2E",
  },
  avatar: {
    objectFit: "contain",
    backgroundColor: theme.palette.darkBlue,
  },
  edited: {
    opacity: 0.7,
    fontSize: 12,
    marginTop: 5
  }
}));

export const ChatMessage = ({ message, onEdit }) => {
  const { user, table, time } = message;
  const loggedInUser = useSelector(state => state.auth.userInfo);
  const bannedUsers = useSelector(state => state.live.bannedUsers);
  const [isBanned, setIsBanned] = useState(false);
  const dispatch = useDispatch();

  const classes = useStyles();
  const adminAvatar = `${UPLOADED_MEDIA_URL}/assets/static/images/logos/k--light.png`;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsBanned(!!bannedUsers.find(item => item.id === message.user.id));
  }, [message, bannedUsers]);

  const removeMessage = () => {
    const confirmed = confirm(i18n.t("Are you sure you want to remove this message?"));
    if (confirmed) {
      socketIo.emit(socketEvents.removeMessage, message);
    }
  };

  const toggleMessageByAdmin = () => {
    const confirmed = confirm(message.hiddenByAdmin ? i18n.t("Are you sure you want to show this message?") : i18n.t("Are you sure you want to hide this message?"));
    if (confirmed) {
      socketIo.emit(socketEvents.toggleMessageByAmin, message);
    }
  }

  const banUser = () => {
    const confirmed = confirm(i18n.t("Are you sure you want to ban {{firstName}} {{lastName}}?", message.user));
    if (confirmed) {
      axios.put(`${SERVER_URL}/user/${message.user.id}`, { bannedAt: new Date() }, authorizationHeader()).then(() => {
        socketIo.emit(socketEvents.banUser, message.user);
        dispatch(addBannedUser(message.user));
      }).catch((e) => {
        console.warn(e);
        alert("Something went wrong");
      });
    }
  }

  const unbanUser = () => {
    const confirmed = confirm(i18n.t("Are you sure you want to unban {{firstName}} {{lastName}}?", message.user));
    if (confirmed) {
      axios.put(`${SERVER_URL}/user/${message.user.id}`, { bannedAt: null }, authorizationHeader()).then(() => {
        socketIo.emit(socketEvents.unbanUser, message.user);
        dispatch(removeBannedUser(message.user));
      }).catch((e) => {
        console.warn(e);
        alert("Something went wrong");
      });
    }
  }

  const hasDropdown = (loggedInUser && loggedInUser.isAdmin) || (loggedInUser && loggedInUser.id === user.id && !message.hiddenByAdmin);

  return (
    <ListItem
      alignItems="flex-start"
      className={classnames(classes.root, user.isAdmin ? classes.highlight : null, hasDropdown ? ['hasDropdown'] : null)}
    >
      <ListItemAvatar>
        <>
          {user.isAdmin && <Avatar src={adminAvatar} classes={{ img: classes.avatar }} />}
          {!(user.isAdmin) && <Gravatar userInfo={user} />}
        </>
      </ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{
          component: "div"
        }}
        primary={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box className={classes.name}>{user.firstName} {user.lastName}</Box>
            <Box className={classes.time}>{moment(time).format('HH:mm:ss')}</Box>
            {!!hasDropdown &&
              <>
                <IconButton
                  size="small"
                  sx={{ marginLeft: 0.5 }}
                  color="inherit"
                  onClick={handleMoreClick}
                >
                  <MoreVert />
                </IconButton>
                {message.id &&
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={() => setAnchorEl(null)}
                  >
                    {loggedInUser.id === user.id && !message.hiddenByAdmin &&
                      <MenuItem dense onClick={() => onEdit(message)}>
                        <ListItemIcon>
                          <EditIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {i18n.t('Edit')}
                        </ListItemText>
                      </MenuItem>
                    }
                    {(loggedInUser.id === user.id || loggedInUser.isAdmin) &&
                      <MenuItem dense onClick={removeMessage}>
                        <ListItemIcon>
                          <ClearIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>
                          {i18n.t('Remove')}
                        </ListItemText>
                      </MenuItem>
                    }
                    {loggedInUser.isAdmin && !message.user.isAdmin && !isBanned &&
                      <MenuItem dense onClick={banUser}>
                        <ListItemIcon>
                          <BlockIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {i18n.t('Ban User')}
                        </ListItemText>
                      </MenuItem>
                    }
                    {loggedInUser.isAdmin && !message.user.isAdmin && isBanned &&
                      <MenuItem dense onClick={unbanUser}>
                        <ListItemIcon>
                          <BlockIcon />
                        </ListItemIcon>
                        <ListItemText>
                          {i18n.t('Unban User')}
                        </ListItemText>
                      </MenuItem>
                    }
                    {loggedInUser.isAdmin &&
                      <MenuItem dense onClick={toggleMessageByAdmin}>
                        <ListItemIcon>
                          {message.hiddenByAdmin ?
                            <VisibilityIcon fontSize="small" />
                            :
                            <VisibilityOffIcon fontSize="small" />
                          }
                        </ListItemIcon>
                        <ListItemText>
                          {message.hiddenByAdmin ? i18n.t('Show Message') : i18n.t('Hide Message')}
                        </ListItemText>
                      </MenuItem>
                    }
                  </Menu>
                }
              </>
            }
          </Box>
        }
        secondaryTypographyProps={{
          component: "div"
        }}
        secondary={
          <Box className={classnames(classes.message, hasDropdown ? 'hasDropdown' : null)}>
            <div>
              {table !== undefined && !user.isAdmin && table >= 0 && (
                <strong className={classes.table}>
                  {`T${table + 1}`}
                </strong>
              )}
              {message.hiddenByAdmin ? i18n.t('Hidden by Admin') : message.text}
            </div>
            {message.edited && !message.hiddenByAdmin &&
              <i className={classes.edited}>{i18n.t('Edited')}</i>
            }
          </Box>
        }
      />
    </ListItem>
  );
};

ChatMessage.propTypes = {
  onEdit: PropTypes.func,
  message: PropTypes.object,
};
