import { combineReducers } from "redux";
import auth from "./auth";
import countries from "./countries";
import eventDetail from "./eventDetail";
import relatedEvents from "./relatedEvents";
import liveAndUpcomingEvents from "./liveAndUpcomingEvents";
import feed from "./feed";
import games from "./games";
import instructionDetail from "./instructionDetail";
import instructions from "./instructions";
import liveStreams from "./liveStreams";
import masterclass from "./masterclass";
import playerDetail from "./playerDetail";
import playerMatches from "./playerMatches";
import playlist from "./playlist";
import rankings from "./rankings";
import search from "./search";
import searchInput from "./searchInput";
import eventPlan from "./eventPlan";
import comment from "./comment";
import eventFeeds from "./eventFeeds";
import snackbar from "./snackbar";
import live from "./live";
import premium from "./premium";
import config from "./config";

const rootReducer = combineReducers({
  liveAndUpcomingEvents,
  auth,
  feed,
  rankings,
  playerMatches,
  instructions,
  instructionDetail,
  eventDetail,
  eventFeeds,
  playerDetail,
  liveStreams,
  playlist,
  countries,
  games,
  relatedEvents,
  searchInput,
  masterclass,
  search,
  eventPlan,
  comment,
  snackbar,
  live,
  premium,
  config
});

export default rootReducer;
