export const RankingStyles = (theme) => ({
  root: {},

  navConatiner: {
    top: 120,
    zIndex: 99,
    position: "sticky",
    backgroundColor: theme.palette.grayLight,
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: "1.5px solid #444444;",
    [theme.breakpoints.down("md")]: {
      top: "60px",
    },
  },
  nav: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    cursor: "pointer",
    minWidth: 130,
    textAlign: "center",
  },
  active: {
    position: "relative",
    color: "#000000",
    "&::before": {
      position: "absolute",
      content: "''",
      background: `url(${"https://b-cdn.kozoom.com/assets"}/static/icons/rounded-triangle-indicator.svg)`,
      width: 20,
      height: 8,
      bottom: -17,
      left: "calc(50% - 10px)",
      filter: "invert(0.4)",
    },
  },
  inactive: {
    color: "#444444",
  },
});

