import React from "react";
import { siteLangCode } from "../../../utils/site";
import { Box } from "@mui/material";

export default class DesktopBanner extends React.Component {
  getId = (lang) => {
    switch (lang) {
    case "en":
      return "a658d18f";
    case "fr":
      return "a658d18f";
    case "es":
      return "a658d18f";
    case "nl":
      return "a658d18f";
    case "de":
      return "a658d18f";
    case "tr":
      return "a658d18f";
    case "ko":
      return "a658d18f";
    case "vi":
      return "a658d18f";
    default:
      return "a658d18f";
    }
  };

  getZoneId = (lang) => {
    switch (lang) {
    case "en":
      return "6706";
    case "fr":
      return "6706";
    case "es":
      return "6706";
    case "nl":
      return "6706";
    case "de":
      return "6706";
    case "tr":
      return "6706";
    case "ko":
      return "6706";
    case "vi":
      return "6706";
    default:
      return "6706";
    }
  };

  getN = (lang) => {
    switch (lang) {
    case "en":
      return "a8ed0060";
    case "fr":
      return "a8ed0060";
    case "es":
      return "a8ed0060";
    case "nl":
      return "a8ed0060";
    case "de":
      return "a8ed0060";
    case "tr":
      return "a8ed0060";
    case "ko":
      return "a8ed0060";
    case "vi":
      return "a8ed0060";
    default:
      return "a8ed0060";
    }
  };

  render() {
    const id = this.getId(siteLangCode);
    const zoneId = this.getZoneId(siteLangCode);
    const n = this.getN(siteLangCode);
    return (
      <Box justifyContent="center" display="flex">
        <iframe
          title="infeed"
          id={id}
          name={id}
          src={`https://adserver.kozoom.com/afr.php?zoneid=${zoneId}`}
          frameBorder="0"
          scrolling="no"
          width="728"
          height="90"
          allow="autoplay"
        >
          <a
            href={`https://adserver.kozoom.com/ck.php?n=${n}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={`https://adserver.kozoom.com/avw.php?zoneid=6706&amp;n=${n}`}
              border="0"
              alt=""
            />
          </a>
        </iframe>
      </Box>
    );
  }
}
