import React from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import { Alert } from "@mui/material";
import DocumentTitle from "react-document-title";
import i18n from "i18next";
import { Spinner, LoadMoreSection } from "components";
import { EventsList, PlayersCarousel, Subscribe } from "./components";
import { usePage } from "./hooks";

const MasterClass = () => {
  const {
    classes,
    loading,
    eventsLoading,
    events,
    hasMore,
    players,
    selected,
    onSelectPlayer,
    onLoadMore,
  } = usePage();

  if (loading) {
    return (
      <Grid container spacing={2} className={classes.content}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" py={4}>
            <Spinner />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={classes.content}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center">
          <Typography className={classes.title}>
            {i18n.t("Improve your Game. Learn from the Best.")}
          </Typography>
        </Box>
        <Box py={4}>
          <PlayersCarousel
            players={players}
            selected={selected}
            onSelect={onSelectPlayer}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Subscribe
          text={i18n.t(
            "New video published every month. Order your Premium Pass to unlock all classes."
          )}
          price={7}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" py={4}>
          {!eventsLoading && !events.length && (
            <Alert
              variant="filled"
              severity="info"
              style={{
                backgroundColor: "#02023C",
                color: "white",
                marginBottom: 24,
              }}
            >
              {i18n.t("No master class events found.")}
            </Alert>
          )}
          {events.length > 0 && <EventsList events={events} />}
        </Box>
        {!hasMore && eventsLoading && (
          <Box display="flex" justifyContent="center" py={4}>
            <Spinner />
          </Box>
        )}
        {hasMore && (
          <Box mt={1}>
            <LoadMoreSection
              automatic
              label="More"
              hasMore={true}
              loading={eventsLoading}
              onLoadMore={onLoadMore}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const MasterClassPage = () => {
  const docTitle = i18n.t("Kozoom Events");

  return (
    <DocumentTitle title={docTitle}>
      <Container>
        <MasterClass />
      </Container>
    </DocumentTitle>
  );
};

export default MasterClassPage;
