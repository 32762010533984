const ReviveInfeedBannerStyles = (theme) => ({
  root: {
    margin: "auto",
    textAlign: "center",
  },
  desktop: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
});

export default ReviveInfeedBannerStyles;
