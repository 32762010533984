export const SecondaryHeaderStyles = (theme) => ({
  appBar: {
    boxShadow: "none",
    backgroundColor: "#02023C",
  },
  middlebar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: 0,
      padding: 0
    },
  },
  logo: {
    height: 44,
    [theme.breakpoints.down("md")]: {
      height: 40,
    },
  },
  rightSection: {
    minWidth: 300,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      minWidth: 40,
    },
  },
  outerImgLogo: {
    flex: 1,
    textAlign: "center",
  },
  toolbarButton: {
    paddingTop: 15,
    paddingBottom: 15,
    borderRadius: 0,
  },
  mainBgColor: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    }
  },
  icon: {
    color: "white",
    fontSize: 30,
  },
  smdowm: {
    minWidth: 300,
  },
  subnav: {
    fontSize: 14,
  },
  subnavText: {
    fontWeight: 400,
  },
  mobileSearch: {
    padding: 10,
    zIndex: 9,
    borderTop: "1px solid rgba(255, 255, 255, 0.2)",
    backgroundColor: theme.palette.primary.main
  },
});
