import React from "react";
import { Grid, Box } from "@mui/material";
import { LoadMoreSection } from "components";
import { useEventSearch } from "./hooks";
import i18n from "i18next";
import { EventList, EventsTimeline } from "pages/TV/components";

export const EventSearch = () => {
  const {
    loading,
    hasMore,
    events,
    onLoadMore,
  } = useEventSearch();

  return (
    <>
      <Grid container>
        <Grid item xs={10}>
          <EventList searchEvents={events} />
          {hasMore && (
            <Box mt={3}>
              <LoadMoreSection
                automatic
                loading={loading}
                onLoadMore={onLoadMore}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={2}>
          <EventsTimeline events={events} numCols={3} />
        </Grid>
      </Grid>
      {!loading && !events.length &&
        <Box mt={1} textAlign="center">
          {i18n.t('No events found')}
        </Box>
      }
    </>
  );
};

