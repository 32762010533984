import i18n from "i18next";

import translationEn from "./locales/en/translation.json";
import translationFr from "./locales/fr/translation.json";
import translationNl from "./locales/nl/translation.json";
import translationEs from "./locales/es/translation.json";
import { siteLangCode } from "utils/site";
i18n.init({
  debug: false,
  lng: siteLangCode,
  fallbackLng: "en", // use en if detected lng is not available

  keySeparator: false, // we do not use keys in form messages.welcome

  interpolation: {
    escapeValue: false // react already safes from xss
  },

  resources: {
    en: {
      translation: translationEn
    },
    fr: {
      translation: translationFr
    },
    nl: {
      translation: translationNl
    },
    es: {
      translation: translationEs
    },
  },
  // have a common namespace used around the full app
  ns: ["translation"],
  defaultNS: "translation"
});

export default i18n;
