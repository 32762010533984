import {
  GET_GAMES_START,
  GET_GAMES_FINISH,
  GET_GAMES_ERROR,
} from "store/actions";

const initialState = {
  loading: false,
  listById: {},
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_GAMES_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_GAMES_FINISH:
      newState.loading = false;
      newState.err = null;
      if (Array.isArray(action.payload)) {
        action.payload.forEach(game => {
          newState.listById[game.id] = game;
        });
      }
      return newState;
    case GET_GAMES_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
