import { Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { SportIcon } from "components";
import i18n from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import InstructionCardStyles from "styles/Components/Cards/InstructionCard";
import { siteLangCode } from "../../utils/site";


class InstructionCard extends React.Component {
  addDefaultImage = (ev) => {
    ev.target.src = "https://b-cdn.kozoom.com/assets/static/images/default-image.jpg";
  };

  render() {
    const {
      classes,
      id,
      mode,
      mini,
      thumbnail,
      game,
      name,
      player_name,
      created_at,
    } = this.props;

    let instructionLink = `/${siteLangCode}/games/carom/practice/${id}`;
    return (
      <Grid
        container
        className={classes.root}
      >
        <Grid
          item
          md={5}
          sm={12}
          className={classes.thumbnailContainer}
        >
          <div style={{ alignItems: "center", justifyContent: "center" }}>
            <img
              onError={this.addDefaultImage}
              alt="thumbnail"
              src={
                thumbnail
                  ? thumbnail
                  : "https://b-cdn.kozoom.com/assets/static/images/default-image.jpg"
              }
              className={mini ? classes.mini_thumbnail : classes.thumbnail}
              style={{ width: mode === "thumbnail" ? "100%" : 200 }}
            />
          </div>
        </Grid>
        <Grid
          item
          md={7}
          sm={12}
          className={classes.infoContainer}
        >
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            {game &&
              <Typography variant="body1" className={classes.game}>
                <SportIcon sportId={5}></SportIcon>
                {game}
              </Typography>
            }
          </div>
          <Link
            to={instructionLink}
            className={classes.title}
          >
            {name}
          </Link>
          <div>
            <div
              className={classes.metadataContainer}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.authorName}
              >
                by {player_name ? player_name : i18n.t("Unknown user")}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.create_date}
              >
                <i>{created_at}</i>
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(InstructionCardStyles)(InstructionCard);
