import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { COLOR_NEWS } from "constants/color";
import { PLAYER_MEDIA_URL, SPONSOR_MEDIA_URL, USER_DEFAULT_AVATAR } from "constants/serverUrl";
import i18n from "i18next";
import moment from "moment";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    boxShadow: "none",
  },
  coverImage: {
    padding: 5,
    width: "100%",
    marginBottom: 0,
    float: "left"
  },
  section: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0
    }
  },
  itemCaption: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    color: COLOR_NEWS,
    lineHeight: 1
  },
  itemContent: {
    fontWeight: 600,
  },
  itemLink: {
    textDecoration: "underline",
    marginRight: theme.spacing(2)
  },
  sponsorLogo: {
    width: "auto",
    height: 25,
    marginTop: 5,
    marginRight: theme.spacing(2)
  }
}));

export const PlayerProfile = ({ data }) => {
  const classes = useStyles();
  const avatarImage = data.playerAvatar ? `${PLAYER_MEDIA_URL}/${data.playerAvatar}` : USER_DEFAULT_AVATAR;
  const country = useSelector(state => state.countries.countriesById)[data.countryId];

  return (
    <Card className={classes.card}>
      <Grid container>
        <Grid item lg={4} md={12}>
          <img src={avatarImage}
            className={classes.coverImage}
            alt={`${data.firstName} ${data.lastName}`}
          />
        </Grid>
        <Grid item lg={8} md={12}>
          <Box px={2} py={1}>
            {!!data.birthday &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t("Birthday")}
                </Typography>
                <Typography className={classes.itemContent}>
                  {moment(data.birthday).format("LL")}
                </Typography>
              </Box>
            }
            {!!country &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t("Country")}
                </Typography>
                <Typography className={classes.itemContent}>
                  {country.name}
                </Typography>
              </Box>
            }
            {!!data.club &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t("Club")}
                </Typography>
                <Typography className={classes.itemContent}>
                  {data.club}
                </Typography>
              </Box>
            }
            {!!data.equipments?.length &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t('Equipment')}
                </Typography>
                <Box className={classes.itemContent}>
                  {data.equipments.map(item => (
                    <a href={item.storeUrl} key={item.id} target="_blank" rel="noreferrer" className={classes.itemLink}>
                      {item.label}
                    </a>
                  ))}
                </Box>
              </Box>
            }
            {!!data.websites?.length &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t('Websites')}
                </Typography>
                <Box className={classes.itemContent}>
                  {data.websites.map(item => (
                    <a href={item.path} key={item.id} target="_blank" rel="noreferrer" className={classes.itemLink}>
                      {item.label}
                    </a>
                  ))}
                </Box>
              </Box>
            }

            {!!data.sponsors?.length &&
              <Box className={classes.section}>
                <Typography className={classes.itemCaption}>
                  {i18n.t('Sponsors')}
                </Typography>
                <Box className={classes.itemContent}>
                  {data.sponsors.map(item => (
                    <img src={`${SPONSOR_MEDIA_URL}/${item.logo}`} key={item.id} className={classes.sponsorLogo} />
                  ))}
                </Box>
              </Box>
            }
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

