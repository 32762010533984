import {
  NewsfeedPage,
  NewsDetailPage,
  RankingsPage,
  PlayerDetailPage,
  PlayerProfileEdit,
  SearchPage,
  LiveAndUpcomingPage,
  ReplayEventPage,
  MasterClassPage,
  MasterClassDetailPage,
  EventPage,
  HomePage,
  TV247ChannelPage,
  PremiumPassPage,
  PremiumPreCheckoutPage
} from "./pages";
import i18n from "i18next";
import { COLOR_TV } from "constants/color";
import { currentSite } from "utils/site";

const URL_CLIENT = "/:lang/:sport";
const isDev = Boolean(window.location.hostname.match(/dev|localhost/));

export const kozoomSites = {
  global: isDev ? "https://dev.kozoom.com" : "https://home.kozoom.com",
  news: isDev ? "https://dev-news.kozoom.com" : "https://news.kozoom.com",
  tv: isDev ? "https://dev-tv.kozoom.com" : "https://tv.kozoom.com",
  game: isDev ? "https://dev-game.kozoom.com" : "https://game.kozoom.com",
  community: "https://community.kozoom.com",
  store: isDev
    ? "https://staging-store.kozoom.com"
    : "https://store.kozoom.com",
};

export const HeaderRoutes = [
  {
    domain: kozoomSites.global,
    path: URL_CLIENT,
    name: "global",
    type: "submenu",
    secondaryImage: "k--light",
    visible: true,
    favIcon: "Kglobal-favicon.svg",
    children: [
      {
        path: "",
        name: i18n.t("Home"),
        component: HomePage,
        visible: true,
      },
    ],
  },
  {
    domain: kozoomSites.tv,
    path: URL_CLIENT,
    name: "tv",
    type: "submenu",
    image: "kozoom-tv",
    secondaryImage: "k-tv-light",
    majorColor: COLOR_TV,
    visible: true,
    favIcon: "Ktv-favicon.svg",
    children: [
      {
        path: "/:lang/tv-channel",
        // For translation scanner
        tName: i18n.t("24/7 TV Channel"),
        name: "24/7 TV Channel",
        component: TV247ChannelPage,
        absolutePath: true,
      },
      {
        path: "/news",
        // For translation scanner
        tName: i18n.t("News"),
        name: "News",
        component: NewsfeedPage,
        favIcon: "Knews-favicon.svg",
      },
      {
        path: "/search",
        // For translation scanner
        tName: i18n.t("Search"),
        name: "Search",
        component: SearchPage,
        visible: false,
      },
      {
        path: "/news/:idOrSlug",
        name: i18n.t("News Detail"),
        tName: i18n.t("News Detail"),
        component: NewsDetailPage,
        visible: false,
        favIcon: "Knews-favicon.svg",
      },
      {
        path: "/rankings",
        // For translation scanner
        tName: i18n.t("Rankings"),
        name: "Rankings",
        component: RankingsPage,
        visible: false,
      },
      {
        path: "/rankings/:id",
        // For translation scanner
        tName: i18n.t("Rankings"),
        name: "Rankings",
        component: RankingsPage,
        visible: false,
      },
      {
        path: "/live",
        // For translation scanner
        tName: i18n.t("Live"),
        name: "Live",
        component: LiveAndUpcomingPage,
      },
      {
        path: "/replay",
        // For translation scanner
        tName: i18n.t("Replay"),
        name: "Replay",
        component: ReplayEventPage,
      },
      {
        path: "/masterclass",
        // For translation scanner
        tName: i18n.t("Masterclass"),
        name: "Masterclass",
        component: MasterClassPage,
        backgroundColor: "#02023C",
        visible: false,
      },
      {
        path: "/masterclass/:idOrSlug",
        // For translation scanner
        tName: i18n.t("Masterclass"),
        name: "Masterclass",
        component: MasterClassDetailPage,
        visible: false,
      },
      {
        path: "/:lang/event/:eventId",
        // For translation scanner
        tName: i18n.t("Event"),
        name: "Event",
        component: EventPage,
        visible: false,
        absolutePath: true,
        backgroundColor: "#02023C",
      },
      {
        path: "/:lang/player/:slug",
        // For translation scanner
        tName: i18n.t("Player Detail"),
        name: "Player Detail",
        component: PlayerDetailPage,
        absolutePath: true,
        visible: false,
      },
      {
        path: "/:lang/player/:id/edit",
        // For translation scanner
        tName: i18n.t("Edit Player Profile"),
        name: "Edit Player Profile",
        component: PlayerProfileEdit,
        absolutePath: true,
        visible: false,
      },
      {
        path: "/:lang/premium-pass",
        // For translation scanner
        tName: i18n.t("Premium Pass"),
        name: "Premium Pass",
        component: PremiumPassPage,
        visible: false,
        absolutePath: true,
      },
      {
        path: "/:lang/premium-pre-checkout",
        // For translation scanner
        tName: i18n.t("Premium Pre-checkout"),
        name: "Premium Pre-checkout",
        component: PremiumPreCheckoutPage,
        visible: false,
        absolutePath: true,
      },
    ],
  },
];

const getActiveRoute = () => {
  for (var i in HeaderRoutes) {
    const route = HeaderRoutes[i];
    if (currentSite === route.name) {
      return route;
    }
  }
  return null;
};

export const activeRoute = getActiveRoute();
