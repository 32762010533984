import {
  RESET_INSTRUCTIONS,
  GET_INSTRUCTIONS_START,
  GET_INSTRUCTIONS_FINISH,
  GET_INSTRUCTIONS_ERROR,
  UPLOAD_INSTRUCTION_START,
  UPLOAD_INSTRUCTION_FINISH,
  UPLOAD_INSTRUCTION_ERROR,
} from "store/actions";
const initialState = {
  loading: false,
  data: [],
  offset: -1,
  err: null,
  uploading: false,
  uploadErr: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case RESET_INSTRUCTIONS:
      newState.data = [];
      return newState;
    case GET_INSTRUCTIONS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_INSTRUCTIONS_FINISH:
      newState.loading = false;
      if (Array.isArray(action.payload)) {
        newState.data = [...newState.data].concat(action.payload);
        newState.offset = newState.data.length;
      }

      newState.err = null;
      return newState;
    case GET_INSTRUCTIONS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;

    case UPLOAD_INSTRUCTION_START:
      newState.uploading = true;
      newState.err = null;
      return newState;
    case UPLOAD_INSTRUCTION_FINISH:
      newState.uploading = false;
      newState.err = null;
      return newState;
    case UPLOAD_INSTRUCTION_ERROR:
      newState.uploading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
