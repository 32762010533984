const MiniMatchResultCardStyles = (theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    overflow: "hidden",
    display: "flex",
  },

  replayIconContainer: {
    width: 45,
    minWidth: 45,
    maxWidth: 45,
  },
  replayIcon: {
    border: "1px solid #2BA97C",
    borderTopLeftRadius: 16,
    borderBottomLeftRadius: 16,
    background: "#01023c",
    color: "white",
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 10,
  },
  detailSection: {
    border: "1px solid #a3a0a0",
    display: "flex",
    width: "calc(100% - 45px)",
  },
  player: {
    width: "50%",
    display: "flex",
    alignItems: "center",
  },
  player1: {
    backgroundColor: theme.palette.grayLight,
  },
  player2: {
    backgroundColor: "#e6aa2c",
  },
  player_name: {
    width: "calc(100% - 42px)",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  iconChampion: {
    fontSize: 16,
  },
  flag: {
    width: 32,
    height: 32,
  },
  score: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

export default MiniMatchResultCardStyles;
