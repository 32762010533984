import {
  GET_MASTERCLASS_PLAYERS_START,
  GET_MASTERCLASS_PLAYERS_FINISH,
  GET_MASTERCLASS_PLAYERS_ERROR,
  GET_MASTERCLASS_EVENTS_START,
  GET_MASTERCLASS_EVENTS_FINISH,
  GET_MASTERCLASS_EVENTS_ERROR,
  GET_MASTERCLASS_EVENTS_RESET,
} from "store/actions";

const initialState = {
  events: {
    loading: false,
    error: false,
    hasMore: false,
    data: [],
  },
  players: {
    loading: false,
    error: false,
    data: [],
  },
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_MASTERCLASS_PLAYERS_START:
      newState.players.loading = true;
      newState.players.error = false;
      return newState;
    case GET_MASTERCLASS_PLAYERS_FINISH:
      newState.players.loading = false;
      if (action.payload.data && Array.isArray(action.payload.data))
        newState.players.data = action.payload.data;
      newState.players.error = false;
      return newState;
    case GET_MASTERCLASS_PLAYERS_ERROR:
      newState.players.loading = true;
      newState.players.error = true;
      return newState;
    case GET_MASTERCLASS_EVENTS_START:
      newState.events.loading = true;
      newState.events.error = false;
      return newState;
    case GET_MASTERCLASS_EVENTS_FINISH:
      newState.events.loading = false;
      if (Array.isArray(action.payload)) {
        newState.events.data = [...newState.events.data, ...action.payload];
        if (action.payload.length > 0) {
          newState.events.hasMore = true;
        } else {
          newState.events.hasMore = false;
        }
      } else {
        newState.events.hasMore = false;
      }
      newState.events.error = false;
      return newState;
    case GET_MASTERCLASS_EVENTS_RESET:
      newState.events.data = [];
      newState.events.hasMore = false;
      return newState;
    case GET_MASTERCLASS_EVENTS_ERROR:
      newState.events.loading = true;
      newState.events.error = true;
      return newState;
    default:
      return newState;
  }
}
