import React from "react";
import { Container } from "@mui/material";
import KozoomVideoPlayer from "lib/kozoom-video-player";
import { SERVER_URL } from "constants/serverUrl";

class MasterClassDetailPage extends React.Component {
  render() {
    const url = "http://d2zihajmogu5jn.cloudfront.net/elephantsdream/ed_hd.mp4";

    return (
      <Container>
        <KozoomVideoPlayer
          url={url}
        />
      </Container>
    );
  }
}

export default MasterClassDetailPage;