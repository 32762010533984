import React from "react";
import { Typography, Box } from "@mui/material";
import { UPLOADED_MEDIA_URL } from "constants/serverUrl";
import i18n from "i18next";
import { activeRoute, kozoomSites } from "routes.client";
import { siteLangCode, siteSportSlug } from "utils/site";
import { Link } from "react-router-dom";

const newsSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/News.svg`;
const tvSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/tv.svg`;
const storeSVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/store.svg`;
const communitySVG = `${UPLOADED_MEDIA_URL}/assets/static/icons/Community.svg`;

export const SiteMap = ({ classes }) => {
  return (
    <Box my={2}>
      <a href="https://kozoom.com">
        <img alt="logo" src="https://b-cdn.kozoom.com/assets/static/images/logos/kozoom-tm-light.png" width="170" height="31" />
      </a>
      <Typography className={classes.siteSlogan}>
        {i18n.t("Since 1998")},<br />
        {i18n.t("Active promoter of Billiard Sports")}
      </Typography>
      <Typography className={classes.title}>
        {i18n.t("Visit our sites")}
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        maxWidth={200}
      >
        {activeRoute.name !== 'tv' &&
          <a href={`${kozoomSites.tv}/${siteLangCode}/${siteSportSlug}/news`} rel="noopener noreferrer" target="_blank">
            <img alt="logo" src={newsSVG} height={50} />
          </a>
        }
        {activeRoute.name === 'tv' &&
          <Link to={`/${siteLangCode}/${siteSportSlug}/news`}>
            <img alt="logo" src={newsSVG} height={50} />
          </Link>
        }
        <a
          href={activeRoute.name !== 'tv' ? `${kozoomSites.tv}/${siteLangCode}/${siteSportSlug}/live` : null}
          rel="noopener noreferrer" target="_blank"
        >
          <img alt="logo" src={tvSVG} height={50} />
        </a>
        <a href={`${kozoomSites.store}/${siteLangCode}`} rel="noopener noreferrer" target="_blank">
          <img alt="logo" src={storeSVG} height={50} />
        </a>
        <a href={`${kozoomSites.community}`} rel="noopener noreferrer" target="_blank">
          <img alt="logo" src={communitySVG} height={50} />
        </a>
      </Box>
    </Box>
  );
};
