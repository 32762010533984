import React from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import KozoomVideoPlayer from "lib/kozoom-video-player";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { socketIo } from "socket";
import { updateVideoLastPlayedTime } from "store/actions";
import { useVideoPoster } from "../hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
  },
  content: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0
  },
  defaultBanner: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "top",
  },
}));

const ReplayVideo = ({
  video,
  nextBtnDisabled,
  prevBtnDisabled,
  onNext,
  onPrev,
  defaultBanner
}) => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const url = useMemo(() => {
    if (!video) {
      return '';
    }
    return `${video.baseVideoUrl}?session=${socketIo.id}&mag_id=NEW`;
  }, [video]);

  const handleProgressUpdate = useCallback((time) => {
    if (!video.isLivestreamRecord) {
      dispatch(updateVideoLastPlayedTime(video.id, time));
    }
  }, [dispatch, video]);

  const videoPoster = useVideoPoster(video);

  if (!video) {
    return (
      <Box className={classes.root} tabIndex="0">
        <div className={classes.content}>
          <img
            src={defaultBanner}
            alt="Live Video"
            className={classes.defaultBanner}
          />
        </div>
      </Box>
    );
  }

  return (
    <Box className={classes.root} tabIndex="0">
      <div className={classes.content}>
        <KozoomVideoPlayer
          url={url}
          nextBtnDisabled={nextBtnDisabled}
          prevBtnDisabled={prevBtnDisabled}
          onNext={onNext}
          onPrev={onPrev}
          initialTime={Number(video.lastPlayed)}
          autoplay={true}
          poster={videoPoster}
          onProgressUpdate={handleProgressUpdate}
        />
      </div>
    </Box>
  );
};

ReplayVideo.propTypes = {
  urls: PropTypes.arrayOf(PropTypes.string),
  playlistIndex: PropTypes.number,
  playlistIndexUpdate: PropTypes.func,
  defaultBanner: PropTypes.string,
};

export default ReplayVideo;
