import {
  RESET_RANKINGS,
  GET_RANKINGS_START,
  GET_RANKINGS_FINISH,
  GET_RANKINGS_ERROR,
} from "store/actions";
const initialState = {
  loading: true,
  data: [],
  dataW: [],
  dataN: [],
  dataC: [],
  total: 0,
  category: null,
  offset: 0,
  err: null,
};

export default function (state = initialState, action) {
  let isFirstPage, data, category;
  let newState = Object.assign({}, state);
  let dataVar;
  switch (action.type) {
    case RESET_RANKINGS:
      newState.data = [];
      newState.dataW = [];
      newState.dataN = [];
      newState.dataC = [];
      return newState;
    case GET_RANKINGS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_RANKINGS_FINISH:
      isFirstPage = action.payload.isFirstPage;
      data = action.payload.data;
      category = action.payload.category;
      newState.loading = false;
      if (category === "World") {
        dataVar = "dataW";
      } else if (category === "Continental") {
        dataVar = "dataC";
      } else if (category === "National") {
        dataVar = "dataN";
      } else {
        dataVar = "data";
      }
      if (isFirstPage) {
        newState[dataVar] = [];
      }
      newState.total = data.length;
      for (let item of data) {
        const existed = !!newState[dataVar].find(
          (i) => i.event_id === item.event_id
        );
        if (!existed) newState[dataVar].push(item);
      }

      newState.err = null;
      return newState;
    case GET_RANKINGS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
