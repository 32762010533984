const ReviveDefaultBannerStyles = (theme) => ({
  root: {
    margin: "auto",
    marginTop: 12,
    marginBottom: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ReviveDefaultBannerStyles;
