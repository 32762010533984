import React, { useCallback, useEffect, useState } from "react";
import DocumentTitle from "react-document-title";
import { Typography, Grid, Container, Box, Hidden } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadMoreSection,
  NewsCard,
  TopNewsFeedBanner,
  ReviveVerticalBanner,
  ReviveInfeedBanner,
  VideoReplayCard,
  NewsSocialWidget,
} from "components";
import { getFeeds } from "store/actions/feed";
import { useStyles } from "./styles";
import i18n from "i18next";
import { siteLangCode } from "utils/site";
import { useHistory } from "react-router-dom";

export const NewsfeedPage = () => {
  const feed = useSelector(state => state.feed);
  const [data, setData] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const loadMore = useCallback(() => {
    const entities = [];
    if (feed.hasMoreNews) {
      entities.push("news");
    }
    if (feed.hasMoreVideos) {
      entities.push("video");
    }
    dispatch(getFeeds(
      entities.join(","),
      loadMore ? feed.newsCount : 0,
      loadMore ? feed.videosCount : 0,
    ));
  }, [feed, dispatch]);

  useEffect(() => {
    dispatch(getFeeds(
      "news,video",
      0,
      0
    ));
  }, [dispatch]);

  useEffect(() => {
    const data = [];
    let count = 0;
    if (Array.isArray(feed.data)) {
      feed.data.forEach((elem) => {
        data.push(elem);
        count++;
        // Push ad banners
        if (count === 10) {
          data.push(null);
          count = 0;
        }
      });
    }
    setData(data);
    setTimeout(() => {
      if (window.FB) {
        window.FB?.XFBML?.parse();
      }
    }, 200);
  }, [feed.data]);

  return (
    <DocumentTitle title={i18n.t("Kozoom News")}>
      <Container>
        <Box mb={2}>
          <Hidden mdDown>
            <TopNewsFeedBanner />
            <Typography className={classes.title}>{i18n.t("News Feed")}</Typography>
          </Hidden>
          <Grid container spacing={2}>
            <Grid item lg={9} xs={12}>
              {data.map((item, index) => {
                if (!item) {
                  return <Box key={`ad-${index}`} my={2}><ReviveInfeedBanner /></Box>;
                }
                if (item.isHighlighted)
                  return (
                    <Box my={2} key={`highlighted-card-${item.newsId}`}>
                      <NewsSocialWidget
                        news={item}
                        showLocation={false}
                      />
                    </Box>
                  );
                if (item.newsId) {
                  return (
                    <Box my={2} key={`news-card-${item.newsId}`}>
                      <NewsCard
                        news={item}
                        showLocation={false}
                      />
                    </Box>
                  );
                }
                if (item.videoId) {
                  return (
                    <Box my={2} key={`video-card-${item.videoId}`}>
                      <VideoReplayCard
                        video={item}
                        onClick={() => history.push(`/${siteLangCode}/event/${item.eventId}?videoId=${item.videoId}`)}
                      />
                    </Box>
                  );
                }
              })}
              {(feed.hasMoreNews || feed.hasMoreVideos) &&
                <LoadMoreSection
                  automatic
                  label="More"
                  loading={feed.loading}
                  onLoadMore={loadMore}
                />
              }
            </Grid>
            <Grid item lg={3} xs={12}>
              <Hidden lgDown>
                <ReviveVerticalBanner />
              </Hidden>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </DocumentTitle>
  )
}

