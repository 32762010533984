import { makeStyles } from "@mui/styles";
import {
  COLOR_NEWS,
  COLOR_TV,
  COLOR_COMMUNITY,
  COLOR_STORE,
  COLOR_GAME,
} from "constants/color";
export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  galleryItem: {
    paddingTop: "56.25%",
    position: "relative",
    backgroundSize: "100%;",
    backgroundPosition: "center",
    borderRadius: 5,
    transition: "background-size 200ms ease-in-out",
    border: "1px solid #ccc",
    overflow: "hidden",
    "&:hover": {
      backgroundSize: "105%",
    },
  },
  overlay: {
    "&:before": {
      position: "absolute",
      content: "\"\"",
      top: "60%",
      left: 0,
      right: 0,
      bottom: 0,
      backgroundImage: "linear-gradient(rgba(2, 2, 60, 0), rgba(2, 2, 60, 1))",
    },
  },
  promotionItem: {
    paddingTop: "calc(28.125% - 6px)",
    "&:before": {
      display: "none",
    },
  },
  gameIcon: {
    position: "absolute",
    top: 10,
    left: 10,
  },
  storeTag: {
    backgroundColor: COLOR_STORE,
    display: "flex",
    alignItems: "center",
    color: "white",
    whiteSpace: "nowrap",
    fontSize: 15,
    lineHeight: "20px",
    padding: "5px 8px",
    borderRadius: 3,
    width: "fit-content",
  },
  galleryTitle: {
    position: "absolute",
    bottom: 10,
    left: 10,
    right: 10,
    display: "flex",
    alignItems: "center",
    "& h4": {
      color: "#fff",
      margin: 0,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.15,
    },
  },
  "@keyframes pulsing": {
    to: { boxShadow: "0 0 0 12px rgba(255, 0, 0, 0)" },
  },
  liveText: {
    color: "red",
    marginRight: 12,
  },
  live: {
    width: 20,
    height: 20,
    position: "relative",
    top: 2,
    display: "inline-block",
    marginRight: 8,
    boxShadow: "0 0 0 0 rgba(232, 76, 61, 0.7)",
    borderRadius: "50%",
    backgroundColor: "#FF0000",
    WebkitAnimation: "$pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)",
    MozAnimation: "$pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)",
    msAnimation: "$pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)",
    animation: "$pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1)",
    transition: "all 300ms ease-in-out",
  },
  headerText: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 30,
    opacity: 0.7,
    color: "#000000",
    letterSpacing: 0,
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  errorText: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 30,
    opacity: 0.7,
    color: "#000000",
    letterSpacing: 0,
    font: 18,
  },
  news: {
    backgroundColor: COLOR_NEWS,
  },
  tv: {
    backgroundColor: COLOR_TV,
  },
  game: {
    backgroundColor: COLOR_GAME,
  },
  community: {
    backgroundColor: COLOR_COMMUNITY,
  },
  store: {
    backgroundColor: COLOR_STORE,
  },
  loading: {
    textAlign: "center",
    marginBottom: 20,
  },
}));
