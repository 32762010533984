import {
  GET_FEED_START,
  GET_FEED_FINISH,
  GET_FEED_ERROR,
} from "store/actions";

export const initialState = {
  loading: false,
  data: [],
  newsCount: 0,
  videosCount: 0,
  hasMoreNews: true,
  hasMoreVideos: true,
  err: null,
  limit: 10,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FEED_START:
      return {
        ...state,
        data: action.payload.firstLoad ? [] : state.data,
        loading: true,
        err: null
      };
    case GET_FEED_FINISH: {
      const hasMoreNews = action.payload.data.filter(item => item.newsId).length >= initialState.limit;
      const hasMoreVideos = action.payload.data.filter(item => item.videoId).length >= initialState.limit;
      const data = [...state.data.concat(action.payload.data).sort((a, b) => (
        (new Date(a.publishedDate)) > (new Date(b.publishedDate)) ? -1 : 1
      ))];
      return {
        data,
        newsCount: data.filter(item => item.newsId).length,
        videosCount: data.filter(item => item.videoId).length,
        hasMoreNews,
        hasMoreVideos,
        loading: false,
        err: null
      };
    }
    case GET_FEED_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload
      };
    default:
      return state;
  }
}
