import numeral from "numeral";

export const getFlagUrlByCio = (cio) => {
  return `https://b-cdn.kozoom.com//flags/1x1/cio/${cio}.svg`;
};


/**
 * Find Element From Object Array By Id
 * @param {array} data
 * @param {number} id
 * @param {string} key optional id field
 *
 * @returns {Object}
 */
export const findItemById = (data, id, key = "") => {
  let res = null;
  for (var item of data) {
    let idKey = "id";
    if (key !== "") idKey = key;
    if (item[idKey] === id) {
      res = item;
      break;
    }
  }
  return res;
};


export const copyStringToClipboard = (str) => {
  // Create new element
  var el = document.createElement("textarea");
  // Set value (string to be copied)
  el.value = str;
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute("readonly", "");
  el.style = {
    position: "absolute",
    left: "-9999px",
  };
  document.body.appendChild(el);
  // Select text inside element
  el.select();
  // Copy text to clipboard
  document.execCommand("copy");
  // Remove temporary element
  document.body.removeChild(el);
};

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {
    type: "image/png",
  });
};

export const getGameName = (games, gameId) => {
  if (games.listById && games.listById[gameId]) {
    return games.listById[gameId].name;
  }
  return "";
};

export const abbreviateNumber = (number) => {
  const num = Number(number);
  return num > 1000 ? numeral(num).format("0.0a") : num;
}
