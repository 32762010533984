import React from "react";
import { Grid, Box } from "@mui/material";
import { LoadMoreSection, VideoCard } from "components";
import { useVideoSearch } from "./hooks";
import i18n from "i18next";

export const VideoSearch = () => {
  const {
    videos,
    loading,
    hasMore,
    onLoadMore,
    onClickVideo
  } = useVideoSearch();

  return (
    <>
      <Grid container rowSpacing={0} columnSpacing={2}>
        {videos.map((video, index) => (
          <Grid key={index} item xs={12} md={6}>
            <VideoCard
              video={video}
              isPlaying={false}
              onClick={() => onClickVideo(video)}
            />
          </Grid>
        ))}
      </Grid>
      {hasMore &&
        <LoadMoreSection
          loading={loading}
          onLoadMore={onLoadMore}
        />
      }
      {!loading && !videos.length &&
        <Box my={3} textAlign="center">
          {i18n.t('No videos found')}
        </Box>
      }
    </>
  );
};

