import { COLOR_NEWS } from "constants/color";

const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles((theme) => ({
  playerName: {
    marginLeft: 12,
    fontSize: 24,
    fontWeight: 600
  },
  starIcon: {
    marginLeft: 8,
    fontSize: 28,
    color: COLOR_NEWS,
    verticalAlign: "sub"
  },
  country: {
    width: 42,
    height: 42,
  },
  tabContainer: {
    borderBottom: "2px solid #E51135",
  },
  tabButton: {
    fontWeight: 600,
    border: "none",
    minWidth: 180,
    color: theme.palette.dark,
    textTransform: "uppercase"
  },
  tabIndicator: {
    position: "relative",
    backgroundColor: "transparent!important",
    "&::after": {
      position: "absolute",
      content: "''",
      width: 0,
      height: 0,
      bottom: 0,
      left: 75,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderBottom: "5px solid #E51135",
    },
  },
  tabContent: {
    maxHeight: 600,
    overflow: "auto",
  }
}));