import {
  GET_EVENT_FEEDS_START,
  GET_EVENT_FEEDS_FINISH,
  GET_EVENT_FEEDS_ERROR,
} from "store/actions";
const initialState = {
  loading: false,
  data: [],
  err: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_FEEDS_START:
      return {
        ...initialState,
        loading: true
      };
    case GET_EVENT_FEEDS_FINISH:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case GET_EVENT_FEEDS_ERROR:
      return {
        ...state,
        loading: false,
        err: action.payload
      };
    default:
      return state;
  }
};
