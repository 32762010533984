import React from "react";
import { Box, IconButton } from "@mui/material";
import Slider from "react-slick";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MasterPlayer } from "components";
import "./carousel.css";

const CarouselNextArrow = ({ className, onClick }) => (
  <IconButton className={className} onClick={onClick} size="large">
    <ChevronRightIcon className="carousel-arrow-icon" />
  </IconButton>
);

const CarouselPrevArrow = ({ className, onClick }) => (
  <IconButton className={className} onClick={onClick} size="large">
    <ChevronLeftIcon className="carousel-arrow-icon" />
  </IconButton>
);

const PlayersCarousel = ({ players, selected = null, onSelect }) => {
  const option = {
    dots: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <CarouselNextArrow className="slick-arrow slick-next-arrow" />,
    prevArrow: <CarouselPrevArrow className="slick-arrow slick-prev-arrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box>
      <Slider {...option}>
        {players.map((player, index) => (
          <MasterPlayer
            key={index}
            player={player}
            selected={selected === player.id}
            onClick={() => onSelect(player.id)}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default PlayersCarousel;
