export const SubNavigatorStyles = (theme) => ({
  appBar: {
    boxShadow: "0 1px 8px rgba(0,0,0,.3)",
    position: "relative",
    paddingTop: 2,
    paddingBottom: 2,
  },
  subNavContainer: {
    marginLeft: theme.spacing(3.5),
    marginRight: theme.spacing(3.5),
    textTransform: "uppercase",
  },
  subNav: {
    fontSize: 14,
    fontWeight: 800,
  },
  active: {
    position: "relative",
    "&::before": {
      position: "absolute",
      content: "''",
      background: `url(${"https://b-cdn.kozoom.com/assets"}/static/icons/rounded-triangle-indicator.svg)`,
      width: 20,
      height: 8,
      bottom: -10,
      left: 0,
      right: 0,
      margin: "auto",
      filter: "invert(1)",
    },
  },
});
