import React from "react";
import { Grid, Box, Hidden } from "@mui/material";
import { Spinner, ReviveVerticalBanner } from "components";
import {
  EventHeader,
  NewsPanel,
  RelatedDropdown,
  NoLiveStreamsPlaceholder,
  NoneWatchablePlaceholder
} from "./components";
import {
  useEventFeeds,
  useStyles,
  useEventBanner,
  useWatchingGuard
} from "./hooks";
import MobileBanner from "components/Revive/InFeed/mobile";
import { siteLangCode } from "utils/site";
import moment from "moment";

const Upcoming = ({ event }) => {
  const classes = useStyles();
  const { feeds, loading } = useEventFeeds(event.id);
  const defaultBanner = useEventBanner(event);
  const { watchable, loginRequired, userLoading, premiumRequired } = useWatchingGuard(event);


  const onChangeVideo = (index) => {
    window.scrollTo(0, 40);
    history.push(`/${siteLangCode}/event/${event.id}?videoId=${index}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" py={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
        <EventHeader
          event={event}
          status="upcoming"
        />
        <Box mb={2}>
          {!watchable ?
            (
              <NoneWatchablePlaceholder
                subscriptionPlanId={event.subscriptionPlanId}
                defaultBanner={defaultBanner}
                loginRequired={loginRequired}
                userLoading={userLoading}
                premiumRequired={premiumRequired} />
            )
            : moment(event.startTime).diff(moment(), 'hours') > 3 ? (
              <NoneWatchablePlaceholder
                defaultBanner={defaultBanner}
                loginRequired={false}
                premiumRequired={false} />
            ) : (
              <NoLiveStreamsPlaceholder event={event} />
            )
          }

        </Box>
        {!!feeds.length &&
          <NewsPanel
            loading={loading}
            feeds={feeds}
            onPlayVideo={onChangeVideo}
          />
        }
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <RelatedDropdown />
        <Hidden mdDown>
          <ReviveVerticalBanner />
        </Hidden>
        <Hidden mdUp>
          <MobileBanner />
        </Hidden>
      </Grid>
    </Grid>
  );
};

export default Upcoming;
