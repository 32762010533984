import {
  GET_RELATED_EVENTS_START,
  GET_RELATED_EVENTS_FINISH,
  GET_RELATED_EVENTS_ERROR,
} from "store/actions";
const initialState = {
  loading: false,
  data: [],
  err: null,
};

export default function (state = initialState, action) {
  let newState = Object.assign({}, state);
  switch (action.type) {
    case GET_RELATED_EVENTS_START:
      newState.loading = true;
      newState.err = null;
      return newState;
    case GET_RELATED_EVENTS_FINISH:
      newState.loading = false;
      newState.data = action.payload;
      newState.err = null;
      return newState;
    case GET_RELATED_EVENTS_ERROR:
      newState.loading = false;
      newState.err = action.payload;
      return newState;
    default:
      return newState;
  }
}
