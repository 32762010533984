import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { COLOR_TV } from "constants/color";

const useStyles = makeStyles((theme) => ({
  badge: (props) => ({
    display: "flex",
    alignItems: "center",
    color: "white",
    whiteSpace: "nowrap",
    marginRight: props.noMarginRight ? 0 : theme.spacing(1),
    fontSize: 15,
    lineHeight: "20px",
    padding: "5px 8px",
    borderRadius: 3,
    width: "fit-content",
    backgroundColor: props.bgColor
  }),
  icon: {
    width: 20,
    height: 20,
    filter: "invert(1)",
    marginRight: 5,
    verticalAlign: "bottom",
  }
}));

const GameBadge = ({ gameId, bgColor = COLOR_TV, noMarginRight }) => {
  const classes = useStyles({
    bgColor,
    noMarginRight
  });
  const games = useSelector((state) => state.games);
  const game = games.listById[gameId];
  if (!game) {
    return null;
  }
  return (
    <Typography
      variant="body1"
      className={classes.badge}
    >
      {game.icon &&
        <img src={game.icon} alt="Game Icon" className={classes.icon} />
      }
      {game.name}
    </Typography>
  );
};

GameBadge.propTypes = {
  gameId: PropTypes.number,
  bgColor: PropTypes.string,
  noMarginRight: PropTypes.bool,
};

export { GameBadge };