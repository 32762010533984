import React, { useCallback } from "react";
import { Alert, AlertTitle, Box, Paper, Button, Container, Grid, Hidden, Typography } from "@mui/material";
import axios from "axios";
import {
  Comments, EventLink, GameBadge, HashtagsSection,
  ReviveDefaultBanner, ShareSection, Spinner,
  ConfirmationDialog
} from "components";
import { SERVER_URL, UPLOADED_MEDIA_URL } from "constants/serverUrl";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStyles } from "./styles";
import { useWindowSize } from "utils/hooks";
import { siteLangCode } from "utils/site";
import { formatDate } from "utils/time";
import { RelatedNews } from "./RelatedNews";
import { LoadingButton } from "@mui/lab";
import { authorizationHeader } from "auth";
import i18next from "i18next";


export const NewsDetailPage = () => {
  const classes = useStyles();
  const { idOrSlug } = useParams();
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [communityLoading, setCommunityLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios.get(`${SERVER_URL}/news/${idOrSlug}`, { params: { lang: siteLangCode } }).then(res => {
      setData(res.data);
      setLoading(false);
    }).catch(err => {
      setError(err.message);
      setLoading(false);
    })
  }, [idOrSlug, dispatch]);

  const { parsedContent, thumbnail } = useNewsContent(data);

  const windowSize = useWindowSize();

  const createCommunityTopic = useCallback(() => {
    setOpenDialog(false);
    if (data) {
      setCommunityLoading(true);
      axios.get(`${SERVER_URL}/newsTopic`, { params: { lang: siteLangCode, newsId: data.id }, ...authorizationHeader() }).then(res => {
        const { topicLink } = res.data;
        setCommunityLoading(false);
        setData({
          ...data,
          communityTopicLink: topicLink
        });
        if (topicLink) {
          window.open(topicLink, "_blank");
        }
      }).catch(err => {
        setCommunityLoading(false);
      });
    }
  }, [data]);

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" margin={5}>
        <Spinner />
      </Box>
    )
  }
  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>{i18n.t("Error")}</AlertTitle>
        {error}
      </Alert>
    )
  }

  if (!data) {
    return null;
  }

  const docTitle = data.title || "Kozoom News";
  return (
    <Container disableGutters={windowSize.width < 900}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{docTitle}</title>
        <meta property="og:title" content={docTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={thumbnail} />
        <meta property="og:url" content={location.href} />
        <meta name="twitter:card" content={thumbnail} />
      </Helmet>
      <Hidden mdDown>
        <ReviveDefaultBanner />
      </Hidden>

      {!!data && (
        <Grid container spacing={3} mb={2}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Paper>
              <Box display="flex" alignItems="center" p={2}>
                <GameBadge gameId={Number(data.gameId)} isEventLive={data.isEventLive} />
                <EventLink eventId={Number(data.eventId)} eventTypeName={data.eventTypeName} city={data.city} countryId={data.countryId} />
              </Box >
              <Box px={2}>
                <Typography variant="h4" className={classes.title}>
                  {data.title}
                </Typography>
                <Box mt={2}>
                  <div className={classes.metadata}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.date}
                    >
                      {formatDate(data.publishedDate, "L", true, 7)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" className={classes.authorName} >
                      <span>{i18n.t("Published by")} </span>
                      <span style={{ textTransform: "capitalize" }}>
                        {typeof data.authorName === "string"
                          ? data.authorName.toLowerCase()
                          : ""}
                      </span>
                    </Typography>
                  </div>
                  <ShareSection commentCount={0} />
                </Box>
              </Box>
              <Box display="flex" flexDirection="column">
                <Box position="relative">
                  <img
                    alt="thumbnail"
                    src={data.thumbnail || thumbnail}
                    className={classes.thumbnail}
                  />
                  {!!data.credit &&
                    <div className={classes.credit}>© {data.credit}</div>
                  }
                </Box>
                {!!data.legend &&
                  <div className={classes.legend}>{data.legend}</div>
                }
              </Box>
              <Box p={2}>
                <Box className={classes.content}>
                  {parsedContent}
                </Box>
                {data.hashtags &&
                  <Box mt={2}>
                    <HashtagsSection hashtags={data.hashtags} />
                  </Box>
                }
                {!!auth.userInfo &&
                  <>
                    {!!data.communityTopicLink &&
                      <Box my={2}>
                        <Typography fontSize={20} fontWeight={600} marginRight={2} variant="span">{i18n.t('Join the discussion about this news in')}</Typography>
                        <Button color="community" variant="contained" href={data.communityTopicLink} target="_blank">Kozoom Community</Button>
                      </Box>
                    }
                    {!data.communityTopicLink &&
                      <Box my={2}>
                        <Typography fontSize={20} fontWeight={600} marginRight={2} variant="span">{i18n.t('Open the discussion in')}</Typography>
                        <LoadingButton color="community" variant="contained" loading={communityLoading} onClick={() => setOpenDialog(true)}>Kozoom Community</LoadingButton>
                      </Box>
                    }
                    <Typography fontSize={20} fontWeight={600} marginRight={2}>{i18n.t('Or post your comment here')}</Typography>
                  </>
                }
                <Box mt={2}>
                  <Comments entityId={data.id} entity="news" showHeading={!auth.userInfo} />
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <RelatedNews id={data.id} eventId={data.eventId} />
          </Grid>
        </Grid>
      )}
      <ConfirmationDialog
        open={openDialog}
        title={i18next.t("Notice")}
        message={i18next.t("Click below to confirm publication of this article in our forum Kozoom Community, then post your first comment to open the discussion.")}
        onAgree={createCommunityTopic}
        onCancel={() => setOpenDialog(false)}
      />
    </Container>
  );
};

const useNewsContent = (data) => {
  if (!data) {
    return {
      thumbnail: null,
      parsedContent: null,
    }
  }
  let { text, thumbnail } = data;

  const parsedContent = ReactHtmlParser(text ? text : "", {
    transform: (node) => {
      if (node.type === "tag" && node.name === "img") {
        if (
          !node.attribs.src.includes("https://") &&
          !node.attribs.src.includes("http://")
        )
          node.attribs.src = `${UPLOADED_MEDIA_URL}/${node.attribs.src}`;
      }
      if (
        !thumbnail &&
        node.type === "tag" &&
        node.name === "img"
      ) {
        thumbnail = node.attribs.src;
        return null;
      }
    },
  });
  return {
    thumbnail: thumbnail,
    parsedContent,
  };
};
