import {
  ADD_BANNED_USER,
  GET_BANNED_USERS,
  PLAY_OUT,
  REMOVE_BANNED_USER,
  UPDATE_EVENT_ROOM_USER_COUNT,
} from "store/actions/live";

const initialState = {
  liveViewers: {},
  bannedUsers: [],
  playOut: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_EVENT_ROOM_USER_COUNT:
      return {
        ...state,
        liveViewers: {
          ...state.liveViewers,
          ...action.payload
        }
      };
    case GET_BANNED_USERS:
      return {
        ...state,
        bannedUsers: action.payload
      }
    case ADD_BANNED_USER:
      return {
        ...state,
        bannedUsers: [
          ...state.bannedUsers,
          action.payload
        ]
      };
    case REMOVE_BANNED_USER:
      return {
        ...state,
        bannedUsers: state.bannedUsers.filter(user => user.id !== action.payload.id)
      };
    case PLAY_OUT:
      return {
        ...state,
        playOut: true
      };
    default:
      return state;
  }
}
