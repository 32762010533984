import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import KozoomVideoPlayer from "lib/kozoom-video-player";

const LiveVideo = ({ url, numbTable, defaultBanner, table, setTable }) => {
  const onNext = useCallback(() => {
    setTable(table + 1)
  }, [setTable, table]);
  const onPrev = useCallback(() => {
    setTable(table - 1)
  }, [setTable, table]);
  return (
    <Box tabIndex="0">
      <KozoomVideoPlayer
        url={url}
        nextBtnDisabled={table >= numbTable - 1}
        prevBtnDisabled={table === 0}
        onNext={onNext}
        onPrev={onPrev}
        poster={defaultBanner}
        isLive={true}
        autoplay={true}
      />
    </Box>
  );
};

LiveVideo.propTypes = {
  url: PropTypes.string,
  numbTable: PropTypes.number,
  defaultBanner: PropTypes.string,
  table: PropTypes.number,
  setTable: PropTypes.func,
};

export default LiveVideo;
